
import { Box, Typography, Button, FormControl, Select, MenuItem, InputLabel, TextField, Container, FormControlLabel } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useNavigate, NavLink, } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Grid from "@mui/material/Grid";
import TitleHead from '../../components/TitleHead';
import SearchIcon from '@mui/icons-material/Search';
import GridField from '../../components/GridTextField';
import React, { useState, useEffect, useContext } from 'react';
import Checkbox from '@mui/material/Checkbox';
import api from '../../api/api';

import Swal from 'sweetalert2'
import withReactContent from "sweetalert2-react-content";
import { Formik } from 'formik';
import * as Yup from 'yup';
import ApiContext from '../../context/ApiProvider';
export default function Addformation() {
    const { getPersonalTitle, getPersonalProvince, getPersonalDistrict, getPersonalSubDistrict, getCurPersonalDistrict, getCurPersonalSubDistrict, getPersonalDetail } = useContext(ApiContext)
    const [title, setTitle] = useState([])
    useEffect(() => {
        getPersonalTitle(setTitle)
    }, [getPersonalTitle, setTitle])
    return (
        <>
            <Container maxWidth='xl' sx={{ height: 'auto', marginTop: '40px' }}>
                <TitleHead title='เพิ่มข้อมูลวิทยาการ' subTitle='ข้อมูลทะเบียนประวัติส่วนบุคคล'>
                </TitleHead>
                <Box sx={{ display: 'flex', color: '#000', marginTop: '50px', }}>
                    <Typography variant='h5'>เพิ่มข้อมูลส่วนบุคคล</Typography>
                </Box>
                <Grid container spacing={2} sx={{ marginTop: '15px', }}>
                    <Grid item lg={12} md={6} xs={12}>
                        <Grid container>
                            <Grid item md={2} xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: { md: 'flex-end', sm: 'flex-start' } }}>
                                <Typography sx={{ marginRight: '15px', }}>ประกาศนียบัตร</Typography>
                            </Grid>
                            <Grid item md={10} xs={12}><TextField size='small' name="czAddress" placeholder="ข้อมูลการรับประกาศนียบัตรชั้นสูงกิตติมศักดิ์" label="" variant="outlined" color="success" sx={{ width: '100%' }} /></Grid>


                        </Grid>

                    </Grid>
                    <GridField text='คำนำหน้า'>
                        <FormControl

                            required

                            sx={{
                                width: "100%",
                                display: "flex",
                                justifyItems: "center",
                            }}
                            variant="outlined"
                            color="success"

                        >


                            <Select

                                displayEmpty
                                size='small'
                                inputProps={{ "aria-label": "Without label" }}
                                name='titleID'
                                
                                // value={titleid}
                                // placeholder='fff'
                             
                               

                            >

                                <MenuItem value="" >
                                    <em>กรุณาเลือกคำนำหน้าชื่อ</em>
                                </MenuItem>
                                {title?.map((x, i) => <MenuItem key={i} value={x?.mt_titleID}>{x?.mt_titleNameTH}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </GridField>
                    <GridField text='ชื่อ'>
                        <TextField name="czMoo" size='small' label="" variant="outlined" placeholder="ชื่อ" color="success" sx={{ width: '100%' }} />
                    </GridField>
                    <GridField text='สกลุ'>
                        <TextField name="czMoo" size='small' label="" variant="outlined" placeholder="นามสกุล" color="success" sx={{ width: '100%' }} />
                    </GridField>
                    <Grid item lg={8} md={6} xs={12}>
                        <Grid container>
                            <Grid item md={3} xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: { md: 'flex-end', sm: 'flex-start' } }}>
                                <Typography sx={{ marginRight: '15px', }}>หลักสูตร</Typography>
                            </Grid>
                            <Grid item md={9} xs={12}><TextField size='small' name="czAddress" placeholder="" label="" variant="outlined" color="success" sx={{ width: '100%' }} /></Grid>


                        </Grid>

                    </Grid>

                    <GridField text='วันที่สอน'>
                        <TextField type="date" name="czMoo" size='small' label="" variant="outlined" placeholder="" color="success" sx={{ width: '100%' }} />
                    </GridField>
                    <GridField text='วิชาที่สอน'>
                        <TextField name="czMoo" size='small' label="" variant="outlined" placeholder="" color="success" sx={{ width: '100%' }} />
                    </GridField>
                    <GridField text='เวลา'>
                        <TextField   name="czMoo" size='small' label="" variant="outlined" placeholder="" color="success" sx={{ width: '100%' }} />
                    </GridField>
                    <GridField text='หมายเหตุ'>
                        <TextField name="czMoo" size='small' label="" variant="outlined" placeholder="" color="success" sx={{ width: '100%' }} />
                    </GridField>
                    <Grid item lg={8} md={6} xs={12}>
                        <Grid container>
                            <Grid item md={3} xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: { md: 'flex-end', sm: 'flex-start' } }}>
                                <Typography sx={{ marginRight: '15px', }}>ที่มาของข้อมูล</Typography>
                            </Grid>
                            <Grid item md={9} xs={12}><TextField size='small' name="czAddress" placeholder="" label="" variant="outlined" color="success" sx={{ width: '100%', }} /></Grid>


                        </Grid>

                    </Grid>
                    <GridField>

                    </GridField>
                </Grid>

            </Container>
            <Box sx={{
                position: 'sticky', bottom: '0px', width: '100%', marginTop: '50px', background: '#fff'
            }}>
                <Divider sx={{ marginBottom: '10px' }} />
                <Container maxWidth='xl' sx={{ height: 'auto', }}>
                    <Box sx={{ padding: '5px 20px', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant='outlined' sx={{
                            width: '150px', height: 'fit-content', fontSize: '18px', color: '#0fb123', border: '1px solid #0fb123',
                            ':hover': { border: '1px solid #028b13' }
                        }} component={NavLink} to='/Scienceinformation'>กลับ</Button>
                        <Button variant='contained' type='sumbit' sx={{ width: '150px', height: 'fit-content', fontSize: '18px', marginLeft: '20px', backgroundColor: '#0fb123', ':hover': { backgroundColor: '#028b13' } }}>บันทึก</Button>
                    </Box>
                </Container>
            </Box>
        </>
    )
}
