
import React, { useEffect, useState } from 'react'
import { Box, Button,Autocomplete, Container, FormControl, Stack, Tab, Table, TableBody, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { NavLink, useLocation, useParams } from "react-router-dom";
import TitleHead from "../../components/TitleHead";
import SearchIcon from "@mui/icons-material/Search";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import moment from 'moment/moment';
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import api from "../../api/api";
import mime from "mime";
import { saveAs } from 'file-saver';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#94949473",
        color: theme.palette.common,
        fontWeight: "500",
        fontSize: 16,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        /*  backgroundColor: '#F2F5F8', */
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));
export default function Report1() {
    const [startDate, setStartDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [minDate, setMinDate] = useState();
    const [endDate, setEndDate] = useState();
    const [reportlist, setReport] = useState([])
    const [value,setValue] = useState('')
    // console.log(value);
    const [detere,setDatere]= useState([])
    const handleClick = () => {

        api.get(`api/v1.0/report/Report1/list?date=${value.calDate}`).then((res) => {
            setReport(res.data.result);
            // console.log(reportlist);
        })

    }

    useEffect(() => {
        api.get(`api/v1.0/report/reportdate/list?cerType=1`).then((res) => {
            setDatere(res.data.result);
            // console.log(detere);           
             setValue(res.data.result[0])

        })
    }, [ ])
    return (
        <Container maxWidth='xl' sx={{ height: 'auto', marginTop: '40px', marginBottom: '40px' }}>
            <TitleHead
                title="รายงาน"
                subTitle="รายงานผู้ได้รับเข็มกิตติคุณสถาบันพระปกเกล้าประจำปี"
            >
                <Box sx={{ display: "flex", justifyContent: "right" }}>
                    <Button
                        color="success"
                        onClick={async () => {
                            await api
                                .get(`api/v1.0/report/Report1/download?date=${value?.calDate}&format=txt`, {
                                    responseType: "blob",
                                })
                                .then((blobFile) => {
                                    const blobFileRes = new File(
                                        [blobFile?.data],
                                        "Template.txt",
                                        {
                                            type: mime.getType(".txt"),
                                        }
                                    );
                                    saveAs(blobFileRes, `รายงานผู้ได้รับเข็มกิตติคุณสถาบันพระปกเกล้าประจำปี ${value?.calDate}.txt`)
                                    // saveAs(URL.createObjectURL(blobFileRes, "Template.txt"))
                                    // window.open(
                                    //    ,
                                    //     "_blank"
                                    // );
                                });
                        }}
                        variant="outlined"
                        sx={{
                            color: "#4caf50",
                           paddingLeft:'16px',
                           paddingRight:'16px',
                            marginTop: "23px",
                            marginRight: '23px'

                        }}
                    >
                        <SaveAltIcon sx={{ marginRight: '10px' }} />  Text File
                    </Button>
                    <Button
                        color="success"
                        onClick={async () => {
                            await api
                                .get(`api/v1.0/report/Report1/download?date=${value?.calDate}&format=xlsx`, {
                                    responseType: "blob",
                                })
                                .then((blobFile) => {
                                    const blobFileRes = new File(
                                        [blobFile?.data],
                                        "Template.xlsx",
                                        {
                                            type: mime.getType(".xlsx"),
                                        }
                                    );
                                    saveAs(blobFileRes, `รายงานผู้ได้รับเข็มกิตติคุณสถาบันพระปกเกล้าประจำปี ${value?.calDate}.xlsx`)

                                    // window.open(
                                    //     URL.createObjectURL(blobFileRes, "Template.xlsx")
                                    //     ,
                                    //     "_blank"
                                    // );
                                });
                        }}
                        variant="outlined"
                        sx={{
                            color: "#4caf50",
                           paddingLeft:'16px',
                           paddingRight:'16px',
                            marginTop: "23px",
                            

                        }}
                    >
                        <SaveAltIcon sx={{ marginRight: '10px' }} /> Excel
                    </Button>
                </Box>
            </TitleHead>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ marginTop: '15px' }}>
                    <Typography sx={{ fontSize: '14px' }}>รายงาน&nbsp;/&nbsp;
                        <span style={{ color: '#35cc62', fontSize: '14px' }}>รายงานผู้ได้รับเข็มกิตติคุณสถาบันพระปกเกล้าประจำปี
                        </span>
                    </Typography>
                </Box>
                <Box >
                    <Box sx={{display:'flex'}}>
                  
                        
                  
                    
                        <Autocomplete
                          
                            fullWidth
                           size='small'
                            value={value}
                            onChange={(event, newValue) => {
                                setValue(newValue)
                            }}
                            options={detere}
                            getOptionLabel={option => moment(option?.calDate).add(543, 'year').format('DD-MM-yyyy') ? moment(option?.calDate).add(543, 'year').format('DD-MM-yyyy') : ''}
                            renderInput={(params) => <TextField {...params} placeholder='เลิอกวันที่' color="success" sx={{marginRight:'30px', width:'180px'}}/>}
                        />
                   
                        {/* <FormControl sx={{ marginRight: 0, }} size="small">
                            <DesktopDatePicker
                                label=""
                                inputFormat="DD/MM/YYYY"
                                value={startDate}
                                name="lecDate"
                                onChange={(newValue) => {
                                    setStartDate(moment(newValue).format("YYYY-MM-DD"));
                                    setMinDate(moment(newValue).format("YYYY-MM-DD"));
                                    setEndDate(null);
                                }}

                                renderInput={(params) => <TextField size='small' color="success" sx={{ width: '150px', marginRight: '15px' }}
                                    InputProps={params?.InputProps}
                                    inputRef={params?.inputRef}
                                    placeholder={params?.inputProps?.placeholder}
                                    value={params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}`}
                                    onChange={params?.inputProps?.onChange}
                                    type={params?.inputProps?.type}
                                />}
                            />
                        </FormControl> */}
                        {/* <FormControl sx={{ marginRight: 0, }} size="small">
                            <Typography sx={{ m: 1 }}>~</Typography>
                        </FormControl>
                        <FormControl sx={{ marginRight: 0, }} size="small">
                            <DesktopDatePicker
                                label=""
                                inputFormat="DD/MM/YYYY"

                                value={endDate}
                                name="lecDate"
                                disabled={minDate === null}
                                minDate={minDate}
                                onChange={(newValue) => {
                                    setEndDate(moment(newValue).format("YYYY-MM-DD"));
                                }}
                                renderInput={(params) => <TextField size='small' color="success" sx={{ width: '100%', marginRight: '15px' }}
                                    InputProps={params?.InputProps}
                                    inputRef={params?.inputRef}
                                    placeholder={params?.inputProps?.placeholder}
                                    value={params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}`}
                                    onChange={params?.inputProps?.onChange}
                                    type={params?.inputProps?.type}
                                />}
                            />
                        </FormControl>
                        <FormControl sx={{ marginRight: 0, }} size="small">
                            <Typography sx={{ m: 1 }}>~</Typography>
                        </FormControl>
                        <FormControl sx={{ marginRight: 0, }} size="small">
                            <DesktopDatePicker
                                label=""
                                inputFormat="DD/MM/YYYY"

                                value={endDate}
                                name="lecDate"
                                disabled={minDate === null}
                                minDate={minDate}
                                onChange={(newValue) => {
                                    setEndDate(moment(newValue).format("YYYY-MM-DD"));
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        color="success"
                                        size="small"
                                        sx={{ width: '100%', marginRight: "15px" }}

                                        {...params}
                                    />
                                )}
                            />
                        </FormControl> */}

                        <Button
                            variant="contained"
                            size='small'
                            onClick={handleClick}
                            sx={{
                                padding: "8px",
                                paddingLeft:'16px',
                                paddingRight:'16px',

                                backgroundColor: "#0fb123",
                                ":hover": { backgroundColor: "#028b13" },
                            }}
                        >
                            <SearchIcon />
                        </Button>
                    </Box>
                </Box>
            </Box>

            <Stack spacing={3}>
                <TableContainer sx={{ marginTop: "20px", marginBottom: "20px" }}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell
                                    align="center"
                                    sx={{ fontSize: "18px", fontWeight: "400" }}
                                >
                                    #
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    sx={{ fontSize: "18px", fontWeight: "400" }}
                                >
                                    ชื่อ-สกุล
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    sx={{ fontSize: "18px", fontWeight: "400" }}
                                >
                                    ชั้นเข็ม
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    sx={{ fontSize: "18px", fontWeight: "400" }}
                                >
                                    วันที่อนุมัติ
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    sx={{ fontSize: "18px", fontWeight: "400" }}
                                >
                                    คุณสมบัติ
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    sx={{ fontSize: "18px", fontWeight: "400" }}
                                >
                                    ดำรงตำแหน่ง / ปฏิบัติหน้าที่ / ทำคุณประโยชน์
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    sx={{ fontSize: "18px", fontWeight: "400" }}
                                >
                                    ผู้เสนอ
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>{
                            reportlist?.map((e, i) => (
                                <TableRow key={i}>
                                    <StyledTableCell
                                        align="center"
                                        sx={{ fontSize: "18px", fontWeight: "400" }}
                                    >
                                        {i + 1}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="center"
                                        sx={{ fontSize: "18px", fontWeight: "400" }}
                                    >
                                        {e?.titleNameTH || ''}{e?.firstname || ''} {e?.lastname || ''}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="center"
                                        sx={{ fontSize: "18px", fontWeight: "400" }}
                                    >
                                        {e?.s_rank}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="center"
                                        sx={{ fontSize: "18px", fontWeight: "400" }}
                                    >
                                        {e?.remarkSecond ? e?.remarkSecond + '\n' : ''}{new Date(e?.approveDateSecond).toLocaleDateString('th-TH', { dateStyle: 'long' })}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="center"
                                        sx={{ fontSize: "18px", fontWeight: "400" }}
                                    >
                                        {e?.cerName}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="center"
                                        sx={{ fontSize: "18px", fontWeight: "400" }}
                                    >
                                        {e?.positionName || ''}
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="center"
                                        sx={{ fontSize: "18px", fontWeight: "400" }}
                                    >
                                        {e?.present || ''}
                                    </StyledTableCell>

                                </TableRow>
                            ))}
                    </Table>

                </TableContainer>
            </Stack>

        </Container>
    )
}
