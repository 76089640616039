import { Grid, Typography } from '@mui/material'
import React from 'react'

export default function GridTextField({ text, children }) {
    return (
        <Grid item lg={4} md={6} xs={12}>
            <Grid container>
                <Grid item md={6} xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: { md: 'flex-end', sm: 'flex-start' } }}>
                    <Typography sx={{ marginRight: '15px', }}>{text}</Typography>
                </Grid>
                <Grid item md={6} xs={12} sx={{position:'relative'}}>{children}</Grid>
            </Grid>
        </Grid>
    )
}

