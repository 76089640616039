import { Api } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import api from "../../api/api";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

export default function DialogAdd({
  open,
  setOpen,
  apprecia,
  setapprecia,
  id,
}) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [mPosition, setMPosition] = useState([]);
  const [mpreset, setMPresent] = useState([]);
  const [value, setValue] = useState(null);
  const [ponum, setPunum] = useState("");
  const [positionDsc, setPositionDsc] = useState("");
  const [rank, setRank] = React.useState("");
  const [remark, setRemark] = useState("");
  const [appreciatetype, setappreciatetype] = useState([]);
  // console.log(ponum);
  const handleChange = (event) => {
    setRank(event.target.value);
  };
  const handleChange2 = (event) => {
    setRemark(event.target.value);
  };
  // const body = {
  //     startDate: moment(startDate).format('YYYY-MM-DD') === 'Invalid date' ? moment(new Date()).format('YYYY-MM-DD') : moment(startDate).format('YYYY-MM-DD'),
  //     endDate: moment(endDate).format('YYYY-MM-DD') === 'Invalid date' ? moment(new Date()).format('YYYY-MM-DD') : moment(endDate).format('YYYY-MM-DD'),
  //     positionID: value?.positionID,
  //     positionName: value?.positionName,
  //     positionDsc: positionDsc,
  //     status: true
  // }

  const body = {
    appreciateID: "",
    personalID: id,
    appreciateDate:
      moment(startDate).format("YYYY-MM-DD") === "Invalid date"
        ? moment(new Date()).format("YYYY-MM-DD")
        : moment(startDate).format("YYYY-MM-DD"),
    description: positionDsc,
    rank: rank === "0" ? null : rank,
    present: ponum,
    appreciateTypeID: remark,
    status: true,
  };
  const handleClose = () => {
    setOpen(false);
    setStartDate(null);
    setPunum("");
    setRank("");
    setPositionDsc("");
    setRemark('')
  };
  const handleClick = () => {
    if (
      positionDsc !== "" &&
      startDate !== "" &&
      rank !== "" &&
      ponum !== "" &&
      ((rank === "0" && remark === "") || (rank !== "0" && remark !== ""))
    ) {
      const postappre = async () => {
        const res = await api.post(`api/v1.0/personal/create-Appreciate`, body);
        const data = await res.data;
        if (data.status === true) {
          // console.log(data.result);
          // console.log(apprecia);
          setapprecia([...apprecia, data.result]);
        }
      };
      postappre();
      setStartDate(null);
      setPunum("");
      setRank("");
      setPositionDsc("");
      setRemark('')
      setOpen(false);
    } else {
      alert("กรอกข้อมูลให้ครบ");
    }
  };
  useEffect(() => {
    const getMasterPresent = async () => {
      const res = await api.get(`api/v1.0/master/present/list`);
      const data = await res.data;
      if (data.status === true) {
        setMPresent(data.result);
      }
    };
    getMasterPresent();
  }, []);
  useEffect(() => {
    const getMasterappreciatetype = async () => {
      const res = await api.get(`api/v1.0/master/appreciatetype/list`);
      const data = await res.data;
      if (data.status === true) {
        setappreciatetype(data.result);
      }
    };
    getMasterappreciatetype();
  }, []);
  // console.log(workexperinceDetail)
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle sx={{ margin: "10px 40px" }}>
        ได้รับการยกย่องบําเพ็ญประโยชน์
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ margin: { md: "10px 100px", xs: "10px 30px" } }}>
        <Grid container spacing={2}>
          <Grid item lg={4}>
            <Typography sx={{ marginRight: "40px" }}>วันที่</Typography>
          </Grid>
          <Grid item lg={8} xs={12}>
            <DesktopDatePicker
              label=""
              inputFormat="DD/MM/YYYY"
              value={startDate}
              onChange={(newValue) => {
                setStartDate(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  size="small"
                  color="success"
                  sx={{ width: "100%", marginLeft: "5px" }}
                  InputProps={params?.InputProps}
                  inputRef={params?.inputRef}
                  placeholder={params?.inputProps?.placeholder}
                  value={
                    params?.inputProps?.value
                      ? params?.inputProps?.value?.substring(
                          0,
                          params?.inputProps?.value?.length - 4
                        ) +
                        `${
                          +params?.inputProps?.value?.substring(
                            params?.inputProps?.value?.length - 4
                          ) + 543
                        }`
                      : null
                  }
                  onChange={params?.inputProps?.onChange}
                  type={params?.inputProps?.type}
                />
              )}
            />
          </Grid>

          <Grid item lg={4}>
            <Typography sx={{ marginRight: "40px" }}>รายละเอียด</Typography>
          </Grid>
          <Grid item lg={8} xs={12}>
            <TextField
              multiline
              rows={4}
              size="small"
              fullWidth
              variant="outlined"
              value={positionDsc}
              onChange={(e) => setPositionDsc(e.target.value)}
            />
          </Grid>
          <Grid item lg={4}>
            <Typography>ชั้นเข็ม/ประกาศนียบัตร</Typography>
          </Grid>
          <Grid item lg={8} xs={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">ชั้นเข็ม/ประกาศนียบัตร</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={rank}
                label="ชั้นเข็ม/ประกาศนียบัตร"
                size="small"
                onChange={handleChange}
              >
                <MenuItem value="0">ไม่ระบุชั้นเข็ม</MenuItem>
                <MenuItem value="5">5</MenuItem>
                <MenuItem value="4">4</MenuItem>
                <MenuItem value="3">3</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="999">ประกาศนียบัตรชั้นสูงกิตติมศักดิ์</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={4}>
            <Typography sx={{ marginRight: "40px" }}>ผู้เสนอ</Typography>
          </Grid>
          <Grid item lg={8} xs={12}>
            <Autocomplete
              id="free-solo-demo"
              // freeSolo
              name="certificate"
              value={ponum}
              onChange={(event, newInputValue) => {
                setPunum(newInputValue);
              }}
              options={mpreset?.map((option) => option?.presentFullName)}
              renderInput={(params) => (
                <TextField
                  required
                  placeholder="จำเป็นต้องระบุ"
                  {...params}
                  size="small"
                  label=""
                  color="success"
                  sx={{ width: "100%" }}
                />
              )}
            />
          </Grid>
          <Grid item lg={4}>
            <Typography sx={{ marginRight: "40px" }}>กรณีพิเศษ</Typography>
          </Grid>
          <Grid item lg={8} xs={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">หมายเหตุ</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={remark}
                label="หมายเหตุ"
                size="small"
                onChange={handleChange2} 
                disabled={rank === "0" ? true : false}
              >
               
                {console.log(appreciatetype)}
                {appreciatetype?.map((option, i) => (
                  <MenuItem key={i} value={option?.appreciateTypeID}>
                    {option?.appreciateTypeName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid item lg={9} xs={12}>
          <Autocomplete
              id="free-solo-demo"
              // freeSolo
              name="appreciateTypeTypeName"
              disabled={rank === '0'? true:false}
              value={remark}
              onChange={(event, newInputValue) => {
                setRemark(newInputValue)
                console.log(newInputValue)
              }}
              options={appreciatetype?.map((option) => option?.appreciateTypeTypeName)}
              renderInput={(params) => <TextField required placeholder='จำเป็นต้องระบุ' {...params} size='small' label="" color="success" sx={{ width: '100%' }} />}
            />  
          </Grid> */}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          variant="outlined"
          sx={{
            borderColor: "#1fc64c",
            "&:hover": { borderColor: "#4caf50" },
            color: "#1fc64c",
            padding: "7px 55px",
            marginBottom: "20px",
          }}
          onClick={handleClose}
        >
          กลับ
        </Button>
        <Button
          onClick={handleClick}
          sx={{
            backgroundColor: "#1fc64c",
            "&:hover": { background: "#4caf50" },
            color: "#FFFF",
            padding: "7px 55px",
            marginBottom: "20px",
          }}
        >
          บันทึก
        </Button>
      </DialogActions>
    </Dialog>
  );
}
