import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Container,
  Menu,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Dialog,
  DialogActions,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { useNavigate, NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import moment from "moment";
import Switch from "@mui/material/Switch";

import SearchIcon from "@mui/icons-material/Search";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";

import api from "../../api/api";
import Swal from "sweetalert2";
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    /*  backgroundColor: '#F2F5F8', */
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(index, img, name, user, date) {
  return { index, img, name, user, date };
}

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

export default function Permission() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openM = Boolean(anchorEl);
  const [checked, setChecked] = React.useState(true);
  const [txt_add, setTxt_add] = useState("");
  const [dialog_id, setDialog_id] = useState(null);
  const [openAdd, setOpenAdd] = useState(false);
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  //-----------Function Get slidesbanner------------
  const [userlist, setUserlist] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [roleList, setRoleList] = useState([]);

  const getRole = async () => {
    await api.get("api/v1.0/setting/role/list").then((res) => {
      setRoleList(res?.data?.result);
    });
  };

  useEffect(() => {
    getRole();
  }, []);

  const handleSubmitDialog = () => {
    if (txt_add !== "") {
      const body = {
        roleID: dialog_id ? dialog_id : null,
        roleName: txt_add,
        level: 0,
        status: true,
      };
      api
        .post("api/v1.0/setting/role/create", body)
        .then((response) => response.data)
        .then((data) => {
          if (data.status === true) {
            setOpenAdd(false);
            getRole();
            Swal.fire({
              title: "สร้างข้อมูลสำเร็จ?",
              showDenyButton: false,
              showCancelButton: false,
              icon: "success",
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                getRole();
                setTxt_add("");
                setOpenAdd(false);
              }
            });
          } else {
            console.log("failed");
            setOpenAdd(false);
          }
        });
    } else {
      alert("กรุณากรอกหัวข้อ");
    }
  };
  const EditDialog = (id, txt) => {
    setTxt_add(txt);
    setDialog_id(id);
    setOpenAdd(true);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            color: "#000",
            marginTop: "50px",
            marginLeft: "65px",
          }}
        >
          <Typography variant="h4">การจัดการสิทธิ์</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "right",
            width: "100%",
            marginTop: "-18px",
            paddingRight: "50px",
          }}
        >
          <Button
            onClick={() => setOpenAdd(true)}
            sx={{
              backgroundColor: "#1fc64c",
              "&:hover": { background: "#4caf50" },
              color: "#FFFF",
              padding: "7px 55px",
              marginTop: "23px",
            }}
          >
            สร้างใหม่
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "right",
            width: "100%",

            paddingRight: "70px",
          }}
        ></Box>

        <Box
          sx={{
            display: "flex",
            color: "#000",
            marginTop: "20px",
            marginLeft: "50px",
            justifyContent: "right",
            paddingRight: "70px",
          }}
        >
          <Divider
            sx={{ width: "1485px", marginTop: "10px", marginLeft: "65px" }}
          />
        </Box>

        <Container maxWidth="xl">
          <Stack spacing={3}>
            <TableContainer sx={{ marginTop: "20px", marginBottom: "80px" }}>
              <Table
                sx={{
                  minWidth: 400,
                  borderCollapse: "inherit",
                  borderSpacing: "0",
                }}
              >
                <TableHead sx={{ backgroundColor: "#e7e7e7" }}>
                  <TableRow>
                    {/*  <TableCell align="left" sx={{ fontSize: "18px", fontWeight: "600", color: '#3a3a3ae6', border: 'none' }}>#</TableCell> */}

                    <TableCell
                      align="left"
                      sx={{
                        fontSize: "18px",
                        fontWeight: "600",
                        color: "#3a3a3ae6",
                        border: "none",
                      }}
                    >
                      สิทธิ์
                    </TableCell>
                    {/* <TableCell
                      align="left"
                      sx={{
                        fontSize: "18px",
                        fontWeight: "600",
                        color: "#3a3a3ae6",
                        border: "none",
                      }}
                    >
                      สถานะ
                    </TableCell> */}
                    <TableCell
                      align="left"
                      sx={{
                        fontSize: "18px",
                        fontWeight: "600",
                        color: "#3a3a3ae6",
                        border: "none",
                      }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {roleList.map((row, i) => (
                    <StyledTableRow
                      key={i}
                      /* onClick={() => navigate(`/ProjectSource/SearchPoliticalMinor`)} */
                      sx={{
                        fontSize: "18px",
                        cursor: "pointer",
                        "&:hover": {
                          background: "#f3f2f2",
                        },
                      }}
                    >
                      <TableCell align="left" sx={{ fontSize: "17px" }}>
                        {row.roleName}
                      </TableCell>
                      {/* <TableCell align="left" sx={{ fontSize: "17px" }}>
                        <AntSwitch
                          type="checkbox"
                          name="status"
                          checked={row.status}
                          onChange={async () => {
                            const body = {
                              roleID: row?.roleID,
                              status: !row.status,
                            };
                            const res = await api.post(
                              "api/v1.0/setting/role/updatestatus",
                              body
                            );
                            if (res?.data?.status) {
                              getRole();
                            }
                          }}
                          inputProps={{ "aria-label": "ant design" }}
                          sx={{ marginLeft: "10px" }}
                        />
                      </TableCell> */}
                      {/*  <TableCell align="left" sx={{ fontSize: "17px" }}>{moment(row.createDate).format("DD-MM-YYYY")}</TableCell> */}
                      <TableCell align="left">
                        <ThreeDotsMenu func={EditDialog} data={row} />
                      </TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Container>
      </Box>
      <Dialog
        open={openAdd}
        onClose={() => {
          setOpenAdd(false);
          setTxt_add("");
          setDialog_id(null);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        borderRadius="20px"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">สร้างสิทธิ์</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <InputLabel>ชื่อสิทธิ์</InputLabel>
            <TextField
              fullWidth
              value={txt_add}
              onChange={(e) => setTxt_add(e.target.value)}
              placeholder="กรุณากรอกชื่อสิทธิ์"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenAdd(false);
              setTxt_add("");
              setDialog_id(null);
            }}
            variant="outlined"
            color="success"
            sx={{
              color: "#4caf50",
              padding: "7px 55px",
              marginTop: "23px",
              marginRight: "20px",
            }}
          >
            กลับ
          </Button>
          <Button
            onClick={() => handleSubmitDialog()}
            variant="outlined"
            color="success"
            sx={{
              backgroundColor: "#1fc64c",
              "&:hover": { background: "#4caf50" },
              color: "#FFFF",
              padding: "7px 55px",
              marginTop: "23px",
            }}
          >
            นำข้อมูลเข้า
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const ThreeDotsMenu = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleMenuClick(Val1) {
    // console.log(Val1);
  }

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data } = props;
  return (
    <>
      <React.Fragment>
        <MoreHorizIcon
          onClick={handleClick}
          sx={{ cursor: "pointer", fontSize: "25px" }}
        />

        <Menu
          id="dense-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          // elevation={0}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{
            ".MuiPopover-paper": {
              boxShadow: "unset",
              border: "0.5px red",
              color: "#3a3a3ae6",
              backgroundColor: "#f9f9f9",
              borderRadius: "5px",
              // border: 'px solid',
            },
          }}
        >
          <MenuItem
            sx={{
              width: "120px",
              fontSize: "20px",
              color: "#00695C",
            }}
            onClick={() => {
              props.func(data?.roleID, data?.roleName);
            }}
          >
            {" "}
            <EditRoundedIcon sx={{ marginRight: "15px" }} />
            เเก้ไข
          </MenuItem>
        </Menu>
      </React.Fragment>
    </>
  );
};
