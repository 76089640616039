import React, { useContext, useEffect, useRef, useState } from 'react'
import ApiContext from '../../context/ApiProvider';
import { Box, Button, Typography, Divider, Grid, FormControl, Select, MenuItem, TextField, InputLabel, FormHelperText, FormControlLabel, Switch, Container, Autocomplete } from '@mui/material'
import GridField from '../../components/GridTextField'
import TitleHead from '../../components/TitleHead'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import { Formik } from 'formik'
import * as Yup from 'yup';
import api from '../../api/api'
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from "sweetalert2";

export default function DetailCommittee() {
  const param = useParams()
  const { boardId } = param
  // const [personal, setPersonal] = useState([]);
  const [boardDetail, setBoardDetail] = useState([])
  const fileRef = useRef()
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [citizen, setCitizen] = useState('')
  const [newCitizen, setNewCitizen] = useState('')
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [subtract, setSubtract] = useState({})
  const [personal, setPersonal] = useState([]);
  const [position, setPosition] = useState([]);
  const [appointer, setAppointer] = useState([]);
  const [breakCal, setBreakCal] = useState([]);
  const [startBDate, setStartBDate] = useState(null);
  const [endBDate, setEndBDate] = useState(null);
  const [bComment, setBComment] = useState('');
  const [bPresent, setBPresent] = useState('');
  const [newPosition, setNewPosition] = useState('')
  const [status, setStatus] = useState(true);
  const [isCalPin, setIsCalPin] = useState(true);
  const [remark, setRemark] = useState('');
  const [files, setFiles] = useState([])
  let formData = new FormData();
  const location = useLocation()
  const [loading, setLoading] = useState(true);
  const [yearMeet, setYearMeet] = useState()
  const [countMeet, setCountMeet] = useState()
  const [dateMeet, setDateMeet] = useState('')
  const [openMeet, setOpenMeet] = useState(false)
  const { getPersonal, getPosition, getAppointer, fileUpload, FileDownload, updateBoard, getBoardDetail } = useContext(ApiContext)
  useEffect(() => {
    getBoardDetail(setBoardDetail, boardId, setLoading)
  }, [])

  useEffect(() => {
    if(firstname && lastname){
      getPersonal(setPersonal, firstname, lastname)
    }
  }, [firstname, lastname])

  useEffect(() => {
    getPosition(setPosition)
  }, [])

  useEffect(() => {
    // if (!citizen) {
    //   getAppointer(setAppointer, newCitizen)
    // }
    getAppointer(setAppointer, citizen)
  }, [])
  // console.log(appointer)
  useEffect(() => {
    setFirstname(boardDetail[0]?.personal?.firstname)
    setLastname(boardDetail[0]?.personal?.lastname)
    // setNewPosition(boardDetail[0]?.newPositionID)
    setCitizen(boardDetail[0]?.appointer?.firstname)
    setFiles(boardDetail[0]?.boardAttachment)
    setBreakCal(boardDetail[0]?.breakCal)
  }, [boardDetail])

  const handleFileUpload = (event) => {
    if (event.target && event.target.files) {
      let file = event.target.files[0];
      // setFiles([...files, file]);

      const maxAllowedSize = 8 * 1024 * 1024;
      if (file.size < maxAllowedSize) {
        formData.append("file", file)
        // console.log(formData)
        // fileUpload(formData, file, files, setFiles)
        api.post('api/v1.0/filemanages/upload', formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then((res) => res.data)
          .then((data) => {
            if (data.status === true) {
              // console.log(data.result)
              setFiles([...files, data.result]);
            }
            else {
              console.log(' failed')
            }
          })

      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'ไฟล์อัพโหลดใหญ่เกินไป',
        })
      }
    }

  };
  // console.log(files)

  const handleBreakCal = () => {
    const body = {
      breakCalID: "",
      startDate: moment(startBDate).format('YYYY-MM-DD') === 'Invalid date' ? moment(new Date()).format('YYYY-MM-DD') : moment(startBDate).format('YYYY-MM-DD'),
      endDate: moment(endBDate).format("YYYY-MM-DD") === "Invalid date" ? null : moment(endBDate).format("YYYY-MM-DD"),
      remark: bComment,
      present: bPresent,
      status: true
    }
    setBreakCal([...breakCal, body])
    setBComment('')
    setBPresent('')
    // setStartBDate(null)
    // setEndBDate(null)
  }

  const handleRemoveFileUpload = (x, i) => {
    const body = files.map((z) => {
      z.boardAttachmentID = z?.boardAttachmentID || ""
      z.fileID = z.fileID
      z.status = z.fileID === x.fileID ? false : z.status
      return z
    }
    )
    setFiles(body)
  }
  useEffect(() => {
    const subtract = moment.duration(moment(moment(endDate).format('DD-MM-YYYY') ? moment(endDate).format('DD-MM-YYYY') : new Date(), 'DD-MM-YYYY').diff(moment(moment(startDate).format('DD-MM-YYYY'), 'DD-MM-YYYY')))._data
    // const subtract = moment.duration(moment(moment(boardDetail[0]?.endDate).format('DD-MM-YYYY') ? moment(boardDetail[0]?.endDate).format('DD-MM-YYYY') : new Date(), 'DD-MM-YYYY').diff(moment(moment(boardDetail[0]?.startDate).format('DD-MM-YYYY'), 'DD-MM-YYYY')))._data
    setSubtract(subtract)
  }, [endDate, startDate, boardDetail])
  // console.log(boardDetail[0]?.boardAttachment)
  // console.log(boardDetail[0]?.boardMeeting)


  // console.log(boardDetail)
  return (
    // <>
    //   {loading ? 'loading...' :
    <Formik
      enableReinitialize={true}
      initialValues={{
        boardID: boardDetail[0]?.boardID,
        personalID: boardDetail[0]?.personalID || null,
        newPositionID: boardDetail[0]?.newPositionID || null,
        newPositionDes: boardDetail[0]?.newPositionDes || null,
        boardDepID: param?.id,
        startDate: boardDetail[0]?.startDate,
        endDate: boardDetail[0]?.endDate,
        appointerID: boardDetail[0]?.appointerID,
        remark: boardDetail[0]?.remark,
        status: boardDetail[0]?.status,
        isCalPin: boardDetail[0]?.isCalPin,
        boardAttach: files,
        // boardMeet: openMeet ? boardDetail[0]?.boardMeeting !== undefined ? [
        //   ...boardDetail[0]?.boardMeeting,
        //   {
        //     boardMeetID: "",
        //     year: yearMeet.toString(),
        //     countMeet: +countMeet,
        //     isCal: true
        //   }
        // ] : [] : boardDetail[0]?.boardMeeting !== undefined ? [...boardDetail[0]?.boardMeeting,] : [],

        // files.map((item) =>
        // ({
        //   boardAttachmentID: "",
        //   fileID: item.fileID,
        //   status: true
        // })
        // ),
        breakCal: breakCal,
        submit: null
      }}
      validationSchema={Yup.object().shape({
        // bankAccNo: Yup.string().max(255).required('กรุณาระบุ เลขบัญชี'),
        // bankAccName: Yup.string().max(255).required('กรุณาระบุ ชื่อบัญชี'),
        // bankAccNickName: Yup.string().max(255).required('กรุณาระบุ ชื่อเล่น'),
      })}
      onSubmit={async (value, { setErrors, setStatus, setSubmitting }) => {
        // console.log(value)
        try {

          // console.log(value)
          updateBoard(value)
          // createBoard(value)

          setStatus({ success: true })
          setSubmitting(false)
        } catch (err) {
          console.log(err)
          setStatus({ success: false })
          setErrors({ submit: err.message })
          setSubmitting(false)
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Container maxWidth='xl' sx={{ height: 'auto', marginTop: '40px' }}>
            <TitleHead title={location.state.open === 'see' ? 'ข้อมูลคณะกรรมการ' : 'เเก้ไขคณะกรรมการ'} subTitle='ข้อมูลทะเบียนประวัติส่วนบุคคล'>
            </TitleHead>
            <Box>
              <Typography variant='h6' sx={{ marginBottom: '20px' }}>ข้อมูลคณะกรรมการ</Typography>
              <Grid container spacing={2}>
                <GridField text='คำนำ'>
                  {/* <FormControl
            sx={{
              width: "100%",
              display: "flex",
              justifyItems: "center",
            }}
            variant="outlined"
            color="success"
          >

            <Select
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              value={param}
              onChange={e => setParam(e.target.value)}
            >
              {NEWS_GROUPS.map((x, i) => <MenuItem key={i} value={x.value}>{x.title}</MenuItem>
              )}
            </Select>
          </FormControl> */}
                  <TextField label="" size='small' variant="outlined"
                    disabled={location.state.open === 'see' ? false : true}
                    readOnly={location.state.open === 'see' ? true : false}
                    color="success" name='personalID' value={firstname?.length > 0 ? boardDetail[0]?.personal?.title?.titleNameTH : ''} sx={{ width: '100%' }} />
                </GridField>
                <GridField text='ชื่อ'>
                  {/* <TextField label="" variant="outlined" color="success" sx={{ width: '100%' }} /> */}
                  <Autocomplete
                    id="free-solo-demo"
                    disabled={location.state.open === 'see' ? false : true}
                    readOnly={location.state.open === 'see' ? true : false}
                    value={firstname}
                    onChange={(event, newValue) => {
                      setFirstname(newValue);
                    }}
                    // freeSolo
                    // inputValue={firstname}
                    // onInputChange={(event, newInputValue) => {
                    //   setFirstname(newInputValue);
                    // }}
                    options={personal?.map((option) => option?.firstname)}
                    renderInput={(params) => <TextField {...params} size='small' label="" color="success" sx={{ width: '100%' }} />}
                  />
                </GridField>
                <GridField text='นามสกุล'>
                  <Autocomplete
                    id="free-solo-demo"
                    // freeSolo
                    disabled={location.state.open === 'see' ? false : true}
                    readOnly={location.state.open === 'see' ? true : false}
                    value={lastname}
                    onChange={(event, newValue) => {
                      setLastname(newValue);
                    }}
                    // inputValue={lastname}
                    // onInputChange={(event, newInputValue) => {
                    //   setLastname(newInputValue);
                    // }}
                    options={personal?.map((option) => option?.lastname)}
                    renderInput={(params) => <TextField {...params} size='small' label="" color="success" sx={{ width: '100%' }} />}
                  />
                </GridField>
                <GridField text='ตำเเหน่งปัจจุบัน'>
                  <TextField label="" size='small' variant="outlined"
                    disabled={location.state.open === 'see' ? false : true}
                    readOnly={location.state.open === 'see' ? true : false}
                    color="success" value={personal[0]?.position || ''} sx={{ width: '100%' }} />
                </GridField>
                {values.startDate ? setStartDate(values.startDate) : ''}
                <GridField text='วันที่เริ่มต้น'>
                  <DesktopDatePicker
                    label=""
                    readOnly={location.state.open === 'see' ? true : false}
                    inputFormat="DD/MM/YYYY"
                    value={values.startDate ? values.startDate : null}
                    maxDate={endDate ? new Date(endDate) : ''}
                    onChange={(newValue) => {
                      setFieldValue('startDate', moment(newValue).format('YYYY-MM-DD'))
                      setStartDate(newValue)
                    }}
                    renderInput={(params) => <TextField size='small' color="success" disabled={location.state.open === 'see' ? true : false} sx={{ width: '100%' }}
                      InputProps={params?.InputProps}
                      inputRef={params?.inputRef}
                      // placeholder={params?.inputProps?.placeholder}
                      // value={params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}`}
                      // onChange={params?.inputProps?.onChange}
                      // type={params?.inputProps?.type}
                      inputProps={
                        {
                          placeholder: params?.inputProps?.placeholder,
                          readOnly: true,
                          value: params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null,
                          onChange: params?.inputProps?.onChange,
                          type: params?.inputProps?.type
                        }
    
                      }
                    />}
                  />
                </GridField>
                {values.endDate ? setEndDate(values.endDate) : ''}

                <GridField text='วันที่สิ้นสุด'>
                  <DesktopDatePicker
                    label=""
                    readOnly={location.state.open === 'see' ? true : false}
                    inputFormat="DD/MM/YYYY"
                    value={values.endDate ? values.endDate  : null}
                    minDate={new Date(startDate)}
                    onChange={(newValue) => {
                      setFieldValue('endDate', moment(newValue).format('YYYY-MM-DD'))
                      setEndDate(newValue)
                    }}
                    renderInput={(params) => <TextField size='small' color="success" disabled={location.state.open === 'see' ? true : false} sx={{ width: '100%' }}
                      InputProps={params?.InputProps}
                      inputRef={params?.inputRef}
                      // placeholder={params?.inputProps?.placeholder}
                      // value={params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}`}
                      // onChange={params?.inputProps?.onChange}
                      // type={params?.inputProps?.type}
                      inputProps={
                        {
                          placeholder: params?.inputProps?.placeholder,
                          readOnly: true,
                          value: params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null,
                          onChange: params?.inputProps?.onChange,
                          type: params?.inputProps?.type
                        }
    
                      }
                    />}
                  />
                </GridField>
                <GridField text='ตำเเหน่งในคณะกรรมการ'>
                  <TextField size='small' disabled={location.state.open === 'see' ? true : false} value={values?.newPositionDes} onChange={e => setFieldValue('newPositionDes', e.target.value)} label="" variant="outlined" color="success" sx={{ width: '100%' }} />
                </GridField>
                {/* <GridField text='คุณสมบัติ'>
                  <FormControl
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyItems: "center",
                    }}
                    variant="outlined"
                    color="success"
                  >
                    <Select
                      labelId="demo-simple-select-label"
                      inputProps={{ "aria-label": "Without label" }}
                      // label="เลือกตำเเหน่ง"
                      readOnly={location.state.open === 'see' ? true : false}
                      size='small'
                      value={values?.newPositionID}
                      onChange={e => setFieldValue('newPositionID', e.target.value)}
                    >
                      {position.map((x, i) => <MenuItem key={i} value={x?.positionID}>{x?.positionName}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </GridField> */}
                <Grid item lg={4} md={6} xs={12}>
                  <Grid container>
                    <Grid item md={2} xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: { md: 'flex-end', sm: 'flex-start' } }}>
                    </Grid>
                    <Grid item md={10} xs={12} sx={{ position: 'relative' }}>
                      <Grid container>
                        {/* <Grid item lg={2} xs={12}>
                    </Grid> */}
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item sm={2} xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>
                              <Typography sx={{ marginRight: '15px', }}>ปี</Typography>
                            </Grid>
                            <Grid item sm={2} xs={12}>
                              <TextField size='small' disabled label="" placeholder='YY' variant="outlined" color="success" value={subtract?.years} sx={{ width: '100%', background: '#f7f7f7' }} />
                            </Grid>
                            <Grid item sm={2} xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>
                              <Typography sx={{ marginRight: '15px', }}>เดือน</Typography>
                            </Grid>
                            <Grid item sm={2} xs={12}>
                              <TextField size='small' disabled label="" placeholder='MM' variant="outlined" color="success" value={subtract?.months} sx={{ width: '100%', background: '#f7f7f7' }} />
                            </Grid>
                            <Grid item sm={2} xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>
                              <Typography sx={{ marginRight: '15px', }}>วัน</Typography>
                            </Grid>
                            <Grid item sm={2} xs={12}>
                              <TextField size='small' label="" disabled placeholder='DD' variant="outlined" color="success" value={subtract?.days} sx={{ width: '100%', background: '#f7f7f7' }} />
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* <Grid item lg={4}>
                    </Grid> */}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <GridField text='ผู้แต่งตั้ง'>
                  {/* {console.log(appointer.filter((x) => x?.appointerID === values.appointerID)[0])}
                  <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    readOnly={location.state.open === 'see' ? true : false}
                    inputValue={newCitizen}
                    onInputChange={(event, newInputValue) => {
                      setNewCitizen(newInputValue);
                    }}
                    value={appointer.filter((x) => x?.appointerID === values.appointerID)[0]}
                    onChange={(event, newValue) => {
                      setCitizen(newValue);
                    }}
                    // options={appointer?.map((option) => option?.appointerName)}
                    options={appointer}
                    getOptionLabel={option => option?.appointerName}
                    renderInput={(params) => <TextField  {...params} size='small' label="" color="success" sx={{ width: '100%' }} />}
                  /> */}
                  <FormControl
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyItems: "center",
                    }}
                    variant="outlined"
                    color="success"
                  >
                    {/* <InputLabel id="demo-simple-select-label">เลือกตำเเหน่ง</InputLabel> */}
                    <Select
                      labelId="demo-simple-select-label"
                      inputProps={{ "aria-label": "Without label" }}
                      // label="เลือกตำเเหน่ง"
                      readOnly={location.state.open === 'see' ? true : false}
                      size='small'
                      value={+values?.appointerID}
                      onChange={e => setFieldValue('appointerID', e.target.value)}
                    >
                      {appointer && appointer?.map((x, i) => <MenuItem key={i} value={+x?.appointerID}>{x?.appointerName}</MenuItem>)}
                    </Select>
                  </FormControl>
                  {/* {console.log(values?.appointerID)} */}
                </GridField>
                <GridField text='สถานะ'>
                  <FormControlLabel control={<Switch name='status'
                    disabled={location.state.open === 'see' ? true : false}
                    checked={values.status} onChange={e => setFieldValue('status', e.target.checked)} />} label="" />
                </GridField>
                <GridField text='มีสิทธิ์การรับเข็ม'>
                  <FormControlLabel control={<Switch name='isCalPin'
                    disabled={location.state.open === 'see' ? true : false}
                    checked={values.isCalPin} onChange={e => setFieldValue('isCalPin', e.target.checked)} />} label="" />
                </GridField>
                {/* <Grid item xs={12} >
                  <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />
                  <Grid container>
                    <Grid item lg={2} md={3} xs={12} sx={{ display: 'flex', alignItems: 'start', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>
                      <Typography sx={{ marginRight: '15px', }}>วาระการประชุม</Typography>
                    </Grid>
                    {!openMeet ? <> <Grid item lg={10} md={9} xs={12}>
                      <Button variant='outlined' sx={{
                        height: 'fit-content', fontSize: '18px', color: '#0fb123', border: '1px solid #0fb123',
                        ':hover': { border: '1px solid #028b13' }
                      }} onClick={() => {
                        setOpenMeet(true)
                        setYearMeet(+boardDetail[0]?.boardMeeting?.sort((a, b) => new Date(b?.createDate) - new Date(a?.createDate))[0]?.year + 1 || new Date().getFullYear() + 543)
                      }}>เพิ่ม</Button>
                    </Grid>
                      <Grid item lg={2} md={3} xs={12} sx={{ display: 'flex', alignItems: 'start', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>
                      </Grid></> : ''}

                    <Grid item lg={10} md={9} xs={12}>
                      {boardDetail[0]?.boardMeeting?.map((x) => <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginTop: '10px' }}>
                        <Typography sx={{ marginRight: '10px' }}>ปี</Typography>
                        <TextField label="" disabled size='small' variant="outlined" color="success" value={x?.year} sx={{ width: '80px', marginRight: '10px' }} />
                        <TextField label="" size='small' variant="outlined" color="success" type='text' onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }} disabled value={x?.countMeet} sx={{ width: '80px', marginRight: '10px' }} />
                        <Typography sx={{ marginRight: '10px' }}>ครั้ง</Typography>


                      </Box>
                      )}
                      {openMeet ?
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Typography sx={{ marginRight: '10px' }}>ปี</Typography>
                          <TextField label="" disabled size='small' variant="outlined" color="success" value={yearMeet} sx={{ width: '80px', marginRight: '10px' }} />
                          <TextField label="" size='small' variant="outlined" color="success" type='text' onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }} value={countMeet} onChange={e => {
                            setCountMeet(e.target.value)
                          }} sx={{ width: '80px', marginRight: '10px' }} />
                          <Typography sx={{ marginRight: '10px' }}>ครั้ง</Typography>
                          <DeleteIcon sx={{ color: 'red', cursor: 'pointer' }} onClick={() => setOpenMeet(false)} />
                        </Box>
                        : ''}

                    </Grid>

                  </Grid>
                  <Divider sx={{ marginTop: '20px' }} />
                </Grid> */}
                <Grid item xs={12} >
                  <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />
                  <Grid container sx={{ alignItems: 'start' }}>
                    <Grid item lg={2} md={3} xs={12} sx={{ display: 'flex', alignItems: 'start', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>
                      <Typography sx={{ marginRight: '15px', }}>การเข้าร่วมประชุม</Typography>
                    </Grid>
                    <Grid item lg={10} md={9} xs={12}>
                      {boardDetail[0]?.boardMeetPer.length > 0 ? boardDetail[0]?.boardMeetPer?.map((item, i) =>
                        <Box key={i} sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                          <Typography sx={{marginRight:'10px', marginLeft:'20px'}}>ปี</Typography>
                          <TextField label="" disabled size='small' variant="outlined" color="success" value={moment(item?.meetDate).add(543, 'year').format('DD/MM/yyyy').substring(6)} onChange={e => setYearMeet(e.target.value)} sx={{ width: '80px', marginRight: '10px', background: '#f7f7f7' }} />
                          <TextField label="วันที่ประชุม" disabled size='small' variant="outlined" color="success" InputLabelProps={{ shrink: true }} value={moment(item?.meetDate).format('LL').substring(0, moment(item?.meetDate).format('LL') - 4)} onChange={e => setDateMeet(e.target.value)} sx={{ width: '150px', marginRight: '10px', background: '#f7f7f7' }} />
                          <TextField label="ครั้งที่" disabled size='small' variant="outlined" color="success" value={item?.boardMeet?.seqMeet} type="text" placeholder='ครั้งที่ประชุม' onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }} sx={{ width: '120px', marginRight: '10px' }} />

                        </Box> 
                      ):
                        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                          <Typography sx={{marginRight:'10px', marginLeft:'20px'}}>ปี</Typography>
                          <TextField label="" disabled size='small' variant="outlined" color="success" sx={{ width: '80px', marginRight: '10px', background: '#f7f7f7' }} />
                          <TextField label="วันที่ประชุม" disabled size='small' variant="outlined" color="success"  sx={{ width: '150px', marginRight: '10px', background: '#f7f7f7' }} />
                          <TextField label="ครั้งที่" disabled size='small' variant="outlined" color="success" type="text" placeholder='ครั้งที่ประชุม' onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }} sx={{ width: '120px', marginRight: '10px' }} />

                        </Box>}
                     
                    </Grid>

                  </Grid>

                </Grid>
                <Grid item xs={12} >
                  <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />
                  <Grid container >
                    <Grid item lg={2} md={3} xs={12} sx={{ display: 'flex', alignItems: 'start', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>
                      <Typography sx={{ marginRight: '15px', }}>ระยะเวลาที่ไม่นำไปคำนวณ</Typography>
                    </Grid>
                    <Grid item lg={4} md={9} xs={12}>
                      {
                        breakCal?.map((item, i) =>
                          <div key={i}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              {/* <Typography sx={{ marginRight: '10px' }}>ปี</Typography> */}
                              <DesktopDatePicker
                                label=""
                                inputFormat="DD/MM/YYYY"
                                value={item?.startDate}
                                disabled
                                maxDate={endBDate ? new Date(endBDate) : ''}
                                // onChange={(newValue) => { setStartBDate(newValue) }}
                                renderInput={(params) => <TextField size='small' color="success" disabled sx={{ width: '200px', marginRight: '20px' }}
                                  InputProps={params?.InputProps}
                                  inputRef={params?.inputRef}
                                  // placeholder={params?.inputProps?.placeholder}
                                  // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                                  // onChange={params?.inputProps?.onChange}
                                  // type={params?.inputProps?.type}
                                  inputProps={
                                    {
                                      placeholder: params?.inputProps?.placeholder,
                                      readOnly: true,
                                      value: params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null,
                                      onChange: params?.inputProps?.onChange,
                                      type: params?.inputProps?.type
                                    }
                
                                  }
                                />}
                              />
                              <Typography sx={{ margin: '0 10px' }}>~</Typography>
                              <DesktopDatePicker
                                label=""
                                inputFormat="DD/MM/YYYY"
                                value={item?.endDate}
                                disabled
                                minDate={new Date(startBDate)}
                                // onChange={(newValue) => { setEndBDate(newValue) }}
                                renderInput={(params) => <TextField size='small' color="success" disabled sx={{ width: '200px', marginRight: '20px' }}
                                  InputProps={params?.InputProps}
                                  inputRef={params?.inputRef}
                                  // placeholder={params?.inputProps?.placeholder}
                                  // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                                  // onChange={params?.inputProps?.onChange}
                                  // type={params?.inputProps?.type}
                                  inputProps={
                                    {
                                      placeholder: params?.inputProps?.placeholder,
                                      readOnly: true,
                                      value: params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null,
                                      onChange: params?.inputProps?.onChange,
                                      type: params?.inputProps?.type
                                    }
                
                                  }
                                />}
                              />
                            </Box>
                            <TextField multiline rows={4} disabled size='small' value={item?.remark} placeholder='หมายเหตุ' sx={{ width: '100%', marginTop: '10px' }} />
                            <TextField size='small' disabled value={item?.present} placeholder='ผู้เสนอการหักคำนวณ' sx={{ width: '50%', marginTop: '10px' }} />
                            <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />
                          </div>
                        )
                      }

                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {/* <Typography sx={{ marginRight: '10px' }}>ปี</Typography> */}
                        <DesktopDatePicker
                          label=""
                          inputFormat="DD/MM/YYYY"
                          disabled={location.state.open === 'see' ? true : false}
                          value={startBDate}
                          maxDate={endBDate ? new Date(endBDate) : ''}
                          onChange={(newValue) => { setStartBDate(newValue) }}
                          renderInput={(params) => <TextField size='small' color="success" sx={{ width: '200px', marginRight: '20px' }} disabled={location.state.open === 'see' ? true : false}
                            InputProps={params?.InputProps}
                            inputRef={params?.inputRef}
                            // placeholder={params?.inputProps?.placeholder}
                            // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                            // onChange={params?.inputProps?.onChange}
                            // type={params?.inputProps?.type}
                            inputProps={
                              {
                                placeholder: params?.inputProps?.placeholder,
                                readOnly: true,
                                value: params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null,
                                onChange: params?.inputProps?.onChange,
                                type: params?.inputProps?.type
                              }
          
                            }
                          />}
                        />
                        <Typography sx={{ margin: '0 10px' }}>~</Typography>
                        <DesktopDatePicker
                          label=""
                          inputFormat="DD/MM/YYYY"
                          disabled={location.state.open === 'see' ? true : false}
                          value={endBDate}
                          minDate={new Date(startBDate)}
                          onChange={(newValue) => { setEndBDate(newValue) }}
                          renderInput={(params) => <TextField size='small' color="success" sx={{ width: '200px', marginRight: '20px' }} disabled={location.state.open === 'see' ? true : false}
                            InputProps={params?.InputProps}
                            inputRef={params?.inputRef}
                            // placeholder={params?.inputProps?.placeholder}
                            // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                            // onChange={params?.inputProps?.onChange}
                            // type={params?.inputProps?.type}
                            inputProps={
                              {
                                placeholder: params?.inputProps?.placeholder,
                                readOnly: true,
                                value: params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null,
                                onChange: params?.inputProps?.onChange,
                                type: params?.inputProps?.type
                              }
          
                            }
                          />}
                        />
                      </Box>
                      <TextField multiline rows={4} size='small' disabled={location.state.open === 'see' ? true : false} value={bComment} onChange={e => setBComment(e.target.value)} placeholder='หมายเหตุ' sx={{ width: '100%', marginTop: '10px' }} />
                      <TextField disabled={location.state.open === 'see' ? true : false} size='small' value={bPresent} onChange={e => setBPresent(e.target.value)} placeholder='ผู้เสนอการหักคำนวณ' sx={{ width: '50%', marginTop: '10px' }} />
                      <br />
                      <Button disabled={startBDate && endBDate && bPresent !== '' && bComment !== '' ? false : true} onClick={handleBreakCal} variant='outlined' sx={{
                        height: 'fit-content', marginTop: '10px', fontSize: '18px', color: '#0fb123', border: '1px solid #0fb123',
                        ':hover': { border: '1px solid #028b13' }
                      }}>เพิ่ม</Button>


                    </Grid>

                  </Grid>

                  <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />

                </Grid>

                <Grid item lg={8} xs={12}  >
                  <Grid container>
                    <Grid item lg={3} sm={6} xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>
                      <Typography sx={{ marginRight: '15px', }}>แนปไฟล์เอกสารที่เกี่ยวข้อง</Typography>
                    </Grid>
                    {/* {boardDetail[0]?.boardAttachment.length !== files.length ? boardDetail[0]?.boardAttachment.map(x => setFiles([x, ...files])) : ''} */}

                    <Grid item lg={2} sm={6} xs={12}>
                      <input
                        type="file" ref={fileRef} accept="image/*,.pdf,.doc,.docx,.xlsx,.csv,.xls" hidden onChange={
                          // event => {
                          // handleFileUpload(event)
                          // setFieldValue('boardAttach', files)
                          // }
                          handleFileUpload
                        } />
                      <Button
                        disabled={location.state.open === 'see' ? true : false}

                        variant='outlined' onClick={() => fileRef.current.click()} sx={{
                          height: 'fit-content', fontSize: '18px', color: '#0fb123', border: '1px solid #0fb123', marginRight: '10px',
                          ':hover': { border: '1px solid #028b13' }
                        }}>อัพโหลดไฟล์</Button>
                    </Grid>

                    <Grid item lg={7} xs={12} sx={{ display: 'flex', alignItems: 'end' }}>
                      {files?.filter((z) => z?.status === true).map((x, i) =>
                        <Box sx={{ width: '120px', display: 'flex', alignItems: 'center', border: '1px solid #0089ff', borderRadius: '15px', padding: '3px 5px 3px 10px', marginRight: '10px' }}>
                          <Typography onClick={() => FileDownload(x)} sx={{
                            height: 'auto',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: '1',
                            overflow: 'hidden',
                            cursor: 'pointer'
                          }}>{x?.file?.filename || x.filename}</Typography>
                          <CancelIcon onClick={() => handleRemoveFileUpload(x, i)} sx={{ display: location.state.open === 'see' ? 'none' : 'block', color: '#0089ff', cursor: 'pointer' }} />
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} >
                  <Grid container>
                    <Grid item lg={2} md={3} xs={12} sx={{ display: 'flex', alignItems: 'start', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>
                      <Typography sx={{ marginRight: '15px', }}></Typography>
                    </Grid>
                    <Grid item lg={6} md={9} xs={12}>
                      <Typography sx={{ color: '#d7d7d7', fontSize: '12px' }}>ไฟล์อัพโหลดต้องมีขนาดไม่เกิน 8 MB เเละเป็นไฟล์ PNG, JPG, DOC, XML, PDF  </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} >
                  <Grid container>
                    <Grid item lg={2} md={3} xs={12} sx={{ display: 'flex', alignItems: 'start', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>
                      <Typography sx={{ marginRight: '15px', }}>เหตุผลสิ้นสุดวาระ</Typography>
                    </Grid>
                    <Grid item lg={6} md={9} xs={12}>
                      <TextField
                        disabled={location.state.open === 'see' ? true : false}
                        label="" name='remark' value={values.remark} onChange={e => setFieldValue('remark', e.target.value)} variant="outlined" multiline
                        rows={6} color="success" sx={{ width: '100%' }} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

            </Box>
          </Container>
          <Box sx={{
            position: 'sticky', bottom: '0px', width: '100%', marginTop: '50px', background: '#fff'
          }}>
            <Divider sx={{ marginBottom: '10px' }} />
            <Container maxWidth='xl' sx={{ height: 'auto', }}>
              <Box sx={{ padding: '5px 20px', display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant='outlined' sx={{
                  width: '150px', height: 'fit-content', fontSize: '18px', color: '#0fb123', border: '1px solid #0fb123',
                  ':hover': { border: '1px solid #028b13' }
                }} component={NavLink} to={`/Committee/${param.id}`}>กลับ</Button>
                <Button disabled={location.state.open === 'see' ? true : false} variant='contained' type='submit' sx={{ width: '150px', height: 'fit-content', fontSize: '18px', marginLeft: '20px', backgroundColor: '#0fb123', ':hover': { backgroundColor: '#028b13' } }}>บันทึก</Button>
              </Box>
            </Container>
          </Box>

        </form>)}
    </Formik>
    //   }
    // </>
  )
}
