import {
  Box,
  Button,
  TextField,
  FormControl,
  Select,
  InputLabel,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Container,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { NavLink } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import TitleHead from "../../components/TitleHead";
import ApiContext from "../../context/ApiProvider";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { selectCanActivate } from "../../store/modules/selector";
import api from "../../api/api";

function createData(name, pee, datec, datea, status) {
  return { name, pee, datec, datea, status };
}

const rows = [
  createData("ฝ่ายตรวจสอบภายใน", 2561, "25/09/42", "30/09/42", "เสร็จสิ้น"),
];

export default function DataVerification() {
  const [boardDepartmentList, setBoardDepartmentList] = useState([]);

  const { getAppointerList } = useContext(ApiContext);
  const [open, setOpen] = React.useState(false);
  const [titleBoard, setTitleBoard] = useState("");
  const [item, setItem] = useState();
  const [appointerList, setAppointerList] = useState([]);

  const [searchDBoard, setSearchDBoard] = useState("");
  const [body, setBody] = useState("");
  const checkPermission = useSelector(selectCanActivate);
  const handleClickSearchDBoard = () => {
    setBody(searchDBoard);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openM = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickOpen = () => {
    setOpen(true);
    // setItem(item)
    // console.log(item)
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const handleSubmit = () => {
    const data = {
      appointerID: "",
      appointerName: titleBoard,
      status: true,
    };
    const post = async () => {
      const res = await api.post(`api/v1.0/appointer/createDonate`, data)
      const data = await res.data
      if (data.status === true) {
        setOpen(false);

        Swal.fire(
          "สร้างเเล้ว"
          // 'success'
        );
      }
      post()
    }

    // addBoardDepartment(data, setOpen);
  };
  useEffect(() => {
    getAppointerList(setAppointerList, body);
  }, [getAppointerList, body]);

  return (
    <Container
      maxWidth="xl"
      sx={{ height: "auto", marginTop: "40px", marginBottom: "40px" }}
    >
      <TitleHead title="ผู้เเต่งตั้ง" subTitle="ข้อมูลผู้เเต่งตั้ว">
        {/* <Button variant='contained' component={NavLink} to='/DataVerification/Addverifi'  sx={{ height: 'fit-content', fontSize: '18px', backgroundColor: '#0fb123', ':hover': { backgroundColor: '#028b13' } }}state={{ add: true }}>สร้างใหม่</Button> */}
        {checkPermission.find((x) => x.menuNo === "M002" && x.isAdd) && (
          <Button
            // component={NavLink}
            // to="/DataVerification/Addverifi"
            onClick={handleClickOpen}
            sx={{
              backgroundColor: "#1fc64c",
              "&:hover": { background: "#4caf50" },
              color: "#FFFF",
              padding: "7px 55px",
              marginTop: "23px",
            }}
            state={{ add: true }}
          >
            สร้างใหม่
          </Button>
        )}
      </TitleHead>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "20px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {/* <FormControl
            sx={{ m: 1, minWidth: 100, marginRight: 3 }}
            color="success"
          >
            <InputLabel>ทั้งหมด</InputLabel>
            <Select
              // open={open}
              // onClose={handleClose}
              // onOpen={handleOpen}
              // defaultValue={1}
              // value={site}
              // onChange={handleSelect}
              label="Fillter"
            >
              <MenuItem value="1">คณะกรรมการพิจารณา</MenuItem>
              <MenuItem value="2">ปี</MenuItem>
            </Select>
          </FormControl> */}
          <TextField
            id="outlined-basic"
            label="กรอกข้อมูลค้นหา"
            value={searchDBoard}
            onChange={(e) => setSearchDBoard(e.target.value)}
            variant="outlined"
            color="success"
            size="small"
          />
          <Button
            onClick={handleClickSearchDBoard}
            variant="contained"
            sx={{
              padding: "7px",
              marginLeft: "20px",
              backgroundColor: "#0fb123",
              ":hover": { backgroundColor: "#028b13" },
            }}
          >
            <SearchIcon />
          </Button>
        </Box>
      </Box>
      <TableContainer>
        <Table
          sx={{ minWidth: 400, borderCollapse: "inherit", borderSpacing: "0" }}
        >
          <TableHead sx={{ backgroundColor: "#EDEFF0", fontWeight: "500" }}>
            <TableRow>
              <TableCell
                sx={{
                  color: "#000",
                  border: "none",
                  fontSize: "18px",
                  fontWeight: "500",
                }}
                align="left"
              >
                #
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  border: "none",
                  fontSize: "18px",
                  fontWeight: "500",
                }}
                align="left"
              >
                ชื่อ-นามสกุล
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  border: "none",
                  fontSize: "18px",
                  fontWeight: "500",
                }}
                align="left"
              >

              </TableCell>
              {/* <TableCell
                sx={{
                  color: "#000",
                  border: "none",
                  fontSize: "18px",
                  fontWeight: "500",
                }}
                align="left"
              >
                วันที่อนุมัติ
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  border: "none",
                  fontSize: "18px",
                  fontWeight: "500",
                }}
                align="left"
              >
                สถานะ
              </TableCell>
              <TableCell
                sx={{ color: "#000", border: "none" }}
                align="center"
              ></TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {appointerList.map((board, i) => (
              <>
                <TableRowC key={i} i={i} board={board} />
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog maxWidth="md" fullWidth={true} open={open} onClose={handleClose}>
        <DialogTitle sx={{ fontSize: "2rem" }}>สร้างชื่อผู้เเต่งตั้ง</DialogTitle>
        <DialogContent sx={{ padding: "10px 60px" }}>
          {/* <DialogContentText>
              To subscribe to this website, please enter your email address here. We
              will send updates occasionally.
            </DialogContentText> */}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="ชื่อผู้เเต่งตั้ง"
            type="text"
            fullWidth
            color="success"
            variant="standard"
            value={titleBoard}
            onChange={(e) => setTitleBoard(e.target.value)}
            sx={{
              ".MuiInputBase-root, .MuiInputLabel-root": { fontSize: "1.5rem" },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{
              width: "150px",
              height: "fit-content",
              fontSize: "18px",
              color: "#0fb123",
              border: "1px solid #0fb123",
              ":hover": { border: "1px solid #028b13" },
            }}
          >
            ยกเลิก
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{
              width: "150px",
              height: "fit-content",
              fontSize: "18px",
              marginLeft: "20px",
              backgroundColor: "#0fb123",
              ":hover": { backgroundColor: "#028b13" },
            }}
          >
            สร้าง
          </Button>
        </DialogActions>
      </Dialog>
    </Container>

  );
}

export function TableRowC({ board, i }) {
  const [open, setOpen] = React.useState(false);
  const [titleBoard, setTitleBoard] = useState();
  const [item, setItem] = useState();
  const [searchDBoard, setSearchDBoard] = useState("");
  const [textSearchDBoard, setTextSearchDBoard] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openM = Boolean(anchorEl);
  const checkPermission = useSelector(selectCanActivate);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickOpen = (e, item) => {
    setOpen(true);
    setTitleBoard(board?.appointerName);
    // setItem(item);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const handleSubmit = () => {
    const data = {
      appointerID: board?.appointerID,
      appointerName: titleBoard,
      status: true,
    };
    const post = async () => {
      const res = await api.post(`api/v1.0/appointer/createDonate`, data)
      const data = await res.data
      if (data.status === true) {
        setOpen(false);

        Swal.fire(
          "เเก้ไขเเล้ว"
          // 'success'
        );
      }
      post()
    }
  }

  const handleDelet = (e, item) => {
    setAnchorEl(null);

    const data = {
      boardDepID: item?.boardDepID,
      boardDepName: titleBoard,
      status: false,
    };
    Swal.fire({
      title: "ต้องการลบหรือไม่ ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "ลบ",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          "ลบเเล้ว"
          // 'success'
        );
      }
    });
  };
  return (
    <>
      <Dialog maxWidth="md" fullWidth={true} open={open} onClose={handleClose}>
        <DialogTitle sx={{ fontSize: "2rem" }}>เเก้ไขชื่อผู้เเต่งตั้ง</DialogTitle>
        <DialogContent sx={{ padding: "10px 60px" }}>
          {/* <DialogContentText>
              To subscribe to this website, please enter your email address here. We
              will send updates occasionally.
            </DialogContentText> */}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="ชื่อผู้เเต่งตั้ง"
            type="text"
            fullWidth
            color="success"
            variant="standard"
            value={titleBoard}
            onChange={(e) => setTitleBoard(e.target.value)}
            sx={{
              ".MuiInputBase-root, .MuiInputLabel-root": { fontSize: "1.5rem" },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{
              width: "150px",
              height: "fit-content",
              fontSize: "18px",
              color: "#0fb123",
              border: "1px solid #0fb123",
              ":hover": { border: "1px solid #028b13" },
            }}
          >
            ยกเลิก
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            sx={{
              width: "150px",
              height: "fit-content",
              fontSize: "18px",
              marginLeft: "20px",
              backgroundColor: "#0fb123",
              ":hover": { backgroundColor: "#028b13" },
            }}
          >
            อัพเดท
          </Button>
        </DialogActions>
      </Dialog>
      {/* {console.log(board)} */}
      <TableRow key={i}>
        <TableCell
          sx={{ color: "#000", width: "20%", fontSize: "16px" }}
          align="left"
        >
          {board?.appointerID}
        </TableCell>
        <TableCell
          sx={{ color: "#000", width: "20%", fontSize: "16px" }}
          align="left"
        >
          {board?.appointerName}
        </TableCell>
        {/* <TableCell
          sx={{ color: "#000", width: "20%", fontSize: "16px" }}
          align="left"
        >
          {board?.datec}
        </TableCell> */}
        {/* <TableCell
          sx={{ color: "#000", width: "20%", fontSize: "16px" }}
          align="left"
        >
          {board?.datea}
        </TableCell>
        <TableCell
          sx={{
            color: "#000",
            width: "20%",
            fontSize: "16px",
            color: "#0fb123",
          }}
          align="left"
        >
          {board?.status}
        </TableCell> */}

        <TableCell
          sx={{ color: "#000", width: "30%", position: "relative" }}
          align="right"
        >
          {/* <Button variant='outlined' sx={{
          height: 'fit-content', fontSize: '18px', color: '#0fb123', border: '1px solid #0fb123',
          ':hover': { border: '1px solid #028b13' }
        }}
          onClick={handleClick}
        // component={NavLink} to={`/Committee/${board?.boardDepID}`} state={{ state: true, title: board?.boardDepName }}
        >ดูทั้งหมด</Button> */}
          <MoreHorizIcon
            onClick={handleClick}
            sx={{ cursor: "pointer", fontSize: "35px" }}
          />

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openM}
            onClose={handleClose}
            // elevation={0}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              ".MuiPopover-paper": {
                boxShadow: "unset",
                border: "1px solid",
              },
            }}
          >
            {/* {checkPermission.find((x) => x.menuNo === "M002" && x.isView) && (
              <MenuItem
                sx={{ width: "100px", fontSize: "20px" }}
                component={NavLink}
                to={`/Committee/${board?.boardDepID}`}
                onClick={(e) =>
                  localStorage.setItem("title", board?.boardDepName)
                }
                state={{ state: true, title: board?.boardDepName }}
              >
                ดู
              </MenuItem>
            )} */}
            {checkPermission.find((x) => x.menuNo === "M002" && x.isEdit) && (
              <MenuItem
                sx={{ width: "100px", fontSize: "20px" }}
                onClick={(e) => handleClickOpen(e, board)}
              >
                เเก้ไข
              </MenuItem>
            )}
            {checkPermission.find((x) => x.menuNo === "M002" && x.isDelete) && (
              <MenuItem
                sx={{ width: "100px", fontSize: "20px" }}
                onClick={(e) => handleDelet(e, board)}
              >
                ลบ
              </MenuItem>
            )}
          </Menu>
        </TableCell>
      </TableRow>
    </>
  );
}
