import httpClientInstants from "../../libs/utils/HttpClient";
class AuthService {
  refreshToken = async (refreshToken) => {
    try {
      const response = await httpClientInstants.get(
        "api/v1.0/auth/refresh",
        {}
      );

      if (response.status) return response;
      return Promise.reject(response);
    } catch (error) {
      const newError = error;
      throw newError;
    }
  };
}

export default new AuthService();
