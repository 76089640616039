import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../api/api";

const initialState = {
  canActivate: [],
  loading: false,
  error: "",
};
export const actionAsync = createAsyncThunk("activate", async () => {
  try {
    const activate = await api.get(
      "api/v1.0/setting/configaction/list/bytoken"
    );
    if (activate?.data?.code === 2000) {
      return activate.data.result;
    }
  } catch (error) {
    throw error;
  }
});

const actionSlice = createSlice({
  name: "canActivate",
  initialState,
  reducers: {},
  extraReducers: {
    [actionAsync.pending]: (state, action) => {
      state.loading = true;
      state.error = "";
    },
    [actionAsync.fulfilled]: (state, action) => {
      state.canActivate = action.payload;
      state.loading = false;
      state.error = "";
    },
    [actionAsync.rejected]: (state, action) => {
      state.canActivate = [];
      state.loading = false;
      state.error = action.error.message;
    },
  },
});
export default actionSlice.reducer;
