import { Autocomplete, Box, Button, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom';
import TitleHead from '../../components/TitleHead'
import ApiContext from '../../context/ApiProvider';
import api from '../../api/api'
import moment from 'moment';
export default function AddBoardDep() {
    const location = useLocation()
    const { postBoardCreate, getBoardList, postBoardMeet } = useContext(ApiContext)
    const [meetName, setMeetName] = useState('')
    const [present, setPresent] = useState('')
    const [loading, setLoading] = useState(false)
    const [checkedPin, setCheckedPin] = useState(false);
    const [checkedCer, setCheckedCer] = useState(false);
    const [meetDate, setMeetDate] = useState(null);
    const [seqMeet, setSeqMeet] = useState(0);
    const [boardList, setBoardList] = useState([])
    const [mpreset, setMPresent] = useState([]);
    const [boardMeetPer, setBoardMeetPer] = useState([]);
    const [open, setOpen] = useState(false)
    const [openSave, setOpenSave] = useState(false)
    const fileRef = useRef()
    const [uploadImg, setUploadedImg] = useState();
    // console.log(location)
    const handleFileChange = async (event) => {
        const fileObj = event.target.files && event.target.files[0];
        // console.log('fileObj is', fileObj);

        let formData = new FormData();
        formData.append("file", fileObj);
        // event.target.value = null;

        const res = await api.post(`api/v1.0/filemanages/upload`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                // 'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySUQiOiI0NjIxZDJmZS04ZjkyLTQyNDctODcwZi1jYThiNTNkOGJhZTAiLCJyb2xlIjp7InJvbGVJRCI6IjEiLCJyb2xlTmFtZSI6IkRldmVsb3BlciIsImxldmVsIjoxLCJzdGF0dXMiOnRydWUsImNyZWF0ZUlEIjpudWxsLCJjcmVhdGVEYXRlIjoiMjAyMi0wNy0yMFQwNzoxMTozMi41MTlaIiwibW9kaWZpZWRJRCI6bnVsbCwibW9kaWZpZWREYXRlIjoiMjAyMi0wNy0yMFQwNzoxMTozMi41MTlaIn0sImlhdCI6MTY2Mzc0MzczMCwiZXhwIjoxNjY0NjQzNzMwfQ.GkENpUAqQny_TthYu9ZwzNTaGlZmfXq7Ytt5rtbK3R4`
            },
        });
        const data = await res.data;
        if (data.status === true) {
            setUploadedImg(data.result);
            const handleFileChange = async (event) => {
                const fileObj = event.target.files && event.target.files[0];
                // console.log('fileObj is', fileObj);

                let formData = new FormData();
                formData.append("file", fileObj);
                // event.target.value = null;

                const res = await api.post(`api/v1.0/filemanages/upload`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        // 'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySUQiOiI0NjIxZDJmZS04ZjkyLTQyNDctODcwZi1jYThiNTNkOGJhZTAiLCJyb2xlIjp7InJvbGVJRCI6IjEiLCJyb2xlTmFtZSI6IkRldmVsb3BlciIsImxldmVsIjoxLCJzdGF0dXMiOnRydWUsImNyZWF0ZUlEIjpudWxsLCJjcmVhdGVEYXRlIjoiMjAyMi0wNy0yMFQwNzoxMTozMi41MTlaIiwibW9kaWZpZWRJRCI6bnVsbCwibW9kaWZpZWREYXRlIjoiMjAyMi0wNy0yMFQwNzoxMTozMi41MTlaIn0sImlhdCI6MTY2Mzc0MzczMCwiZXhwIjoxNjY0NjQzNzMwfQ.GkENpUAqQny_TthYu9ZwzNTaGlZmfXq7Ytt5rtbK3R4`
                    },
                });
                const data = await res.data;
                if (data.status === true) {
                    setUploadedImg(data.result);

                }
            };
        }
    };
    // const handleChange = (event) => {
    //     setChecked(event.target.checked);
    // };
    const handleClose = () => {
        setOpen(false);

    };
    const handleSubmit = () => {
        const body = {
            boardDepID: "",
            boardDepName: meetName,
            isPin: checkedPin,
            isCer: checkedCer,
            present: present,
            status: true
        }
        postBoardCreate(body, setOpenSave)
    }
    useEffect(() => {
        const getMasterPresent = async () => {
            const res = await api.get(`api/v1.0/master/present/list`)
            const data = await res.data
            if (data.status === true) {
                setMPresent(data.result)
            }

        }
        getMasterPresent()
    }, [])
    useEffect(() => {
        const param = { id: location?.state?.boardDepID }
        getBoardList(setBoardList, param, '', '')
    }, [location])
    const columns = [
        // { field: 'id', headerName: 'ID', width: 50 },
        { field: 'title', headerName: 'คำนำหน้า', width: 150 },
        {
            field: 'firstName',
            headerName: 'ชื่อ',
            width: 180,
            editable: true,
        },
        {
            field: 'lastName',
            headerName: 'นามสกุล',
            width: 180,
            editable: true,
        },
        {
            field: 'newPosition',
            headerName: 'ตำเเหน่งในคณะกรรมการ',
            width: 200,
            editable: true,
        },


    ];
    // console.log(boardList)
    const rows = boardList?.map((x, i) => {
        return {
            id: 1,
            title: x?.personal?.title?.titleNameTH,
            firstName: x?.personal?.firstname,
            lastName: x?.personal?.lastname,
            newPosition: x?.newPosition?.positionName
        }
    }
    )
    // honorPinListCandidate?.map((x, i) => {
    //     return {
    //         id: i + 1,
    //         title: x?.title,
    //         firstName: x?.firstname,
    //         lastName: x?.lastname,
    //         cerName: x?.cerName,
    //         oldRankDate: moment(x?.oldRankDate).add(543, 'year').format('DD/MM/yyyy').substring(6) === 'd date' ? '' : moment(x?.oldRankDate).add(543, 'year').format('DD/MM/yyyy').substring(6),
    //         oldRank: x?.oldRank ? `ชั้นที่ ` + x?.oldRank : '',
    //         rankCounter: x?.cerName === 'ผู้บริจาคเงิน' ? `${parseFloat(x?.rankCounter, 10).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} บาท` : x?.cerName === 'ประธาน รองประธาน กรรมการสภาสถาบัน' ? x?.rankCounter : x?.rankCounter,
    //         rank: x?.rank ? `ชั้นที่ ` + x?.rank : ''
    //     }
    // });

    const [selectionModel, setSelectionModel] = React.useState([]);

    const boardBody = {
        boardMeetID: "",
        boardDepID: location?.state?.boardDepID || 0,
        meetDate: moment(meetDate).format('YYYY-MM-DD') === 'Invalid date' ? moment(new Date()).format('YYYY-MM-DD') : moment(meetDate).format('YYYY-MM-DD'),
        seqMeet: seqMeet,
        fileID: uploadImg?.fileID,
        status: true,
        boardMeetPer: boardList?.map((x, i) => {
            return {
                personalID: x?.personalID,
                status: selectionModel?.filter(y => y === i + 1)[0] == i + 1
            }
        })
    }

    const handleSave = () => {
        postBoardMeet(boardBody)
    }
    return (
        <>
            <Container
                maxWidth="xl"
                sx={{ height: "auto", marginTop: "80px", marginBottom: "320px" }}
            >
                <TitleHead title="สร้างชุดคณะกรรมการ" subTitle="ข้อมูลทะเบียนคณะกรรมการ">
                    {/* <Button variant='contained' onClick={handleClickOpen} sx={{ height: 'fit-content', fontSize: '18px', backgroundColor: '#0fb123', ':hover': { backgroundColor: '#028b13' } }}>สร้างใหม่</Button> */}
                </TitleHead>
                <Grid container spacing={2} sx={{ marginTop: '80px', marginBottom: '40px' }}>
                    <Grid item xs={12} >
                        <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                            <Grid item lg={2} md={3} xs={12} sx={{ display: 'flex', alignItems: 'start', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>
                                <Typography sx={{ marginRight: '15px', }}>ชื่อการประชุม</Typography>
                            </Grid>


                            <Grid item lg={10} md={9} xs={12}>

                                <TextField label="" size='small' value={meetName} onChange={e => setMeetName(e.target.value)} variant="outlined" color="success" type="text" sx={{ width: '500px' }} />

                            </Grid>
                            <Grid item lg={2} md={3} xs={12} sx={{ display: 'flex', alignItems: 'start', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>
                                <Typography sx={{ marginRight: '15px', }}>สิทธิการได้รับ</Typography>
                            </Grid>


                            <Grid item lg={10} md={9} xs={12} sx={{ display: 'flex', alignItems: 'center' }}>

                                <Checkbox
                                    checked={checkedPin}
                                    onChange={() => setCheckedPin(!checkedPin)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    sx={{ marginRight: '15px', }}
                                />
                                <Typography sx={{ marginRight: '15px', }}>เข็มกิตติคุณ</Typography>
                                <Checkbox
                                    checked={checkedCer}
                                    onChange={() => setCheckedCer(!checkedCer)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    sx={{ marginRight: '15px', }}
                                />
                                <Typography sx={{ marginRight: '15px', }}>ประกาศนียบัตรชั้นสูงกิตติมศักดิ์</Typography>

                            </Grid>
                            <Grid item lg={2} md={3} xs={12} sx={{ display: 'flex', alignItems: 'start', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>
                                <Typography sx={{ marginRight: '15px', }}>ผู้เสนอ</Typography>
                            </Grid>


                            <Grid item lg={10} md={9} xs={12}>
                                <Autocomplete
                                    id="free-solo-demo"
                                    // freeSolo
                                    name="certificate"
                                    inputValue={present}
                                    onInputChange={(event, newInputValue) => {
                                        setPresent(newInputValue)
                                    }}
                                    options={mpreset?.map((option) => option?.presentFullName + ' ' + option.presentShortName)}
                                    renderInput={(params) => <TextField required placeholder='จำเป็นต้องระบุ' {...params} size='small' label="" color="success" sx={{ width: '500px' }} />}
                                />
                                
                            </Grid>
                            {/* <Grid item lg={2} md={3} xs={12} sx={{ display: 'flex', alignItems: 'start', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>
                                <Typography sx={{ marginRight: '15px', }}>การประชุม</Typography>
                            </Grid>


                            <Grid item lg={10} md={9} xs={12}>

                                <DesktopDatePicker
                                    label=""
                                    inputFormat="DD/MM/YYYY"
                                    value={date}

                                    onChange={(newValue) => { setDate(newValue) }}
                                    renderInput={(params) => <TextField size='small' color="success" sx={{ width: '200px', marginRight: '20px' }}
                                        InputProps={params?.InputProps}
                                        inputRef={params?.inputRef}
                                        placeholder={params?.inputProps?.placeholder}
                                        value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                                        onChange={params?.inputProps?.onChange}
                                        type={params?.inputProps?.type}
                                    />}
                                />
                                <TextField label="" size='small' variant="outlined" color="success" type="text" onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }} sx={{ width: '80px', marginRight: '20px' }} />
                                <Button variant='contained' onClick={() => setOpen(true)} sx={{ width: '150px', height: 'fit-content', fontSize: '18px', backgroundColor: '#0fb123', ':hover': { backgroundColor: '#028b13' } }}>ผู้เข้าร่วมประชุม</Button>

                            </Grid>
                            <Grid item lg={2} md={3} xs={12} sx={{ display: 'flex', alignItems: 'start', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>
                                <Typography sx={{ marginRight: '15px', }}></Typography>
                            </Grid>


                            <Grid item lg={10} md={9} xs={12}>

                                <Button variant='outlined' sx={{
                                    width: '150px', height: 'fit-content', fontSize: '18px', color: '#0fb123', border: '1px solid #0fb123',
                                    ':hover': { border: '1px solid #028b13' }
                                }}>อัพโหลดไฟล์</Button>
                            </Grid>
                            <Grid item lg={2} md={3} xs={12} sx={{ display: 'flex', alignItems: 'start', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>
                                <Typography sx={{ marginRight: '15px', }}></Typography>
                            </Grid>


                            <Grid item lg={10} md={9} xs={12}>

                                <Button variant='outlined' sx={{
                                    width: '150px', height: 'fit-content', fontSize: '18px', color: '#0fb123', border: '1px solid #0fb123',
                                    ':hover': { border: '1px solid #028b13' }
                                }}>เพิ่ม</Button>

                            </Grid> */}
                        </Grid>
                        {/* {openSave && <Grid container spacing={2} sx={{ alignItems: 'center', marginTop: '10px' }}>
                            <Grid item lg={2} md={3} xs={12} sx={{ display: 'flex', alignItems: 'start', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>
                                <Typography sx={{ marginRight: '15px', }}>การประชุม</Typography>
                            </Grid>


                            <Grid item lg={10} md={9} xs={12}>

                                <DesktopDatePicker
                                    label=""
                                    inputFormat="DD/MM/YYYY"
                                    value={meetDate}

                                    onChange={(newValue) => { setMeetDate(newValue) }}
                                    renderInput={(params) => <TextField size='small' color="success" sx={{ width: '200px', marginRight: '20px' }}
                                        InputProps={params?.InputProps}
                                        inputRef={params?.inputRef}
                                        placeholder={params?.inputProps?.placeholder}
                                        value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                                        onChange={params?.inputProps?.onChange}
                                        type={params?.inputProps?.type}
                                    />}
                                />
                                <TextField label="" size='small' variant="outlined" value={seqMeet} onChange={e => setSeqMeet(e.target.value)} color="success" type="text" onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }} sx={{ width: '80px', marginRight: '20px' }} />
                                <Button variant='contained' onClick={() => setOpen(true)} sx={{ width: '150px', height: 'fit-content', fontSize: '18px', backgroundColor: '#0fb123', ':hover': { backgroundColor: '#028b13' } }}>ผู้เข้าร่วมประชุม</Button>

                            </Grid>
                            <Grid item lg={2} md={3} xs={12} sx={{ display: 'flex', alignItems: 'start', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>
                                <Typography sx={{ marginRight: '15px', }}></Typography>
                            </Grid>


                            <Grid item lg={10} md={9} xs={12} sx={{ display: 'flex', alignItems: 'center' }}>

                                <input
                                    type="file" ref={fileRef} hidden onChange={handleFileChange} />
                                <Button variant='contained' onClick={() => fileRef.current.click()} sx={{ width: '150px', height: 'fit-content', fontSize: '18px', backgroundColor: '#0fb123', ':hover': { backgroundColor: '#028b13' } }}>อัพโหลดไฟล์</Button>
                                <Typography sx={{ marginLeft: '15px', }}>{uploadImg?.filename}</Typography>
                            </Grid>
                            <Grid item lg={2} md={3} xs={12} sx={{ display: 'flex', alignItems: 'start', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>

                            </Grid>


                            <Grid item lg={10} md={9} xs={12}>

                                <Button variant='outlined' sx={{
                                    width: '150px', height: 'fit-content', fontSize: '18px', color: '#0fb123', border: '1px solid #0fb123',
                                    ':hover': { border: '1px solid #028b13' }
                                }}>เพิ่ม</Button>

                            </Grid>
                            <Grid item lg={2} md={3} xs={12} sx={{ display: 'flex', alignItems: 'start', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>

                            </Grid>


                            <Grid item lg={10} md={9} xs={12}>

                                {/* <Typography>{location?.state}</Typography> */}

                        {/* </Grid>
                        </Grid>} */}
                    </Grid>
                </Grid>


            </Container>
            <Box sx={{
                position: 'sticky', bottom: '0px', width: '100%', marginTop: '50px', background: '#fff', zIndex: '100'
            }}>
                <Divider sx={{ marginBottom: '10px' }} />
                <Container maxWidth='xl' sx={{ height: 'auto', }}>
                    <Box sx={{ padding: '5px 20px', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant='outlined' sx={{
                            width: '150px', height: 'fit-content', fontSize: '18px', color: '#0fb123', border: '1px solid #0fb123',
                            ':hover': { border: '1px solid #028b13' }
                        }} component={NavLink} to={`/Committee`}>กลับ</Button>
                        <Button variant='contained' disabled={meetName !== '' && present !== '' ? false : true} onClick={handleSubmit} sx={{ width: '150px', height: 'fit-content', fontSize: '18px', marginLeft: '20px', backgroundColor: '#0fb123', ':hover': { backgroundColor: '#028b13' } }}>บันทึก</Button>
                    </Box>
                </Container>
            </Box>
            <Dialog
                maxWidth="md"
                fullWidth={true}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle sx={{ fontSize: "2rem" }}>สร้างชุดคณะกรรมการ</DialogTitle>
                <Divider sx={{ marginBottom: '20px' }} />
                <DialogContent sx={{ padding: "10px 60px" }}>
                    <Box sx={{ height: 400, width: '100%', }}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            hideFooter={true}
                            checkboxSelection
                            disableSelectionOnClick
                            disableColumnMenu
                            experimentalFeatures={{ newEditingApi: true }}
                            onSelectionModelChange={(newSelectionModel) => {
                                setSelectionModel(newSelectionModel);
                            }}
                            selectionModel={selectionModel}
                        />
                    </Box>

                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        variant="outlined"
                        sx={{
                            width: "150px",
                            height: "fit-content",
                            fontSize: "18px",
                            color: "#0fb123",
                            border: "1px solid #0fb123",
                            ":hover": { border: "1px solid #028b13" },
                        }}
                    >
                        ยกเลิก
                    </Button>
                    <Button
                        onClick={handleSave}
                        variant="contained"

                        sx={{
                            width: "150px",
                            height: "fit-content",
                            fontSize: "18px",
                            marginLeft: "20px",
                            backgroundColor: "#0fb123",
                            ":hover": { backgroundColor: "#028b13" },
                        }}
                    >
                        สร้าง
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
