import Layout from "./components/Layout";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import { ThemeProvider, CssBaseline } from "@mui/material";
import PersonalInformation from "./page/Personal_Information";
import Committee from "./page/Committee";
import theme from "./styles/theme";
import CommitteeList from "./page/Committee/CommitteeList";
import AddpersonalInformation from "./page/Personal_Information/Addpersonal_information";
import AddCommittee from "./page/Committee/AddCommittee";
import Login from "./page/Login";
import Eiditpersonal from "./page/Personal_Information/Eidit_personal";
import envInstants from "./libs/configs/env";
import { setDefaultURL } from "./api/api";
import { useDispatch } from "react-redux";
import { profileAsync } from "./store/slices/profileSlice";
import { useEffect, useState } from "react";
import DetailCommittee from "./page/Committee/DetailCommittee";
import DataVerification from "./page/DataVerification";
import Diploma from "./page/Diploma";
import Addverifi from "./page/DataVerification/Addverifi";
import Addformation from "./page/Scienceinformation/Addformation";
import Scienceinformation from "./page/Scienceinformation";
import AddDiploma from "./page/Diploma/AddDiploma";
import Notification from "./page/Notification";
import Lecturer from "./page/Lecturer";
import ManageLecturer from "./page/Lecturer/ManageLecturer";
import Donate from "./page/Donate";
import CalculateRules from "./page/CalculateRules";
import Report from "./page/Report";
import HonorPin from "./page/HonorPin";
import AddHonor from "./page/HonorPin/AddHonor";
import ForgotPassword from "./page/ForgotPassword";
import ResetPassword from "./page/ResetPassword";
import UserList from "./page/Setting/UserList";
import AddUser from "./page/Setting/AddUser";
import Permission from "./page/Permission";
import MenuActivate from "./page/MenuActivate";
import DetailHonor from "./page/HonorPin/DetailHonor";
import Logfile from "./page/Logfile";
import Loguser from "./page/Loguser";
import { actionAsync } from "./store/slices/actionSlice";
import DetailHonorDiploma from "./page/Diploma/DetailHonorDiploma";
import Report1 from "./page/Report/Report1";
import Report2 from "./page/Report/Report2";
import Report3 from "./page/Report/Report3";
import Report4 from "./page/Report/Report4";
import Report5 from "./page/Report/Report5";
import Report6 from "./page/Report/Report6";
import Report7 from "./page/Report/Report7";
import Report8 from "./page/Report/Report8";
import Report9 from "./page/Report/Report9";
import Report10 from "./page/Report/Report10";
import Report11 from "./page/Report/Report11";
import Report12 from "./page/Report/Report12";
import Report13 from "./page/Report/Report13";
import AddBoardDep from "./page/Committee/AddBoardDep";
import EditBoardDep from "./page/Committee/EditBoardDep";
import { baseUrlAsync } from "./store/slices/baseUrlSlice";
import Profile from "./page/Profile";
import PresentList from "./page/Present/PresentList";
import AddPresent from "./page/Present/AddPresent";
function App() {
  const Dispatch = useDispatch();

  const local_token = localStorage.getItem("accessToken");
  const [isInitEnvError, setInitEnvError] = useState(false);
  const [isInitEnv, setInitEnv] = useState(false);
  const [notification, setnotification] = useState([]);

  useEffect(() => {
    const loadEnv = async () => {
      try {
        await envInstants.init();
        // httpClientInstants.setBaseUrl(envInstants.getConfig().baseUrl);
        setDefaultURL(envInstants.getConfig().baseUrl);
        // const userRes = await api.post("/api/users/user");
        // console.log(userRes);
        // const roleRes = await api.get(
        //   `/api/setting/actions/role?Role=${userRes.data.results.roleId}`
        // );

        const url = envInstants.getConfig().baseUrl
        if (local_token) {
          Dispatch(profileAsync());
          Dispatch(actionAsync());
          Dispatch(baseUrlAsync(url));
        }
      } catch (error) {
        console.log(error);
        setInitEnvError(true);
        // history.push("/login");
      } finally {
        setInitEnv(true);
      }
    };

    loadEnv();
  }, [Dispatch]);

  useEffect(() => {
    if (local_token) {
      const interval = setInterval(() => {
        let newFotmat = new Date(
          localStorage.getItem("ActiveTimeOut")
        ).getHours(); //เวลาออนไลน์ล่าสุด +2 ชม
        let newDate = new Date().getHours(); // ชม ปัจจุบัน
        let sum = newDate - newFotmat;

        if (sum >= 2) {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("ActiveTimeOut");
          window.location = "/Login";
        }
      }, 100000);
      return () => clearInterval(interval);
    }
  }, []);

  if (!isInitEnv) return "loading...";
  if (isInitEnvError) return "Cannot load env !!!";
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {local_token ? (
          <Route path="*" element={<Navigate to="/" />} />
        ) : (
          <Route path="/Login" element={<Login />} />
        )}
        <Route path="ForgotPassword" element={<ForgotPassword />} />
        <Route path="ResetPassword" element={<ResetPassword />} />

        <Route
          element={local_token ? <Outlet /> : <Navigate to="/Login" replace />}
        >
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<PersonalInformation />} />
            <Route path="/addpersonal" element={<AddpersonalInformation />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/:id" element={<Eiditpersonal />} />
            <Route path="/committee" element={<Committee />} />
            <Route path="/committee/addboard" element={<AddBoardDep />} />
            <Route path="/committee/editboard/:id" element={<EditBoardDep />} />
            <Route path="/committee/:id" element={<CommitteeList />} />
            <Route path="/committee/:id/add" element={<AddCommittee />} />
            <Route
              path="/committee/:id/detail/:boardId"
              element={<DetailCommittee />}
            />
            <Route path="/DataVerification" element={<DataVerification />} />
            <Route path="/DataVerification/Addverifi" element={<Addverifi />} />
            <Route path="/HonorPin" element={<HonorPin />} />
            <Route path="/HonorPin/AddHonorPin" element={<AddHonor />} />
            <Route
              path="/HonorPin/DetailHonorPin/:id"
              element={<DetailHonor />}
            />

            {/* ข้อมูลวิทยากร */}
            <Route path="/Lecturer" element={<Lecturer />} />
            <Route
              path="Lecturer/LecturerDetail/:id/:views"
              element={<ManageLecturer />}
            ></Route>
            <Route path="LecturerAdd" element={<ManageLecturer />}></Route>
            <Route
              path="Lecturer/LecturerEdit/:id"
              element={<ManageLecturer />}
            ></Route>
            {/* ข้อมูลผู้บริจาค */}
            <Route path="/Donate" element={<Donate />} />
            {/* คำนวณเหรียญ */}
            <Route path="/RuleCalculate" element={<CalculateRules />} />
            {/* รายงาน */}
            <Route path="/Report" element={<Report />} />
            <Route path="/Diploma" element={<Diploma />} />
            <Route path="/Diploma/AddDiploma" element={<AddDiploma />} />
            <Route
              path="/Diploma/DetailHonorCer/:id"
              element={<DetailHonorDiploma />}
            />
            <Route
              path="/Scienceinformation"
              element={<Scienceinformation />}
            />
            <Route
              path="/Scienceinformation/Addformation"
              element={<Addformation />}
            />
            <Route path="/Logfile" element={<Logfile />}></Route>
            <Route path="/Loguser" element={<Loguser />}></Route>
            <Route path="/Notification" element={<Notification />} />
            {/* เริ่มหน้าUser*/}
            <Route path="UserList" element={<UserList />}></Route>
            {/* About User */}
            <Route path="/AddUser" element={<AddUser />}></Route>
            <Route
              path="User/UserDetail/:id/:views"
              element={<AddUser />}
            ></Route>
            <Route path="/PresentList" element={<PresentList />}></Route>
            <Route path="/AddPresent" element={<AddPresent />}></Route>
            <Route
              path="/Present/PresentDetail/:id/:views"
              element={<AddPresent />}
            ></Route>
            <Route path="/Present/PresentEdit/:id" element={<AddPresent />}></Route>

            <Route path="Report1" element={<Report1 />}></Route>
            <Route path="Report2" element={<Report2 />}></Route>
            <Route path="Report3" element={<Report3 />}></Route>
            <Route path="Report4" element={<Report4 />}></Route>
            <Route path="Report5" element={<Report5 />}></Route>
            <Route path="Report6" element={<Report6 />}></Route>
            <Route path="Report7" element={<Report7 />}></Route>
            <Route path="Report8" element={<Report8 />}></Route>
            <Route path="Report9" element={<Report9 />}></Route>
            <Route path="Report10" element={<Report10 />}></Route>
            <Route path="Report11" element={<Report11 />}></Route>
            <Route path="Report12" element={<Report12 />}></Route>
            <Route path="Report13" element={<Report13 />}></Route>

            <Route path="User/UserEdit/:id" element={<AddUser />}></Route>
            {/* Permission */}
            <Route path="/Permission" element={<Permission />}></Route>
            {/* MenuActivate */}
            <Route path="/MenuActivate" element={<MenuActivate />}></Route>
          </Route>
        </Route>
      </Routes>
    </ThemeProvider >
  );
}

export default App;
