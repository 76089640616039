import axios from "axios";
import authService from "../store/service/authService";
// import envInstants from "../../libs/configs/env";
// import { useHistory } from "react-router-dom";

const apiExcept = "api/v1.0/auth/login";

const apiExcept2 = ["api/v1.0/auth/login", "api/v1.0/auth/refresh"];

const x1 = apiExcept2.filter((x) => x.indexOf(apiExcept) === -1);
if (x1.length === 0) {
}

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
  timeout: 5000,
});

// instance.interceptors.response.use(
//   (response) =>
//     new Promise((resolve, reject) => {
//       resolve(response);
//     }),
//   (error) => {
//     const _url = error.response.config.url;
//     console.log(_url);
//     if (_url.indexOf(apiExcept) === -1) {
//       if (error.response.status === 401) {
//         localStorage.clear();
//         // window.location = "/login";
//       } else {
//         return new Promise((resolve, reject) => {
//           reject(error);
//         });
//       }
//     }
//   }
// );

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");

    if (token) {
      localStorage.setItem("ActiveTimeOut", new Date());
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${token}`,
      };
      // config.headers["Authorization"] =
    }
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;

    if (error.response.status === 401) {
      localStorage.clear();
      window.location = "/login";
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      return axios.get("api/v1.0/auth/refresh").then((res) => {
        if (res.status === 1000) {
          localStorage.setItem("accessToken", res?.result?.accessToken);
          localStorage.setItem("refreshToken", res?.result?.refreshToken);
          instance.defaults.headers = {
            ...instance.defaults.headers,
            authorization: `Bearer ${res?.result?.accessToken}`,
          };

          return axios(originalRequest);
        }
      });
    }
    return Promise.reject(error);
  }
);

export default instance;

export function setDefaultURL(url) {
  instance.defaults.baseURL = url;
}
