import {
  Box,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Container,
  FormControlLabel,
  Avatar,
  Autocomplete,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { useNavigate, NavLink, useParams, Router } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";

import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import Grid from "@mui/material/Grid";
import TitleHead from "../../components/TitleHead";
import SearchIcon from "@mui/icons-material/Search";
import GridField from "../../components/GridTextField";
import React, { useState, useEffect, useContext, useRef } from "react";
import Checkbox from "@mui/material/Checkbox";
import api from "../../api/api";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Formik } from "formik";
import * as Yup from "yup";
import ApiContext from "../../context/ApiProvider";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AddIcon from "@mui/icons-material/Add";
import DialogJob from "./DialogJob";
import DialogJobedetail from "./DialogJobedetail";
import DialogAdd from "./DialogAdd";
import DialogAddedit from "./DialogAddedit";
import DialogJobe from "./DialogJobe";
import { IMaskInput } from "react-imask";
import { useSelector } from "react-redux";
import DialogBreakCalP from "./DialogBreakCalP";
import DialogBreakCalPEdite from "./DialogBreakCalPEdite";
import { selectCanActivate } from "../../store/modules/selector";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#94949473",
    color: theme.palette.common,
    fontWeight: "500",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    /*  backgroundColor: '#F2F5F8', */
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
export default function Eidit_personal() {
  const [title, setTitle] = useState([]);
  let { id } = useParams();

  const {
    getPersonalTitle,
    getPersonalProvince,
    getPersonalDistrict,
    getPersonalSubDistrict,
    getCurPersonalDistrict,
    getCurPersonalSubDistrict,
    getPersonalDetail,
  } = useContext(ApiContext);
  const { baseUrl } = useSelector((state) => state.baseUrl);

  const navigate = useNavigate();
  const [titleid, setTitleid] = useState("");
  const [province, setProvince] = useState([]);
  const [provinceid, setProvinceid] = useState();
  const [district, setDistrict] = useState([]);
  const [districtItem, setDistrictItem] = useState([]);
  const [districtid, setDistrictID] = useState("");
  const [subdistrict, setSubDistrict] = useState([]);
  const [houseNo, setHouseNo] = useState("");
  const [moo, setMoo] = useState("");
  const [address, setAddress] = useState("");
  const [subdistrictid, setSubdistrictID] = useState("");
  const [newdistrictid, setNewdistrictID] = useState("");
  const [newprovinceid, setNewprovinceid] = useState("");
  const [newsubdistrictid, setnewSubdistrictID] = useState("");
  const [pDetail, setPDtail] = useState([]);
  const [povin, setProvinceItem] = useState("");
  const [curprovince, setCurProvince] = useState([]);
  const [curdistrict, setCurdistrict] = useState([]);
  const [cursubdistrict, setcurSubdistrict] = useState([]);
  const [zipcode, setZipcode] = useState();
  const [cazipcode, setczZipcode] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState(true);
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [workexp, setworkexp] = useState([]);
  const [apprecia, setapprecia] = useState([]);
  const [listBreakCalPersonal, setListBreakCalPersonal] = useState([]);
  const [openappre, setappre] = useState(false);
  const [openwork, setopenwork] = useState(false);
  const [openBCP, setOpenBCP] = useState(false);
  const [openBCPE, setOpenBCPE] = useState(false);
  const [openworkedit, setopenworkedit] = useState(false);
  const [checked, setchecked] = useState(false);
  const [idwork, setidwork] = useState("");
  const fileRef = useRef();
  const checkPermission = useSelector(selectCanActivate);
  const [uploadImg, setUploadedImg] = useState();
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getPersonalTitle(setTitle);
  }, [getPersonalTitle, setTitle]);
  useEffect(() => {
    getPersonalProvince(setProvince, setCurProvince);
    if (provinceid) {
      getPersonalDistrict(setDistrict, provinceid);
      if (districtid) {
        getPersonalSubDistrict(setSubDistrict, provinceid, districtid);
      }
    }
  }, [provinceid, districtid]);
  useEffect(() => {
    if (newprovinceid) {
      getCurPersonalDistrict(setCurdistrict, newprovinceid);
      if (newdistrictid) {
        getCurPersonalSubDistrict(
          setcurSubdistrict,
          newprovinceid,
          newdistrictid
        );
      }
    }
  }, [
    newprovinceid,
    getCurPersonalDistrict,
    setNewdistrictID,
    newdistrictid,
    getCurPersonalSubDistrict,
    setnewSubdistrictID,
  ]);
  useEffect(() => {
    getPersonalDetail(setPDtail, id, setIsLoading);
  }, [getPersonalDetail, setPDtail, id, setIsLoading]);

  const postdata = (data) => {
    api.post("api/v1.0/personal/update-personal", data).then((res) => {
      Swal.fire({
        title: "บันทึกแล้ว",
        text: "บันทึกข้อมูลสำเร็จ",
        icon: "success",
        confirmButtonColor: "#0f962d",
        confirmButtonText: "ตกลง",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location = `/`;
        }
      });
      // const MySwal = withReactContent(Swal)

      // MySwal.fire({
      //     title: <p>สร้างข้อมูลสำเร็จ</p>,

      //     icon: "success",

      //     didOpen: () => {
      //         // `MySwal` is a subclass of `Swal` with all the same instance & static methods

      //         MySwal.clickConfirm();
      //     },
      // }).then(() => {
      //     return MySwal.fire({
      //         title: 'บันทึกแล้ว',
      //         text: "บันทึกข้อมูลสำเร็จ",
      //         icon: "success",
      //         confirmButtonColor: '#0f962d',
      //         confirmButtonText: 'ตกลง',

      //     }

      //     ).then((result) => {
      //         if (result.isConfirmed) {
      //             window.location.href = '/'
      //         }
      //     });
      // });
    });
  };
  const handleDeleteW = (item) => {
    const body = {
      appreciateID: item?.appreciateID,
      personalID: item?.personalID,
      appreciateDate: item?.appreciateDate,
      description: item?.description,
      status: false,
    };
    // const D =
    //     workexp.filter((x) => x.status === true).
    setapprecia(apprecia.filter((x) => x.appreciateID !== item.appreciateID));
    const Delete = async () => {
      const res = await api.post("api/v1.0/personal/create-Appreciate", body);
      const data = await res?.data;
      // console.log(data);
    };
    Delete();
    // console.log(workexp);
  };
  const handleDeleteWork = (item) => {
    const w = {
      WorkID: item?.WorkID,
      personalID: item?.personalID,
      positionID: item?.positionID,
      startDate: item?.startDate,
      endDate: item?.endDate,
      positionDsc: item?.positionDsc,
      status: false,
    };
    // const D =
    //     workexp.filter((x) => x.status === true).
    setworkexp(workexp.filter((x) => x.WorkID !== item.WorkID));
    const Delete = async () => {
      const res = await api.post("api/v1.0/personal/create-workexperince", w);
      const data = await res?.data;
      // console.log(data);
    };
    Delete();
  };
  const handleEdit = async (e) => {
    setidwork(e);

    // console.log(idwork);
  };
  const appre = () => {
    setappre(true);
  };

  const workopen = () => {
    setopenwork(true);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const getwork = async () => {
    const res = await api.get(`api/v1.0/personal/listWorkEXP?personalID=${id}`);
    setworkexp(res?.data?.result);
  };
  useEffect(() => {
    getwork();
  }, []);
  const getadd = async () => {
    const res = await api.get(
      `api/v1.0/personal/listAppreciate?personalID=${id}`
    );

    setapprecia(res?.data?.result);
  };

  useEffect(() => {
    getadd();
  }, []);
  const getListBreakCalPersonal = async () => {
    const res = await api.get(
      `api/v1.0/personal/listBreakCalPersonal?personalID=${id}`
    );

    setListBreakCalPersonal(res?.data?.result);
  };

  useEffect(() => {
    getListBreakCalPersonal();
  }, []);
  const TextMaskCitizen = React.forwardRef(function TextMaskCitizen(
    props,
    ref
  ) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="0-0000-00000-00-0"
        definitions={{
          "#": /[0-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  });
  const TextMaskMobile = React.forwardRef(function TextMaskMobile(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="000-000-0000"
        definitions={{
          "#": /[0-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  });

  return (
    <>
      {isLoading ? (
        "loading..."
      ) : (
        <Formik
          initialValues={{
            personalID: pDetail?.personalID,
            personalNo: pDetail?.personalNo,
            citizenID: pDetail?.citizenID,
            position: pDetail?.position,
            titleID: pDetail?.titleID,
            firstname: pDetail?.firstname,
            lastname: pDetail?.lastname,
            mobile: pDetail?.mobile,
            email: pDetail?.email,
            czAddress: pDetail?.czAddress,
            czHouseNo: pDetail?.czHouseNo,
            czMoo: pDetail?.czMoo,
            czProvinceID: pDetail?.czProvinceID,
            czDistrictID: pDetail?.czDistrictID,
            czSubDistrictID: pDetail?.czSubDistrictID,
            checked: false,
            crAddress: pDetail?.crAddress,
            crHouseNo: pDetail?.crHouseNo,
            crMoo: pDetail?.crMoo,
            crProvinceID: pDetail?.crProvinceID,
            crDistrictID: pDetail?.crDistrictID,
            crSubDistrictID: pDetail?.crSubDistrictID,
            isBlacklist: pDetail?.isBlacklist,
            remarkBlackList: pDetail?.remarkBlackList,
            note: pDetail?.note,
            status: pDetail?.status,
            fileID: pDetail?.fileID,
            submit: null,
          }}
          // enableReinitialize
          validationSchema={Yup.object().shape({
            titleID: Yup.string().max(255).required("กรุณาระบุ คำนำหน้า"),
            firstname: Yup.string().max(255).required("กรุณาระบุ ชื่อ"),
            lastname: Yup.string().max(255).required("กรุณาระบุ นามสกุล"),
            // bankAccNo: Yup.string().max(255).required('กรุณาระบุ เลขบัญชี'),
            // bankAccName: Yup.string().max(255).required('กรุณาระบุ ชื่อบัญชี'),
            // bankAccNickName: Yup.string().max(255).required('กรุณาระบุ ชื่อเล่น'),
          })}
          onSubmit={async (value, { setErrors, setStatus, setSubmitting }) => {
            // console.log(value)
            try {
              // console.log(value)
              postdata(value);
              // create_bankaccount(value)

              setStatus({ success: true });
              setSubmitting(false);
            } catch (err) {
              console.log(err);
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Container
                maxWidth="xl"
                sx={{ height: "auto", marginTop: "40px" }}
              >
                <TitleHead
                  title="รายละเอียดข้อมูลส่วนบุคคล"
                  subTitle="ข้อมูลทะเบียนประวัติส่วนบุคคล"
                ></TitleHead>
                {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                                    <Box>
                                        <Typography sx={{ fontSize: '32px' }}>รายละเอียดข้อมูลส่วนบุคคล</Typography>
                                        <Typography sx={{ color: '#bdbdbd', fontSize: '15px' }}>ข้อมูลทะเบียนประวัติส่วนบุคคล</Typography>
                                    </Box>
                                </Box> */}
                <Tabs
                  value={value}
                  onChange={handleChangeTab}
                  aria-label="basic tabs example"
                  sx={{
                    color: "#000",
                    "& .MuiTabs-indicator": {
                      display: "none",
                    },
                    "& .MuiTabs-scrollButtons": {
                      color: "#000",
                    },
                  }}
                >
                  <Tab
                    label="รายละเอียด"
                    sx={{
                      color: "#bdbdbd",
                      borderBottom: "1px solid #bdbdbd",
                      "&.Mui-selected": {
                        color: "#000",
                        borderTop: "1px solid #bdbdbd",
                        borderRight: "1px solid #bdbdbd",
                        borderLeft: "1px solid #bdbdbd",
                        borderBottom: "1px solid #fff",
                        borderRadius: "5px 5px 0 0",
                      },
                    }}
                  />
                  <Tab
                    label="ตำเเหน่งงาน"
                    sx={{
                      color: "#bdbdbd",
                      borderBottom: "1px solid #bdbdbd",
                      "&.Mui-selected": {
                        color: "#000",
                        borderTop: "1px solid #bdbdbd",
                        borderRight: "1px solid #bdbdbd",
                        borderLeft: "1px solid #bdbdbd",
                        borderBottom: "1px solid #fff",
                        borderRadius: "5px 5px 0 0",
                      },
                    }}
                  />
                  <Tab
                    label="ได้รับการยกย่องบําเพ็ญประโยชน์"
                    sx={{
                      color: "#bdbdbd",
                      borderBottom: "1px solid #bdbdbd",
                      "&.Mui-selected": {
                        color: "#000",
                        borderTop: "1px solid #bdbdbd",
                        borderRight: "1px solid #bdbdbd",
                        borderLeft: "1px solid #bdbdbd",
                        borderBottom: "1px solid #fff",
                        borderRadius: "5px 5px 0 0",
                      },
                    }}
                  />
                  <Tab
                    label="เงื่อนไขการลดสิทธิ"
                    sx={{
                      color: "#bdbdbd",
                      borderBottom: "1px solid #bdbdbd",
                      "&.Mui-selected": {
                        color: "#000",
                        borderTop: "1px solid #bdbdbd",
                        borderRight: "1px solid #bdbdbd",
                        borderLeft: "1px solid #bdbdbd",
                        borderBottom: "1px solid #fff",
                        borderRadius: "5px 5px 0 0",
                      },
                    }}
                  />
                  <Box
                    sx={{
                      width: "100%",
                      borderBottom: "1px solid #bdbdbd",
                    }}
                  ></Box>
                </Tabs>
                <TabPanel value={value} index={0}>
                  <Grid container spacing={2} sx={{ marginTop: "6px" }}>
                    <GridField text="เลขทะเบียน">
                      <TextField
                        name="personalNo"
                        size="small"
                        value={values.personalNo}
                        onChange={handleChange}
                        label=""
                        variant="outlined"
                        color="success"
                        disabled
                        sx={{ width: "100%", background: "#f7f7f7" }}
                      />
                    </GridField>

                    <GridField text="เลขที่บัตรประชาชน">
                      {checkPermission.find(
                        (x) => x.menuNo === "M001" && x.isEdit
                      ) ? (
                        <TextField
                          name="citizenID"
                          size="small"
                          value={values.citizenID}
                          onChange={(e) =>
                            setFieldValue(
                              "citizenID",
                              e.target.value.replaceAll("-", "")
                            )
                          }
                          label=""
                          variant="outlined"
                          color="success"
                          sx={{ width: "100%" }}
                          InputProps={{
                            inputComponent: TextMaskCitizen,
                          }}
                        />
                      ) : (
                        <TextField
                          name="citizenID"
                          size="small"
                          label=""
                          variant="outlined"
                          disabled
                          sx={{ width: "100%", background: "#f7f7f7" }}
                          color="success"
                          InputProps={{
                            inputComponent: TextMaskCitizen,
                          }}
                        />
                      )}
                    </GridField>
                    {checkPermission.find(
                      (x) => x.menuNo === "M001" && x.isEdit
                    ) ? (
                      <GridField text="ตำแหน่งปัจจุบัน">
                        <TextField
                          size="small"
                          name="position"
                          value={values.position}
                          onChange={handleChange}
                          label=""
                          variant="outlined"
                          color="success"
                          sx={{ width: "100%" }}
                        />
                      </GridField>
                    ) : (
                      <GridField text="ตำแหน่งปัจจุบัน">
                        <TextField
                          size="small"
                          name="position"
                          value={values.position}
                          onChange={handleChange}
                          label=""
                          variant="outlined"
                          color="success"
                          disabled
                          sx={{ width: "100%", background: "#f7f7f7" }}
                        />
                      </GridField>
                    )}
                    {checkPermission.find(
                      (x) => x.menuNo === "M001" && x.isEdit
                    ) ? (
                      <GridField text="คำนำ">
                        <FormControl
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyItems: "center",
                          }}
                          variant="outlined"
                          color="success"
                        >
                          <Select
                            size="small"
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            name="titleID"
                            // error={Boolean(touched.titleID)}
                            // value={titleid}
                            value={values?.titleID}
                            onChange={(e) => {
                              setFieldValue("titleID", e.target.value);
                              setTitleid(e.target.value);
                            }}
                          >
                            {title?.map((x, i) => (
                              <MenuItem key={i} value={x?.mt_titleID}>
                                {x?.mt_titleNameTH}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridField>) : <GridField text="คำนำ">
                      <FormControl
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyItems: "center",
                        }}
                        variant="outlined"
                        color="success"
                      >
                        <Select
                          size="small"
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          name="titleID"
                          // error={Boolean(touched.titleID)}
                          // value={titleid}
                          disabled
                          value={values?.titleID}
                          onChange={(e) => {
                            setFieldValue("titleID", e.target.value);
                            setTitleid(e.target.value);
                          }}
                        >
                          {title?.map((x, i) => (
                            <MenuItem key={i} value={x?.mt_titleID}>
                              {x?.mt_titleNameTH}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridField>}
                    {checkPermission.find(
                      (x) => x.menuNo === "M001" && x.isEdit
                    ) ? (
                      <GridField text="ชื่อ">
                        <TextField
                          name="firstname"
                          value={values.firstname}
                          // error={Boolean(touched.firstname)}
                          size="small"
                          onChange={handleChange}
                          label=""
                          variant="outlined"
                          color="success"
                          sx={{ width: "100%" }}
                        />
                      </GridField>
                    ) : <GridField text="ชื่อ">
                      <TextField
                        name="firstname"
                        value={values.firstname}
                        // error={Boolean(touched.firstname)}
                        size="small"
                        disabled
                        onChange={handleChange}
                        label=""
                        variant="outlined"
                        color="success"
                        sx={{ width: "100%", background: "#f7f7f7" }}
                      />
                    </GridField>}
                    {checkPermission.find(
                      (x) => x.menuNo === "M001" && x.isEdit
                    ) ? (
                      <GridField text="สกุล">
                        <TextField
                          name="lastname"
                          size="small"
                          // error={Boolean(touched.lastname)}
                          value={values.lastname}
                          onChange={handleChange}
                          label=""
                          variant="outlined"
                          color="success"
                          sx={{ width: "100%" }}
                        />
                      </GridField>) :
                      <GridField text="สกุล">
                        <TextField
                          name="lastname"
                          size="small"
                          // error={Boolean(touched.lastname)}
                          value={values.lastname}
                          onChange={handleChange}
                          label=""
                          variant="outlined"
                          disabled
                          color="success"
                          sx={{ width: "100%", background: "#f7f7f7" }}
                        />
                      </GridField>}
                    {checkPermission.find(
                      (x) => x.menuNo === "M001" && x.isEdit
                    ) ? (
                      <GridField text="เบอร์มือถือ">
                        <TextField
                          name="mobile"
                          type="tel"
                          size="small"
                          value={values.mobile}
                          onChange={(e) =>
                            setFieldValue(
                              "mobile",
                              e.target.value.replaceAll("-", "")
                            )
                          }
                          label=""
                          variant="outlined"
                          color="success"
                          sx={{ width: "100%" }}
                          InputProps={{
                            inputComponent: TextMaskMobile,
                          }}
                        />
                      </GridField>) :
                      <GridField text="เบอร์มือถือ">
                        <TextField
                          name="mobile"
                          type="tel"
                          size="small"
                          disabled
                          value={values.mobile}
                          onChange={(e) =>
                            setFieldValue(
                              "mobile",
                              e.target.value.replaceAll("-", "")
                            )
                          }
                          label=""
                          variant="outlined"
                          color="success"
                          sx={{ width: "100%", background: "#f7f7f7" }}
                          InputProps={{
                            inputComponent: TextMaskMobile,
                          }}
                        />
                      </GridField>}
                    {checkPermission.find(
                      (x) => x.menuNo === "M001" && x.isEdit
                    ) ? (
                      <GridField text="อีเมล">
                        <TextField
                          name="email"
                          size="small"
                          value={values.email}
                          onChange={handleChange}
                          label=""
                          variant="outlined"
                          color="success"
                          sx={{ width: "100%" }}
                        />
                      </GridField>) : <GridField text="อีเมล">
                      <TextField
                        name="email"
                        size="small"
                        value={values.email}
                        onChange={handleChange}
                        label=""
                        disabled
                        variant="outlined"
                        color="success"
                        sx={{ width: "100%", background: "#f7f7f7" }}
                      />
                    </GridField>}
                    {checkPermission.find(
                      (x) => x.menuNo === "M001" && x.isEdit
                    ) ? (
                      <GridField text="สถานะ">
                        <FormControlLabel
                          control={
                            <Switch
                              name="status"

                              checked={values.status}
                              onChange={(e) =>
                                setFieldValue("status", e.target.checked)
                              }
                            />
                          }
                          label=""
                        />
                      </GridField>) :
                      <GridField text="สถานะ">
                        <FormControlLabel
                          control={
                            <Switch
                              name="status"
                              disabled
                              checked={values.status}
                              onChange={(e) =>
                                setFieldValue("status", e.target.checked)
                              }
                            />
                          }
                          label=""
                        />
                      </GridField>}
                    <Grid item lg={8} md={6} xs={12}>
                      <Grid container sx={{ alignItems: "center" }}>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          xs={12}
                          sx={{
                            display: "flex",
                            alignItems: "start",
                            justifyContent: {
                              sm: "flex-end",
                              xs: "flex-start",
                            },
                          }}
                        >
                          <Avatar
                            sx={{ width: 56, height: 56, marginRight: "15px" }}
                            alt="Remy Sharp"
                            src={`${baseUrl}api/v1.0/filemanages/download?fileID=${values?.fileID}`}
                          />
                        </Grid>
                        <Grid item lg={3} md={9} xs={12}>
                          <TextField
                            label=""
                            size="small"
                            placeholder=""
                            variant="outlined"
                            color="success"
                            sx={{ width: "100%", background: "#f7f7f7" }}
                            value={uploadImg?.filename}
                            disabled
                          />
                        </Grid>
                        {/* <Grid item lg={2} md={9} xs={12}>
                                            <input
                                                type="file" ref={fileRef} hidden onChange={handleFileChange} />
                                            <Button variant='contained' onClick={() => fileRef.current.click()} sx={{ width: '150px', height: 'fit-content', fontSize: '18px', marginLeft: '20px', backgroundColor: '#0fb123', ':hover': { backgroundColor: '#028b13' } }}>อัพโหลดไฟล์</Button>
                                            <FileUploadC />
                                        </Grid> */}


                        <FileUploadC
                          setUploadedImg={setUploadedImg}
                          fileRef={fileRef}
                          disabled
                          setFieldValue={setFieldValue}
                        />
                      </Grid>
                      <Grid
                        container
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "20px",
                        }}
                      >
                        <Grid item md={6} xs={12} lg={3}></Grid>

                        <Grid
                          item
                          md={6}
                          xs={12}
                          lg={9}
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          {checkPermission.find(
                            (x) => x.menuNo === "M001" && x.isEdit
                          ) ? (
                            <TextField
                              sx={{ width: "100%" }}
                              multiline
                              variant="outlined"
                              color="success"
                              name="note"
                              value={values.note}
                              onChange={handleChange}
                              placeholder="รายละเอียดเพิ่มเติม"
                              rows={4}
                            ></TextField>) :
                            <TextField
                              sx={{ width: "100%" }}
                              multiline
                              variant="outlined"
                              color="success"
                              name="note"
                              value={values.note}
                              disabled
                              onChange={handleChange}
                              placeholder="รายละเอียดเพิ่มเติม"
                              rows={4}
                            ></TextField>}
                        </Grid>
                      </Grid>
                      <Grid item lg={12} md={6} xs={12}>
                        <Grid
                          container
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "20px",
                          }}
                        >
                          <Grid item md={6} xs={12} lg={3}></Grid>
                          {
                            <Grid
                              item
                              md={6}
                              xs={12}
                              lg={1}
                              sx={{ alignItems: "center" }}
                            >
                              {" "}
                              {checkPermission.find(
                                (x) => x.menuNo === "M001" && x.isEdit
                              ) ? (
                                <Checkbox
                                  checked={
                                    values.isBlacklist == true ? true : false
                                  }

                                  color="success"
                                  value={values.isBlacklist}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "isBlacklist",
                                      e.target.checked
                                    );
                                  }}
                                />) : <Checkbox
                                checked={
                                  values.isBlacklist == true ? true : false
                                }

                                color="success"
                                value={values.isBlacklist}
                                disabled
                                onChange={(e) => {
                                  setFieldValue(
                                    "isBlacklist",
                                    e.target.checked
                                  );
                                }}
                              />}
                            </Grid>
                          }
                          <Grid
                            item
                            md={6}
                            xs={12}
                            lg={8}
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <Typography sx={{ marginRight: "15px" }}>
                              ไม่ถูกไม่คำนวณการรับเข็มกิตติคุณ
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      {values.isBlacklist == true ? (
                        <Grid item lg={12} md={6} xs={12}>
                          <Grid
                            container
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "20px",
                            }}
                          >
                            <Grid item md={6} xs={12} lg={3}></Grid>

                            <Grid
                              item
                              md={6}
                              xs={12}
                              lg={9}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <TextField
                                sx={{ width: "100%" }}
                                multiline
                                variant="outlined"
                                color="success"
                                name="remarkBlackList"
                                value={values.remarkBlackList}
                                onChange={handleChange}
                                rows={4}
                              ></TextField>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                  <Accordion sx={{
                    marginTop: "50px",
                    boxShadow: 'none', ':before': {
                      content: '""',
                      height: "0px"
                    }
                  }}>
                    <AccordionSummary
                      expandIcon={<ArrowDownwardIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      style={{ padding: '0px', borderBottom: "1px solid #e7e7e7" }}
                    >
                      <Box sx={{ display: "flex", color: "#000", }}>
                        <Typography variant="h5">ที่อยุ่ตามบัตรประชาชน</Typography>
                      </Box>

                    </AccordionSummary>
                    <AccordionDetails sx={{ marginTop: "20px" }}>
                      {/* <Box
                    sx={{ display: "flex", color: "#000", marginTop: "50px" }}
                  >
                    <Typography variant="h5">ที่อยู่ตามบัตรประชาชน</Typography>
                  </Box>
                  <Divider
                    sx={{
                      width: "100%",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  /> */}
                      <Grid container spacing={2}>
                        <GridField text="เลขที่">
                          {checkPermission.find(
                            (x) => x.menuNo === "M001" && x.isEdit
                          ) ? (
                            <TextField
                              size="small"
                              name="czHouseNo"
                              value={values.czHouseNo}
                              onChange={handleChange}
                              label=""
                              variant="outlined"
                              color="success"
                              sx={{ width: "100%" }}
                            />) : <TextField
                            size="small"
                            name="czHouseNo"
                            value={values.czHouseNo}
                            onChange={handleChange}
                            label=""
                            disabled
                            variant="outlined"
                            color="success"
                            sx={{ width: "100%", background: "#f7f7f7" }}
                          />}
                        </GridField>
                        <Grid item lg={8} md={6} xs={12}>
                          <Grid container>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: {
                                  md: "flex-end",
                                  sm: "flex-start",
                                },
                              }}
                            >
                              <Typography sx={{ marginRight: "15px" }}>
                                ที่อยู่
                              </Typography>
                            </Grid>
                            <Grid item md={9} xs={12}>
                              {checkPermission.find(
                                (x) => x.menuNo === "M001" && x.isEdit
                              ) ? (
                                <TextField
                                  size="small"
                                  name="czAddress"
                                  value={values.czAddress}
                                  onChange={handleChange}
                                  label=""
                                  variant="outlined"
                                  color="success"
                                  sx={{ width: "100%" }}
                                />) : <TextField
                                size="small"
                                name="czAddress"
                                value={values.czAddress}
                                onChange={handleChange}
                                label=""
                                variant="outlined"
                                color="success"
                                disabled
                                sx={{ width: "100%", backgroundColor: '#f7f7f7' }}
                              />}
                            </Grid>
                          </Grid>
                        </Grid>

                        <GridField text="หมู่">
                          {checkPermission.find(
                            (x) => x.menuNo === "M001" && x.isEdit
                          ) ? (
                            <TextField
                              name="czMoo"
                              size="small"
                              value={values.czMoo}
                              onChange={handleChange}
                              label=""
                              variant="outlined"
                              color="success"
                              sx={{ width: "100%" }}
                            />) : <TextField
                            name="czMoo"
                            size="small"
                            value={values.czMoo}
                            onChange={handleChange}
                            label=""
                            variant="outlined"
                            color="success"
                            disabled
                            sx={{ width: "100%", backgroundColor: '#f7f7f7' }}
                          />}
                        </GridField>

                        <GridField text="จังหวัด">
                          {values.czProvinceID
                            ? setProvinceid(values.czProvinceID)
                            : ""}
                          {checkPermission.find(
                            (x) => x.menuNo === "M001" && x.isEdit
                          ) ? (
                            <Autocomplete
                              size="small"
                              fullWidth

                              value={
                                province?.filter(
                                  (x) => x?.mp_provinceID === values?.czProvinceID
                                )[0]
                                  ? province?.filter(
                                    (x) => x?.mp_provinceID === values?.czProvinceID
                                  )[0]
                                  : {}
                              }
                              onChange={(e, newValue) => {
                                setProvinceid(newValue.mp_provinceID);
                                setProvinceItem(newValue);
                                setFieldValue("czProvinceID", newValue.mp_provinceID);
                                setDistrictID({});
                              }}
                              options={province}
                              getOptionLabel={(option) =>
                                option?.mp_provinceName ? option?.mp_provinceName : ""
                              }

                              renderInput={(params) => (

                                <TextField   {...params} placeholder="เลือกจังหวัด" />
                              )}
                            />) : <Autocomplete
                            size="small"
                            fullWidth
                            disabled
                            value={
                              province?.filter(
                                (x) => x?.mp_provinceID === values?.czProvinceID
                              )[0]
                                ? province?.filter(
                                  (x) => x?.mp_provinceID === values?.czProvinceID
                                )[0]
                                : {}
                            }
                            onChange={(e, newValue) => {
                              setProvinceid(newValue.mp_provinceID);
                              setProvinceItem(newValue);
                              setFieldValue("czProvinceID", newValue.mp_provinceID);
                              setDistrictID({});
                            }}
                            options={province}
                            getOptionLabel={(option) =>
                              option?.mp_provinceName ? option?.mp_provinceName : ""
                            }

                            renderInput={(params) => (

                              <TextField   {...params} placeholder="เลือกจังหวัด" />
                            )}
                          />}
                          {/* <FormControl
                                                sx={{
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyItems: "center",
                                                }}
                                                variant="outlined"
                                                color="success"
                                            >
                                               
                                                <Select
                                                    displayEmpty
                                                    inputProps={{ "aria-label": "Without label" }}
                                                    value={values.czProvinceID}
                                                    onChange={e => {
                                                        // if(values.checked) { setFieldValue('crProvinceID', e.target.value)}
                                                        setFieldValue('czProvinceID', e.target.value)
                                                        setProvinceid(e.target.value)
                                                    }}
                                                >
                                                    {province?.map((x, i) => <MenuItem key={i} value={x?.mp_provinceID}>{x?.mp_provinceName}</MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl> */}
                        </GridField>
                        <GridField text="เขต/อำเภอ">
                          {values.czDistrictID
                            ? setDistrictID(values.czDistrictID)
                            : ""}
                          {checkPermission.find(
                            (x) => x.menuNo === "M001" && x.isEdit
                          ) ? (
                            <Autocomplete
                              size="small"
                              fullWidth
                              value={
                                district.filter(
                                  (e) => e.md_districtID === values.czDistrictID
                                )[0]
                                  ? district.filter(
                                    (e) => e.md_districtID === values.czDistrictID
                                  )[0]
                                  : {}
                              }
                              onChange={(e, newValue) => {
                                setDistrictItem(newValue);
                                setFieldValue(
                                  "czDistrictID",
                                  newValue?.md_districtID
                                );
                                setSubdistrictID("");
                              }}
                              options={district}
                              getOptionLabel={(option) =>
                                option.md_districtName ? option.md_districtName : ""
                              }
                              renderInput={(params) => (
                                <TextField {...params} placeholder="เลือกเขต/อำเภอ" />
                              )}
                            />) : <Autocomplete
                            size="small"
                            fullWidth
                            disabled
                            value={
                              district.filter(
                                (e) => e.md_districtID === values.czDistrictID
                              )[0]
                                ? district.filter(
                                  (e) => e.md_districtID === values.czDistrictID
                                )[0]
                                : {}
                            }
                            onChange={(e, newValue) => {
                              setDistrictItem(newValue);
                              setFieldValue(
                                "czDistrictID",
                                newValue?.md_districtID
                              );
                              setSubdistrictID("");
                            }}
                            options={district}
                            getOptionLabel={(option) =>
                              option.md_districtName ? option.md_districtName : ""
                            }
                            renderInput={(params) => (
                              <TextField {...params} placeholder="เลือกเขต/อำเภอ" />
                            )}
                          />}
                          {/* <FormControl
                                                sx={{
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyItems: "center",
                                                }}
                                                variant="outlined"
                                                color="success"
                                            >
                                                
                                                <Select
                                                    displayEmpty
                                                    inputProps={{ "aria-label": "Without label" }}
                                                    value={values.czDistrictID}
                                                    onChange={e => {
                                                        // if(values.checked) { setFieldValue('crDistrictID', e.target.value)}
                                                        setFieldValue('czDistrictID', e.target.value)
                                                        setDistrictID(e.target.value)
                                                    }}
                                                >
                                                    {district?.map((x, i) => <MenuItem key={i} value={x?.md_districtID}>{x?.md_districtName}</MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl> */}
                        </GridField>
                        <GridField text="แขวง/ตำบล">
                          <FormControl
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyItems: "center",
                            }}
                            disabled={checkPermission.find(
                              (x) => x.menuNo === "M001" && x.isEdit
                            ) ? false : true}
                            variant="outlined"
                            color="success"
                          >
                            <Select
                              size="small"
                              name="czSubDistrictID"
                              displayEmpty
                              inputProps={{ "aria-label": "Without label" }}
                              value={values.czSubDistrictID}
                              onChange={(e) => {
                                // if(values.checked) { setFieldValue('crSubDistrictID', e.target.value)}
                                setFieldValue("czSubDistrictID", e.target.value);
                                setSubdistrictID(e.target.value);
                              }}
                            >
                              {subdistrict?.map((x, i) => (
                                <MenuItem
                                  key={i}
                                  value={x?.msd_subdistrictID}
                                  onClick={setZipcode(x.msd_zipcode)}
                                >
                                  {x?.msd_subdistrictName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {/* <Autocomplete
                                                size='small'
                                                fullWidth
                                                value={subdistrict.filter((e) => e.msd_subdistrictID === values.czSubDistrictID)[0] ? subdistrict.filter((e) => e.msd_subdistrictID === values.czSubDistrictID)[0] : {}}
                                                onChange={(e, newValue) => {
                                                    setSubdistrictID(newValue)
                                                    setFieldValue('czSubDistrictID', newValue?.msd_subdistrictID)
                                                    setZipcode(newValue.msd_zipcode)
                                                    // console.log(newValue)
                                                }}
                                                options={subdistrict}
                                                getOptionLabel={option => option.msd_subdistrictName ? option.msd_subdistrictName : ''}
                                                renderInput={(params) => <TextField {...params} placeholder='เลือกแขวง/ตำบล' />}
                                            /> */}
                        </GridField>
                        <GridField text="รหัสไปรษณีย์">
                          <TextField
                            size="small"
                            label=""
                            disabled={checkPermission.find(
                              (x) => x.menuNo === "M001" && x.isEdit
                            ) ? false : true}
                            value={zipcode}
                            variant="outlined"
                            color="success"
                            sx={{
                              width: "100%", backgroundColor: checkPermission.find(
                                (x) => x.menuNo === "M001" && x.isEdit
                              ) ? '' : '#fafafa'
                            }}
                          />
                        </GridField>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion sx={{
                    marginTop: "50px",
                    boxShadow: 'none', ':before': {
                      content: '""',
                      height: "0px"
                    }
                  }}>
                    <AccordionSummary
                      expandIcon={<ArrowDownwardIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                      style={{ padding: '0px', borderBottom: "1px solid #e7e7e7" }}
                    >
                      <Box sx={{ display: "flex", color: "#000", }}>
                        <Typography variant="h5">
                          ที่อยู่ปัจจุบัน
                          <FormControlLabel
                            label=""
                            size="small"
                            control={
                              <Checkbox
                                disabled={checkPermission.find(
                                  (x) => x.menuNo === "M001" && x.isEdit
                                ) ? false : true}
                                name="checked"
                                color="success"
                                checked={values.checked}
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) => {
                                  setFieldValue("checked", e.target.checked);
                                  setFieldValue(
                                    "crProvinceID",
                                    e.target.checked
                                      ? values.czProvinceID
                                      : values.crProvinceID
                                  );
                                  setFieldValue(
                                    "crDistrictID",
                                    e.target.checked
                                      ? values.czDistrictID
                                      : values.crDistrictID
                                  );
                                  setFieldValue(
                                    "crSubDistrictID",
                                    e.target.checked
                                      ? values.czSubDistrictID
                                      : values.crSubDistrictID
                                  );
                                }}
                                sx={{ marginLeft: "10px" }}
                              />

                            }
                          />
                          ใช้ที่อยู่เดียวกับที่อยู่ตามบัตรประชาชน
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={{ marginTop: "20px" }}>


                      <Grid container spacing={2}>
                        <GridField text="เลขที่">
                          {checkPermission.find(
                            (x) => x.menuNo === "M001" && x.isEdit
                          ) ? (
                            <TextField

                              disabled={values.checked ? true : false}
                              name="crHouseNo"
                              size="small"
                              value={
                                values.checked
                                  ? (values.crHouseNo = values.czHouseNo)
                                  : houseNo || values.crHouseNo
                              }
                              onChange={(e) => {
                                setFieldValue("crHouseNo", e.target.value);
                                setHouseNo(e.target.value);
                              }}
                              label=""
                              variant="outlined"
                              color="success"
                              sx={{ width: "100%" }}
                            />)
                            : <TextField

                              disabled
                              name="crHouseNo"
                              size="small"
                              value={
                                values.checked
                                  ? (values.crHouseNo = values.czHouseNo)
                                  : houseNo || values.crHouseNo
                              }
                              onChange={(e) => {
                                setFieldValue("crHouseNo", e.target.value);
                                setHouseNo(e.target.value);
                              }}
                              label=""
                              variant="outlined"
                              color="success"
                              sx={{ width: "100%", backgroundColor: '#fafafa' }}
                            />}
                        </GridField>
                        <Grid item lg={8} md={6} xs={12}>
                          <Grid container>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: {
                                  md: "flex-end",
                                  sm: "flex-start",
                                },
                              }}
                            >
                              <Typography sx={{ marginRight: "15px" }}>
                                ที่อยู่
                              </Typography>
                            </Grid>
                            <Grid item md={9} xs={12}>
                              {checkPermission.find(
                                (x) => x.menuNo === "M001" && x.isEdit
                              ) ? (
                                <TextField
                                  disabled={values.checked ? true : false}
                                  size="small"
                                  name="crAddress"
                                  value={
                                    values.checked
                                      ? (values.crAddress = values.czAddress)
                                      : address || values.crAddress
                                  }
                                  onChange={(e) => {
                                    setFieldValue("crAddress", e.target.value);
                                    setAddress(e.target.value);
                                  }}
                                  label=""
                                  variant="outlined"
                                  color="success"
                                  sx={{ width: "100%" }}
                                />) : <TextField

                                size="small"
                                name="crAddress"
                                value={
                                  values.checked
                                    ? (values.crAddress = values.czAddress)
                                    : address || values.crAddress
                                }
                                onChange={(e) => {
                                  setFieldValue("crAddress", e.target.value);
                                  setAddress(e.target.value);
                                }}
                                label=""
                                disabled
                                variant="outlined"
                                color="success"
                                sx={{ width: "100%", backgroundColor: "#fafafa" }}
                              />}
                            </Grid>
                          </Grid>
                        </Grid>

                        <GridField text="หมู่">
                          {checkPermission.find(
                            (x) => x.menuNo === "M001" && x.isEdit
                          ) ? (
                            <TextField
                              disabled={values.checked ? true : false}
                              name="crMoo"
                              size="small"
                              value={
                                values.checked
                                  ? (values.crMoo = values.czMoo)
                                  : moo || values.crMoo
                              }
                              onChange={(e) => {
                                setFieldValue("crMoo", e.target.value);
                                setMoo(e.target.value);
                              }}
                              label=""
                              variant="outlined"
                              color="success"
                              sx={{ width: "100%" }}
                            />) : <TextField
                            disabled
                            name="crMoo"
                            size="small"
                            value={
                              values.checked
                                ? (values.crMoo = values.czMoo)
                                : moo || values.crMoo
                            }
                            onChange={(e) => {
                              setFieldValue("crMoo", e.target.value);
                              setMoo(e.target.value);
                            }}
                            label=""
                            variant="outlined"
                            color="success"
                            sx={{ width: "100%", backgroundColor: '#fafafa' }}
                          />}
                        </GridField>
                        <GridField text="จังหวัด">
                          {values.crProvinceID
                            ? setNewprovinceid(values.crProvinceID)
                            : ""}
                          {/* <Autocomplete
                                                size='small'
                                                fullWidth
                                                value={province?.filter((x) => x?.mp_provinceID === values?.crProvinceID)[0] ? province?.filter((x) => x?.mp_provinceID === values?.crProvinceID)[0] : {}}
                                                onChange={(e, newValue) => {
                                                    setProvinceid(newValue.mp_provinceID)
                                                    setProvinceItem(newValue)
                                                    setFieldValue('crProvinceID', newValue.mp_provinceID)
                                                    setDistrictID({})
                                                }}
                                                options={province}
                                                getOptionLabel={(option) => option.mp_provinceName}
                                                renderInput={(params) => <TextField {...params} placeholder='เลือกจังหวัด' />}
                                            /> */}
                          {checkPermission.find(
                            (x) => x.menuNo === "M001" && x.isEdit
                          ) ? (
                            <Autocomplete
                              size="small"
                              fullWidth
                              disabled={values.checked ? true : false}
                              value={
                                values.checked
                                  ? province?.filter(
                                    (x) => x?.mp_provinceID === values?.czProvinceID
                                  )[0]
                                    ? province?.filter(
                                      (x) =>
                                        x?.mp_provinceID === values?.czProvinceID
                                    )[0]
                                    : {}
                                  : curprovince?.filter(
                                    (x) => x?.mp_provinceID === values?.crProvinceID
                                  )[0]
                                    ? curprovince?.filter(
                                      (x) => x?.mp_provinceID === values?.crProvinceID
                                    )[0]
                                    : {}
                              }
                              onChange={(e, newValue) => {
                                setNewprovinceid(newValue);
                                setFieldValue(
                                  "crProvinceID",
                                  values.checked
                                    ? values.czProvinceID
                                    : newValue.mp_provinceID
                                );
                                setNewdistrictID({});
                              }}
                              options={curprovince}
                              getOptionLabel={(option) =>
                                option.mp_provinceName ? option.mp_provinceName : ""
                              }
                              renderInput={(params) => (
                                <TextField {...params} placeholder="เลือกจังหวัด" />
                              )}
                            />) : <Autocomplete
                            size="small"
                            fullWidth
                            disabled

                            value={
                              values.checked
                                ? province?.filter(
                                  (x) => x?.mp_provinceID === values?.czProvinceID
                                )[0]
                                  ? province?.filter(
                                    (x) =>
                                      x?.mp_provinceID === values?.czProvinceID
                                  )[0]
                                  : {}
                                : curprovince?.filter(
                                  (x) => x?.mp_provinceID === values?.crProvinceID
                                )[0]
                                  ? curprovince?.filter(
                                    (x) => x?.mp_provinceID === values?.crProvinceID
                                  )[0]
                                  : {}
                            }
                            onChange={(e, newValue) => {
                              setNewprovinceid(newValue);
                              setFieldValue(
                                "crProvinceID",
                                values.checked
                                  ? values.czProvinceID
                                  : newValue.mp_provinceID
                              );
                              setNewdistrictID({});
                            }}
                            options={curprovince}
                            getOptionLabel={(option) =>
                              option.mp_provinceName ? option.mp_provinceName : ""
                            }
                            renderInput={(params) => (
                              <TextField {...params} placeholder="เลือกจังหวัด" />
                            )}
                          />}

                          {/* <FormControl
                                                sx={{
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyItems: "center",
                                                }}
                                                variant="outlined"
                                                color="success"
                                            >
                                                {values.crProvinceID ? setNewprovinceid(values.crProvinceID) : ''}
                                                <Select
                                                    displayEmpty
                                                    inputProps={{ "aria-label": "Without label" }}
                                                    // value={newprovinceid}
                                                    name='crProvinceID'
                                                    value={values.checked ? values.crProvinceID = values.czProvinceID : values.crProvinceID}
                                                    onChange={e => {
                                                        setFieldValue('crProvinceID', e.target.value)
                                                        setNewprovinceid(e.target.value)
                                                    }

                                                    }
                                                >
                                                    {curprovince?.map((x, i) => <MenuItem key={i} value={x?.mp_provinceID}>{x?.mp_provinceName}</MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl> */}
                        </GridField>
                        <GridField text="เขต/อำเภอ">
                          {values.crDistrictID
                            ? setNewdistrictID(values.crDistrictID)
                            : ""}
                          {checkPermission.find(
                            (x) => x.menuNo === "M001" && x.isEdit
                          ) ? (
                            <Autocomplete
                              size="small"
                              fullWidth
                              disabled={values.checked ? true : false}
                              value={
                                values.checked
                                  ? district.filter(
                                    (e) => e.md_districtID === values.czDistrictID
                                  )[0]
                                    ? district.filter(
                                      (e) => e.md_districtID === values.czDistrictID
                                    )[0]
                                    : {}
                                  : curdistrict.filter(
                                    (e) => e.md_districtID === values.crDistrictID
                                  )[0]
                                    ? curdistrict.filter(
                                      (e) => e.md_districtID === values.crDistrictID
                                    )[0]
                                    : {}
                              }
                              onChange={(e, newValue) => {
                                setNewdistrictID(newValue);
                                setFieldValue(
                                  "crDistrictID",
                                  values.checked
                                    ? values.czDistrictID
                                    : newValue?.md_districtID
                                );
                                setnewSubdistrictID("");
                              }}
                              options={values.checked ? district : curdistrict}
                              getOptionLabel={(option) =>
                                option.md_districtName ? option.md_districtName : ""
                              }
                              renderInput={(params) => (
                                <TextField {...params} placeholder="เลือกเขต/อำเภอ" />
                              )}
                            />) : <Autocomplete
                            size="small"
                            fullWidth
                            disabled={values.checked ? true : false}
                            value={
                              values.checked
                                ? district.filter(
                                  (e) => e.md_districtID === values.czDistrictID
                                )[0]
                                  ? district.filter(
                                    (e) => e.md_districtID === values.czDistrictID
                                  )[0]
                                  : {}
                                : curdistrict.filter(
                                  (e) => e.md_districtID === values.crDistrictID
                                )[0]
                                  ? curdistrict.filter(
                                    (e) => e.md_districtID === values.crDistrictID
                                  )[0]
                                  : {}
                            }
                            onChange={(e, newValue) => {
                              setNewdistrictID(newValue);
                              setFieldValue(
                                "crDistrictID",
                                values.checked
                                  ? values.czDistrictID
                                  : newValue?.md_districtID
                              );
                              setnewSubdistrictID("");
                            }}
                            options={values.checked ? district : curdistrict}
                            getOptionLabel={(option) =>
                              option.md_districtName ? option.md_districtName : ""
                            }
                            renderInput={(params) => (
                              <TextField {...params} placeholder="เลือกเขต/อำเภอ" />
                            )}
                          />}
                          {/* <FormControl
                                                sx={{
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyItems: "center",
                                                }}
                                                variant="outlined"
                                                color="success"
                                            >
                                                {values.crDistrictID ? setNewdistrictID(values.crDistrictID) : ''}
                                                <Select
                                                    size='small'
                                                    displayEmpty
                                                    inputProps={{ "aria-label": "Without label" }}
                                                    name='crDistrictID'
                                                    value={values.checked ? values.crDistrictID = values.czDistrictID : values.crDistrictID}
                                                    onChange={e => {
                                                        setFieldValue('crDistrictID', e.target.value)
                                                        setNewdistrictID(e.target.value)
                                                    }}
                                                >
                                                    {values.checked ? district?.map((x, i) => <MenuItem key={i} value={x?.md_districtID}>{x?.md_districtName}</MenuItem>
                                                    ) : curdistrict?.map((x, i) => <MenuItem key={i} value={x?.md_districtID}>{x?.md_districtName}</MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl> */}
                        </GridField>
                        <GridField text="แขวง/ตำบล">
                          {checkPermission.find(
                            (x) => x.menuNo === "M001" && x.isEdit
                          ) ? (
                            <FormControl
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyItems: "center",
                              }}
                              variant="outlined"
                              color="success"
                            >

                              <Select
                                size="small"
                                name="crSubDistrictID"
                                displayEmpty
                                disabled={values.checked ? true : false}
                                inputProps={{ "aria-label": "Without label" }}
                                value={
                                  values.checked
                                    ? (values.crSubDistrictID =
                                      values.czSubDistrictID)
                                    : values.crSubDistrictID
                                }
                                onChange={(e) => {
                                  setFieldValue("crSubDistrictID", e.target.value);
                                  setnewSubdistrictID(e.target.value);
                                }}
                              >
                                {values.checked
                                  ? subdistrict?.map((x, i) => (
                                    <MenuItem
                                      key={i}
                                      value={x?.msd_subdistrictID}
                                      onClick={setZipcode(x.msd_zipcode)}
                                    >
                                      {x?.msd_subdistrictName}
                                    </MenuItem>
                                  ))
                                  : cursubdistrict?.map((x, i) => (
                                    <MenuItem
                                      key={i}
                                      value={x?.msd_subdistrictID}
                                      onClick={setczZipcode(x?.msd_zipcode)}
                                    >
                                      {x?.msd_subdistrictName}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>) : <FormControl
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyItems: "center",
                              }}
                              variant="outlined"
                              color="success"

                            >

                            <Select
                              size="small"
                              name="crSubDistrictID"
                              displayEmpty

                              disabled
                              inputProps={{ "aria-label": "Without label" }}
                              value={
                                values.checked
                                  ? (values.crSubDistrictID =
                                    values.czSubDistrictID)
                                  : values.crSubDistrictID
                              }
                              onChange={(e) => {
                                setFieldValue("crSubDistrictID", e.target.value);
                                setnewSubdistrictID(e.target.value);
                              }}
                            >
                              {values.checked
                                ? subdistrict?.map((x, i) => (
                                  <MenuItem
                                    key={i}
                                    value={x?.msd_subdistrictID}
                                    onClick={setZipcode(x.msd_zipcode)}
                                  >
                                    {x?.msd_subdistrictName}
                                  </MenuItem>
                                ))
                                : cursubdistrict?.map((x, i) => (
                                  <MenuItem
                                    key={i}
                                    value={x?.msd_subdistrictID}
                                    onClick={setczZipcode(x?.msd_zipcode)}
                                  >
                                    {x?.msd_subdistrictName}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>}
                        </GridField>
                        <GridField text="รหัสไปรษณีย์">
                          {checkPermission.find(
                            (x) => x.menuNo === "M001" && x.isEdit
                          ) ? (
                            <TextField
                              label=""
                              size="small"
                              disabled={values.checked ? true : false}
                              value={values.checked ? zipcode : cazipcode}
                              variant="outlined"
                              color="success"
                              sx={{ width: "100%" }}
                            />) : <TextField
                            label=""
                            size="small"
                            disabled

                            value={values.checked ? zipcode : cazipcode}
                            variant="outlined"
                            color="success"
                            sx={{ width: "100%" }}
                          />}
                        </GridField>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Box
                    sx={{ display: "flex", color: "#000", marginTop: "50px" }}
                  >
                    <Typography variant="h5">ประวัติเข็มกิตติคุณ</Typography>
                  </Box>
                  <Divider
                    sx={{
                      width: "100%",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  />
                  <Stack spacing={3}>
                    <TableContainer>
                      <Table
                        sx={{ minWidth: 700 }}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow sx={{ backgroundColor: "#e7e7e7" }}>
                            <TableCell
                              sx={{
                                color: "#000",
                                border: "none",
                                fontSize: "18px",
                                fontWeight: "500",
                              }}
                              align="left"
                            >
                              ปี-เข็มกิตติคุณ
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000",
                                border: "none",
                                fontSize: "18px",
                                fontWeight: "500",
                              }}
                              align="left"
                            >
                              ชั้น-เข็มกิตติคุณ
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000",
                                border: "none",
                                fontSize: "18px",
                                fontWeight: "500",
                              }}
                              align="left"
                            >
                              วันที่อนุมัติ
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {pDetail?.honorPin?.map((item, i) => (
                            <TableRow key={i}>
                              <TableCell
                                sx={{
                                  color: "#000",
                                  border: "none",
                                  fontSize: "18px",
                                  fontWeight: "500",
                                }}
                                align="left"
                              >
                                {moment(item?.year)
                                  .add(543, "year")
                                  .format("DD/MM/yyyy")
                                  .substring(6) === "d date"
                                  ? ""
                                  : moment(item?.year)
                                    .add(543, "year")
                                    .format("DD/MM/yyyy")
                                    .substring(6) || ""}
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#000",
                                  border: "none",
                                  fontSize: "18px",
                                  fontWeight: "500",
                                }}
                                align="left"
                              >{`ชั้น ${item?.cerRank}`}</TableCell>
                              <TableCell
                                sx={{
                                  color: "#000",
                                  border: "none",
                                  fontSize: "18px",
                                  fontWeight: "500",
                                }}
                                align="left"
                              >
                                {moment(item?.approveDate)
                                  .add(543, "year")
                                  .format("LL")}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Stack>
                  <Box
                    sx={{ display: "flex", color: "#000", marginTop: "50px" }}
                  >
                    <Typography variant="h5">ประกาศนียบัตร</Typography>
                  </Box>
                  <Divider
                    sx={{
                      width: "100%",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                  />
                  <Stack spacing={3}>
                    <TableContainer>
                      <Table
                        sx={{ minWidth: 700 }}
                        aria-label="customized table"
                      >
                        <TableHead sx={{ backgroundColor: "#e7e7e7" }}>
                          <TableRow>
                            <TableCell
                              sx={{
                                color: "#000",
                                border: "none",
                                fontSize: "18px",
                                fontWeight: "500",
                              }}
                              align="left"
                            >
                              ปี-ประกาศนียบัตร
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000",
                                border: "none",
                                fontSize: "18px",
                                fontWeight: "500",
                              }}
                              align="left"
                            >
                              วันที่อนุมัติ
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {/* {console.log(pDetail)} */}
                          {pDetail?.honorCer?.map((item, i) => (
                            <TableRow key={i}>
                              <TableCell
                                sx={{
                                  color: "#000",
                                  border: "none",
                                  fontSize: "18px",
                                  fontWeight: "500",
                                }}
                                align="left"
                              >
                                {item?.year}
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "#000",
                                  border: "none",
                                  fontSize: "18px",
                                  fontWeight: "500",
                                }}
                                align="left"
                              >
                                {moment(item?.approveDate).format("LL")}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Stack>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Box sx={{ minHeight: "60vh" }}>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button
                        variant="outlined"
                        onClick={workopen}
                        sx={{
                          borderColor: "#1fc64c",
                          "&:hover": { borderColor: "#4caf50" },
                          color: "#1fc64c",
                          padding: "7px 55px",
                          marginTop: "23px",
                          marginBottom: "20px",
                        }}
                      >
                        <AddIcon /> เพิ่ม
                      </Button>
                    </Box>
                    <TableContainer>
                      <Table
                        sx={{
                          minWidth: 650,
                          borderCollapse: "inherit",
                          borderSpacing: "0",
                        }}
                      >
                        <TableHead sx={{ backgroundColor: "#e7e7e7" }}>
                          <TableRow>
                            <TableCell
                              sx={{
                                color: "#000",
                                border: "none",
                                fontSize: "14px",
                                fontWeight: "700",
                              }}
                              align="center"
                            >
                              #
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000",
                                border: "none",
                                fontSize: "14px",
                                fontWeight: "700",
                              }}
                              align="center"
                            >
                              วันที่เริ่มต้น
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000",
                                border: "none",
                                fontSize: "14px",
                                fontWeight: "700",
                              }}
                              align="center"
                            >
                              วันที่สิ้นสุด
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000",
                                border: "none",
                                fontSize: "14px",
                                fontWeight: "700",
                              }}
                              align="center"
                            >
                              คุณสมบัติ
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000",
                                border: "none",
                                fontSize: "14px",
                                fontWeight: "700",
                              }}
                              align="center"
                            >
                              ตำเเหน่ง
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000",
                                border: "none",
                                fontSize: "14px",
                                fontWeight: "700",
                              }}
                              align="right"
                            ></TableCell>
                            {/* <TableCell sx={{ color: '#000', border: 'none', }} align="center"></TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {/* {console.log(workexp)} */}
                          {workexp
                            ?.filter((status) => status?.status === true)
                            .map((x, i) => (
                              <Tableposition
                                x={x}
                                i={i}
                                workexp={workexp}
                                setworkexp={setworkexp}
                                fetdata={getwork}
                              />
                              // <TableRow key={i}>
                              //     <TableCell sx={{ color: '#000', border: 'none', fontSize: '14px', fontWeight: '500' }} align="center">{i + 1}</TableCell>
                              //     <TableCell sx={{ color: '#000', border: 'none', fontSize: '14px', fontWeight: '500' }} align="center">{moment(x?.startDate).format('DD-MM-yyyy')}</TableCell>
                              //     <TableCell sx={{ color: '#000', border: 'none', fontSize: '14px', fontWeight: '500' }} align="center">{moment(x?.endtDate).format('DD-MM-yyyy')}</TableCell>
                              //     <TableCell sx={{ color: '#000', border: 'none', fontSize: '14px', fontWeight: '500' }} align="center">{x?.position?.positionName}</TableCell>
                              //     <TableCell sx={{ color: '#000', border: 'none', fontSize: '14px', fontWeight: '500' }} align="center">{x?.positionDsc}</TableCell>
                              //     <TableCell sx={{ color: '#000', border: 'none', fontSize: '14px', fontWeight: '500' }} align="right"><Button variant="outlined" sx={{ borderColor: "#1fc64c", '&:hover': { borderColor: '#4caf50', backgroundColor: 'none' }, color: "#1fc64c" }} onClick={() => handleEdit(x)} ><EditIcon /></Button> <Button variant='outlined' sx={{ borderColor: "red", '&:hover': { borderColor: 'red', backgroundColor: 'none' }, color: "red" }} onClick={() => handleDeleteWork(x)}>x</Button> </TableCell>
                              // </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                  <DialogJob open={open} setOpen={setOpen} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <Box sx={{ minHeight: "60vh" }}>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button
                        variant="outlined"
                        sx={{
                          borderColor: "#1fc64c",
                          "&:hover": { borderColor: "#4caf50" },
                          color: "#1fc64c",
                          padding: "7px 55px",
                          marginTop: "23px",
                          marginBottom: "20px",
                        }}
                        onClick={appre}
                      >
                        <AddIcon /> เพิ่ม
                      </Button>
                    </Box>
                    <TableContainer>
                      <Table
                        sx={{
                          minWidth: 650,
                          borderCollapse: "inherit",
                          borderSpacing: "0",
                        }}
                      >
                        <TableHead sx={{ backgroundColor: "#e7e7e7" }}>
                          <TableRow>
                            <TableCell
                              sx={{
                                color: "#000",
                                border: "none",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                              align="center"
                            >
                              #
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000",
                                border: "none",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                              align="center"
                            >
                              วันที่
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000",
                                border: "none",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                              align="center"
                            >
                              รายละเอียด
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000",
                                border: "none",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                              align="center"
                            >
                              ชั้นเข็ม/ประกาศนียบัตร
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000",
                                border: "none",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                              align="center"
                            >
                              กรณีพิเศษ
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000",
                                border: "none",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                              align="center"
                            >
                              ผู้เสนอ
                            </TableCell>

                            <TableCell
                              sx={{
                                color: "#000",
                                border: "none",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                              align="center"
                            ></TableCell>
                            {/* <TableCell sx={{ color: '#000', border: 'none', }} align="center"></TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {apprecia?.map((x, index) => (
                            <Tableadd
                              x={x}
                              index={index}
                              workexp={apprecia}
                              setworkexp={setapprecia}
                              fetdata={getadd}
                            />
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <Box sx={{ minHeight: "60vh" }}>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button
                        variant="outlined"
                        sx={{
                          borderColor: "#1fc64c",
                          "&:hover": { borderColor: "#4caf50" },
                          color: "#1fc64c",
                          padding: "7px 55px",
                          marginTop: "23px",
                          marginBottom: "20px",
                        }}
                        onClick={() => setOpenBCP(true)}
                      >
                        <AddIcon /> เพิ่ม
                      </Button>
                    </Box>
                    <TableContainer>
                      <Table
                        sx={{
                          minWidth: 650,
                          borderCollapse: "inherit",
                          borderSpacing: "0",
                        }}
                      >
                        <TableHead sx={{ backgroundColor: "#e7e7e7" }}>
                          <TableRow>
                            <TableCell
                              sx={{
                                color: "#000",
                                border: "none",
                                fontSize: "14px",
                                fontWeight: "700",
                              }}
                              align="center"
                            >
                              #
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000",
                                border: "none",
                                fontSize: "14px",
                                fontWeight: "700",
                              }}
                              align="center"
                            >
                              วันที่เริ่มต้น
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000",
                                border: "none",
                                fontSize: "14px",
                                fontWeight: "700",
                              }}
                              align="center"
                            >
                              วันที่สิ้นสุด
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000",
                                border: "none",
                                fontSize: "14px",
                                fontWeight: "700",
                              }}
                              align="center"
                            >
                              รายละเอียด
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000",
                                border: "none",
                                fontSize: "14px",
                                fontWeight: "700",
                              }}
                              align="center"
                            >
                              ผู้เสนอ
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "#000",
                                border: "none",
                                fontSize: "14px",
                                fontWeight: "700",
                              }}
                              align="center"
                            ></TableCell>
                            {/* <TableCell sx={{ color: '#000', border: 'none', }} align="center"></TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {listBreakCalPersonal?.map((x, index) => (
                            <TableListBreakCalPersonal
                              x={x}
                              index={index}
                              listBreakCalPersonal={listBreakCalPersonal}
                              setListBreakCalPersonal={setListBreakCalPersonal}
                              fetdata={getListBreakCalPersonal}
                            />
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </TabPanel>
              </Container>
              <Box
                sx={{
                  position: "sticky",
                  bottom: "0px",
                  zIndex: "100",
                  width: "100%",
                  marginTop: "50px",
                  background: "#fff",
                }}
              >
                <Divider sx={{ marginBottom: "10px" }} />
                <Container maxWidth="xl" sx={{ height: "auto" }}>
                  <Box
                    sx={{
                      padding: "5px 20px",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        height: "fit-content",
                        fontSize: "18px",
                        color: "#0fb123",
                        border: "1px solid #0fb123",
                        ":hover": { border: "1px solid #028b13" },
                      }}
                      component={NavLink}
                      to="/"
                    >
                      กลับ
                    </Button>
                    <Button
                      variant="contained"
                      type="sumbit"
                      sx={{
                        height: "fit-content",
                        fontSize: "18px",
                        marginLeft: "20px",
                        backgroundColor: "#0fb123",
                        ":hover": { backgroundColor: "#028b13" },
                      }}
                    >
                      บันทึก
                    </Button>
                  </Box>
                </Container>
              </Box>
              <DialogAdd
                open={openappre}
                setOpen={setappre}
                apprecia={apprecia}
                setapprecia={setapprecia}
                id={id}
              ></DialogAdd>
              <DialogJobe
                open={openwork}
                setOpen={setopenwork}
                workexp={getwork}
                setworkexp={setworkexp}
                id={id}
              ></DialogJobe>
              <DialogBreakCalP
                open={openBCP}
                setOpen={setOpenBCP}
                listBreakCalPersonal={listBreakCalPersonal}
                setListBreakCalPersonal={setListBreakCalPersonal}
                id={id}
                fetdata={getListBreakCalPersonal}
              ></DialogBreakCalP>
              <DialogJobedetail
                setOpen={setopenworkedit}
                open={openworkedit}
                workexp={idwork}
              ></DialogJobedetail>
            </form>
          )}
        </Formik>
      )}
    </>
  );
}
export const FileUploadC = ({ setUploadedImg, fileRef, setFieldValue }) => {
  // const fileRef = useRef()

  const handleFileChange = async (event) => {
    const fileObj = event.target.files && event.target.files[0];
    // console.log('fileObj is', fileObj);

    let formData = new FormData();
    formData.append("file", fileObj);
    // event.target.value = null;

    const res = await api.post(`api/v1.0/filemanages/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        // 'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySUQiOiI0NjIxZDJmZS04ZjkyLTQyNDctODcwZi1jYThiNTNkOGJhZTAiLCJyb2xlIjp7InJvbGVJRCI6IjEiLCJyb2xlTmFtZSI6IkRldmVsb3BlciIsImxldmVsIjoxLCJzdGF0dXMiOnRydWUsImNyZWF0ZUlEIjpudWxsLCJjcmVhdGVEYXRlIjoiMjAyMi0wNy0yMFQwNzoxMTozMi41MTlaIiwibW9kaWZpZWRJRCI6bnVsbCwibW9kaWZpZWREYXRlIjoiMjAyMi0wNy0yMFQwNzoxMTozMi41MTlaIn0sImlhdCI6MTY2Mzc0MzczMCwiZXhwIjoxNjY0NjQzNzMwfQ.GkENpUAqQny_TthYu9ZwzNTaGlZmfXq7Ytt5rtbK3R4`
      },
    });
    const data = await res.data;
    if (data.status === true) {
      setUploadedImg(data.result);
      setFieldValue("fileID", data.result.fileID);
    }
  };
  return (
    <Grid item lg={2} md={9} xs={12}>
      <input
        type="file"
        ref={fileRef}
        accept="image/*"
        hidden
        onChange={handleFileChange}
      />
      <Button
        variant="contained"
        onClick={() => fileRef.current.click()}
        sx={{
          width: "150px",
          height: "fit-content",
          fontSize: "18px",
          marginLeft: "20px",
          backgroundColor: "#0fb123",
          ":hover": { backgroundColor: "#028b13" },
        }}
      >
        อัพโหลดไฟล์
      </Button>
    </Grid>
  );
};
export function Tableposition({ x, i, workexp, setworkexp, fetdata }) {
  const [openworkedit, setopenworkedit] = useState(false);
  // console.log(workexp);
  const checkPermission = useSelector(selectCanActivate);
  const handleDeleteWork = (item) => {
    const w = {
      WorkID: item?.WorkID,
      personalID: item?.personalID,
      positionID: item?.positionID,
      startDate: item?.startDate,
      endDate: item?.endDate,
      positionDsc: item?.positionDsc,
      status: false,
    };
    // const D =
    //     workexp.filter((x) => x.status === true).
    setworkexp(workexp?.filter((e) => e?.WorkID !== item?.WorkID));
    const Delete = async () => {
      const res = await api.post("api/v1.0/personal/create-workexperince", w);
      const data = await res?.data;
      // console.log(data);
    };
    Delete();
  };

  const handleEdit = async (e) => {
    // console.log(workexp);
  };
  return (
    <>
      <TableRow key={i}>
        <TableCell
          sx={{
            color: "#000",
            border: "none",
            fontSize: "14px",
            fontWeight: "700",
          }}
          align="center"
        >
          {i + 1}
        </TableCell>
        <TableCell
          sx={{
            color: "#000",
            border: "none",
            fontSize: "14px",
            fontWeight: "700",
          }}
          align="center"
        >
          {moment(x?.startDate).add(543, "year").format("LL")}
        </TableCell>
        <TableCell
          sx={{
            color: "#000",
            border: "none",
            fontSize: "14px",
            fontWeight: "700",
          }}
          align="center"
        >
          {moment(x?.endDate).add(543, "year").format("LL") ===
            "Invalid date"
            ? null
            : moment(x?.endDate).add(543, "year").format("LL")}
        </TableCell>
        <TableCell
          sx={{
            color: "#000",
            border: "none",
            fontSize: "14px",
            fontWeight: "700",
          }}
          align="center"
        >
          {x?.position?.positionName}
        </TableCell>
        <TableCell
          sx={{
            color: "#000",
            border: "none",
            fontSize: "14px",
            fontWeight: "700",
          }}
          align="center"
        >
          {x?.positionDsc}
        </TableCell>
        {checkPermission.find(
          (x) => x.menuNo === "M001" && x.isEdit
        ) ? (
          <TableCell
            sx={{
              color: "#000",
              border: "none",
              fontSize: "14px",
              fontWeight: "700",
            }}
            align="right"
          >

            <Button
              variant="outlined"
              sx={{
                borderColor: "#1fc64c",
                "&:hover": { borderColor: "#4caf50", backgroundColor: "none" },
                color: "#1fc64c",
              }}
              onClick={() => setopenworkedit(true)}
            >
              <EditIcon />
            </Button>
            <Button
              variant="outlined"
              sx={{
                borderColor: "red",
                marginLeft: "8px",
                "&:hover": { borderColor: "red", backgroundColor: "none" },
                color: "red",
              }}
              onClick={() => handleDeleteWork(x)}
            >
              x
            </Button>
          </TableCell>
        ) : ""}
      </TableRow>
      <DialogJobedetail
        setOpen={setopenworkedit}
        open={openworkedit}
        workexp={x}
        fetdata={fetdata}
      ></DialogJobedetail>
    </>
  );
}

export function Tableadd({ x, index, workexp, setworkexp, fetdata }) {
  const [apprecia, setapprecia] = useState([]);
  const [openadd, setopenadd] = useState(false);
  const checkPermission = useSelector(selectCanActivate);
  const handleDeleteW = (item) => {
    const body = {
      appreciateID: item?.appreciateID,
      personalID: item?.personalID,
      appreciateDate: item?.appreciateDate,
      description: item?.description,
      status: false,
    };
    // console.log(workexp);
    // const D =
    //     workexp.filter((x) => x.status === true).
    setworkexp(workexp.filter((e) => e.appreciateID !== item.appreciateID));
    const Delete = async () => {
      const res = await api.post("api/v1.0/personal/create-Appreciate", body);
      const data = await res?.data;
      // console.log(data);
    };
    Delete();
  };
  return (
    <>
      <TableRow key={index}>
        <TableCell
          sx={{
            color: "#000",
            border: "none",
            fontSize: "14px",
            fontWeight: "500",
          }}
          align="center"
        >
          {index + 1}
        </TableCell>
        <TableCell
          sx={{
            color: "#000",
            border: "none",
            fontSize: "14px",
            fontWeight: "500",
          }}
          align="center"
        >
          {moment(x?.appreciateDate).add(543, "year").format("DD-MM-YYYY")}
        </TableCell>
        <TableCell
          sx={{
            color: "#000",
            border: "none",
            fontSize: "14px",
            fontWeight: "500",
          }}
          align="center"
        >
          {x?.description}
        </TableCell>
        <TableCell
          sx={{
            color: "#000",
            border: "none",
            fontSize: "14px",
            fontWeight: "500",
          }}
          align="center"
        >
          {x?.rank === null ? "ไม่ระบุชั้นเข็ม" : x?.rank === '999' ? 'ประกาศนียบัตรชั้นสูงกิตติมศักดิ์' : x?.rank}
        </TableCell>
        <TableCell
          sx={{
            color: "#000",
            border: "none",
            fontSize: "14px",
            fontWeight: "500",
          }}
          align="center"
        >
          {x?.appreciateType?.appreciateTypeName}
        </TableCell>
        <TableCell
          sx={{
            color: "#000",
            border: "none",
            fontSize: "14px",
            fontWeight: "500",
          }}
          align="center"
        >
          {x?.present}
        </TableCell>
        {checkPermission.find(
          (x) => x.menuNo === "M001" && x.isEdit
        ) ? (
          <TableCell
            sx={{
              color: "#000",
              border: "none",
              fontSize: "14px",
              fontWeight: "500",
            }}
            align="right"
          >

            <Button
              variant="outlined"
              sx={{
                borderColor: "#1fc64c",
                "&:hover": { borderColor: "#4caf50", backgroundColor: "none" },
                color: "#1fc64c",
              }}
              onClick={() => setopenadd(true)}
            >
              <EditIcon />
            </Button>
            <Button
              variant="outlined"
              sx={{
                borderColor: "red",
                marginLeft: "8px",
                "&:hover": { borderColor: "red", backgroundColor: "none" },
                color: "red",
              }}
              onClick={() => handleDeleteW(x)}
            >
              x
            </Button>
          </TableCell>) : ''}
      </TableRow>
      <DialogAddedit
        setOpen={setopenadd}
        open={openadd}
        apprecia={x}
        setapprecia={setapprecia}
        fetdata={fetdata}
      />
    </>
  );
}
export function TableListBreakCalPersonal({
  x,
  index,
  listBreakCalPersonal,
  setListBreakCalPersonal,
  fetdata,
}) {
  // const [apprecia, setapprecia] = useState([])
  const [open, setOpen] = useState(false);
  const checkPermission = useSelector(selectCanActivate);
  const handleDeleteW = () => {
    const body = {
      breakCalID: x?.breakCalID,
      personalID: x?.personalID,
      startDate: x?.startDate,
      endDate: x?.endDate,
      remark: x?.remark,
      // position: {
      //     positionName: value?.positionName
      // },
      present: x?.present,

      status: false,
    };
    // console.log(workexp);
    // const D =
    //     workexp.filter((x) => x.status === true).
    // setListBreakCalPersonal(workexp.filter((e) => e.appreciateID !== item.appreciateID))
    const Delete = async () => {
      const res = await api.post(
        "api/v1.0/personal/createbreakcal-personal",
        body
      );
      const data = await res?.data;
      if (data?.status === true) {
        fetdata();
      }
      // console.log(data);
    };
    Delete();
  };
  return (
    <>
      <TableRow key={index}>
        <TableCell
          sx={{
            color: "#000",
            border: "none",
            fontSize: "14px",
            fontWeight: "500",
          }}
          align="center"
        >
          {index + 1}
        </TableCell>
        <TableCell
          sx={{
            color: "#000",
            border: "none",
            fontSize: "14px",
            fontWeight: "500",
          }}
          align="center"
        >
          {moment(x?.startDate).add(543, "year").format("LL")}
        </TableCell>
        <TableCell
          sx={{
            color: "#000",
            border: "none",
            fontSize: "14px",
            fontWeight: "500",
          }}
          align="center"
        >
          {moment(x?.endDate).add(543, "year").format("LL")}
        </TableCell>
        <TableCell
          sx={{
            color: "#000",
            border: "none",
            fontSize: "14px",
            fontWeight: "500",
          }}
          align="center"
        >
          {x?.remark}
        </TableCell>
        <TableCell
          sx={{
            color: "#000",
            border: "none",
            fontSize: "14px",
            fontWeight: "500",
          }}
          align="center"
        >
          {x?.present}
        </TableCell>
        {checkPermission.find(
          (x) => x.menuNo === "M001" && x.isEdit
        ) ? (
          <TableCell
            sx={{
              color: "#000",
              border: "none",
              fontSize: "14px",
              fontWeight: "500",
            }}
            align="right"
          >
            <Button
              variant="outlined"
              sx={{
                borderColor: "#1fc64c",
                "&:hover": { borderColor: "#4caf50", backgroundColor: "none" },
                color: "#1fc64c",
              }}
              onClick={() => setOpen(true)}
            >
              <EditIcon />
            </Button>
            <Button
              variant="outlined"
              sx={{
                borderColor: "red",
                marginLeft: "8px",
                "&:hover": { borderColor: "red", backgroundColor: "none" },
                color: "red",
              }}
              onClick={() => handleDeleteW()}
            >
              x
            </Button>
          </TableCell>) : ""}
      </TableRow>
      <DialogBreakCalPEdite
        setOpen={setOpen}
        open={open}
        x={x}
        fetdata={fetdata}
      />
    </>
  );
}
