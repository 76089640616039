import {
  Box,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Container,
  FormControlLabel,
  Avatar,
  Input,
  Autocomplete,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { useNavigate, NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import Grid from "@mui/material/Grid";
import TitleHead from "../../components/TitleHead";
import SearchIcon from "@mui/icons-material/Search";
import GridField from "../../components/GridTextField";
import React, { useState, useEffect, useContext, useRef } from "react";
import Checkbox from "@mui/material/Checkbox";
import api from "../../api/api";
import PropTypes from "prop-types";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Formik } from "formik";
import * as Yup from "yup";
import ApiContext from "../../context/ApiProvider";
import { IMaskInput } from "react-imask";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AddIcon from "@mui/icons-material/Add";
import DialogJob from "./DialogJob";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import DialogJobedit from "./DialogJobedit";
import { useSelector } from "react-redux";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EDEFF0",
    color: theme.palette.common,
    fontWeight: "500",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    /*  backgroundColor: '#F2F5F8', */
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function Addpersonal_information() {
  const [title, setTitle] = useState([]);
  const {
    getPersonalTitle,
    getPersonalProvince,
    getPersonalDistrict,
    getPersonalSubDistrict,
    getCurPersonalDistrict,
    getCurPersonalSubDistrict,
    getPersonalDetail,
  } = useContext(ApiContext);
  const [status, setStatus] = useState(true);
  const navigate = useNavigate();
  const [titleid, setTitleid] = useState("");
  const [province, setProvince] = useState([]);
  const [provinceItem, setProvinceItem] = useState();
  const [provinceid, setProvinceid] = useState();
  const [district, setDistrict] = useState([]);
  const [districtid, setDistrictID] = useState("");
  const [subdistrict, setSubDistrict] = useState([]);
  const [subdistrictid, setSubdistrictID] = useState("");
  const [houseNo, setHouseNo] = useState("");
  const [moo, setMoo] = useState("");
  const [address, setAddress] = useState("");

  const [newdistrictid, setNewdistrictID] = useState("");
  const [newprovinceid, setNewprovinceid] = useState("");
  const [newsubdistrictid, setnewSubdistrictID] = useState("");
  const [curprovince, setCurProvince] = useState([]);
  const [curdistrict, setCurdistrict] = useState([]);
  const [cursubdistrict, setcurSubdistrict] = useState([]);
  const [zipcode, setZipcode] = useState("");
  const [cazipcode, setczZipcode] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [workexperinceDetail, setWorkexperinceDetail] = React.useState([]);
  const fileRef = useRef();
  const [uploadImg, setUploadedImg] = useState();
  const { baseUrl } = useSelector(state => state.baseUrl)
  // console.log(baseUrl)
  // const handleFileChange = async (event) => {
  //     const fileObj = event.target.files && event.target.files[0];
  //     // console.log('fileObj is', fileObj);

  //     let formData = new FormData();
  //     formData.append("file", fileObj);
  //     // event.target.value = null;

  //     const res = await api.post(`api/v1.0/filemanages/upload`, formData, {
  //         headers: {
  //             "Content-Type": "multipart/form-data",
  //             // 'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySUQiOiI0NjIxZDJmZS04ZjkyLTQyNDctODcwZi1jYThiNTNkOGJhZTAiLCJyb2xlIjp7InJvbGVJRCI6IjEiLCJyb2xlTmFtZSI6IkRldmVsb3BlciIsImxldmVsIjoxLCJzdGF0dXMiOnRydWUsImNyZWF0ZUlEIjpudWxsLCJjcmVhdGVEYXRlIjoiMjAyMi0wNy0yMFQwNzoxMTozMi41MTlaIiwibW9kaWZpZWRJRCI6bnVsbCwibW9kaWZpZWREYXRlIjoiMjAyMi0wNy0yMFQwNzoxMTozMi41MTlaIn0sImlhdCI6MTY2Mzc0MzczMCwiZXhwIjoxNjY0NjQzNzMwfQ.GkENpUAqQny_TthYu9ZwzNTaGlZmfXq7Ytt5rtbK3R4`
  //         },
  //     });
  //     const data = await res.data;
  //     if (data.status === true) {
  //         setUploadedImg(data.result);
  //     }
  // };
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    getPersonalTitle(setTitle);
  }, [getPersonalTitle, setTitle]);
  useEffect(() => {
    getPersonalProvince(setProvince, setCurProvince);
    if (provinceid) {
      getPersonalDistrict(setDistrict, provinceid);
      if (districtid) {
        getPersonalSubDistrict(setSubDistrict, provinceid, districtid);
      }
    }
  }, [
    getPersonalProvince,
    setProvince,
    setCurProvince,
    provinceid,
    getPersonalDistrict,
    setDistrict,
    getPersonalSubDistrict,
    setSubDistrict,
    districtid,
  ]);
  useEffect(() => {
    if (newprovinceid) {
      getCurPersonalDistrict(setCurdistrict, newprovinceid);
      if (newdistrictid) {
        getCurPersonalSubDistrict(
          setcurSubdistrict,
          newprovinceid,
          newdistrictid
        );
      }
    }
  }, [
    newprovinceid,
    getCurPersonalDistrict,
    setNewdistrictID,
    newdistrictid,
    getCurPersonalSubDistrict,
    setnewSubdistrictID,
  ]);
  const postdata = (data) => {
    api.post("api/v1.0/personal/create-personal", data).then((res) => {
      // console.log() 
      if (res?.data?.status === true) {
        Swal.fire({
          title: 'สร้างแล้ว',
          text: "สร้างข้อมูลสำเร็จ",
          icon: "success",
          confirmButtonColor: '#0f962d',
          confirmButtonText: 'ตกลง',
          allowOutsideClick: false
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            navigate(`/${res?.data?.result?.personalID}`)
          }
        })
      }
      // const MySwal = withReactContent(Swal);

      // MySwal.fire({
      //   title: <p>สร้างข้อมูลสำเร็จ</p>,

      //   icon: "success",

      //   didOpen: () => {
      //     // `MySwal` is a subclass of `Swal` with all the same instance & static methods

      //     MySwal.clickConfirm();
      //   },
      // }).then(() => {
      //   return MySwal.fire({
      //     title: "บันทึกแล้ว",
      //     text: "บันทึกข้อมูลสำเร็จ",
      //     icon: "success",
      //     confirmButtonColor: "#0f962d",
      //     confirmButtonText: "ตกลง",
      //     allowOutsideClick: false,
      //   }).then((result) => {
      //     if (result.isConfirmed) {
      //       navigate = `/`;
      //     }
      //   });
      // });

    });
  };

  const TextMaskCitizen = React.forwardRef(function TextMaskCitizen(
    props,
    ref
  ) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="0-0000-00000-00-0"
        definitions={{
          "#": /[0-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  });
  const TextMaskMobile = React.forwardRef(function TextMaskMobile(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="000-000-0000"
        definitions={{
          "#": /[0-9]/,
        }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  });
  // console.log(newprovinceid)
  return (
    <Formik
      initialValues={{
        personalID: "",
        personalNo: "",
        citizenID: "",
        position: "",
        titleID: null,
        firstname: "",
        lastname: "",
        mobile: "",
        email: "",
        fileID: null,
        workexperinceDetail: [],
        appreciateDate: null,
        description: null,
        czAddress: "",
        czHouseNo: "",
        czMoo: "",
        czProvinceID: null,
        czDistrictID: null,
        czSubDistrictID: null,
        checked: false,
        crAddress: "",
        crHouseNo: "",
        crMoo: "",
        note: "",
        crProvinceID: null,
        crDistrictID: null,
        crSubDistrictID: null,
        status: status,
        submit: null,
      }}
      // enableReinitialize
      validationSchema={Yup.object().shape({
        titleID: Yup.string().max(255).required("กรุณาระบุ คำนำหน้า"),
        firstname: Yup.string().max(255).required("กรุณาระบุ ชื่อ"),
        lastname: Yup.string().max(255).required("กรุณาระบุ นามสกุล"),
        // bankAccNo: Yup.string().max(255).required('กรุณาระบุ เลขบัญชี'),
        // bankAccName: Yup.string().max(255).required('กรุณาระบุ ชื่อบัญชี'),
        // bankAccNickName: Yup.string().max(255).required('กรุณาระบุ ชื่อเล่น'),
      })}
      onSubmit={async (value, { setErrors, setStatus, setSubmitting }) => {
        // console.log(value)
        try {
          // console.log(value)
          postdata(value);
          // create_bankaccount(value)

          setStatus({ success: true });
          setSubmitting(false);
        } catch (err) {
          console.log(err);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
        setValues,
      }) => (
        <form onSubmit={handleSubmit}>
          <Container maxWidth="xl" sx={{ height: "auto", marginTop: "40px" }}>
            {/* <TitleHead title='เพิ่มข้อมูลส่วนบุคคล' subTitle='ข้อมูลทะเบียนประวัติส่วนบุคคล'>
                        </TitleHead> */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <Box>
                <Typography sx={{ fontSize: "32px" }}>
                  เพิ่มข้อมูลส่วนบุคคล
                </Typography>
                <Typography sx={{ color: "#bdbdbd", fontSize: "15px" }}>
                  ข้อมูลทะเบียนประวัติส่วนบุคคล
                </Typography>
              </Box>
            </Box>
            <Tabs
              value={value}
              onChange={handleChangeTab}
              aria-label="basic tabs example"
              sx={{
                color: "#000",
                "& .MuiTabs-indicator": {
                  display: "none",
                },
                "& .MuiTabs-scrollButtons": {
                  color: "#000",
                },
              }}
            >
              <Tab
                label="รายละเอียด"
                sx={{
                  color: "#000",
                  "&.Mui-selected": {
                    color: "#000",
                    borderTop: "1px solid #bdbdbd",
                    borderRight: "1px solid #bdbdbd",
                    borderLeft: "1px solid #bdbdbd",
                    borderRadius: "5px 5px 0 0",
                  },
                }}
              />
              <Box
                sx={{
                  width: "100%",
                  borderBottom: "1px solid #bdbdbd",
                }}
              ></Box>
            </Tabs>

            <TabPanel value={value} index={0}>
              <Grid container spacing={2} sx={{ marginTop: "6px" }}>
                <GridField text="เลขทะเบียน">
                  <TextField
                    name="personalNo"
                    size="small"
                    value={values.personalNo}
                    onChange={handleChange}
                    label=""
                    variant="outlined"
                    color="success"
                    disabled
                    sx={{ width: "100%", background: "#f7f7f7" }}
                  />
                </GridField>

                <GridField text="เลขที่บัตรประชาชน">
                  <TextField
                    name="citizenID"
                    value={values.citizenID}
                    placeholder=""
                    onChange={(e) =>
                      setFieldValue(
                        "citizenID",
                        e.target.value.replaceAll("-", "")
                      )
                    }
                    label=""
                    variant="outlined"
                    color="success"
                    sx={{ width: "100%" }}
                    size="small"
                    InputProps={{
                      inputComponent: TextMaskCitizen,
                    }}
                  >
                    {/* <InputMask mask="(0)999 999 99 99" maskChar=" " /> */}
                  </TextField>
                  {/* <FormControl variant="outlined">
                                        <Input
                                            value={values.citizenID}
                                            onChange={handleChange}
                                            name="citizenID"
                                            // id="formatted-text-mask-input"
                                            inputComponent={TextMaskCustom}
                                        />
                                    </FormControl> */}
                </GridField>
                {/* <GridField text='ตำแหน่งปัจจุบัน'>
                                    <TextField name="position" placeholder='ประธาน' size='small' value={values.position} onChange={handleChange} label="" variant="outlined" color="success" sx={{ width: '100%' }} />
                                </GridField> */}
                <GridField text=""></GridField>
                <GridField text="คำนำ">
                  <FormControl
                    required
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyItems: "center",
                    }}
                    variant="outlined"
                    color="success"
                  >
                    <Select
                      displayEmpty
                      size="small"
                      inputProps={{ "aria-label": "Without label" }}
                      name="titleID"
                      // error={Boolean(touched.titleID)}
                      // value={titleid}
                      // placeholder='fff'
                      value={values.titleID}
                      onChange={(e) => {
                        setFieldValue("titleID", e.target.value);
                        setTitleid(e.target.value);
                      }}
                    >
                      <MenuItem value="">
                        <em>กรุณาเลือกคำนำหน้าชื่อ</em>
                      </MenuItem>
                      {title?.map((x, i) => (
                        <MenuItem key={i} value={x?.mt_titleID}>
                          {x?.mt_titleNameTH}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridField>
                <GridField text="ชื่อ">
                  <TextField
                    name="firstname"
                    value={values.firstname}
                    size="small"
                    //  error={Boolean(touched.firstname)}
                    onChange={handleChange}
                    placeholder="กรุณากรอกชื่อ"
                    required
                    label=""
                    variant="outlined"
                    color="success"
                    sx={{ width: "100%" }}
                  />
                </GridField>
                <GridField text="สกุล">
                  <TextField
                    size="small"
                    name="lastname"
                    // error={Boolean(touched.lastname)}
                    value={values.lastname}
                    placeholder="นามสกุล"
                    required
                    onChange={handleChange}
                    label=""
                    variant="outlined"
                    color="success"
                    sx={{ width: "100%" }}
                  />
                </GridField>
                <GridField text="เบอร์มือถือ">
                  <TextField
                    name="mobile"
                    size="small"
                    placeholder="เบอร์โทร"
                    value={values.mobile}
                    onChange={(e) =>
                      setFieldValue(
                        "mobile",
                        e.target.value.replaceAll("-", "")
                      )
                    }
                    label=""
                    variant="outlined"
                    color="success"
                    sx={{ width: "100%" }}
                    // onKeyPress={(event) => {
                    //     if (!/[0-9]/.test(event.key)) {
                    //         event.preventDefault();
                    //     }
                    // }}
                    // inputProps={
                    //     { maxLength: 10 }
                    // }
                    InputProps={{
                      inputComponent: TextMaskMobile,
                    }}
                  />
                </GridField>
                <GridField text="อีเมล">
                  <TextField
                    name="email"
                    size="small"
                    value={values.email}
                    onChange={handleChange}
                    label=""
                    variant="outlined"
                    color="success"
                    type="email"
                    placeholder="Example@xxx.com"
                    sx={{ width: "100%" }}
                  />
                </GridField>
                <GridField text="สถานะ">
                  <FormControlLabel
                    control={
                      <Switch
                        name="status"
                        checked={status}
                        onChange={(e) => setStatus(e.target.checked)}
                      />
                    }
                    label=""
                  />
                </GridField>
                <Grid item lg={8} md={6} xs={12}>
                  <Grid container sx={{ alignItems: "center" }}>
                    <Grid
                      item
                      lg={3}
                      md={3}
                      xs={12}
                      sx={{
                        display: "flex",
                        alignItems: "start",
                        justifyContent: { sm: "flex-end", xs: "flex-start" },
                      }}
                    >
                      <Avatar
                        sx={{ width: 56, height: 56, marginRight: "15px" }}
                        alt="Remy Sharp"
                        src={`${baseUrl}api/v1.0/filemanages/download?fileID=${uploadImg?.fileID}`}
                      />
                    </Grid>
                    <Grid item lg={3} md={9} xs={12}>
                      <TextField
                        label=""
                        size="small"
                        placeholder=""
                        variant="outlined"
                        color="success"
                        sx={{ width: "100%", background: "#f7f7f7" }}
                        value={uploadImg?.filename}
                        disabled
                      />
                    </Grid>

                    {/* <Grid item lg={2} md={9} xs={12}>
                                            <input
                                                type="file" ref={fileRef} hidden onChange={handleFileChange} />
                                            <Button variant='contained' onClick={() => fileRef.current.click()} sx={{ width: '150px', height: 'fit-content', fontSize: '18px', marginLeft: '20px', backgroundColor: '#0fb123', ':hover': { backgroundColor: '#028b13' } }}>อัพโหลดไฟล์</Button>
                                            <FileUploadC />
                                        </Grid> */}
                    <FileUploadC
                      setUploadedImg={setUploadedImg}
                      fileRef={fileRef}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <Grid
                    container
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "20px",
                    }}
                  >
                    <Grid item md={6} xs={12} lg={3}></Grid>

                    <Grid
                      item
                      md={6}
                      xs={12}
                      lg={9}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <TextField
                        sx={{ width: "100%" }}
                        multiline
                        variant="outlined"
                        color="success"
                        name="note"
                        value={values.note}
                        onChange={handleChange}
                        placeholder="รายละเอียดเพิ่มเติม"
                        rows={4}
                      ></TextField>
                    </Grid>
                  </Grid>
                  <Grid container>

                  </Grid>
                </Grid>

              </Grid>
              <Box sx={{ display: "flex", color: "#000", marginTop: "50px" }}>
                <Typography variant="h5">ตำเเหน่งงาน</Typography>
              </Box>
              <Divider
                sx={{ width: "100%", marginTop: "20px", marginBottom: "20px" }}
              />
              <TableContainer>
                <Table
                  sx={{
                    minWidth: 650,
                    borderCollapse: "none",
                    borderSpacing: "0",
                  }}
                >
                  <TableHead sx={{ backgroundColor: "#e7e7e7" }}>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#000",
                          border: "none",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                        align="left"
                      >
                        #
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#000",
                          border: "none",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                        align="left"
                      >
                        วันที่เริ่มต้น
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#000",
                          border: "none",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                        align="left"
                      >
                        วันที่สิ้นสุด
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#000",
                          border: "none",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                        align="left"
                      >
                        คุณสมบัติ
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#000",
                          border: "none",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                        align="left"
                      >
                        ตำแหน่ง
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#000",
                          border: "none",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                        align="left"
                      >

                      </TableCell>

                      {/* <TableCell
                        sx={{
                          color: "#000",
                          border: "none",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                        align="right"
                      >

                      </TableCell> */}

                      {/* <TableCell sx={{ color: '#000', border: 'none', }} align="center"></TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values?.workexperinceDetail?.map(
                      (x, i) => (
                        <Editjob
                          x={x}
                          i={i}
                          setValues={setValues}
                          values={values}
                          setFieldValue={setFieldValue}
                        />
                      )
                      //   <TableRow key={i}>
                      //     <TableCell
                      //       sx={{ color: "#000", width: "10%", fontSize: "16px" }}
                      //       align="left"
                      //     >
                      //       {i + 1}
                      //     </TableCell>
                      //     <TableCell
                      //       sx={{ color: "#000", width: "15%", fontSize: "16px" }}
                      //       align="left"
                      //     >
                      //       {x.startDate}
                      //     </TableCell>
                      //     <TableCell
                      //       sx={{ color: "#000", width: "15%", fontSize: "16px" }}
                      //       align="left"
                      //     >
                      //       {x.endDate}
                      //     </TableCell>
                      //     <TableCell
                      //       sx={{ color: "#000", width: "30%", fontSize: "16px" }}
                      //       align="center"
                      //     >
                      //       {x.positionName}
                      //     </TableCell>
                      //     <TableCell
                      //       sx={{ color: "#000", width: "30%", fontSize: "16px" }}
                      //       align="center"
                      //     >
                      //       {x.positionDsc}
                      //     </TableCell>
                      //     <TableCell
                      //       sx={{ color: "#000", width: "30%", fontSize: "16px" }}
                      //       align="center"
                      //     >
                      //       <Button
                      //         onClick={() =>
                      //           setValues({
                      //             workexperinceDetail:
                      //               values.workexperinceDetail.filter(
                      //                 (newE) => newE !== x
                      //               ),
                      //           })
                      //         }
                      //       >
                      //         x
                      //       </Button>
                      //     </TableCell>
                      //   </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {values?.workexperinceDetail[0] === undefined ? <Button
                variant="outlined"
                onClick={handleOpen}
                sx={{
                  borderColor: "#1fc64c",
                  "&:hover": { borderColor: "#4caf50" },
                  color: "#1fc64c",
                  padding: "7px 55px",
                  marginTop: "23px",
                }}
              >
                <AddIcon /> เพิ่ม
              </Button> : ''}

              <Box sx={{ display: "flex", color: "#000", marginTop: "50px" }}>
                <Typography variant="h5">
                  ได้รับการยกย่องบําเพ็ญประโยชน์
                </Typography>
              </Box>
              <Divider
                sx={{ width: "100%", marginTop: "20px", marginBottom: "20px" }}
              />
              <Grid container spacing={2}>
                <GridField text="วันที่">
                  <DesktopDatePicker
                    label=""
                    inputFormat="DD/MM/YYYY"
                    value={values.appreciateDate}
                    onChange={(newValue) => {
                      setFieldValue(
                        "appreciateDate",
                        moment(newValue).format("YYYY-MM-DD")
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        color="success"
                        sx={{ width: "100%" }}
                        InputProps={params?.InputProps}
                        inputRef={params?.inputRef}
                        // placeholder={params?.inputProps?.placeholder}
                        // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                        // onChange={params?.inputProps?.onChange}
                        // type={params?.inputProps?.type}
                        inputProps={
                          {
                            placeholder: params?.inputProps?.placeholder,
                            readOnly: true,
                            value: params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null,
                            onChange: params?.inputProps?.onChange,
                            type: params?.inputProps?.type
                          }

                        }
                      />
                    )}
                  />
                </GridField>
                <Grid item lg={8} md={6} xs={12}>
                  <Grid container>
                    <Grid
                      item
                      lg={3}
                      md={3}
                      xs={12}
                      sx={{
                        display: "flex",
                        alignItems: "start",
                        justifyContent: { sm: "flex-end", xs: "flex-start" },
                      }}
                    >
                      <Typography sx={{ marginRight: "15px" }}>
                        รายละเอียด
                      </Typography>
                    </Grid>
                    <Grid item lg={9} md={9} xs={12}>
                      <TextField
                        label=""
                        size="small"
                        placeholder=""
                        disabled={values.appreciateDate === null ? true : false}
                        variant="outlined"
                        multiline
                        rows={6}
                        color="success"
                        sx={{ width: "100%" }}
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>





              <Accordion sx={{
                marginTop: "50px",
                boxShadow: 'none', ':before': {
                  content: '""',
                  height: "0px"
                }
              }}>
                <AccordionSummary
                  expandIcon={<ArrowDownwardIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  style={{ padding: '0px', borderBottom: "1px solid #e7e7e7" }}
                >
                  <Box sx={{ display: "flex", color: "#000", }}>
                    <Typography variant="h5">ที่อยุ่ตามบัตรประชาชน</Typography>
                  </Box>

                </AccordionSummary>
                <AccordionDetails sx={{ marginTop: "20px" }}>
                  {/* <Divider
                    sx={{ width: "100%", marginBottom: "20px" }}
                  /> */}
                  <Grid container spacing={2}>
                    <GridField text="เลขที่">
                      <TextField
                        size="small"
                        name="czHouseNo"
                        value={values.czHouseNo}
                        onChange={handleChange}
                        label=""
                        variant="outlined"
                        color="success"
                        sx={{ width: "100%" }}
                      />
                    </GridField>
                    <Grid item lg={8} md={6} xs={12}>
                      <Grid container>
                        <Grid
                          item
                          md={3}
                          xs={12}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: { md: "flex-end", sm: "flex-start" },
                          }}
                        >
                          <Typography sx={{ marginRight: "15px" }}>
                            ที่อยู่
                          </Typography>
                        </Grid>
                        <Grid item md={9} xs={12}>
                          <TextField
                            size="small"
                            name="czAddress"
                            value={values.czAddress}
                            placeholder="รายละเอียดที่อยู่"
                            onChange={handleChange}
                            label=""
                            variant="outlined"
                            color="success"
                            sx={{ width: "100%" }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <GridField text="หมู่">
                      <TextField
                        name="czMoo"
                        value={values.czMoo}
                        size="small"
                        onChange={handleChange}
                        label=""
                        variant="outlined"
                        placeholder=""
                        color="success"
                        sx={{ width: "100%" }}
                      />
                    </GridField>
                    <GridField text="จังหวัด">
                      {/* <FormControl
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            justifyItems: "center",
                                        }}
                                        variant="outlined"
                                        color="success"
                                        size='small'
                                    >

                                        <Select
                                            displayEmpty
                                            inputProps={{ "aria-label": "Without label" }}
                                            name='czProvinceID'
                                            value={provinceid}
                                            placeholder="เลือกจังหวัด"
                                            onChange={e => {
                                                // if(values.checked) { setFieldValue('crProvinceID', e.target.value)}
                                                setFieldValue('czProvinceID', e.target.value)
                                                setProvinceid(e.target.value)
                                            }}
                                        >
                                            <MenuItem value="" disabled>  เลือกจังหวัด </MenuItem>

                                            {province?.map((x, i) => <MenuItem key={i} value={x?.mp_provinceID}>{x?.mp_provinceName}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl> */}
                      <Autocomplete
                        size="small"
                        fullWidth
                        value={provinceid}
                        onChange={(e, newValue) => {
                          setProvinceid(newValue.mp_provinceID);
                          setProvinceItem(newValue);
                          setFieldValue("czProvinceID", newValue.mp_provinceID);
                          setDistrictID({});
                        }}
                        options={province}
                        getOptionLabel={(option) => option.mp_provinceName}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="เลือกจังหวัด" />
                        )}
                      />
                    </GridField>
                    <GridField text="เขต/อำเภอ">
                      {/* <FormControl
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            justifyItems: "center",
                                        }}
                                        variant="outlined"
                                        color="success"
                                        size='small'
                                    >

                                        <Select
                                            displayEmpty
                                            inputProps={{ "aria-label": "Without label" }}

                                            value={districtid}
                                            onChange={e => {
                                                // if(values.checked) { setFieldValue('crDistrictID', e.target.value)}
                                                setFieldValue('czDistrictID', e.target.value)
                                                setDistrictID(e.target.value)
                                            }}
                                        >
                                            {district?.map((x, i) => <MenuItem key={i} value={x?.md_districtID}>{x?.md_districtName}</MenuItem>
                                            )}
                                        </Select>
                                        </FormControl> */}
                      <Autocomplete
                        size="small"
                        fullWidth
                        value={districtid}
                        onChange={(e, newValue) => {
                          setDistrictID(newValue);
                          setFieldValue("czDistrictID", newValue?.md_districtID);
                          setSubdistrictID("");
                        }}
                        options={district}
                        getOptionLabel={(option) =>
                          option.md_districtName ? option.md_districtName : ""
                        }
                        renderInput={(params) => (
                          <TextField {...params} placeholder="เลือกเขต/อำเภอ" />
                        )}
                      />
                    </GridField>
                    <GridField text="แขวง/ตำบล">
                      {/* <FormControl
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            justifyItems: "center",
                                        }}
                                        variant="outlined"
                                        color="success"
                                        size='small'
                                    >

                                        <Select
                                            displayEmpty
                                            inputProps={{ "aria-label": "Without label" }}
                                            value={subdistrictid}
                                            onChange={e => {
                                                // if(values.checked) { setFieldValue('crSubDistrictID', e.target.value)}
                                                setFieldValue('czSubDistrictID', e.target.value)
                                                setSubdistrictID(e.target.value)
                                            }}
                                        >
                                            {subdistrict?.map((x, i) => <MenuItem key={i} value={x?.msd_subdistrictID} onClick={setZipcode(x.msd_zipcode)}>{x?.msd_subdistrictName}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl> */}
                      <Autocomplete
                        size="small"
                        fullWidth
                        value={subdistrictid}
                        onChange={(e, newValue) => {
                          setSubdistrictID(newValue);
                          setFieldValue(
                            "czSubDistrictID",
                            newValue?.msd_subdistrictID
                          );
                          setZipcode(newValue.msd_zipcode);
                          // console.log(newValue)
                        }}
                        options={subdistrict}
                        getOptionLabel={(option) =>
                          option.msd_subdistrictName
                            ? option.msd_subdistrictName
                            : ""
                        }
                        renderInput={(params) => (
                          <TextField {...params} placeholder="เลือกแขวง/ตำบล" />
                        )}
                      />
                    </GridField>
                    <GridField text="รหัสไปรษณีย์">
                      <TextField
                        size="small"
                        label=""
                        value={zipcode}
                        variant="outlined"
                        color="success"
                        placeholder="รหัสไปรษณีย์"
                        sx={{ width: "100%" }}
                      />
                    </GridField>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              {/* <Divider
                    sx={{ width: "100%", marginTop: "20px", marginBottom: "20px" }}
                  /> */}
              <Accordion sx={{
                marginTop: "50px",
                boxShadow: 'none', ':before': {
                  content: '""',
                  height: "0px"
                }
              }}>
                <AccordionSummary
                  expandIcon={<ArrowDownwardIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  style={{ padding: '0px', borderBottom: "1px solid #e7e7e7" }}
                >
                  <Box sx={{ display: "flex", color: "#000", }}>
                    <Typography variant="h5">
                      ที่อยู่ปัจจุบัน
                      <FormControlLabel
                        label=""
                        size="small"
                        control={
                          <Checkbox
                            disabled={zipcode.length > 0 ? false : true}
                            name="checked"
                            color="success"
                            checked={values.checked}
                            onClick={(e) => e.stopPropagation()}
                            onChange={(e) => {
                              setFieldValue("checked", e.target.checked);
                              setFieldValue(
                                "crProvinceID",
                                e.target.checked
                                  ? values.czProvinceID
                                  : newprovinceid?.mp_provinceID
                              );
                              setFieldValue(
                                "crDistrictID",
                                e.target.checked
                                  ? values.czDistrictID
                                  : newdistrictid?.md_districtID
                              );
                              setFieldValue(
                                "crSubDistrictID",
                                e.target.checked
                                  ? values.czSubDistrictID
                                  : newsubdistrictid?.msd_subdistrictID
                              );
                              // console.log(e.target.checked ? values.czProvinceID : values.crProvinceID)
                              // console.log(e.target.checked ? values.czDistrictID : values.crDistrictID)
                              // console.log(e.target.checked ? values.czSubDistrictID : values.crSubDistrictID)
                            }}
                            sx={{ marginLeft: "10px" }}
                          />
                        }
                      />
                      ใช้ที่อยู่เดียวกับที่อยู่ตามบัตรประชาชน
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ marginTop: "20px" }}>

                  <Grid container spacing={2}>
                    <GridField text="เลขที่">
                      <TextField
                        name="crHouseNo"
                        size="small"
                        disabled={values.checked ? true : false}
                        value={
                          values.checked
                            ? (values.crHouseNo = values.czHouseNo)
                            : houseNo
                        }
                        onChange={(e) => {
                          setFieldValue("crHouseNo", e.target.value);
                          setHouseNo(e.target.value);
                        }}
                        label=""
                        variant="outlined"
                        color="success"
                        sx={{ width: "100%" }}
                      />
                    </GridField>
                    <Grid item lg={8} md={6} xs={12}>
                      <Grid container>
                        <Grid
                          item
                          md={3}
                          xs={12}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: { md: "flex-end", sm: "flex-start" },
                          }}
                        >
                          <Typography sx={{ marginRight: "15px" }}>
                            ที่อยู่
                          </Typography>
                        </Grid>
                        <Grid item md={9} xs={12}>
                          <TextField
                            size="small"
                            disabled={values.checked ? true : false}
                            name="crAddress"
                            value={
                              values.checked
                                ? (values.crAddress = values.czAddress)
                                : address
                            }
                            placeholder="รายละเอียดที่อยู่"
                            onChange={(e) => {
                              setFieldValue("crAddress", e.target.value);
                              setAddress(e.target.value);
                            }}
                            label=""
                            variant="outlined"
                            color="success"
                            sx={{ width: "100%" }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <GridField text="หมู่">
                      <TextField
                        name="crMoo"
                        disabled={values.checked ? true : false}
                        size="small"
                        value={values.checked ? (values.crMoo = values.czMoo) : moo}
                        onChange={(e) => {
                          setFieldValue("crMoo", e.target.value);
                          setMoo(e.target.value);
                        }}
                        label=""
                        variant="outlined"
                        color="success"
                        sx={{ width: "100%" }}
                      />
                    </GridField>
                    <GridField text="จังหวัด">
                      {/* 
                                    <FormControl
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            justifyItems: "center",
                                        }}
                                        variant="outlined"
                                        color="success"
                                        size='small'
                                    >

                                        <Select
                                            displayEmpty
                                            inputProps={{ "aria-label": "Without label" }}
                                            // value={newprovinceid}
                                            name='crProvinceID'
                                            value={values.checked ? values.crProvinceID = values.czProvinceID : values.crProvinceID}
                                            onChange={e => {
                                                setFieldValue('crProvinceID', e.target.value)
                                                setNewprovinceid(e.target.value)
                                            }
                                            }
                                        >
                                            {curprovince?.map((x, i) => <MenuItem key={i} value={x?.mp_provinceID}>{x?.mp_provinceName}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl> */}
                      {/* {console.log(provinceItem , newprovinceid)} */}
                      <Autocomplete
                        size="small"
                        fullWidth
                        disabled={values.checked ? true : false}
                        value={values.checked ? provinceItem : newprovinceid}
                        onChange={(e, newValue) => {
                          setNewprovinceid(newValue);
                          setFieldValue(
                            "crProvinceID",
                            values.checked
                              ? values.czProvinceID
                              : newValue.mp_provinceID
                          );
                          setNewdistrictID({});
                        }}
                        options={curprovince}
                        getOptionLabel={(option) =>
                          option.mp_provinceName ? option.mp_provinceName : ""
                        }
                        renderInput={(params) => (
                          <TextField {...params} placeholder="เลือกจังหวัด" />
                        )}
                      />
                    </GridField>
                    <GridField text="เขต/อำเภอ">
                      {/* <FormControl
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            justifyItems: "center",
                                        }}
                                        variant="outlined"
                                        color="success"
                                        size='small'
                                    >

                                        <Select
                                            displayEmpty
                                            inputProps={{ "aria-label": "Without label" }}
                                            name='crDistrictID'
                                            value={values.checked ? values.crDistrictID = values.czDistrictID : values.crDistrictID}
                                            onChange={e => {
                                                setFieldValue('crDistrictID', e.target.value)
                                                setNewdistrictID(e.target.value)
                                            }}
                                        >
                                            {values.checked ? district?.map((x, i) => <MenuItem key={i} value={x?.md_districtID}>{x?.md_districtName}</MenuItem>
                                            ) : curdistrict?.map((x, i) => <MenuItem key={i} value={x?.md_districtID}>{x?.md_districtName}</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl> */}
                      <Autocomplete
                        size="small"
                        fullWidth
                        value={values.checked ? districtid : newdistrictid}
                        disabled={values.checked ? true : false}
                        onChange={(e, newValue) => {
                          setNewdistrictID(newValue);
                          setFieldValue(
                            "crDistrictID",
                            values.checked
                              ? values.czDistrictID
                              : newValue?.md_districtID
                          );
                        }}
                        options={values.checked ? district : curdistrict}
                        getOptionLabel={(option) =>
                          option.md_districtName ? option.md_districtName : ""
                        }
                        renderInput={(params) => (
                          <TextField {...params} placeholder="เลือกเขต/อำเภอ" />
                        )}
                      />
                    </GridField>
                    <GridField text="แขวง/ตำบล">
                      {/* <FormControl
                                        sx={{
                                            width: "100%",
                                            display: "flex",
                                            justifyItems: "center",
                                        }}
                                        variant="outlined"
                                        color="success"
                                        size='small'
                                    >

                                        <Select
                                            name='crSubDistrictID'
                                            // displayEmpty
                                            // inputProps={{ "aria-label": "Without label" }}
                                            value={values.checked ? values.czSubDistrictID : values.crSubDistrictID}
                                            onChange={e => {
                                                setFieldValue('crSubDistrictID', e.target.value)
                                                setnewSubdistrictID(e.target.value)
                                            }}
                                        >{values.checked ? subdistrict?.map((x, i) => <MenuItem key={i} value={x?.msd_subdistrictID} onClick={setZipcode(x.msd_zipcode)}>{x?.msd_subdistrictName}</MenuItem>
                                        ) : cursubdistrict?.map((x, i) => <MenuItem key={i} value={x?.msd_subdistrictID} onClick={setczZipcode(x?.msd_zipcode)}>{x?.msd_subdistrictName}</MenuItem>
                                        )}
                                        </Select>
                                    </FormControl> */}
                      <Autocomplete
                        size="small"
                        fullWidth
                        value={values.checked ? subdistrictid : newsubdistrictid}
                        disabled={values.checked ? true : false}
                        onChange={(e, newValue) => {
                          setnewSubdistrictID(newValue);
                          setFieldValue(
                            "crSubDistrictID",
                            newValue?.msd_subdistrictID
                          );
                          setczZipcode(newValue?.msd_zipcode);
                        }}
                        options={values.checked ? subdistrict : cursubdistrict}
                        getOptionLabel={(option) =>
                          option.msd_subdistrictName
                            ? option.msd_subdistrictName
                            : ""
                        }
                        renderInput={(params) => (
                          <TextField {...params} placeholder="เลือกแขวง/ตำบล" />
                        )}
                      />
                    </GridField>
                    <GridField text="รหัสไปรษณีย์">
                      <TextField
                        label=""
                        size="small"
                        disabled={values.checked ? true : false}
                        value={values.checked ? zipcode : cazipcode}
                        variant="outlined"
                        color="success"
                        placeholder="รหัสไปรษณีย์"
                        sx={{ width: "100%" }}
                      />
                    </GridField>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              {/* <Divider
                sx={{ width: "100%", marginTop: "20px", marginBottom: "20px" }}
              /> */}
              <Box sx={{ display: "flex", color: "#000", marginTop: "50px" }}>
                <Typography variant="h5">ประวัติเข็มกิตติคุณ</Typography>
              </Box>
              <Divider
                sx={{ width: "100%", marginTop: "20px", marginBottom: "20px" }}
              />
              <Stack spacing={3}>
                <TableContainer>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead sx={{ backgroundColor: "#e7e7e7" }}>
                      <TableRow>
                        <TableCell
                          sx={{
                            color: "#000",
                            border: "none",
                            fontSize: "18px",
                            fontWeight: "500",
                          }}
                          align="left"
                        >
                          ปี-เข็มกิตติคุณ
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            border: "none",
                            fontSize: "18px",
                            fontWeight: "500",
                          }}
                          align="left"
                        >
                          ชั้น-เข็มกิตติคุณ
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            border: "none",
                            fontSize: "18px",
                            fontWeight: "500",
                          }}
                          align="left"
                        >
                          วันที่อนุมัติ
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody></TableBody>
                  </Table>
                </TableContainer>
              </Stack>
              <Box sx={{ display: "flex", color: "#000", marginTop: "50px" }}>
                <Typography variant="h5">ประกาศนียบัตร</Typography>
              </Box>
              <Divider
                sx={{ width: "100%", marginTop: "20px", marginBottom: "20px" }}
              />
              <Stack spacing={3}>
                <TableContainer>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead sx={{ backgroundColor: "#e7e7e7" }}>
                      <TableRow>
                        <TableCell
                          sx={{
                            color: "#000",
                            border: "none",
                            fontSize: "18px",
                            fontWeight: "500",
                          }}
                          align="left"
                        >
                          ปี-ประกาศนียบัตร
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#000",
                            border: "none",
                            fontSize: "18px",
                            fontWeight: "500",
                          }}
                          align="left"
                        >
                          วันที่อนุมัติ
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody></TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            </TabPanel>
          </Container>

          <DialogJob
            open={open}
            setOpen={setOpen}
            setFieldValue={setFieldValue}
            workexperinceDetail={values.workexperinceDetail}
          />
          <Box
            sx={{
              position: "sticky",
              bottom: "0px",
              width: "100%",
              marginTop: "50px",
              background: "#fff",
            }}
          >
            <Divider sx={{ marginBottom: "10px" }} />
            <Container maxWidth="xl" sx={{ height: "auto" }}>
              <Box
                sx={{
                  padding: "5px 20px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    width: "150px",
                    height: "fit-content",
                    fontSize: "18px",
                    color: "#0fb123",
                    border: "1px solid #0fb123",
                    ":hover": { border: "1px solid #028b13" },
                  }}
                  component={NavLink}
                  to="/"
                >
                  กลับ
                </Button>
                <Button
                  variant="contained"
                  type="sumbit"
                  sx={{
                    width: "150px",
                    height: "fit-content",
                    fontSize: "18px",
                    marginLeft: "20px",
                    backgroundColor: "#0fb123",
                    ":hover": { backgroundColor: "#028b13" },
                  }}
                >
                  บันทึก
                </Button>
              </Box>
            </Container>
          </Box>
        </form>
      )}
    </Formik>
  );
}
export function Editjob({ x, i, setValues, values, setFieldValue }) {
  // console.log(x);
  const [openD, setD] = useState(false);
  return (
    <>
      <TableRow key={i}>
        <TableCell
          sx={{ color: "#000", width: "10%", fontSize: "16px" }}
          align="left"
        >
          {i + 1}
        </TableCell>
        <TableCell
          sx={{ color: "#000", width: "15%", fontSize: "16px" }}
          align="left"
        >
          {moment(x?.startDate).add(543, 'year').format('DD-MM-yyyy')}
        </TableCell>
        <TableCell
          sx={{ color: "#000", width: "15%", fontSize: "16px" }}
          align="left"
        >
          {moment(x?.endDate).format("YYYY-MM-DD") === "Invalid date" ? null : moment(x?.endDate).format("YYYY-MM-DD")}
        </TableCell>
        <TableCell
          sx={{ color: "#000", width: "30%", fontSize: "16px" }}
          align="left"
        >
          {x?.positionName}
        </TableCell>
        <TableCell
          sx={{ color: "#000", width: "30%", fontSize: "16px" }}
          align="left"
        >
          {x?.positionDsc}
        </TableCell>
        <TableCell
          sx={{ color: "#000", width: "30%", fontSize: "16px" }}
          align="right"
        >
          <Box sx={{ display: "flex" }}>
            <Button
              variant="outlined"
              onClick={() => setD(true)}
              sx={{
                borderColor: "#1fc64c",
                "&:hover": { borderColor: "#4caf50", backgroundColor: "none" },
                color: "#1fc64c",
              }}
            >
              <EditIcon />
            </Button>
            <Button
              variant="outlined"
              onClick={() =>
                setValues({
                  workexperinceDetail: values.workexperinceDetail.filter(
                    (newE) => newE !== x
                  ),
                })
              }
              sx={{
                borderColor: "red",
                marginLeft: "8px",
                "&:hover": { borderColor: "red", backgroundColor: "none" },
                color: "red",
              }}
            >
              x
            </Button>
          </Box>
        </TableCell>
      </TableRow>
      <DialogJobedit setOpen={setD} open={openD} workexperinceDetail={x} setFieldValue={setFieldValue} values={values} i={i} />
    </>
  );
}

export const FileUploadC = ({ setUploadedImg, fileRef, setFieldValue }) => {
  // const fileRef = useRef()

  const handleFileChange = async (event) => {
    const fileObj = event.target.files && event.target.files[0];
    // console.log('fileObj is', fileObj);

    let formData = new FormData();
    formData.append("file", fileObj);
    // event.target.value = null;

    const res = await api.post(`api/v1.0/filemanages/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        // 'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySUQiOiI0NjIxZDJmZS04ZjkyLTQyNDctODcwZi1jYThiNTNkOGJhZTAiLCJyb2xlIjp7InJvbGVJRCI6IjEiLCJyb2xlTmFtZSI6IkRldmVsb3BlciIsImxldmVsIjoxLCJzdGF0dXMiOnRydWUsImNyZWF0ZUlEIjpudWxsLCJjcmVhdGVEYXRlIjoiMjAyMi0wNy0yMFQwNzoxMTozMi41MTlaIiwibW9kaWZpZWRJRCI6bnVsbCwibW9kaWZpZWREYXRlIjoiMjAyMi0wNy0yMFQwNzoxMTozMi41MTlaIn0sImlhdCI6MTY2Mzc0MzczMCwiZXhwIjoxNjY0NjQzNzMwfQ.GkENpUAqQny_TthYu9ZwzNTaGlZmfXq7Ytt5rtbK3R4`
      },
    });
    const data = await res.data;
    if (data.status === true) {
      setUploadedImg(data.result);
      setFieldValue("fileID", data.result.fileID);
    }
  };
  return (
    <Grid item lg={2} md={9} xs={12}>
      <input
        type="file"
        ref={fileRef}
        accept="image/*"
        hidden
        onChange={handleFileChange}
      />
      <Button
        variant="contained"
        onClick={() => fileRef.current.click()}
        sx={{
          width: "150px",
          height: "fit-content",
          fontSize: "18px",
          marginLeft: "20px",
          backgroundColor: "#0fb123",
          ":hover": { backgroundColor: "#028b13" },
        }}
      >
        อัพโหลดไฟล์
      </Button>
    </Grid>
  );
};
