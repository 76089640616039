import { Box, Button, Container, FormControl, Stack, Tab, Table, TableBody, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import TitleHead from "../../components/TitleHead";
import SearchIcon from "@mui/icons-material/Search";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import moment from 'moment/moment';
import api from "../../api/api";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#94949473",
        color: theme.palette.common,
        fontWeight: "500",
        fontSize: 14,
        width: 100,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 10,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        /*  backgroundColor: '#F2F5F8', */
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));
export default function Logfile() {
    const [startDate, setStartDate] = useState();
    const [minDate, setMinDate] = useState();
    const [endDate, setEndDate] = useState();
    const [logList, setLog] = useState([]);










    useEffect(() => {
        api.get(`api/v1.0/setting/log/file?=${startDate}&enddate=${endDate}`).then((res) => {

            setLog(res.data.result);
        })
    }, [])

    const handleClick = () => {

        api.get(`api/v1.0/setting/log/file?=${startDate}&enddate=${endDate}`).then((res) => {
            // console.log(startDate);
            setLog(res.data.result);
            // console.log(res.data.result);
        })
    }
    const handleReset = () => {
        setStartDate(null)
        setEndDate(null)
        setMinDate('')
    }
    return (
        <Container
            maxWidth="xl"
            sx={{ height: "auto", marginTop: "40px", marginBottom: "40px" }}
        >
            <TitleHead
                title="Logfile"
                subTitle="ประวัติการปรับปรุงแก้ไขการใช้งานระบบ"
            ></TitleHead>
            <Box
                sx={{
                    display: "flex",
                    color: "#000",
                    marginTop: "20px",
                    marginLeft: "50px",
                    justifyContent: "right",
                    paddingRight: "50px",
                }}
            >
                <FormControl sx={{ marginRight: 0, marginTop: '15px' }} size="small">
                    <DesktopDatePicker
                        label=""
                        inputFormat="DD/MM/YYYY"
                        value={startDate}
                        name="lecDate"
                        onChange={(newValue) => {
                            setStartDate(moment(newValue).format("YYYY-MM-DD"));
                            setMinDate(moment(newValue).format("YYYY-MM-DD"));
                            setEndDate(null);
                        }}
                        renderInput={(params) => <TextField size='small' color="success" sx={{ width: '100%' }}
                            InputProps={params?.InputProps}
                            inputRef={params?.inputRef}
                            placeholder={params?.inputProps?.placeholder}
                            value={params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}`}
                            onChange={params?.inputProps?.onChange}
                            type={params?.inputProps?.type}
                        />}
                    />
                </FormControl>
                <FormControl sx={{ marginRight: 0, marginTop: '15px' }} size="small">
                    <Typography sx={{ m: 1 }}>~</Typography>
                </FormControl>
                <FormControl sx={{ marginRight: 0, marginTop: '15px' }} size="small">
                    <DesktopDatePicker
                        label=""
                        inputFormat="DD/MM/YYYY"

                        value={endDate}
                        name="lecDate"
                        disabled={minDate === null}
                        minDate={minDate}
                        onChange={(newValue) => {
                            setEndDate(moment(newValue).format("YYYY-MM-DD"));
                        }}
                        renderInput={(params) => <TextField size='small' color="success" sx={{ width: '100%', marginLeft: "5px" }}
                            InputProps={params?.InputProps}
                            inputRef={params?.inputRef}
                            placeholder={params?.inputProps?.placeholder}
                            value={params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}`}
                            onChange={params?.inputProps?.onChange}
                            type={params?.inputProps?.type}
                        />}
                    />
                </FormControl>
                <Box sx={{ display: "flex", m: 1, alignItems: "center" }}>

                    <Button
                        variant="contained"
                        onClick={handleClick}
                        sx={{
                            padding: "15px",
                            marginLeft: "20px",
                            backgroundColor: "#0fb123",
                            ":hover": { backgroundColor: "#028b13" },
                        }}
                    >
                        <SearchIcon />
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleReset}
                        sx={{
                            padding: "15px",
                            marginLeft: "20px",
                            backgroundColor: "#DCDCDC",
                            color: '#000',
                            ":hover": { backgroundColor: "#BEBEBE" },
                        }}
                    >รีเซ็ต</Button>
                </Box>
            </Box>
            <Container maxWidth="xl">
                <Stack spacing={3}>
                    <TableContainer sx={{ marginTop: "20px", marginBottom: "20px" }}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell
                                        align="left"
                                        sx={{ fontSize: "18px", fontWeight: "400" }}
                                    >
                                        #
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="left"
                                        sx={{ fontSize: "18px", fontWeight: "400" }}
                                    >
                                        วันที่
                                    </StyledTableCell>

                                    <StyledTableCell
                                        align="left"
                                        sx={{ fontSize: "18px", fontWeight: "400" }}
                                    >
                                        ชื่อ
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="left"
                                        sx={{ fontSize: "18px", fontWeight: "400" }}
                                    >
                                        นามสกุล
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="left"
                                        sx={{ fontSize: "18px", fontWeight: "400" }}
                                    >
                                        webBrowser
                                    </StyledTableCell>

                                    <StyledTableCell
                                        align="left"
                                        sx={{ fontSize: "18px", fontWeight: "400" }}
                                    >
                                        os
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="left"
                                        sx={{ fontSize: "18px", fontWeight: "400" }}
                                    >
                                        method
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="left"
                                        sx={{ fontSize: "18px", fontWeight: "400" }}
                                    >
                                        params
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="left"
                                        sx={{ fontSize: "18px", fontWeight: "400" }}
                                    >
                                        query
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="center"
                                        sx={{ fontSize: "18px", fontWeight: "400" }}
                                    >
                                        body
                                    </StyledTableCell>
                                    <StyledTableCell
                                        align="left"
                                        sx={{ fontSize: "18px", fontWeight: "400" }}
                                    >
                                        route
                                    </StyledTableCell>


                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {logList.map((e, i) => (
                                    <TableRow key={i}>
                                        <StyledTableCell
                                            align="left"
                                            sx={{ fontSize: "12px", fontWeight: "400" }}
                                        >
                                            {i + 1}
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align="left"
                                            sx={{ fontSize: "12px", fontWeight: "400" }}
                                        >
                                            {moment(e?.createDate).add(543,'year').format('DD/MM/YYYY')}
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align="left"
                                            sx={{ fontSize: "12px", fontWeight: "400" }}
                                        >
                                            {e?.user?.firstname}
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align="left"
                                            sx={{ fontSize: "12px", fontWeight: "400" }}
                                        >
                                            {e?.user?.lastname}
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align="left"
                                            sx={{ fontSize: "12px", fontWeight: "400" }}
                                        >
                                            {e?.webBrowser}
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align="left"
                                            sx={{ fontSize: "12px", fontWeight: "400" }}
                                        >
                                            {e?.os}
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align="left"
                                            sx={{ fontSize: "12px", fontWeight: "400" }}
                                        >
                                            {e?.method}
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align="left"
                                            sx={{ fontSize: "12px", fontWeight: "400" }}
                                        >
                                            {e?.params}
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align="left"
                                            sx={{ fontSize: "12px", fontWeight: "400" }}
                                        >
                                            {e?.query}
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align="left"
                                            sx={{ fontSize: "12px", fontWeight: "400" }}
                                        >
                                            
                                           <TextField rows={3} sx={{ width: '200px' ,fontSize:'12px'}} value={e?.body} multiline>
                                          
                                            </TextField>
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align="left"
                                            sx={{ fontSize: "12px", fontWeight: "400", }}
                                        >
                                            {e?.route}
                                        </StyledTableCell>


                                    </TableRow>
                                ))}

                            </TableBody>
                        </Table>
                    </TableContainer>

                </Stack>
            </Container>
        </Container>

    )
}
