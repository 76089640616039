import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Swal from "sweetalert2";
import kpi from "../../assets/image/KPI-LOGO-PNG-Size-S.png";

// import axios from "axios";
// import configData from '../../config.json'
import api from "../../api/api";
import httpClientInstants from "../../libs/utils/HttpClient";
import authService from "../service/authService";

// const api_url = configData.REACT_APP_BASE_API
// let api_url = window.REACT_APP_BASE_API || process.env.REACT_APP_BASE_API
const initialState = {
  user: null,
  loading: false,
  error: "",
  refreshToken: "",
  accessToken: "",
};

export const loginAsync = createAsyncThunk(
  "login",
  async ({ username, password }, store) => {
    try {
      const user = await api.post(`api/v1.0/auth/login`, {
        username,
        password,
      });
      if (user.data.code === 1000) {
        // console.log('login ss')
        Swal.fire(
          {
            title: ``,
            width: 900,
            padding: '1em 2em',
            html:'<p>สถาบันตระหนักถึงความสำคัญของข้อมูลส่วนบุคคล และได้ดำเนินตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 ทั้งนี้ ฐานข้อมูลนี้พัฒนาขึ้นโดยมีวัตถุประสงค์เพื่อประกอบการเสนอชื่อและพิจารณาผู้มีคุณสมบัติที่จะได้รับเข็มกิตติคุณสถาบันพระปกเกล้า/การพิจารณาประสาทประกาศนียบัตรชั้นสูงกิตติมศักดิ์สถาบันพระปกเกล้า จึงขอให้การใช้งานเป็นไปตามวัตถุประสงค์ และไม่อนุญาตให้นำข้อมูลไปใช้เพื่อการอื่นได้</p>',
            imageUrl: kpi,
            imageWidth: 90,
            imageHeight: 130,
            imageAlt: 'Custom image',
            // icon: "success",
            confirmButtonColor: "#0f962d",
            confirmButtonText: "ยินยอม",
            allowOutsideClick: false,
            showCancelButton: true,
            // confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText:'ปฏิเสธ'
            // confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
               localStorage.setItem("accessToken", user?.data?.result?.accessToken);
        localStorage.setItem("refreshToken", user?.data?.result?.refreshToken);

        httpClientInstants.setToken(user?.data?.result?.accessToken);
        window.location.href = "/";
            }
          })

      
      } else {
        console.log("login failed");
        Swal.fire(
          {
            title: `${user.data.message}`,
            // text: "บันทึกข้อมูลสำเร็จ",
            // icon: "success",
            confirmButtonColor: "#0f962d",
            confirmButtonText: "ตกลง",
          }
          // `${user.data.message}`,
        );
      }
      return user.data;
    } catch (error) {
      throw error;
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state, action) => {
      state.user = null;
      state.loading = false;
      state.error = "";
    },
    refreshTokenRequest(state, action) {
      state.isLoading = true;
      state.error = initialState.error;
    },
    refreshTokenSuccess(state, action) {
      state.loading = false;
      state.accessToken = action.payload.result.accessToken;
      state.refreshToken = action.payload.result.refreshToken;
    },
    refreshTokenFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
  extraReducers: {
    [loginAsync.pending]: (state, action) => {
      state.loading = true;
      state.error = "";
    },
    [loginAsync.fulfilled]: (state, action) => {
      state.user = action.payload;
      state.accessToken = action?.payload?.result?.accessToken;
      state.refreshToken = action?.payload?.result?.refreshToken;
      state.loading = false;
      state.error = "";
    },
    [loginAsync.rejected]: (state, action) => {
      state.user = null;
      state.loading = false;
      state.error = action.error.message;
    },
  },
});

export const { refreshTokenRequest, refreshTokenSuccess, refreshTokenFailure } =
  authSlice.actions;

export function refreshToken(isDisplayError = true) {
  return async (dispatch, getState) => {
    dispatch(refreshTokenRequest());
    try {
      const tokenRefresh = getState().auth.refreshToken;
      const response = await authService.refreshToken(tokenRefresh);
      const results = response?.result || {};

      httpClientInstants.setToken(results.accessToken);

      dispatch(refreshTokenSuccess(results));
    } catch (error) {
      dispatch(refreshTokenFailure(error.message));
      if (isDisplayError) {
        alert("Session expired!!", "Please try to login again.");
      }
      dispatch(logout());
      throw error;
    }
  };
}

export const { logout } = authSlice.actions;
export default authSlice.reducer;
