import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Container,
  FormControl,
  Stack,
  Tab,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { NavLink, useLocation, useParams } from "react-router-dom";
import TitleHead from "../../components/TitleHead";
import SearchIcon from "@mui/icons-material/Search";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import moment from "moment/moment";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import api from "../../api/api";
import mime from "mime";
import { saveAs } from "file-saver";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#94949473",
    color: theme.palette.common,
    fontWeight: "500",
    fontSize: 14,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 8,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    /*  backgroundColor: '#F2F5F8', */
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export default function Report13() {
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [minDate, setMinDate] = useState("");

  const [endDate, setEndDate] = useState("");
  const [listreport, setReport] = useState([]);
  const [detere, setDatere] = useState([]);
  const [value, setValue] = useState("");
  const handleClick = () => {
    api
      .get(
        `api/v1.0/report/Report13/list?startyear=${minDate}&endyear=${endDate}`
      )
      .then((res) => {
        // console.log(res);
        setReport(res?.data?.result);
      });
  };

  // useEffect(() => {
  //   api.get(`api/v1.0/report/reportdate/list?cerType=1`).then((res) => {
  //     setDatere(res.data.result);
  //     setValue(res.data.result[0]);
  //   });
  // }, []);
  useEffect(() => {
    api
      .get(
        `api/v1.0/report/Report13/list?startyear=${minDate}&endyear=${endDate}`
      )
      .then((res) => {
        // console.log(res?.data?.result);
        setReport(res?.data?.result);
      });
  }, [startDate, setReport]);
  return (
    <Container
      maxWidth="xl"
      sx={{ height: "auto", marginTop: "40px", marginBottom: "40px" }}
    >
      <TitleHead title="รายงาน" subTitle="รายงานคณะกรรมการแต่ละชุด">
        <Box sx={{ display: "flex", justifyContent: "right" }}>
          <Button
            color="success"
            variant="outlined"
            sx={{
              color: "#4caf50",
              padding: "8px",
              paddingLeft: "16px",
              paddingRight: "16px",
              marginTop: "23px",
              marginRight: "23px",
            }}
            onClick={async () => {
              await api
                .get(
                  `api/v1.0/report/Report13/download?startyear=${minDate}&endyear=${endDate}
                  &format=txt`,
                  {
                    responseType: "blob",
                  }
                )
                .then((blobFile) => {
                  const blobFileRes = new File(
                    [blobFile?.data],
                    "Template.txt",
                    {
                      type: mime.getType(".txt"),
                    }
                  );
                  // saveAs(URL.createObjectURL(blobFileRes, "Template.txt"))
                  saveAs(blobFileRes, `รายงานคณะกรรมการแต่ละชุด.txt`);

                  // window.open(

                  //     ,
                  //     "_blank"
                  // );
                });
            }}
          >
            <SaveAltIcon sx={{ marginRight: "10px" }} /> Text File
          </Button>
          <Button
            color="success"
            onClick={async () => {
              await api
                .get(
                  `api/v1.0/report/Report13/download?startyear=${minDate}&endyear=${endDate}
                  &format=xlsx`,
                  {
                    responseType: "blob",
                  }
                )
                .then((blobFile) => {
                  const blobFileRes = new File(
                    [blobFile?.data],
                    "Template.xlsx",
                    {
                      type: mime.getType(".xlsx"),
                    }
                  );

                  // window.open(
                  //     URL.createObjectURL(blobFileRes, "Template.xlsx")
                  //     ,
                  //     "_blank"
                  // );
                  saveAs(blobFileRes, `รายงานคณะกรรมการแต่ละชุด.xlsx`);
                });
            }}
            variant="outlined"
            sx={{
              color: "#4caf50",
              padding: "8px",
              paddingLeft: "16px",
              paddingRight: "16px",
              marginTop: "23px",
            }}
          >
            <SaveAltIcon sx={{ marginRight: "10px" }} /> Excel
          </Button>
        </Box>
      </TitleHead>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ marginTop: "15px" }}>
          <Typography sx={{ fontSize: "14px" }}>
            รายงาน&nbsp;/&nbsp;
            <span style={{ color: "#35cc62", fontSize: "14px" }}>
              {" "}
              รายงานคณะกรรมการแต่ละชุด
            </span>
          </Typography>
        </Box>
        <Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ marginRight: "15px", width: "20%" }}>
              <Typography>ปีที่เริ่ม</Typography>
            </Box>
            <TextField
              sx={{ width: "100%" }}
              size="small"
              type="number"
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 4);
              }}
              onChange={(v) => {
                setMinDate(v.target.value);
              }}
            ></TextField>
            <Box sx={{ marginLeft: "10px", width: "30%" }}>
              <Typography>ปีที่สิ้นสุด</Typography>
            </Box>
            <TextField
              sx={{ width: "100%" }}
              size="small"
              type="number"
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 4);
              }}
              onChange={(v) => {
                setEndDate(v.target.value);
              }}
            ></TextField>
            {/* <Autocomplete
                            size='small'
                            fullWidth

                            value={value}
                            onChange={(event, newValue) => {
                                setValue(newValue)
                            }}
                            options={detere}
                            getOptionLabel={option => moment(option?.calDate).add(543, 'year').format('DD-MM-yyyy') ? moment(option?.calDate).add(543, 'year').format('DD-MM-yyyy') : ''}
                            renderInput={(params) => <TextField {...params} placeholder='เลิอกวันที่' color="success" sx={{ marginRight: '30px', width: '180px' }} />}
                        /> */}
            {/* <FormControl sx={{ marginRight: 0, }} size="small">
                        <DesktopDatePicker
                                label=""
                                inputFormat="DD/MM/YYYY"
                                value={startDate}
                                maxDate={endDate ? new Date(endDate) : ''}
                                onChange={(newValue) => { setStartDate(moment(newValue).format('YYYY-MM-DD')) }}
                                renderInput={(params) => <TextField size='small' color="success" sx={{ width: '100%' }}
                                    InputProps={params?.InputProps}
                                    inputRef={params?.inputRef}
                                    // placeholder={params?.inputProps?.placeholder}
                                    // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                                    // onChange={params?.inputProps?.onChange}
                                    // type={params?.inputProps?.type}
                                    inputProps={
                                        {
                                          placeholder: params?.inputProps?.placeholder,
                                          readOnly: true,
                                          value: params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null,
                                          onChange: params?.inputProps?.onChange,
                                          type: params?.inputProps?.type
                                        }
                    
                                      }
                                />}
                            />
                        </FormControl>

                        <FormControl sx={{ marginRight: 0, }} size="small">
                            <Typography sx={{ m: 1 }}>~</Typography>
                        </FormControl>
                        <FormControl sx={{ marginRight: 0, }} size="small">
                        <DesktopDatePicker
                                label=""
                                inputFormat="DD/MM/YYYY"
                                value={endDate}
                                minDate={new Date(startDate)}
                                onChange={(newValue) => { setEndDate(moment(newValue).format('YYYY-MM-DD')) }}
                                renderInput={(params) => <TextField size='small' color="success" sx={{ width: '100%' }}
                                    InputProps={params?.InputProps}
                                    inputRef={params?.inputRef}
                                    // placeholder={params?.inputProps?.placeholder}
                                    // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                                    // onChange={params?.inputProps?.onChange}
                                    // type={params?.inputProps?.type}
                                    inputProps={
                                        {
                                          placeholder: params?.inputProps?.placeholder,
                                          readOnly: true,
                                          value: params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null,
                                          onChange: params?.inputProps?.onChange,
                                          type: params?.inputProps?.type
                                        }
                    
                                      }
                                />}
                            />
                        </FormControl> */}

            <Button
              onClick={handleClick}
              size="small"
              sx={{
                padding: "8px",
                marginLeft: "16px",
                color: "#fafafa",
                backgroundColor: "#0fb123",
                ":hover": { backgroundColor: "#028b13" },
              }}
            >
              <SearchIcon />
            </Button>
          </Box>
        </Box>
      </Box>

      <Stack spacing={3}>
        <TableContainer sx={{ marginTop: "20px", marginBottom: "20px" }}>
          {/* {console.log(listreport)} */}

          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell
                  align="center"
                  sx={{ fontSize: "18px", fontWeight: "400" }}
                >
                  คณะกรรมการ
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ fontSize: "18px", fontWeight: "400" }}
                >
                  ชือ - สกุล
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ fontSize: "18px", fontWeight: "400" }}
                >
                  ตำแหน่งในคณะกรรมการ
                </StyledTableCell>

                <StyledTableCell
                  align="center"
                  sx={{ fontSize: "18px", fontWeight: "400" }}
                >
                  ช่วงเวลาดำรงตำแหน่ง
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ fontSize: "18px", fontWeight: "400" }}
                >
                  สถานะ
                </StyledTableCell>
              </TableRow>
            </TableHead>
            {listreport.map((e, i) => (
              <TableRow key={i}>
                <StyledTableCell
                  align="center"
                  sx={{ fontSize: "18px", fontWeight: "400" }}
                >
                  {e?.boardDep?.boardDepName}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ fontSize: "18px", fontWeight: "400" }}
                >
                  {e?.personal?.firstname} {e?.personal?.lastname}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ fontSize: "18px", fontWeight: "400" }}
                >
                  {e?.newPositionDes}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  width="300"
                  sx={{ fontSize: "18px", fontWeight: "400" }}
                >
                  {moment(e?.startAt).add(543, "year").format("DD-MM-YYYY")} -{" "}
                  {moment(e?.endAt).add(543, "year").format("DD-MM-YYYY")}
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  sx={{ fontSize: "18px", fontWeight: "400" }}
                >
                  {e?.workStatus}
                </StyledTableCell>

                {/* <StyledTableCell
                                    align="center"
                                    sx={{ fontSize: "18px", fontWeight: "400" }}
                                >
                                    {e?.certificateTypeName}
                                </StyledTableCell> */}
              </TableRow>
            ))}
          </Table>
        </TableContainer>
      </Stack>
    </Container>
  );
}
