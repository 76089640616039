import {
  Box,
  Button,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Container,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Menu,
  MenuItem,
  Grid,
  Divider,
  TablePagination,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { NavLink } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import TitleHead from "../../components/TitleHead";
import ApiContext from "../../context/ApiProvider";
import Swal from "sweetalert2";
import { IMask, useIMask } from "react-imask";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectCanActivate } from "../../store/modules/selector";

export default function Committee() {
  const [boardDepartmentList, setBoardDepartmentList] = useState([]);

  const { getBoardDepartmentList, addBoardDepartment } = useContext(ApiContext);
  const [open, setOpen] = React.useState(false);
  const [titleBoard, setTitleBoard] = useState("");
  const [dateRange, setDateRange] = useState("");
  const [total, setTotal] = useState("");
  const checkPermission = useSelector(selectCanActivate);
  // const [item, setItem] = useState()
  const [searchDBoard, setSearchDBoard] = useState("");
  const [textSearchDBoard, setTextSearchDBoard] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const handleClickSearchDBoard = () => {
    setTextSearchDBoard(searchDBoard);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openM = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickOpen = (e, item) => {
    setOpen(true);
    // setTitleBoard(item?.boardDepName)
    // setItem(item)
    // console.log(item)
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    // console.log(event);
    setPage(0);
  };

  const handleClose = () => {
    setOpen(false);
    setDateRange("");
    setTotal("");
    setTitleBoard("");
    setAnchorEl(null);
  };
  const handleSubmit = () => {
    const data = {
      boardDepID: "",
      boardDepName: titleBoard,
      period: dateRange,
      total: total,
      status: true,
    };
    setOpen(false);
    setAnchorEl(null);

    Swal.fire(
      "สร้างเเล้ว"
      // 'success'
    );
    // console.log(data)
    addBoardDepartment(data, setOpen);
    setTimeout(() => {
      getBoardDepartmentList(setBoardDepartmentList, textSearchDBoard);
    }, 1000);
  };
  useEffect(() => {
    getBoardDepartmentList(setBoardDepartmentList, textSearchDBoard);
  }, [getBoardDepartmentList, setBoardDepartmentList, textSearchDBoard]);
  return (
    <Container
      maxWidth="xl"
      sx={{ height: "auto", marginTop: "40px", marginBottom: "40px" }}
    >
      <TitleHead title="ชุดคณะกรรมการ" subTitle="ข้อมูลทะเบียนคณะกรรมการ">
        {/* <Button variant='contained' onClick={handleClickOpen} sx={{ height: 'fit-content', fontSize: '18px', backgroundColor: '#0fb123', ':hover': { backgroundColor: '#028b13' } }}>สร้างใหม่</Button> */}
        {checkPermission.find((x) => x.menuNo === "M003" && x.isAdd) && (
          <Button
            variant="contained"
            // state={{ add: true }}
            // onClick={handleClickOpen}
            component={NavLink}
            to="/committee/addboard"
            sx={{
              backgroundColor: "#1fc64c",
              "&:hover": { background: "#4caf50" },
              color: "#FFFF",
              padding: "7px 55px",
              marginTop: "23px",
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
          >
            สร้างใหม่
          </Button>
        )}
        <Dialog
          maxWidth="md"
          fullWidth={true}
          open={open}
          onClose={handleClose}
        >
          <DialogTitle sx={{ fontSize: "2rem" }}>
            สร้างชุดคณะกรรมการ
          </DialogTitle>
          <Divider sx={{ marginBottom: "20px" }} />
          <DialogContent sx={{ padding: "10px 60px" }}>
            {/* <DialogContentText>
              To subscribe to this website, please enter your email address here. We
              will send updates occasionally.
            </DialogContentText> */}
            {/* <TextField
              autoFocus
              margin="dense"
              id="name"
              label="ชื่อชุดคณะกรรมการ"
              type="text"
              fullWidth
              variant="standard"
              color="success"
              value={titleBoard}
              onChange={e => setTitleBoard(e.target.value)}
              sx={{ '.MuiInputBase-root, .MuiInputLabel-root': { fontSize: '1.5rem' } }}
            /> */}
            <Grid container spacing={2}>
              <Grid item lg={3}>
                <Typography sx={{ marginRight: "40px" }}>สร้าง</Typography>
              </Grid>
              <Grid item lg={9}>
                <TextField
                  size="small"
                  fullWidth
                  required
                  variant="outlined"
                  placeholder="ชื่อชุดคณะกรรมการ"
                  value={titleBoard}
                  onChange={(e) => setTitleBoard(e.target.value)}
                />
              </Grid>
              <Grid item lg={3}>
                <Typography sx={{ marginRight: "40px" }}>
                  ช่วงเวลาการประชุม
                </Typography>
              </Grid>
              <Grid item lg={9}>
                <TextField
                  size="small"
                  fullWidth
                  required
                  variant="outlined"
                  value={dateRange}
                  onChange={(e) => setDateRange(e.target.value)}
                />
                {/* <input
                  type="text"
                  ref={(inputRef) => {
                    if (inputRef) {
                      ref.current = inputRef;
                    }
                  }}
                /> */}
              </Grid>
              <Grid item lg={3}>
                <Typography sx={{ marginRight: "40px" }}>
                  จำนวนครั้งการประชุม
                </Typography>
              </Grid>
              <Grid item lg={7}>
                <TextField
                  size="small"
                  fullWidth
                  required
                  type="text"
                  variant="outlined"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  value={total}
                  onChange={(e) => setTotal(e.target.value)}
                />
              </Grid>
              <Grid item lg={2}>
                <Typography sx={{ marginRight: "40px" }}>ครั้ง</Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              variant="outlined"
              sx={{
                width: "150px",
                height: "fit-content",
                fontSize: "18px",
                color: "#0fb123",
                border: "1px solid #0fb123",
                ":hover": { border: "1px solid #028b13" },
              }}
            >
              ยกเลิก
            </Button>
            {titleBoard !== "" && dateRange !== "" && total !== "" ? (
              <Button
                onClick={handleSubmit}
                variant="contained"
                sx={{
                  width: "150px",
                  height: "fit-content",
                  fontSize: "18px",
                  marginLeft: "20px",
                  backgroundColor: "#0fb123",
                  ":hover": { backgroundColor: "#028b13" },
                }}
              >
                สร้าง
              </Button>
            ) : (
              ""
            )}
          </DialogActions>
        </Dialog>
      </TitleHead>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "20px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            id="outlined-basic"
            label="กรอกข้อมูลค้นหา"
            size="small"
            value={searchDBoard}
            onChange={(e) => setSearchDBoard(e.target.value)}
            variant="outlined"
            color="success"
          />
          <Button
            onClick={handleClickSearchDBoard}
            sx={{
              color: "#fff",
              padding: "7px",
              marginLeft: "20px",
              paddingLeft: "18px",
              paddingRight: "18px",
              backgroundColor: "#0fb123",
              ":hover": { backgroundColor: "#028b13" },
            }}
          >
            <SearchIcon />
          </Button>
        </Box>
      </Box>
      <TableContainer>
        <Table
          sx={{ minWidth: 650, borderCollapse: "inherit", borderSpacing: "0" }}
        >
          <TableHead sx={{ backgroundColor: "#e7e7e7" }}>
            <TableRow>
              <TableCell
                sx={{
                  color: "#000",
                  border: "none",
                  fontSize: "14px",
                  fontWeight: "700",
                }}
                align="left"
              >
                #
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  border: "none",
                  fontSize: "14px",
                  fontWeight: "700",
                }}
                align="center"
              >
                สิทธิ์การได้รับ
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  border: "none",
                  fontSize: "14px",
                  fontWeight: "700",
                }}
                align="left"
              >
                ชุดคณะกรรมการ
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  border: "none",
                  fontSize: "14px",
                  fontWeight: "700",
                }}
                align="center"
              >
                ผู้นำเสนอ
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  border: "none",
                  fontSize: "14px",
                  fontWeight: "700",
                }}
                align="center"
              >
                จำนวนครั้งการประชุม
              </TableCell>
              <TableCell
                sx={{ color: "#000", border: "none" }}
                align="center"
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {boardDepartmentList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((board, i) => (
                <>
                  <TableRowC
                    key={i}
                    i={i}
                    board={board}
                    setBoardDepartmentList={setBoardDepartmentList}
                    textSearchDBoard={textSearchDBoard}
                  />
                </>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={boardDepartmentList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Container>
  );
}

export function TableRowC({
  board,
  i,
  setBoardDepartmentList,
  textSearchDBoard,
}) {
  const { getBoardDepartmentList, addBoardDepartment } = useContext(ApiContext);
  const [open, setOpen] = React.useState(false);
  const [titleBoard, setTitleBoard] = useState("");
  const [dateRange, setDateRange] = useState("");
  const [total, setTotal] = useState(0);
  const [item, setItem] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openM = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickOpen = (e, item) => {
    setOpen(true);
    setTitleBoard(item?.boardDepName);
    setDateRange(item?.period);
    setTotal(item?.total);
    setItem(item);
  };
  const checkPermission = useSelector(selectCanActivate);
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const handleSubmit = () => {
    setAnchorEl(null);

    const data = {
      boardDepID: item?.boardDepID,
      boardDepName: titleBoard,
      period: dateRange,
      total: total,
      status: true,
    };

    addBoardDepartment(data, setOpen);

    setOpen(false);

    Swal.fire(
      "เเก้ไขเเล้ว"
      // 'success'
    );
    setTimeout(() => {
      getBoardDepartmentList(setBoardDepartmentList, textSearchDBoard);
    }, 1000);
  };
  const handleDelet = (e, item) => {
    setAnchorEl(null);

    const data = {
      boardDepID: item?.boardDepID,
      boardDepName: item?.boardDepName,
      period: item?.period,
      total: item?.total,
      status: false,
    };

    Swal.fire({
      title: "ต้องการลบหรือไม่ ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "ลบ",
      cancelButtonText: "ยกเลิก",
    }).then((result) => {
      if (result.isConfirmed) {
        addBoardDepartment(data, setOpen);
        Swal.fire(
          "ลบเเล้ว"
          // 'success'
        );
        setTimeout(() => {
          getBoardDepartmentList(setBoardDepartmentList, textSearchDBoard);
        }, 1000);
      }
    });
  };
  return (
    <>
      <Dialog maxWidth="md" fullWidth={true} open={open} onClose={handleClose}>
        <DialogTitle sx={{ fontSize: "2rem" }}>เเก้ไขชุดคณะกรรมการ</DialogTitle>
        <Divider sx={{ marginBottom: "20px" }} />
        <DialogContent sx={{ padding: "10px 60px" }}>
          {/* <DialogContentText>
              To subscribe to this website, please enter your email address here. We
              will send updates occasionally.
            </DialogContentText> */}
          <Grid container spacing={2}>
            <Grid item lg={3}>
              <Typography sx={{ marginRight: "40px" }}>สร้าง</Typography>
            </Grid>
            <Grid item lg={9}>
              <TextField
                size="small"
                fullWidth
                required
                variant="outlined"
                value={titleBoard}
                onChange={(e) => setTitleBoard(e.target.value)}
              />
            </Grid>
            <Grid item lg={3}>
              <Typography sx={{ marginRight: "40px" }}>
                ช่วงเวลาการประชุม
              </Typography>
            </Grid>
            <Grid item lg={9}>
              <TextField
                size="small"
                fullWidth
                required
                variant="outlined"
                value={dateRange}
                onChange={(e) => setDateRange(e.target.value)}
              />
              {/* <input
                  type="text"
                  ref={(inputRef) => {
                    if (inputRef) {
                      ref.current = inputRef;
                    }
                  }}
                /> */}
            </Grid>
            <Grid item lg={3}>
              <Typography sx={{ marginRight: "40px" }}>
                จำนวนครั้งการประชุม
              </Typography>
            </Grid>
            <Grid item lg={7}>
              <TextField
                size="small"
                fullWidth
                required
                type="test"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                variant="outlined"
                value={total}
                onChange={(e) => setTotal(e.target.value)}
              />
            </Grid>
            <Grid item lg={2}>
              <Typography sx={{ marginRight: "40px" }}>ครั้ง</Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{
              width: "150px",
              height: "fit-content",
              fontSize: "18px",
              color: "#0fb123",
              border: "1px solid #0fb123",
              ":hover": { border: "1px solid #028b13" },
            }}
          >
            ยกเลิก
          </Button>
          {titleBoard !== "" && dateRange !== "" && total !== "" ? (
            <Button
              onClick={handleSubmit}
              variant="contained"
              sx={{
                width: "150px",
                height: "fit-content",
                fontSize: "18px",
                marginLeft: "20px",
                backgroundColor: "#0fb123",
                ":hover": { backgroundColor: "#028b13" },
              }}
            >
              อัพเดท
            </Button>
          ) : (
            ""
          )}
        </DialogActions>
      </Dialog>
      {/* {console.log(board)} */}
      <TableRow key={i}>
        <TableCell
          sx={{
            color: "#000",
            width: "5%",
            fontSize: "14px",
            fontWeight: "500px",
          }}
          align="left"
        >
          {i + 1}
        </TableCell>
        <TableCell
          sx={{
            color: "#000",
            width: "15%",
            fontSize: "14px",
            ontWeight: "500px",
          }}
          align="center"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ width: "40px", display: "flex" }}>
              {" "}
              {board?.isPin ? (
                <img src="/lgkpi.png" style={{ width: "40px" }} />
              ) : (
                ""
              )}
            </div>
            <div style={{ width: "40px", display: "flex" }}>
              {board?.isCer ? (
                <img src="/014-diploma.png" style={{ width: "40px" }} />
              ) : (
                ""
              )}
            </div>
          </div>
        </TableCell>
        <TableCell
          sx={{
            color: "#000",
            width: "25%",
            fontSize: "14px",
            fontWeight: "500px",
          }}
          align="left"
        >
          {board?.boardDepName}
        </TableCell>
        <TableCell
          sx={{
            color: "#000",
            width: "20%",
            fontSize: "14px",
            fontWeight: "500px",
          }}
          align="center"
        >
          {board?.present}
        </TableCell>
        <TableCell
          sx={{
            color: "#000",
            width: "25%",
            fontSize: "14px",
            fontWeight: "500px",
          }}
          align="center"
        >
          {board?.boardMeeting?.length}
        </TableCell>
        <TableCell
          sx={{ color: "#000", width: "10%", position: "relative" }}
          align="right"
        >
          {/* <Button variant='outlined' sx={{
          height: 'fit-content', fontSize: '18px', color: '#0fb123', border: '1px solid #0fb123',
          ':hover': { border: '1px solid #028b13' }
        }}
          onClick={handleClick}
        // component={NavLink} to={`/Committee/${board?.boardDepID}`} state={{ state: true, title: board?.boardDepName }}
        >ดูทั้งหมด</Button> */}
          <Box sx={{ display: "flex" }}>
            {checkPermission.find((x) => x.menuNo === "M003" && x.isView) && (
              <NavLink
                to={`/Committee/${board?.boardDepID}`}
                state={{ state: true, title: board?.boardDepName }}
                style={{ textDecoration: "none" }}
              >
                <Button
                  onClick={(e) =>
                    localStorage.setItem("title", board?.boardDepName)
                  }
                  // component={NavLink}
                  // to={`/${row?.personalID}`}
                  // state={{ add: true }}
                  sx={{
                    backgroundColor: "#1fc64c",
                    "&:hover": { background: "#4caf50" },
                    color: "#fff",
                  }}
                >
                  ดู
                </Button>
                {/* <MoreHorizIcon /> */}
              </NavLink>
            )}
            {checkPermission.find((x) => x.menuNo === "M003" && x.isEdit) && (
              <NavLink
                style={{ textDecoration: "none" }}
                to={`/committee/editboard/${board?.boardDepID}`}

                // style={{ color: "#000" }}
              >
                <Button
                  // component={NavLink}
                  // to={`/${row?.personalID}`}
                  // state={{ add: true }}
                  sx={{
                    backgroundColor: "#0089ff",
                    "&:hover": { background: "#0089ff" },
                    color: "#fff",
                    marginLeft: "5px",
                  }}
                >
                  เเก้ไข
                </Button>
                {/* <MoreHorizIcon /> */}
              </NavLink>
            )}
          </Box>

          {/* <MoreHorizIcon
            onClick={handleClick}
            sx={{ cursor: "pointer", fontSize: "35px" }}
          />

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openM}
            onClose={handleClose}
            // elevation={0}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              ".MuiPopover-paper": {
                boxShadow: "unset",
                border: "1px solid",
              },
            }}
          >
            {checkPermission.find((x) => x.menuNo === "M003" && x.isView) && (
              <MenuItem
                sx={{ width: "100px", fontSize: "20px" }}
                component={NavLink}
                to={`/Committee/${board?.boardDepID}`}
                onClick={(e) =>
                  localStorage.setItem("title", board?.boardDepName)
                }
                state={{ state: true, title: board?.boardDepName }}
              >
                ดู
              </MenuItem>
            )}
            {checkPermission.find((x) => x.menuNo === "M003" && x.isEdit) && (
              <MenuItem
                sx={{ width: "100px", fontSize: "20px" }}
                // onClick={(e) => handleClickOpen(e, board)}
                component={NavLink}
                to={`/committee/editboard/${board?.boardDepID}`}
                
              >
                เเก้ไข
              </MenuItem>
            )}
            {checkPermission.find((x) => x.menuNo === "M003" && x.isDelete) && (
              <MenuItem
                sx={{ width: "100px", fontSize: "20px" }}
                onClick={(e) => handleDelet(e, board)}
              >
                ลบ
              </MenuItem>
            )}
          </Menu> */}
        </TableCell>
      </TableRow>
    </>
  );
}
