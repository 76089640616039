import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const initialState = {
    baseUrl: '',
    loading: false,
    error: '',
}
export const baseUrlAsync = createAsyncThunk('baseUrl', (url) => {
    const baseUrl = url
    return baseUrl
})
const baseUrlSlice = createSlice({
    name: 'baseUrl',
    initialState,
    reducers: {

    },
    extraReducers: {
        [baseUrlAsync.pending]: (state, action) => {
            state.loading = true
            state.error = ''
        },
        [baseUrlAsync.fulfilled]: (state, action) => {
            state.baseUrl = action.payload
            state.loading = false
            state.error = ''
        },
        [baseUrlAsync.rejected]: (state, action) => {
            state.baseUrl = null
            state.loading = false
            state.error = action.error.message
        }
    },
})
export const { baseUrl } = baseUrlSlice.actions

export default baseUrlSlice.reducer
