import { Drawer, Box, Divider, Typography, List,ListItem,ListItemButton ,ListItemIcon,ListItemText} from '@mui/material'
import React from 'react'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
export default function MenuNoti({ open, setOpenNoti }) {
  return (
    <Drawer variant="persistent" anchor="left" open={open} sx={{
      display: open ? 'block' : 'none', flexShrink: 0, zIndex:'10','& .MuiDrawer-paper': { position: 'relative', width: '240px', height: '86vh', background:'#f7f7f7' }
    }}>
      <List>
      <ListItem disablePadding sx={{ display: 'block' }}>
      <ListItemButton component={NavLink} to='/Notification' sx={{ '&.active': { color: '#0fb123', '& .MuiListItemIcon-root': { color: '#0fb123', } } }}>
                        <ListItemIcon sx={{ justifyContent: 'center' }}>
                          <NotificationsActiveIcon/>
                        </ListItemIcon>
                        <ListItemText primary='การแจ้งเตือน' />
                    </ListItemButton>
      </ListItem>

      </List>
      <Box sx={{ position: 'absolute', bottom: '0', width: '100%' }}>
        <Divider />
        <Box sx={{ display: 'flex', padding: '5px 10px',color:'#939393' }} onClick={() => setOpenNoti(false)}>
          <ArrowCircleLeftIcon sx={{marginRight:'10px'}}/>
          <Typography>ซ่อนเมนู</Typography>
        </Box>
      </Box>
    </Drawer>
  )
}
