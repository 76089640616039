import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Container,
  TablePagination,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { useNavigate, NavLink, Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Checkbox from "@mui/material/Checkbox";
import { CSVLink, CSVDownload } from "react-csv";
import TitleHead from "../../components/TitleHead";
import SearchIcon from "@mui/icons-material/Search";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import api from "../../api/api";
import moment from "moment/moment";
import { useSelector } from "react-redux";
import { selectCanActivate } from "../../store/modules/selector";
import Swal from "sweetalert2";
import mime from "mime";
import { saveAs } from "file-saver";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EDEFF0",
    color: theme.palette.common,
    fontWeight: "500",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    /*  backgroundColor: '#F2F5F8', */
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    // border: 1,
  },
}));

function createData(index, g, name, lastname, pachacon, year, rank, yearpakad) {
  return { index, g, name, lastname, pachacon, year, rank, yearpakad };
}

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

export default function Personal_Information() {
  const [data, setdata] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [typeSearch, setTypeSearch] = useState("keyword");
  const [Check, setCheck] = useState([]);
  const [keyword, setkeyword] = useState("");
  const fileRef = useRef();
  const checkPermission = useSelector(selectCanActivate);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  // console.log(data);
  // console.log("uploadedFiles", uploadedFiles);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (open === false) {
      api.get("api/v1.0/personal/list").then((res) => {
        // setTimeout(() => {
        //     window.location.reload()
        // }, 1000);
        setdata(res.data.result);
      });
    }
  }, [setUploadedFiles, uploadedFiles, setdata]);

  const getdata = async () => {
    const res = await api.get(`api/v1.0/personal/list`);

    setdata(res?.data?.result);
  };
  useEffect(() => {
    getdata();
  }, []);

  const navigate = useNavigate();
  const inputRef = useRef(null);

  const handleClose = () => {
    setOpen(false);
  };
  const handleClick = () => {
    // 👇️ open file input box on click of other element
    inputRef.current.click();
    // console.log(inputRef);
  };
  // const handleClick2 = () => {
  //     console.log(fileRef)
  //     // 👇️ open file input box on click of other element
  //     fileRef.current.click()
  // };

  const handlesearch = () => {
    api.get(`api/v1.0/personal/list?${typeSearch}=${keyword}`).then((res) => {
      setdata(res?.data?.result);
    });
  };

  const handleFileChange = async (event) => {
    const fileObj = event.target.files && event.target.files[0];
    // console.log('fileObj is', fileObj);

    let formData = new FormData();
    formData.append("fileUpload", fileObj);
    event.target.value = null;

    const result = await api
      .post(`api/v1.0/personal/uploads`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setOpen(true);

        // console.log(res.data.result);
        setUploadedFiles(res.data.result);
      });
    // 👇️ is now empty
    // console.log(event.target.files);

    // 👇️ can still access file object here
    // console.log(fileObj);
    // console.log(fileObj.Title);

    return result;
    // if (!fileObj) {

    //     return;
    // }

    // 👇️ reset file input
  };
  // const headers = [
  //     { label: "Title", },
  //     // { label: "Name", key: "Name" },
  //     // { label: "SurName", key: "SurName" },
  //     // { label: "Position", key: "Position" },
  //     // { label: "Email", key: "Email" },
  //     // { label: "czAddress", key: "czAddress" },
  //     // { label: "czHouseNo", key: "czHouseNo" },
  //     // { label: "czProvince", key: "czProvince" },
  //     // { label: "czDistrict", key: "czDistrict" },
  //     // { label: "czSubDistrict", key: "czSubDistrict" },
  //     // { label: "crAddress", key: "crAddress" },
  //     // { label: "crHouseNo", key: "crHouseNo" },
  //     // { label: "crMoo", key: "crMoo" },
  //     // { label: "crProvince", key: "crProvince" },
  //     // { label: "crDistrict", key: "crDistrict" },
  //     // { label: "crSubDistrict", key: "crSubDistrict" },

  // ]
  //console.log(uploadedFiles);
  const csvData = [
    [
      "Title",
      "Name",
      "SurName",
      "citizenID",
      "Position",
      "Email",
      "czAddress",
      "czMoo",
      "czHouseNo",
      "czProvince",
      "czDistrict",
      "czSubDistrict",
      "crAddress",
      "crHouseNo",
      "crMoo",
      "crProvince",
      "crDistrict",
      "crSubDistrict",
    ],
  ];

  const idrows = uploadedFiles.filter((item) => item.isChecked === true);
  const handleChange = (e) => {
    const { value, checked } = e.target;
    if (value === "all") {
      let id = uploadedFiles.map((row) => {
        return { ...row, isChecked: checked };
      });
      setUploadedFiles(id);
    } else {
      let id = uploadedFiles.map((row) =>
        row.runNum == value ? { ...row, isChecked: checked } : row
      );

      // let id = uploadedFiles.map((row) => {
      //     if(row.runNum == value)
      //     {
      //         return { ...row, isChecked: checked };
      //     }
      //     else
      //     {
      //         return row;
      //     }
      // })

      setUploadedFiles(id);
    }
  };
  // con

  //console.log(idrows);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    // console.log(event);
    setPage(0);
  };

  const sumbit = () => {
    const idrows = uploadedFiles.filter((item) => item.isChecked === true);
    // console.log(idrows);
    api.post("api/v1.0/personal/create-personal-array", idrows).then((res) => {
      Swal.fire({
        title: "นำเข้าข้อมูลสำเร็จ",
        confirmButtonColor: "#1fc64c",
      });
      setOpen(false);

      setTimeout(() => {
        getdata();
        // window.location.reload();
      }, 1000);
    });
  };

  return (
    <Container
      maxWidth="xl"
      sx={{ height: "auto", marginTop: "40px", marginBottom: "40px" }}
    >
      <TitleHead
        title="ข้อมูลส่วนบุคคล"
        subTitle="ข้อมูลทะเบียนประวัติส่วนบุคคล"
      >
        <Box sx={{ display: "flex", justifyContent: "right" }}>
          <input
            style={{ display: "none" }}
            ref={inputRef}
            type="file"
            onChange={handleFileChange}
            encType="multipart/form-data"
          />

          <Button
            variant="outlined"
            onClick={async () => {
              await api
                .get(`api/v1.0/personal/template/download`, {
                  responseType: "blob",
                })
                .then((blobFile) => {
                  const blobFileRes = new File([blobFile?.data], "test.csv", {
                    type: mime.getType(".csv"),
                  });

                  // window.open(
                  //   URL.createObjectURL(blobFileRes, "test.csv"),
                  //   "_blank"
                  // );
                  saveAs(blobFileRes, "ตัวอย่าง.csv");
                });
            }}
            sx={{
              color: "#4caf50",
              padding: "7px ",
              marginTop: "23px",
              marginRight: "16px",
              marginLeft: "20px",
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
            color="success"
          >
            <SaveAltIcon sx={{ marginRight: "5px" }} fontSize="small" />
            ดาวน์โหลดเทมเพลต
          </Button>

          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ marginRight: "16px", marginTop: "30px" }}
          />
          {checkPermission.find((x) => x.menuNo === "M001" && x.isAdd) && (
            <>
              <Button
                color="success"
                onClick={handleClick}
                variant="outlined"
                sx={{
                  color: "#4caf50",
                  padding: "7px 55px",
                  marginTop: "23px",
                  marginRight: "16px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
              >
                นำข้อมูลเข้า
              </Button>
              <Button
                component={NavLink}
                to="/addpersonal"
                sx={{
                  backgroundColor: "#1fc64c",
                  "&:hover": { background: "#4caf50" },
                  color: "#FFFF",

                  marginTop: "23px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
                state={{ add: true }}
              >
                สร้างใหม่
              </Button>
            </>
          )}
        </Box>
      </TitleHead>

      <Box
        sx={{
          display: "flex",
          color: "#000",
          marginTop: "20px",
          marginLeft: "50px",
          justifyContent: "right",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "20px",
          }}
        >
          <FormControl
            sx={{ marginRight: "16px", width: "200px" }}
            color="success"
          >
            <InputLabel sx={{ alignItems: "center" }}>กรองข้อมูล</InputLabel>
            <Select
              // open={open}
              // onClose={handleClose}
              // onOpen={handleOpen}
              // defaultValue={1}
              size="small"
              label="กรองข้อมูล"
              // placeholder="ทั้งหมด"
              value={typeSearch}
              // onChange={handleSelect}
              onChange={(e) => {
                setTypeSearch(e.target.value);
              }}
              // size="small"
            >
              <MenuItem value={"keyword"}>ชื่อ-สกุล</MenuItem>
              {checkPermission.find((x) => x.menuNo === "M001" && x.isEdit) ? (
                <MenuItem value={"citizenID"}>เลขบัตรประชาชน</MenuItem>
              ) : (
                ""
              )}
            </Select>
          </FormControl>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              id="outlined-basic"
              value={keyword}
              label="กรอกข้อมูลค้นหา"
              variant="outlined"
              color="success"
              size="small"
              sx={{ width: "200px" }}
              onChange={(e) => setkeyword(e.target.value)}
            />
            <Button
              sx={{
                color: "#fff",
                padding: "8px",
                marginLeft: "16px",

                backgroundColor: "#0fb123",
                ":hover": { backgroundColor: "#028b13" },
              }}
              onClick={handlesearch}
            >
              <SearchIcon />
            </Button>
          </Box>
        </Box>
      </Box>

      <Stack spacing={3}>
        <TableContainer sx={{ marginTop: "20px" }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead sx={{ backgroundColor: "#e7e7e7" }}>
              <TableRow>
                <TableCell
                  sx={{
                    color: "#000",
                    border: "none",
                    fontSize: "14px",
                    fontWeight: "700",
                  }}
                  align="left"
                >
                  สถานะ
                </TableCell>
                <TableCell
                  sx={{
                    color: "#000",
                    border: "none",
                    fontSize: "14px",
                    fontWeight: "700",
                  }}
                  align="left"
                >
                  คำนำหน้าชื่อ
                </TableCell>
                <TableCell
                  sx={{
                    color: "#000",
                    border: "none",
                    fontSize: "14px",
                    fontWeight: "700",
                  }}
                  align="left"
                >
                  ชื่อ
                </TableCell>
                <TableCell
                  sx={{
                    color: "#000",
                    border: "none",
                    fontSize: "14px",
                    fontWeight: "700",
                  }}
                  align="left"
                >
                  นามสกุล
                </TableCell>
                {checkPermission.find(
                  (x) => x.menuNo === "M001" && x.isEdit
                ) ? (
                  <TableCell
                    sx={{
                      color: "#000",
                      border: "none",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                    align="left"
                  >
                    เลขบัตรประชาชน
                  </TableCell>
                ) : (
                  ""
                )}

                <TableCell
                  sx={{
                    color: "#000",
                    border: "none",
                    fontSize: "14px",
                    fontWeight: "700",
                  }}
                  align="left"
                >
                  ปี-เข็มกิตติคุณ
                </TableCell>
                <TableCell
                  sx={{
                    color: "#000",
                    border: "none",
                    fontSize: "14px",
                    fontWeight: "700",
                  }}
                  align="left"
                >
                  ชั้น-เข็มกิตติคุณ
                </TableCell>
                <TableCell
                  sx={{
                    color: "#000",
                    border: "none",
                    fontSize: "14px",
                    fontWeight: "700",
                  }}
                  align="left"
                >
                  ปี-ประกาศนียบัตร
                </TableCell>
                <TableCell
                  sx={{
                    color: "#000",
                    border: "none",
                    fontSize: "14px",
                    fontWeight: "700",
                  }}
                  align="left"
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => (
                  <StyledTableRow
                    key={i}
                    /* onClick={() => navigate(`/ProjectSource/SearchPoliticalMinor`)} */
                    sx={{
                      fontSize: "18px",
                      cursor: "pointer",
                      "&:hover": {
                        background: "#f3f2f2",
                      },
                    }}
                  >
                    <StyledTableCell
                      sx={{
                        color: row?.status === true ? "#4caf50" : "#cd5b5b",
                      }}
                    >
                      {row?.status === true ? "ปกติ" : "ยกเลิก"}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row">
                      {row?.title?.titleNameTH}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row?.firstname}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row?.lastname}
                    </StyledTableCell>
                    {checkPermission.find(
                      (x) => x.menuNo === "M001" && x.isEdit
                    ) ? (
                      <StyledTableCell align="left">
                        {row?.citizenID}
                      </StyledTableCell>
                    ) : (
                      ""
                    )}
                    <StyledTableCell align="left">
                      {moment(row?.pinApproveDate)
                        .add(543, "year")
                        .format("DD/MM/yyyy")
                        .substring(6) === "d date"
                        ? ""
                        : moment(row?.pinApproveDate)
                            .add(543, "year")
                            .format("DD/MM/yyyy")
                            .substring(6) || ""}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row?.cvPinMax}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {moment(row?.cerApproveDate)
                        .add(543, "year")
                        .format("DD/MM/yyyy")
                        .substring(6) === "d date"
                        ? ""
                        : moment(row?.cerApproveDate)
                            .add(543, "year")
                            .format("DD/MM/yyyy")
                            .substring(6) || ""}
                    </StyledTableCell>
                    <TableCell sx={{ color: "#000" }} align="center">
                      <Button
                        component={NavLink}
                        to={`/${row?.personalID}`}
                        state={{ add: true }}
                        variant="outlined"
                        color="success"
                        sx={{
                          borderColor: "#1fc64c",

                          "&:hover": { borderColor: "#1fc64c", color: "susse" },
                          color: "#1fc64c",
                        }}
                      >
                        ดู
                      </Button>
                    </TableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Stack>
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "100%", maxHeight: 500 } }}
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="responsive-dialog-title">
          {"ข้อมูลส่วนบุคคลนำข้อมูลเข้า"}
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
            <Table sx={{ minWidth: 600 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left" sx={{ fontSize: "18px" }}>
                    {" "}
                    <Checkbox
                      onChange={handleChange}
                      value="all"
                      color="success"
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{ fontSize: "18px", fontWeight: "400" }}
                  >
                    คำนำหน้า
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{ fontSize: "18px", fontWeight: "400" }}
                  >
                    ชื่อ
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{ fontSize: "18px", fontWeight: "400" }}
                  >
                    นามสกุล
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{ fontSize: "18px", fontWeight: "400" }}
                  >
                    เลขบัตรประชาชน
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{ fontSize: "18px", fontWeight: "400" }}
                  >
                    หมายเหตุ
                  </StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {uploadedFiles.map((row, i) => (
                  <StyledTableRow
                    key={i}
                    /* onClick={() => navigate(`/ProjectSource/SearchPoliticalMinor`)} */
                    sx={{
                      fontSize: "18px",
                      cursor: "pointer",
                      "&:hover": {
                        background: "#f3f2f2",
                      },
                    }}
                  >
                    <StyledTableCell align="left" fontSize="18px">
                      <Checkbox
                        color="success"
                        value={row.runNum}
                        checked={row?.isChecked || false}
                        onChange={handleChange}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.Title}</StyledTableCell>
                    <StyledTableCell align="left">{row.Name}</StyledTableCell>
                    <StyledTableCell align="left">
                      {row.SurName}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.citizenID}
                    </StyledTableCell>
                    <StyledTableCell align="left" sx={{ color: "red" }}>
                      {row.remark}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        >
          <Button
            onClick={handleClose}
            color="success"
            variant="outlined"
            sx={{ color: "#4caf50", width: "20%", marginRight: "10px" }}
          >
            กลับ
          </Button>
          <Button
            sx={{
              backgroundColor: "#1fc64c",
              "&:hover": { background: "#4caf50" },
              color: "#FFFF",
              width: "20%",
            }}
            onClick={sumbit}
          >
            ยืนยัน
          </Button>
        </Box>
      </Dialog>
    </Container>
  );
}
