import {
  Drawer,
  Box,
  Divider,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import LogoutIcon from "@mui/icons-material/Logout";
import { NavLink, useNavigate } from "react-router-dom";
import api from "../../api/api";
import HistoryIcon from '@mui/icons-material/History';
export default function MenuSetting({ open, setOpenSett }) {
  const navigate = useNavigate();
  const [roleList, setRoleList] = useState([]);

  const getRoleList = async () => {
    await api.get("api/v1.0/setting/configaction/list/bytoken").then((res) => {
      setRoleList(res?.data?.result);
    });
  };

  useEffect(() => {
    getRoleList();
  }, []);

  // const handleLogOut = async (event) => {
  //   // event.preventDefault();
  //   localStorage.removeItem("accessToken");
  //   localStorage.removeItem("refreshToken");
  //   localStorage.removeItem("ActiveTimeOut");
  //   window.location = "/Login";
  // };
  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={open}
      sx={{
        display: open ? "block" : "none",
        flexShrink: 0,
        zIndex: "10",
        "& .MuiDrawer-paper": {
          position: "relative",
          width: "240px",
          height: "86vh",
          background: "#f7f7f7",
        },
      }}
    >
      <List>
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            component={NavLink}
            to="/UserList"
            sx={{
              "&.active": {
                color: "#0fb123",
                "& .MuiListItemIcon-root": { color: "#0fb123" },
              },
            }}
          >
            <ListItemIcon sx={{ justifyContent: "center" }}>
              <IconButton />
            </ListItemIcon>
            <ListItemText primary="ตั้งค่าสมาชิก" />
          </ListItemButton>
          <ListItemButton
            component={NavLink}
            to="/PresentList"
            sx={{
              "&.active": {
                color: "#0fb123",
                "& .MuiListItemIcon-root": { color: "#0fb123" },
              },
            }}
          >
            <ListItemIcon sx={{ justifyContent: "center" }}>
              <IconButton />
            </ListItemIcon>
            <ListItemText primary="ผู้เสนอ" />
          </ListItemButton>
          <ListItemButton
            component={NavLink}
            to="/Permission"
            sx={{
              "&.active": {
                color: "#0fb123",
                "& .MuiListItemIcon-root": { color: "#0fb123" },
              },
            }}
          >
            <ListItemIcon sx={{ justifyContent: "center" }}> </ListItemIcon>
            <ListItemText primary="การจัดการสิทธิ์" />
          </ListItemButton>
          <ListItemButton
            component={NavLink}
            to="/MenuActivate"
            sx={{
              "&.active": {
                color: "#0fb123",
                "& .MuiListItemIcon-root": { color: "#0fb123" },
              },
            }}
          >
            <ListItemIcon sx={{ justifyContent: "center" }}> </ListItemIcon>
            <ListItemText primary="การเข้าถึง" />
          </ListItemButton>
          <ListItemButton
            component={NavLink}
            to="/Loguser"
            sx={{
              "&.active": {
                color: "#0fb123",
                "& .MuiListItemIcon-root": { color: "#0fb123" },
              },
            }}
          >
            <ListItemIcon sx={{ justifyContent: "center" }}>
              <IconButton />
            </ListItemIcon>
            <ListItemText primary="ประวัติการใช้งาน" />
          </ListItemButton>
          <ListItemButton
            component={NavLink}
            to="/Logfile"
            sx={{
              "&.active": {
                color: "#0fb123",
                "& .MuiListItemIcon-root": { color: "#0fb123" },
              },
            }}
          >
            <ListItemIcon sx={{ justifyContent: "center" }}>
              <HistoryIcon />
            </ListItemIcon>
            <ListItemText primary="Logfile" />
          </ListItemButton> 
          {/* <ListItemButton
            onClick={handleLogOut}
            sx={{
              "&.active": {
                color: "#0fb123",
                "& .MuiListItemIcon-root": { color: "#0fb123" },
              },
            }}
          >
            
            
            <ListItemIcon sx={{ justifyContent: "center" }}>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="ออกจากระบบ" />
          </ListItemButton> */}
        </ListItem>
      </List>
      <Box sx={{ position: "absolute", bottom: "0", width: "100%" }}>
        <Divider />
        <Box
          sx={{ display: "flex", padding: "5px 10px", color: "#939393" }}
          onClick={() => setOpenSett(false)}
        >
          <ArrowCircleLeftIcon sx={{ marginRight: "10px" }} />
          <Typography>ซ่อนเมนู</Typography>
        </Box>
      </Box>
    </Drawer>
  );
}
