
import {
    Box,
    Typography,
    Button,
    TextField,
    Container,
    Select,
    MenuItem,
    FormGroup,
    InputLabel,
    FormControl,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { useNavigate, NavLink, useParams } from "react-router-dom";
import api from "../api/api";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { useRef } from "react";
import React, { useState, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
        "& .MuiSwitch-thumb": {
            width: 15,
        },
        "& .MuiSwitch-switchBase.Mui-checked": {
            transform: "translateX(9px)",
        },
    },
    "& .MuiSwitch-switchBase": {
        padding: 2,
        "&.Mui-checked": {
            transform: "translateX(12px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
            },
        },
    },
    "& .MuiSwitch-thumb": {
        boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(["width"], {
            duration: 200,
        }),
    },
    "& .MuiSwitch-track": {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === "dark"
                ? "rgba(255,255,255,.35)"
                : "rgba(0,0,0,.25)",
        boxSizing: "border-box",
    },
}));

export default function Profile() {
    const inputRef = useRef(null);
    const navigate = useNavigate();
    // let { id, views } = useParams();
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [username, setUsername] = useState("");
    const [permission, setPermission] = useState("");
    const [email, setEmail] = useState("");
    const [status, setStatus] = useState("");
    const [userID, setUserID] = useState('');



    const getDetail = async () => {
        await api.get(`api/v1.0/auth/profile`).then((res) => {
            const body = res?.data?.result
            setUserID(body?.userID)
            setFirstname(body?.firstname);
            setLastname(body?.lastname);
            setEmail(body?.email);
            setUsername(body?.username);

        });
    };

    useEffect(() => {

        getDetail();
    }, []);



    const handleSubmit = async () => {
        const body = {
            userID: userID,
            // username: username,
            firstname: firstname,
            lastname: lastname,
            email: email,

        };

        await api.post("api/v1.0/users/updateown", body).then((res) => {
            if (res?.data?.status) {
                Swal.fire(
                    "สำเร็จ",
                    "แก้ไขสำเร็จ",
                    "success"
                );
                getDetail()
            }
        });
        // console.log(body)
    }

    return (
        <>
            <Box sx={{ width: "100%" }}>
                <Box
                    sx={{
                        display: "flex",
                        color: "#000",
                        marginTop: "50px",
                        marginLeft: "50px",
                    }}
                >
                    <Typography variant="h4">ข้อมูลผู้ใช้งาน</Typography>
                </Box>

                <Divider
                    sx={{ width: "1500px", marginLeft: "50px", marginTop: "10px" }}
                />

                <Box
                    sx={{
                        display: "flex",
                        color: "#000",
                        marginTop: "40px",
                        marginLeft: "150px",
                    }}
                >
                    <Typography sx={{ fontSize: "16px" }}>ชื่อ</Typography>
                    <TextField
                        sx={{
                            width: "500px",
                            height: "200px",
                            marginLeft: "100px",
                            marginTop: "-15px",
                        }}
                        id="outlined-textarea"
                        placeholder="กรุณากรอกชื่อ"
                        required
                        // disabled={views === "views"}
                        value={firstname}
                        onChange={(e) => {
                            setFirstname(e.target.value);
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        color: "#000",
                        marginTop: "-100px",
                        marginLeft: "150px",
                    }}
                >
                    <Typography sx={{ fontSize: "16px" }}>นามสกุล</Typography>
                    <TextField
                        sx={{
                            width: "500px",
                            height: "200px",
                            marginLeft: "65px",
                            marginTop: "-15px",
                        }}
                        id="outlined-textarea"
                        placeholder="กรุณากรอกนามสกุล"
                        required
                        // disabled={views === "views"}
                        value={lastname}
                        onChange={(e) => {
                            setLastname(e.target.value);
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        color: "#000",
                        marginTop: "-100px",
                        marginLeft: "150px",
                    }}
                >
                    <Typography sx={{ fontSize: "16px" }}>ชื่อผู้ใช้งาน</Typography>

                    <TextField
                        sx={{
                            width: "500px",
                            height: "65px",
                            marginLeft: "50px",
                            marginTop: "-15px",
                        }}
                        id="outlined-textarea"
                        placeholder="กรุณากรอกชื่อผู้ใช้งาน"
                        required

                        value={username}
                        disabled
                        onChange={(e) => {
                            setUsername(e.target.value);
                        }}
                    />

                </Box>

                <Box
                    sx={{
                        display: "flex",
                        color: "#000",
                        marginTop: "20px",
                        marginLeft: "150px",
                    }}
                >
                    <Typography sx={{ fontSize: "16px" }}>อีเมล</Typography>
                    <TextField
                        sx={{
                            width: "500px",
                            height: "200px",
                            marginLeft: "85px",
                        }}
                        id="outlined-textarea"
                        placeholder="กรุณากรอกอีเมล"
                        required
                        value={email}
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                    // disabled={id || views === "views"}
                    />
                </Box>

                <Box
                    sx={{
                        position: "sticky",
                        bottom: "0px",
                        width: "100%",
                        marginTop: "100px",
                        background: "#fff",
                        zIndex: '100'
                    }}
                >
                    <Divider sx={{ marginBottom: "10px" }} />
                    <Container maxWidth="xl" sx={{ height: "auto" }}>
                        <Box
                            sx={{
                                padding: "5px 20px",
                                marginBottom: "50px",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            <Button
                                variant="outlined"
                                sx={{
                                    width: "150px",
                                    height: "fit-content",
                                    fontSize: "18px",
                                    color: "#0fb123",
                                    border: "1px solid #0fb123",
                                    ":hover": { border: "1px solid #028b13" },
                                }}
                                component={NavLink}
                                to="/UserList"
                            >
                                กลับ
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleSubmit}
                                sx={{
                                    width: "150px",
                                    height: "fit-content",
                                    fontSize: "18px",
                                    marginLeft: "20px",
                                    backgroundColor: "#0fb123",
                                    ":hover": { backgroundColor: "#028b13" },
                                }}
                            >
                                บันทึก
                            </Button>
                        </Box>
                    </Container>
                </Box>
            </Box>
        </>
    );
}
