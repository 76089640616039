import { configureStore } from "@reduxjs/toolkit";
import actionSlice from "./slices/actionSlice";
import authSlice from "./slices/authSlice";
import baseUrlSlice from "./slices/baseUrlSlice";
import profileSlice from "./slices/profileSlice";

export default configureStore({
  reducer: {
    auth: authSlice,
    profile: profileSlice,
    permission: actionSlice,
    baseUrl: baseUrlSlice
  },
});
