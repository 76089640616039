
import React, { useEffect, useState } from 'react'
import { Box, Button, Container, FormControl, Stack, Tab, Table, TableBody, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { NavLink, useLocation, useParams } from "react-router-dom";
import TitleHead from "../../components/TitleHead";
    import SearchIcon from "@mui/icons-material/Search";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import moment from 'moment/moment';
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import api from '../../api/api';
import mime from "mime";
import { saveAs } from 'file-saver';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#94949473",
        color: theme.palette.common,
        fontWeight: "500",
        fontSize: 14,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 10,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        /*  backgroundColor: '#F2F5F8', */
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));
export default function Report3() {
    const [startDate, setStartDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [minDate, setMinDate] = useState();
    const [endDate, setEndDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [listreport, setReport] = useState([]);




    const handleClick = () => {
        api.get(`api/v1.0/report/Report3/list?startyear=${startDate}&endyear=${endDate}`).then((res) => {

            setReport(res?.data?.result)
            // console.log(res?.data?.result);
        })
    }

    useEffect(() => {
        api.get(`api/v1.0/report/Report3/list?startyear=${startDate}&endyear=${endDate}`).then((res) => {

            setReport(res?.data?.result)
            // console.log(res?.data?.result);
        })
    }, [setReport, startDate])
    return (
        <Container maxWidth='xl' sx={{ height: 'auto', marginTop: '40px', marginBottom: '40px' }}>
            <TitleHead
                title="รายงาน"
                subTitle="รายงานผู้ได้รับเข็มซึ่งสามารถเลือกแต่ละชั้นได้"
            >
                <Box sx={{ display: "flex", justifyContent: "right" }}>
                    <Button
                        color="success"
                        onClick={async () => {
                            await api.get(`api/v1.0/report/Report3/download?startyear=${startDate}&endyear=${endDate}&format=txt`, {
                                responseType: "blob",
                            })
                                .then((blobFile) => {
                                    const blobFileRes = new File(
                                        [blobFile?.data],
                                        "Template.txt",
                                        {
                                            type: mime.getType(".txt"),
                                        }
                                    );
                                    saveAs(blobFileRes, `รายงานผู้ได้รับเข็มซึ่งสามารถเลือกแต่ละชั้นได้${startDate}-${endDate}.txt`)
                                    // saveAs(URL.createObjectURL(blobFileRes, "Template.txt"))
                                    // window.open(

                                    //     ,
                                    //     "_blank"
                                    // );
                                });
                        }}
                        variant="outlined"
                        sx={{
                            color: "#4caf50",
                            padding: "8px",
                            paddingLeft:'16px',
                            paddingRight:'16px',
                            marginTop: "23px",
                            marginRight: '23px'

                        }}
                    >
                        <SaveAltIcon sx={{ marginRight: '10px' }} />  Text File
                    </Button>
                    <Button
                        color="success"
                        onClick={async () => {
                            await api
                                .get(`api/v1.0/report/Report3/download?startyear=${startDate}&endyear=${endDate}&format=xlsx`, {
                                    responseType: "blob",
                                })
                                .then((blobFile) => {
                                    const blobFileRes = new File(
                                        [blobFile?.data],
                                        "Template.xlsx",
                                        {
                                            type: mime.getType(".xlsx"),
                                        }
                                    );
                                    saveAs(blobFileRes, `รายงานผู้ได้รับเข็มซึ่งสามารถเลือกแต่ละชั้นได้${startDate}-${endDate}.xlsx`)

                                    // window.open(
                                    //     URL.createObjectURL(blobFileRes, "Template.xlsx")
                                    //     ,
                                    //     "_blank"
                                    // );
                                });
                        }}
                        variant="outlined"
                        sx={{
                            color: "#4caf50",
                            padding: "8px",
                            paddingLeft:'16px',
                            paddingRight:'16px',
                            marginTop: "23px",
                           

                        }}
                    >
                        <SaveAltIcon sx={{ marginRight: '10px' }} /> Excel
                    </Button>
                </Box>
            </TitleHead>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ marginTop: '15px' }}>
                    <Typography sx={{ fontSize: '14px' }}>รายงาน&nbsp;/&nbsp;
                        <span style={{ color: '#35cc62', fontSize: '14px' }}>รายงานผู้ได้รับเข็มซึ่งสามารถเลือกแต่ละชั้นได้
                        </span>
                    </Typography>
                </Box>
                <Box>
                    <Box>
                        <FormControl sx={{ marginRight: 0, }} size="small">
                        <DesktopDatePicker
                            label=""
                            inputFormat="DD/MM/YYYY"
                            value={startDate}
                            maxDate={endDate ? new Date(endDate) : ''}
                            onChange={(newValue) => { setStartDate(moment(newValue).format('YYYY-MM-DD')) }}
                            renderInput={(params) => <TextField size='small' color="success" sx={{ width: '100%' }}
                                InputProps={params?.InputProps}
                                inputRef={params?.inputRef}
                                // placeholder={params?.inputProps?.placeholder}
                                // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                                // onChange={params?.inputProps?.onChange}
                                // type={params?.inputProps?.type}
                                inputProps={
                                    {
                                      placeholder: params?.inputProps?.placeholder,
                                      readOnly: true,
                                      value: params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null,
                                      onChange: params?.inputProps?.onChange,
                                      type: params?.inputProps?.type
                                    }
                
                                  }
                            />}
                        />
                        </FormControl>

                        <FormControl sx={{ marginRight: 0, }} size="small">
                            <Typography sx={{ m: 1 }}>~</Typography>
                        </FormControl>
                        <FormControl sx={{ marginRight: 0, }} size="small">
                        <DesktopDatePicker
                                label=""
                                inputFormat="DD/MM/YYYY"
                                value={endDate}
                                minDate={new Date(startDate)}
                                onChange={(newValue) => { setEndDate(moment(newValue).format('YYYY-MM-DD')) }}
                                renderInput={(params) => <TextField size='small' color="success" sx={{ width: '100%' }}
                                    InputProps={params?.InputProps}
                                    inputRef={params?.inputRef}
                                    // placeholder={params?.inputProps?.placeholder}
                                    // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                                    // onChange={params?.inputProps?.onChange}
                                    // type={params?.inputProps?.type}
                                    inputProps={
                                        {
                                          placeholder: params?.inputProps?.placeholder,
                                          readOnly: true,
                                          value: params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null,
                                          onChange: params?.inputProps?.onChange,
                                          type: params?.inputProps?.type
                                        }
                    
                                      }
                                />}
                            />
                        </FormControl>

                        <Button
                            onClick={handleClick}
                            variant="contained"
                            size='small'
                            sx={{
                                padding: "8px",
                                marginLeft:'16px',
                                paddingLeft:'16px',
                                paddingRight:'16px',

                                backgroundColor: "#0fb123",
                                ":hover": { backgroundColor: "#028b13" },
                            }}
                        >
                            <SearchIcon />
                        </Button>
                    </Box>
                </Box>
            </Box>

            <Stack spacing={3}>
                <TableContainer sx={{ marginTop: "20px", marginBottom: "20px" }}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell
                                    align="center"
                                    sx={{ fontSize: "18px", fontWeight: "400" }}
                                >
                                    #
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    sx={{ fontSize: "18px", fontWeight: "400" }}
                                >
                                    ชื่อ-สกุล
                                </StyledTableCell>

                                {/* <StyledTableCell
                                    align="center"
                                    sx={{ fontSize: "18px", fontWeight: "400" }}
                                >
                                    ดำรงตำแหน่ง / ปฏิบัติหน้าที่ / ทำคุณประโยชน์
                                </StyledTableCell> */}
                                <StyledTableCell
                                    align="center"
                                    sx={{ fontSize: "18px", fontWeight: "400" }}
                                >
                                    ชั้นเข็ม
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    sx={{ fontSize: "18px", fontWeight: "400" }}
                                >
                                    ปี
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    sx={{ fontSize: "18px", fontWeight: "400" }}
                                >
                                    ผู้เสนอ
                                </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        {listreport.map((e, i) => (
                            <TableRow key={i}>
                                <StyledTableCell
                                    align="center"
                                    sx={{ fontSize: "18px", fontWeight: "400" }}
                                >
                                    {i + 1}
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    sx={{ fontSize: "18px", fontWeight: "400" }}
                                >
                                    {e?.firstname} {e?.lastname}
                                </StyledTableCell>
                                {/* <StyledTableCell
                                    align="center"
                                    sx={{ fontSize: "18px", fontWeight: "400" }}
                                >
                                    {e?.cerName}
                                </StyledTableCell> */}
                                <StyledTableCell
                                    align="center"
                                    sx={{ fontSize: "18px", fontWeight: "400" }}
                                >
                                    {e?.rank}
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    sx={{ fontSize: "18px", fontWeight: "400" }}
                                >
                                    {moment(e?.yearCal).add(543, 'year').format('DD-MM-yyyy').substring(6)}
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    sx={{ fontSize: "18px", fontWeight: "400" }}
                                >
                                    {e?.present}
                                </StyledTableCell>
                            </TableRow>
                        ))}
                    </Table>
                </TableContainer>
            </Stack>

        </Container>
    )
}
