import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Container,
  TableRow,
  TableHead,
  Table,
  TableContainer,
  TableBody,
  FormControl,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate, NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import moment from "moment";
import { styled } from "@mui/material/styles";
import api from "../../api/api";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useSelector } from "react-redux";
import { selectCanActivate } from "../../store/modules/selector";
import TitleHead from "../../components/TitleHead";
const data = [];
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#94949473",
    color: theme.palette.common,
    fontWeight: "500",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    /*  backgroundColor: '#F2F5F8', */
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export default function CalculateRules() {
  const navigate = useNavigate();
  const [certificateList, setCerList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [minDate, setMinDate] = useState(null);
  //----------------Function post create-------------------

  const getData = async () => {
    await api.get("api/v1.0/certificate/list").then((res) => {
      setCerList(res?.data?.result);
      // setStartDate(res?.data?.result?.[0]?.startDate);
      // setEndDate(res?.data?.result?.[0]?.endDate);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    updateRank();
  }, [startDate, endDate]);

  const updateRank = (id, field, value) => {
    // Find the item to update and store it in new list
    let el = certificateList.map((item) => {
      if (item.cerID === id) {
        item[field] = value;
      }
      // item.startDate = startDate;
      // item.endDate = endDate;
      return item;
    });

    setCerList(el);
  };
  // console.log(updateRank)
  const SaveData = async () => {
    await api
      .post("api/v1.0/certificate/createLecturer", certificateList)
      .then((res) => {
        if (res?.data?.status) {
          Swal.fire("สำเร็จ", res?.data?.message, "success");
          getData();
        } else {
          Swal.fire("ไม่สำเร็จ", res?.data?.message, "error");
          getData();
        }
      });
  };
  const checkPermission = useSelector(selectCanActivate);
  return (
    <>
      <Container
        maxWidth="xl"
        sx={{ height: "auto", marginTop: "40px", marginBottom: "40px" }}
      >
        <TitleHead
          title="คุณสมบัติที่คำนวณเข็ม"
          subTitle="เงื่อนไขในการคำนวณเพื่อการรับเข็มกิตติคุณ"
        ></TitleHead>

        <Box
          sx={{
            display: "flex",
            color: "#000",

            p: 5,
          }}
        >
          <TableContainer sx={{ marginTop: "20px", marginBottom: "40px" }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    align="left"
                    sx={{ fontSize: "18px", fontWeight: "400" }}
                  >
                    คุณสมบัติ
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{ fontSize: "18px", fontWeight: "400" }}
                  >
                    ชั้นที่ 1
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{ fontSize: "18px", fontWeight: "400" }}
                  >
                    ชั้นที่ 2
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{ fontSize: "18px", fontWeight: "400" }}
                  >
                    ชั้นที่ 3
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{ fontSize: "18px", fontWeight: "400" }}
                  >
                    ชั้นที่ 4
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{ fontSize: "18px", fontWeight: "400" }}
                  >
                    ชั้นที่ 5
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{ fontSize: "18px", fontWeight: "400" }}
                  ></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {certificateList.map((row, i) => (
                  <StyledTableRow
                    key={i}
                    /* onClick={() => navigate(`/ProjectSource/SearchPoliticalMinor`)} */
                    sx={{
                      fontSize: "18px",
                      cursor: "pointer",
                      "&:hover": {
                        background: "#f3f2f2",
                      },
                    }}
                  >
                    <StyledTableCell> {row?.cerName}</StyledTableCell>

                    <StyledTableCell component="th" scope="row">
                      <TextField
                        value={row?.rank1}
                        size="small"
                        disabled={
                          !checkPermission.find(
                            (x) => x.menuNo === "M008" && x.isEdit
                          )
                        }
                        onChange={(e) => {
                          updateRank(row?.cerID, "rank1", e.target.value);
                        }}
                        onKeyDown={(e) => {
                          const pattern = /[^0-9]/g;
                          const isNotAllow =
                            e.key.length === 1 && pattern.test(e.key);

                          if (isNotAllow) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <TextField
                        value={row?.rank2}
                        size="small"
                        disabled={
                          !checkPermission.find(
                            (x) => x.menuNo === "M008" && x.isEdit
                          )
                        }
                        onChange={(e) => {
                          updateRank(row?.cerID, "rank2", e.target.value);
                        }}
                        onKeyDown={(e) => {
                          const pattern = /[^0-9]/g;
                          const isNotAllow =
                            e.key.length === 1 && pattern.test(e.key);

                          if (isNotAllow) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <TextField
                        
                        value={row?.rank3}
                        size="small"
                        disabled={
                          !checkPermission.find(
                            (x) => x.menuNo === "M008" && x.isEdit
                          )
                        }
                        onChange={(e) => {
                          updateRank(row?.cerID, "rank3", e.target.value);
                        }}
                        onKeyDown={(e) => {
                          const pattern = /[^0-9]/g;
                          const isNotAllow =
                            e.key.length === 1 && pattern.test(e.key);

                          if (isNotAllow) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <TextField
                        value={row?.rank4}
                        size='small'
                        disabled={
                          !checkPermission.find(
                            (x) => x.menuNo === "M008" && x.isEdit
                          )
                        }
                        onChange={(e) => {
                          updateRank(row?.cerID, "rank4", e.target.value);
                        }}
                        onKeyDown={(e) => {
                          const pattern = /[^0-9]/g;
                          const isNotAllow =
                            e.key.length === 1 && pattern.test(e.key);

                          if (isNotAllow) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {" "}
                      <TextField
                        value={row?.rank5}
                        size='small'
                        disabled={
                          !checkPermission.find(
                            (x) => x.menuNo === "M008" && x.isEdit
                          )
                        }
                        onChange={(e) => {
                          updateRank(row?.cerID, "rank5", e.target.value);
                        }}
                        onKeyDown={(e) => {
                          const pattern = /[^0-9]/g;
                          const isNotAllow =
                            e.key.length === 1 && pattern.test(e.key);
                          if (isNotAllow) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {" "}
                      {/* <Box
                        sx={{
                          display: "flex",
                          color: "#000",
                          marginTop: "20px",
                          marginLeft: "50px",
                          justifyContent: "right",
                          paddingRight: "50px",
                        }}
                      >
                        <FormControl sx={{ m: 1, marginRight: 0 }} size="small">
                          <DesktopDatePicker
                            label="วันที่เริ่มต้น"
                            inputFormat="DD/MM/YYYY"
                            value={row?.startDate}
                            disabled={
                              !checkPermission.find((x) => x.menuNo === "M008" && x.isEdit)
                            }
                            name="lecDate"
                            onChange={(newValue) => {
                              setStartDate(moment(newValue).format("YYYY-MM-DD"));
                              setMinDate(moment(newValue).format("YYYY-MM-DD"));
                              setEndDate(
                                moment(
                                  new Date(new Date(newValue).getTime() + 86400000 * 365)
                                ).format("YYYY-MM-DD")
                              );
                            }}
                            renderInput={(params) => (
                              <TextField size="small" sx={{ width: "100%" }} {...params} />
                            )}
                          />
                        </FormControl>
                        <FormControl sx={{ m: 1, marginRight: 0 }} size="small">
                          <Typography sx={{ mt: 1 }}>~</Typography>
                        </FormControl>
                        <FormControl sx={{ m: 1, marginRight: 0 }} size="small">
                          <DesktopDatePicker
                            label="วันที่สิ้นสุด"
                            inputFormat="DD/MM/YYYY"
                            value={row?.endDate}
                            name="lecDate"
                            disabled
                            minDate={minDate}
                            onChange={(newValue) => {
                              setEndDate(moment(newValue).format("YYYY-MM-DD"));
                            }}
                            renderInput={(params) => (
                              <TextField
                                size="small"
                                sx={{ width: "100%", marginLeft: "5px" }}
                                {...params}
                              />
                            )}
                          />
                        </FormControl>
                      </Box> */}
                      <DateSetect
                        checkPermission={checkPermission}
                        startDateOld={row?.startDate}
                        endDateOld={row?.startDate}
                        updateRank={updateRank}
                        cerID={row?.cerID}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Container>
      <Box
        sx={{
          position: "sticky",
          bottom: "0px",
          width: "100%",
          marginTop: "5px",
          background: "#fff",
          zIndex: "100",
        }}
      >
        <Divider sx={{ marginBottom: "10px" }} />
        <Container maxWidth="xl" sx={{ height: "auto" }}>
          <Box
            sx={{
              padding: "5px 20px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {checkPermission.find((x) => x.menuNo === "M008" && x.isEdit) && (
              <Button
                variant="contained"
                onClick={() => {
                  SaveData();
                }}
                sx={{
                  width: "150px",
                  height: "fit-content",
                  fontSize: "18px",
                  marginLeft: "20px",
                  marginBottom: "20px",
                  backgroundColor: "#0fb123",
                  ":hover": { backgroundColor: "#028b13" },
                }}
              >
                บันทึก
              </Button>
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
}

export function DateSetect({
  checkPermission,
  startDateOld,
  endDateOld,
  updateRank,
  cerID,
}) {
  const [startDate, setStartDate] = useState(startDateOld);
  const [endDate, setEndDate] = useState(endDateOld);
  const [minDate, setMinDate] = useState(null);

  return (
    <Box
      sx={{
        display: "flex",
        color: "#000",
        marginTop: "20px",
        marginLeft: "50px",
        justifyContent: "right",
        paddingRight: "50px",
      }}
    >
      <FormControl sx={{ m: 1, marginRight: 0 }} size="small">
        <DesktopDatePicker
          label="วันที่เริ่มต้น"
          inputFormat="DD/MM/YYYY"
          value={startDate}
          disabled={
            !checkPermission.find((x) => x.menuNo === "M008" && x.isEdit)
          }
          name="lecDate"
          onChange={(newValue) => {
            updateRank(
              cerID,
              "startDate",
              moment(newValue).format("YYYY-MM-DD")
            );
            setStartDate(moment(newValue).format("YYYY-MM-DD"));
            setMinDate(moment(newValue).format("YYYY-MM-DD"));
            setEndDate(
              moment(
                new Date(new Date(newValue).getTime() + 86400000 * 365)
              ).format("YYYY-MM-DD")
            );
          }}
          renderInput={(params) => (
            <TextField
              size="small"
              color="success"
              sx={{ width: "100%" }}
              InputProps={params?.InputProps}
              inputRef={params?.inputRef}
              inputProps={{
                placeholder: params?.inputProps?.placeholder,
                readOnly: true,
                value: params?.inputProps?.value
                  ? params?.inputProps?.value?.substring(
                      0,
                      params?.inputProps?.value?.length - 4
                    ) +
                    `${
                      +params?.inputProps?.value?.substring(
                        params?.inputProps?.value?.length - 4
                      ) + 543
                    }`
                  : null,
                onChange: params?.inputProps?.onChange,
                type: params?.inputProps?.type,
              }}
              // placeholder={params?.inputProps?.placeholder}
              // value={params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}`}
              // onChange={params?.inputProps?.onChange}
              // type={params?.inputProps?.type}
            />
          )}
        />
      </FormControl>
      <FormControl sx={{ m: 1, marginRight: 0 }} size="small">
        <Typography sx={{ mt: 1 }}>~</Typography>
      </FormControl>
      <FormControl sx={{ m: 1, marginRight: 0 }} size="small">
        <DesktopDatePicker
          label="วันที่สิ้นสุด"
          inputFormat="DD/MM/YYYY"
          value={endDate}
          name="lecDate"
          disabled
          minDate={minDate}
          onChange={(newValue) => {
            updateRank(cerID, "endDate", moment(newValue).format("YYYY-MM-DD"));
            setEndDate(moment(newValue).format("YYYY-MM-DD"));
          }}
          renderInput={(params) => (
            <TextField
              size="small"
              color="success"
              sx={{ width: "100%", marginLeft: "5px" }}
              InputProps={params?.InputProps}
              inputRef={params?.inputRef}
              inputProps={{
                placeholder: params?.inputProps?.placeholder,
                readOnly: true,
                value: params?.inputProps?.value
                  ? params?.inputProps?.value?.substring(
                      0,
                      params?.inputProps?.value?.length - 4
                    ) +
                    `${
                      +params?.inputProps?.value?.substring(
                        params?.inputProps?.value?.length - 4
                      ) + 543
                    }`
                  : null,
                onChange: params?.inputProps?.onChange,
                type: params?.inputProps?.type,
              }}
              // placeholder={params?.inputProps?.placeholder}
              // value={params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}`}
              // onChange={params?.inputProps?.onChange}
              // type={params?.inputProps?.type}
            />
          )}
        />
      </FormControl>
    </Box>
  );
}
