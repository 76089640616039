import {
  Autocomplete,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  TableCell,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import TitleHead from "../../components/TitleHead";
import ApiContext from "../../context/ApiProvider";
import {
  DataGrid,
  GridToolbarQuickFilter,
  GridLinkOperator,
} from "@mui/x-data-grid";
import moment from "moment";
import { useNavigate, useParams, useRoutes } from "react-router-dom";
import api from "../../api/api";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { NavLink } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import PrintIcon from "@mui/icons-material/Print";
import mime from "mime";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";

import { saveAs } from "file-saver";
import VisibilityIcon from '@mui/icons-material/Visibility';
function QuickSearchToolbar() {
  return (
    <Box
      sx={{
        p: 2,
        width: "100%",
        display: "flex",
        justifyContent: "end",
      }}
    >
      <GridToolbarQuickFilter
        quickFilterParser={(searchInput) =>
          searchInput
            .split(",")
            .map((value) => value.trim())
            .filter((value) => value !== "")
        }
      />
    </Box>
  );
}
export default function DetailHonor() {
  const fileRef = useRef();
  const fileRef2 = useRef();

  const [honorPinListCandidateDetail, setHonorPinListCandidateDetail] =
    useState([]);
  const [selectionModel, setSelectionModel] = useState();
  const [textSelect, setTextSelect] = useState("");
  const [text, setText] = useState("");
  // const [startDate, setStartDate] = useState(null);
  const [remarkSecond, setRemarkSecond] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const { getHonorPinListCandidateDetail, FileDownload } =
    useContext(ApiContext);
  const [files, setFiles] = useState([]);
  const [files2, setFiles2] = useState([]);
  let formData = new FormData();
  const [startDate, setStartDate] = useState(null);
  const [date, setDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [remark, setRemark] = useState("");
  const [startDateS, setStartDateS] = useState(null);
  const [mPresent, setMPresent] = useState([]);
  // const [date, setDate] = useState(new Date());
  const [value, setValue] = useState({});
  const [endDateS, setEndDateS] = useState(null);
  const [remarkS, setRemarkS] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupS, setOpenPopupS] = useState(false);
  const [openPopupSS, setOpenPopupSS] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [list,setlist] =useState([]);
 
  const [openperID, setPero] = useState(false);
  // console.log(date)
  const handleText = () => {
    setTextSelect(value.presentFullName);
    setText("");
    setTimeout(() => {
      setTextSelect("");
      setValue(null);
    }, 1000);
  };
  const handleFileUpload = (event) => {
    if (event.target && event.target.files) {
      let file = event.target.files[0];
      // setFiles([...files, file]);
      const maxAllowedSize = 8 * 1024 * 1024;

      if (file.size < maxAllowedSize) {
        formData.append("file", file);
        // console.log(formData)
        // fileUpload(formData, file, files, setFiles)
        api
          .post("api/v1.0/filemanages/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => res.data)
          .then((data) => {
            if (data.status === true) {
              // console.log(data.result)
              setFiles([...files, data.result]);
            } else {
              console.log(" failed");
            }
          });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "ไฟล์อัพโหลดใหญ่เกินไป",
        });
      }
    }
  };
  const handleRemoveFileUpload = (x, i) => {
    const body = files.map((z) => {
      z.cerAttachID = z?.cerAttachID || "";
      z.fileID = z.fileID;
      z.status = z.fileID === x.fileID ? false : z.status;
      return z;
    });
    // console.log(body)
    setFiles(body);
  };
  const handleFileUpload2 = (event) => {
    if (event.target && event.target.files) {
      let file = event.target.files[0];
      // setFiles([...files, file]);
      const maxAllowedSize = 8 * 1024 * 1024;

      if (file.size < maxAllowedSize) {
        formData.append("file", file);
        // console.log(formData)
        // fileUpload(formData, file, files, setFiles)
        api
          .post("api/v1.0/filemanages/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => res.data)
          .then((data) => {
            if (data.status === true) {
              // console.log(data.result)
              setFiles2([...files2, data.result]);
            } else {
              console.log(" failed");
            }
          });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "ไฟล์อัพโหลดใหญ่เกินไป",
        });
      }
    }
  };
  const handleRemoveFileUpload2 = (x, i) => {
    const body = files2.map((z) => {
      z.cerAttachID = z?.cerAttachID || "";
      z.fileID = z.fileID;
      z.status = z.fileID === x.fileID ? false : z.status;
      return z;
    });
    // console.log(body)
    setFiles2(body);
  };
  // useEffect(() => {
  //     getBoardDepartmentList(setBoardDepartmentList, '')
  // }, [])
  // useEffect(() => {
  //     const param = { id: boardSelect === ''}
  //         getBoardList(setBoardList, param, '', 1)
  // }, [boardSelect])
  // useEffect(() => {
  //         getBoardList(setBoardList, '', '', 1)
  // }, [])
  const close =()=>{
    setPero(false)
  }
  // console.log(honorPinListCandidateDetail)
  const handleClose = () => {
    setOpenPopup(false);
  };
  const handleCloseS = () => {
    setOpenPopupS(false);
  };
  const handleCloseSS = () => {
    setOpenPopupSS(false);
  };

  const detail = async  (e)=>{

    const data = await api.get(`api/v1.0/lecturer/detail?personalID=${e?.personalID}&startDate=${e?.oldRankDate}`)
    const res = await data?.data?.result
    setPero(true)
    setlist(res)
    
   
  }
  useEffect(() => {
    getHonorPinListCandidateDetail(setHonorPinListCandidateDetail, id);
  }, [id]);
  useEffect(() => {
    setFiles(honorPinListCandidateDetail?.cerFileAttach);
  }, [honorPinListCandidateDetail]);
  useEffect(() => {
    setFiles2(honorPinListCandidateDetail?.cerFileAttachSecond);
  }, [honorPinListCandidateDetail]);
  useEffect(() => {
    setDate(honorPinListCandidateDetail?.receiveDate);
  }, [honorPinListCandidateDetail]);
  const getMasterPresent = async () => {
    const res = await api.get(`api/v1.0/master/present/list`);
    const data = await res.data;
    if (data.status === true) {
      setMPresent(data.result);
      // setValue(data?.result?.filter((y) => y?.presentFullName === x?.present)[0])
      // console.log(data.result);
    }
  };
  useEffect(() => {
    getMasterPresent();
  }, []);
  // console.log(files)
  const columns = [
    // { field: 'id', headerName: 'ID', width: 50 },
    { field: "title", headerName: "คำนำหน้า", width: 100 },
    {
      field: "firstName",
      headerName: "ชื่อ",
      width: 150,
      editable: false,
    },
    {
      field: "lastName",
      headerName: "นามสกุล",
      width: 150,
      editable: false,
    },
    {
      field: "cerName",
      headerName: "คุณสมบัติ",
      width: 200,
      editable: false,
    },
    {
      field: "boardDepName",
      headerName: "ชุดคณะกรรมการ",
      width: 180,
      editable: false,
    },
    {
      field: "oldRankDate",
      headerName: "ปีที่ได้รับ",
      width: 110,
      editable: false,
    },
    {
      field: "oldRank",
      headerName: "ชั้น-เข็มปัจจุบัน",
      width: 110,
      editable: false,
    },
    {
      field: "rankCounter",
      headerName: "ระยะเวลาดำรงตำเเหน่ง/จำนวนบริจาค",
      width: 180,
      editable: false,
      renderCell: (params) => {
        return (
          <>
            <Typography>{params.row.rankCounter}</Typography>
            {params.row.rankCounter.includes("ผ่านเกณฑ์") ? (
              <VisibilityIcon sx={{marginLeft:'15px'}}  onClick={()=>detail(params.row)}/>
              // <Button
              //   onClick={()=>detail(params.row)}
              // >
              //   d
              // </Button>
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      field: "rank",
      headerName: "ชั้น-เข็มใหม่",
      width: 110,
      editable: false,
    },
    {
      field: "present",
      headerName: "ผู้เสนอ",
      width: 150,
    },
    {
      field: "status1",
      headerName: "เสนอ",
      width: 80,
    },
    {
      field: "status2",
      headerName: "พิจารณา",
      width: 80,
    },
    {
      field: "status3",
      headerName: "อนุมัติ",
      width: 80,
    },
  ];

  // const data = honorPinListCandidateDetail?.cerDetail?.map((x, i) => {
  //     const date = moment.duration(parseFloat(JSON.parse(x?.cerCodition)?.rankCounter) * 24 * 60 * 60 * 1000)._data
  //     return {
  //         id: i + 1,
  //         title: x?.personal?.title?.titleNameTH,
  //         firstName: x?.personal?.firstname,
  //         lastName: x?.personal?.lastname,
  //         cerName: JSON.parse(x?.cerCodition)?.cerName || '',
  //         oldRankDate: moment(JSON.parse(x?.cerCodition)?.oldRankDate).add(543, 'year').format('DD/MM/yyyy').substring(6) === 'd date' ? '' : moment(JSON.parse(x?.cerCodition)?.oldRankDate).add(543, 'year').format('DD/MM/yyyy').substring(6) || '',
  //         oldRank: JSON.parse(x?.cerCodition)?.oldRank ? `ชั้นที่ ` + JSON.parse(x?.cerCodition)?.oldRank : '',
  //         rankCounter: JSON.parse(x?.cerCodition)?.cerName.includes('ผู้บริจาคเงิน') ? `${parseFloat(JSON.parse(x?.cerCodition)?.rankCounter, 10).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} บาท` : `${date?.years} ปี ${date?.months} เดือน ${date?.days} วัน`,
  //         rank: x?.rank ? `ชั้นที่ ` + x?.rank : '',
  //         status: x?.status,
  //         present: x?.present,
  //         isApproveFirst: x?.isApproveFirst,
  //         isApproveSecond: x?.isApproveSecond,
  //         isReceive: x?.isReceive,
  //         // allStatus:  x?.isApproveFirst + ' ' + x?.isApproveSecond + ' ' +x?.isReceive
  //     }
  // });
  // const rows = data?.map((x, i) => {
  //     x.id = x?.id
  //     x.title = x?.title
  //     x.firstName = x.firstName
  //     x.lastName = x.lastName
  //     x.cerName = x?.cerName
  //     x.oldRankDate = x?.oldRankDate
  //     x.oldRank = x?.oldRank
  //     x.rankCounter = x?.rankCounter
  //     x.rank = x?.rank
  //     x.present = textSelect === '' ? x.present : selectionModel?.filter(y => y === i + 1)[0] == i + 1 ? textSelect : x.present
  //     x.status = x?.status
  //     x.isApproveFirst = x?.isApproveFirst
  //     x.isApproveSecond = x?.isApproveSecond
  //     x.isReceive = x?.isReceive

  //     return x
  // });

  const rows = honorPinListCandidateDetail?.cerDetail?.map((x, i) => {
    const date = moment.duration(
      parseFloat(JSON.parse(x?.cerCodition)?.rankCounter) * 24 * 60 * 60 * 1000
    )._data;

    x.id = i + 1;
    x.title = x?.personal?.title?.titleNameTH;
    x.firstName = x?.personal?.firstname;
    x.lastName = x?.personal?.lastname;
    x.cerName = JSON.parse(x?.cerCodition)?.cerName || "";
    x.boardDepName = x?.boardDepName;
    x.oldRankDate = x?.cerCodition
      ? x?.oldRankDate
        ? x?.oldRankDate.includes("-")
          ? moment(moment(JSON.parse(x?.cerCodition)?.oldRankDate))
              .format("DD/MM/yyyy")
              .substring(6) === "d date"
            ? ""
            : moment(moment(JSON.parse(x?.cerCodition)?.oldRankDate))
                .add(543, "year")
                .format("DD/MM/yyyy")
                .substring(6)
          : x?.oldRankDate
        : "-"
      : "";
    x.oldRank = x?.oldRank
      ? x?.oldRank.includes("ชั้นที่")
        ? x?.oldRank
        : `ชั้นที่ ` + x?.oldRank
      : null;
    x.rankCounter = x?.cerCodition
      ? x?.rankCounter
        ? x?.cerName?.includes("วิทยากร")
          ? "ผ่านเกณฑ์"
          : JSON.parse(x?.cerCodition)?.cerName?.includes("ผู้บริจาคเงิน")
          ? x?.rankCounter?.includes("บาท")
            ? x?.rankCounter
            : `${parseFloat(JSON.parse(x?.cerCodition)?.rankCounter, 10)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")} บาท`
          : JSON.parse(x?.cerCodition)?.cerName?.includes("ผู้บำเพ็ญประโยชน์")
          ? x?.rankCounter.includes("ครั้ง")
            ? x?.rankCounter
            : JSON.parse(x?.cerCodition)?.rankCounter != 0
            ? x?.rankCounter === "กรณีพิเศษ"
              ? "กรณีพิเศษ"
              : JSON.parse(x?.cerCodition)?.rankCounter + " ครั้ง"
            : "กรณีพิเศษ"
          : x?.rankCounter?.includes("ปี")
          ? x?.rankCounter
          : `${date?.years} ปี ${date?.months} เดือน ${date?.days} วัน`
        : "-"
      : "";
    x.rank = x?.rank
      ? x?.rank.includes("ชั้นที่")
        ? x.rank
        : `ชั้นที่ ` + x?.rank
      : null;
    x.present =
      textSelect === ""
        ? x.present
        : selectionModel?.filter((y) => y === i + 1)[0] == i + 1
        ? textSelect
        : x.present;
    x.status = x?.status;
    x.isApproveFirst = x?.isApproveFirst;
    x.isApproveSecond = x?.isApproveSecond;
    x.isReceive = x?.isReceive;
    x.status1 = x.status ? "✓" : "x";
    x.status2 =
      honorPinListCandidateDetail?.status === "D"
        ? "x"
        : honorPinListCandidateDetail?.status === "P"
        ? `${x?.isApproveFirst ? "✓" : "x"}`
        : honorPinListCandidateDetail?.status === "A"
        ? x?.isApproveFirst
          ? "✓"
          : "x"
        : x?.isApproveFirst
        ? "✓"
        : "x";
    x.status3 =
      honorPinListCandidateDetail?.status === "D"
        ? "x"
        : honorPinListCandidateDetail?.status === "P"
        ? "x"
        : honorPinListCandidateDetail?.status === "A"
        ? x?.isApproveSecond
          ? "✓"
          : "x"
        : x?.isApproveSecond
        ? "✓"
        : "x";

    return x;
  });
  // console.log(honorPinListCandidateDetail)

  useEffect(() => {
    if (honorPinListCandidateDetail?.status === "P") {
      let selectHonorPin = honorPinListCandidateDetail?.cerDetail
        ?.map((x, i) => (x.isApproveSecond === true ? i + 1 : ""))
        .filter((x) => x !== "");
      setSelectionModel(selectHonorPin);
    }
    if (honorPinListCandidateDetail?.status === "A") {
      let selectHonorPin = honorPinListCandidateDetail?.cerDetail
        ?.map((x, i) => (x.isReceive === true ? i + 1 : ""))
        .filter((x) => x !== "");
      setSelectionModel(selectHonorPin);
    }
    if (honorPinListCandidateDetail?.status === "R") {
      let selectHonorPin = honorPinListCandidateDetail?.cerDetail
        ?.map((x, i) => (x.isReceive === true ? i + 1 : ""))
        .filter((x) => x !== "");
      setSelectionModel(selectHonorPin);
    }
    if (honorPinListCandidateDetail?.status === "D") {
      let selectHonorPin = honorPinListCandidateDetail?.cerDetail
        ?.map((x, i) => (x.isApproveFirst === true ? i + 1 : ""))
        .filter((x) => x !== "");
      setSelectionModel(selectHonorPin);
    }
  }, [honorPinListCandidateDetail]);

  // const dataHonor = honorPinListCandidateDetail?.cerDetail?.map((x, i) => {
  //     return {
  //         seq: x?.seq,
  //         tscCerID: x?.tscCerID,
  //         cerID: x?.cerID,
  //         cerName: x?.cerName,
  //         personalID: x?.personalID,
  //         rank: x?.rank ? x?.rank?.replace('ชั้นที่ ', '') : null,
  //         cerCodition: x?.cerCodition,
  //         present: rows?.filter((y, index) => index == i)[0]?.present || '',
  //         status: honorPinListCandidateDetail?.status === 'D' ? value === "D" ? selectionModel?.filter(y => y === i + 1)[0] == i + 1  : selectionModel?.filter(y => y === i + 1)[0] == i + 1 ? true : x?.status : honorPinListCandidateDetail?.status === 'P' ? x?.status : honorPinListCandidateDetail?.status === 'A' ? x?.status : honorPinListCandidateDetail?.status === 'R' ? x?.status : x?.status,
  //         isApproveFirst: honorPinListCandidateDetail?.status === 'D' ? selectionModel?.filter(y => y === i + 1)[0] == i + 1 : honorPinListCandidateDetail?.status === 'P' ? x?.isApproveFirst : honorPinListCandidateDetail?.status === 'A' ? x?.isApproveFirst : honorPinListCandidateDetail?.status === 'R' ? x?.isApproveFirst : x?.isApproveFirst,
  //         isApproveSecond: honorPinListCandidateDetail?.status === 'D' ? selectionModel?.filter(y => y === i + 1)[0] == i + 1 : honorPinListCandidateDetail?.status === 'P' ? selectionModel?.filter(y => y === i + 1)[0] == i + 1 : honorPinListCandidateDetail?.status === 'A' ? x?.isApproveSecond : honorPinListCandidateDetail?.status === 'R' ? x?.isApproveSecond : x?.isApproveSecond,
  //         isReceive: honorPinListCandidateDetail?.status === 'D' ? selectionModel?.filter(y => y === i + 1)[0] == i + 1 : honorPinListCandidateDetail?.status === 'P' ? selectionModel?.filter(y => y === i + 1)[0] == i + 1 : honorPinListCandidateDetail?.status === 'A' || honorPinListCandidateDetail?.status === 'R' ? selectionModel?.filter(y => y === i + 1)[0] == i + 1 : x?.isReceive,

  //     }
  // })
  const handleSubmit = (value) => {
    const body = {
      tscHeadCerID: honorPinListCandidateDetail?.tscHeadCerID,
      receiveDate:
        honorPinListCandidateDetail?.status === "A"
          ? moment(date).format("YYYY-MM-DD HH:mm")
          : null,
      // calDate: moment(honorPinListCandidateDetail?.calDate).format('YYYY-MM-DD HH:mm'),
      cerDetail: honorPinListCandidateDetail?.cerDetail?.map((x, i) => {
        return {
          seq: x?.seq,
          tscCerID: x?.tscCerID,
          cerID: x?.cerID,
          cerName: x?.cerName,
          personalID: x?.personalID,
          rank: x?.rank ? x?.rank?.replace("ชั้นที่ ", "") : null,
          cerCodition: x?.cerCodition,
          present: rows?.filter((y, index) => index == i)[0]?.present || "",
          status:
            honorPinListCandidateDetail?.status === "D"
              ? value === "D"
                ? selectionModel?.filter((y) => y === i + 1)[0] == i + 1
                : selectionModel?.filter((y) => y === i + 1)[0] == i + 1
                ? true
                : x?.status
              : honorPinListCandidateDetail?.status === "P"
              ? x?.status
              : honorPinListCandidateDetail?.status === "A"
              ? x?.status
              : honorPinListCandidateDetail?.status === "R"
              ? x?.status
              : x?.status,
          isApproveFirst:
            honorPinListCandidateDetail?.status === "D"
              ? selectionModel?.filter((y) => y === i + 1)[0] == i + 1
              : honorPinListCandidateDetail?.status === "P"
              ? x?.isApproveFirst
              : honorPinListCandidateDetail?.status === "A"
              ? x?.isApproveFirst
              : honorPinListCandidateDetail?.status === "R"
              ? x?.isApproveFirst
              : x?.isApproveFirst,
          isApproveSecond:
            honorPinListCandidateDetail?.status === "D"
              ? selectionModel?.filter((y) => y === i + 1)[0] == i + 1
              : honorPinListCandidateDetail?.status === "P"
              ? selectionModel?.filter((y) => y === i + 1)[0] == i + 1
              : honorPinListCandidateDetail?.status === "A"
              ? x?.isApproveSecond
              : honorPinListCandidateDetail?.status === "R"
              ? x?.isApproveSecond
              : x?.isApproveSecond,
          isReceive:
            honorPinListCandidateDetail?.status === "D"
              ? selectionModel?.filter((y) => y === i + 1)[0] == i + 1
              : honorPinListCandidateDetail?.status === "P"
              ? selectionModel?.filter((y) => y === i + 1)[0] == i + 1
              : honorPinListCandidateDetail?.status === "A" ||
                honorPinListCandidateDetail?.status === "R"
              ? selectionModel?.filter((y) => y === i + 1)[0] == i + 1
              : x?.isReceive,
        };
      }),
      cerBoardDep: honorPinListCandidateDetail?.cerBoardDep,
      cerFileAttach: files,
      cerFileAttachSecond: files2,
      considerDateFirst:
        honorPinListCandidateDetail?.status === "P" ||
        honorPinListCandidateDetail?.status === "A" ||
        honorPinListCandidateDetail?.status === "R"
          ? honorPinListCandidateDetail?.considerDateFirst
          : moment(startDate).format("YYYY-MM-DD") === "Invalid date"
          ? null
          : moment(startDate).format("YYYY-MM-DD"),
      approveDateFirst:
        honorPinListCandidateDetail?.status === "P" ||
        honorPinListCandidateDetail?.status === "A" ||
        honorPinListCandidateDetail?.status === "R"
          ? honorPinListCandidateDetail?.approveDateFirst
          : moment(endDate).format("YYYY-MM-DD") === "Invalid date"
          ? null
          : moment(endDate).format("YYYY-MM-DD"),
      considerDateSecond:
        honorPinListCandidateDetail?.status === "A" ||
        honorPinListCandidateDetail?.status === "R"
          ? honorPinListCandidateDetail?.considerDateSecond
          : moment(startDateS).format("YYYY-MM-DD") === "Invalid date"
          ? null
          : moment(startDateS).format("YYYY-MM-DD"),
      approveDateSecond:
        honorPinListCandidateDetail?.status === "A" ||
        honorPinListCandidateDetail?.status === "R"
          ? honorPinListCandidateDetail?.approveDateSecond
          : moment(endDateS).format("YYYY-MM-DD") === "Invalid date"
          ? null
          : moment(endDateS).format("YYYY-MM-DD"),
      remarkFirst:
        honorPinListCandidateDetail?.status === "P" ||
        honorPinListCandidateDetail?.status === "A" ||
        honorPinListCandidateDetail?.status === "R"
          ? honorPinListCandidateDetail?.remarkFirst
          : remark,
      remarkSecond:
        honorPinListCandidateDetail?.status === "A" ||
        honorPinListCandidateDetail?.status === "R"
          ? honorPinListCandidateDetail?.remarkSecond
          : remarkS,
      // boardDepName: honorPinListCandidateDetail?.boardDepName,
      status:
        value === "D"
          ? honorPinListCandidateDetail?.status === "A"
            ? "A"
            : honorPinListCandidateDetail?.status === "P"
            ? "P"
            : honorPinListCandidateDetail?.status === "R"
            ? "R"
            : value
          : value,
    };
    // console.log(body)
    const updateHonorPin = async () => {
      const res = await api.post(`api/v1.0/honor-pin/update`, body);
      const data = await res.data;
      if (data.status === true) {
        setOpenPopup(false);
        setOpenPopupS(false);
        setRemark("");
        setRemarkS("");
        Swal.fire({
          title: "บันทึกแล้ว",
          text: "บันทึกข้อมูลสำเร็จ",
          icon: "success",
          confirmButtonColor: "#0f962d",
          confirmButtonText: "ตกลง",
          allowOutsideClick: false,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            navigate(`/HonorPin/DetailHonorPin/${data?.result?.tscHeadCerID}`);
            // window.location = `/HonorPin`
          }
        });
        // const MySwal = withReactContent(Swal)

        // Swal.fire({
        //     title: <p>สร้างข้อมูลสำเร็จ</p>,

        //     icon: "success",

        //     didOpen: () => {
        //         // `MySwal` is a subclass of `Swal` with all the same instance & static methods

        //         MySwal.clickConfirm();
        //     },
        // }).then(() => {
        //     return MySwal.fire({
        //         title: 'บันทึกแล้ว',
        //         text: "บันทึกข้อมูลสำเร็จ",
        //         icon: "success",
        //         confirmButtonColor: '#0f962d',
        //         confirmButtonText: 'ตกลง',
        //         allowOutsideClick: false
        //     }

        //     ).then((result) => {
        //         if (result.isConfirmed) {
        //             window.location = `/HonorPin`
        //         }
        //     });
        // });
      }
    };
    updateHonorPin();
  };
  // console.log(honorPinListCandidateDetail)
  return (
    <>
      <Container maxWidth="xl" sx={{ height: "auto", marginTop: "40px" }}>
        <TitleHead
          title="ข้อมูลการรับเข็มกิตติคุณ"
          subTitle="ประวัติการรับเข็มกิตติคุณ"
        >
          <Box>
            <Typography>
              สถานะ:{" "}
              <span style={{ color: "#1fc64c" }}>
                {honorPinListCandidateDetail?.status === "D"
                  ? "กำลังดำเนินการ"
                  : honorPinListCandidateDetail?.status === "P"
                  ? "ผ่านการพิจารณา"
                  : honorPinListCandidateDetail?.status === "A"
                  ? "สภาอนุมัติ"
                  : honorPinListCandidateDetail?.status === "R"
                  ? "เสร็จสิ้น"
                  : ""}
              </span>
            </Typography>
            <Button
              color="success"
              onClick={async () => {
                setIsLoading(true);
                await api
                  .get(`api/v1.0/honor-pin/download/${id}`, {
                    responseType: "blob",
                    timeout: 30000,
                  })
                  .then((blobFile) => {
                    const blobFileRes = new File(
                      [blobFile?.data],
                      "Template.pdf",
                      {
                        type: mime.getType(".pdf"),
                      }
                    );
                    // saveAs(blobFileRes, `รายงานผู้ได้รับเข็มกิตติคุณ.pdf`)

                    window.open(
                      URL.createObjectURL(
                        blobFileRes,
                        `รายงานผู้ได้รับเข็มกิตติคุณ.pdf`
                      ),
                      "_blank"
                    );
                    setIsLoading(false);
                  });
              }}
              variant="outlined"
              sx={{
                color: "#4caf50",
                padding: "7px 55px",
                marginTop: "10px",
                width: "240px",
              }}
            >
              {isLoading ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                <>
                  <PrintIcon sx={{ marginRight: "10px" }} />{" "}
                  <Typography>พิมพ์เอกสาร</Typography>
                </>
              )}
            </Button>
          </Box>
          <Dialog
            open={openperID}
            maxWidth="lg"
            onClose={close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <TableContainer sx={{ marginTop: "20px" }}>
                <Table sx={{ minWidth: 600 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        sx={{ fontSize: "18px", fontWeight: "400" }}
                      >
                        หลักสูตร
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontSize: "18px", fontWeight: "400" }}
                      >
                        รุ่น
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontSize: "18px", fontWeight: "300" }}
                      >
                        วันที่สอน
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontSize: "18px", fontWeight: "400" }}
                      >
                        วิชาที่สอน
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ fontSize: "18px", fontWeight: "400" }}
                      >
                        เวลา
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ overflowY: "auto", height: "600" }}>
                    {list?.map((e, i) => (
                      <TableRow key={i}>
                        <TableCell sx={{ fontSize: "18px", fontWeight: "400" }}>
                          {e?.certificate}
                        </TableCell>
                        <TableCell sx={{ fontSize: "18px", fontWeight: "400" }}>
                          {e?.gen}
                        </TableCell>
                        <TableCell sx={{ fontSize: "18px", fontWeight: "400" }}>
                          {moment(e?.lecDate).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell sx={{ fontSize: "18px", fontWeight: "400" }}>
                          {e?.course}
                        </TableCell>
                        <TableCell sx={{ fontSize: "18px", fontWeight: "400" }}>
                          {e?.time}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              {/* <Button onClick={handleClose}>Disagree</Button>
              <Button onClick={handleClose} autoFocus>
                Agree
              </Button> */}
            </DialogActions>
          </Dialog>
        </TitleHead>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* <Box sx={{ display: 'flex', justifyContent: 'space-between' }}> */}
          <Typography sx={{ color: "#1fc64c" }}>
            รายชื่อคณะกรรมการพิจารณา
          </Typography>
          {/* <Typography sx={{ color: '#1fc64c' }}>รายชื่อคณะกรรมการพิจารณาทั้งหมด {honorPinListCandidateDetail?.cerBoardDep?.length} ท่าน</Typography> */}
          {/* </Box> */}
          {honorPinListCandidateDetail?.considerDateFirst && 
          <Box sx={{ display: "flex" }}>
            <Typography>
              วันที่พิจารณา{" "}
              <span style={{ color: "#1fc64c" }}>
                {moment(honorPinListCandidateDetail?.considerDateFirst)
                  .add(543, "year")
                  .format("LL")}
              </span>
            </Typography>
            {/* <span style={{ margin: '0 5px' }}>|</span> */}
            {/* <Typography>วันที่อนุมัติ <span style={{ color: '#1fc64c' }}>{moment(honorPinListCandidateDetail?.approveDateFirst).add(543, 'year').format('LL')}</span></Typography> */}
          </Box>}
        </Box>

        <Grid
          container
          spacing={2}
          sx={{ marginTop: "15px", marginBottom: "30px" }}
        >
          {honorPinListCandidateDetail?.cerBoardDep?.map((board, i) => (
            <Grid key={i} item lg={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "10px",
                }}
              >
                <Typography variant="h6">{`${board?.title} ${board?.firstname} ${board?.lastname}`}</Typography>
                <Typography sx={{ color: "#1fc64c" }}>
                  {board?.position}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ display: "flex" }}>
          <Typography sx={{ marginLeft: "10px" }}>เหตุผล:</Typography>
          <Typography>{honorPinListCandidateDetail?.remarkFirst}</Typography>
        </Box>

        <Typography sx={{ marginBottom: "15px" }}></Typography>
        <Box sx={{ display: "flex" }}>
          <input
            type="file"
            ref={fileRef}
            accept="image/*,.pdf,.doc,.docx,.xlsx,.csv,.xls"
            hidden
            onChange={handleFileUpload}
          />
          <Button
            disabled={
              honorPinListCandidateDetail?.status === "A" ? true : false
            }
            variant="outlined"
            onClick={() => fileRef.current.click()}
            sx={{
              height: "fit-content",
              fontSize: "18px",
              color: "#0fb123",
              border: "1px solid #0fb123",
              ":hover": { border: "1px solid #028b13" },
            }}
          >
            อัพโหลดไฟล์
          </Button>
          {files
            ?.filter((y) => y.status === true)
            .map(
              (x, i) => (
                <Box
                  sx={{
                    width: "120px",
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #0089ff",
                    borderRadius: "15px",
                    padding: "3px 5px 3px 10px",
                    marginRight: "10px",
                    marginLeft: "10px",
                  }}
                >
                  <Typography
                    onClick={() => FileDownload(x)}
                    sx={{
                      height: "auto",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: "1",
                      overflow: "hidden",
                    }}
                  >
                    {x?.filename || x?.fileAttach?.filename}
                  </Typography>
                  <CancelIcon
                    onClick={() => handleRemoveFileUpload(x, i)}
                    sx={{
                      color: "#0089ff",
                      display:
                        honorPinListCandidateDetail?.status === "A"
                          ? "none"
                          : "block",
                    }}
                  />
                </Box>
              )
              // <Typography key={i} sx={{ marginRight: '15px', cursor: 'pointer', position: 'relative', color: '#0089ff', transition: '.3s', ':after': { content: '""', position: 'absolute', width: '0%', height: '2px', left: '0px', bottom: '0px', transition: '.3s', backgroundColor: '#0089ff' }, ':hover': { color: '#0062b7', ':after': { width: '100%', backgroundColor: '#0062b7' } } }} onClick={() => FileDownload(x)}>{x?.filename}</Typography>
            )}
        </Box>
        <Divider sx={{ margin: "40px 0" }} />
        {honorPinListCandidateDetail?.status === "P" ||
        honorPinListCandidateDetail?.status === "A" ||
        honorPinListCandidateDetail?.status === "R" ? (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {/* <Box sx={{ display: 'flex', justifyContent: 'space-between' }}> */}
              <Typography sx={{ color: "#1fc64c" }}>
                รายชื่อคณะกรรมการสภาสถาบันพระปกเกล้า
              </Typography>
              {/* <Typography sx={{ color: '#1fc64c' }}>รายชื่อคณะกรรมการพิจารณาทั้งหมด {honorPinListCandidateDetail?.cerBoardDep?.length} ท่าน</Typography> */}
              {/* </Box> */}
              <Box sx={{ display: "flex" }}>
                {honorPinListCandidateDetail?.considerDateSecond && 
                  <>
                    {" "}
                    <Typography>
                      วันที่พิจารณา{" "}
                      <span style={{ color: "#1fc64c" }}>
                        {moment(honorPinListCandidateDetail?.considerDateSecond)
                          .add(543, "year")
                          .format("LL")}
                      </span>
                    </Typography>{" "}
                    <span style={{ margin: "0 5px" }}>|</span>
                  </>
                }
                {honorPinListCandidateDetail?.approveDateSecond && (
                  <Typography>
                    วันที่อนุมัติ{" "}
                    <span style={{ color: "#1fc64c" }}>
                      {moment(honorPinListCandidateDetail?.approveDateSecond)
                        .add(543, "year")
                        .format("LL")}
                    </span>
                  </Typography>
                )}
              </Box>
            </Box>
            <Grid
              container
              spacing={2}
              sx={{ marginTop: "15px", marginBottom: "30px" }}
            >
              <Grid item lg={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "10px",
                  }}
                >
                  <Typography variant="h6">นาย ชนะ นิติการ</Typography>
                  <Typography sx={{ color: "#1fc64c" }}>ประธาน</Typography>
                </Box>
              </Grid>
              <Grid item lg={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "10px",
                  }}
                >
                  <Typography variant="h6">นาย ทินพัฒน์ เรืองฤทธิ์</Typography>
                  <Typography sx={{ color: "#1fc64c" }}>รองประธาน</Typography>
                </Box>
              </Grid>
              <Grid item lg={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: "10px",
                  }}
                >
                  <Typography variant="h6">นาย ธวัช อธิการบดี</Typography>
                  <Typography sx={{ color: "#1fc64c" }}>กรรมการ</Typography>
                </Box>
              </Grid>
            </Grid>
            {honorPinListCandidateDetail?.remarkSecond && (
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ marginLeft: "10px" }}>เหตุผล:</Typography>
                <Typography>
                  {honorPinListCandidateDetail?.remarkSecond}
                </Typography>
              </Box>
            )}
            <Typography sx={{ marginBottom: "15px" }}></Typography>
            <Box sx={{ display: "flex" }}>
              <input
                type="file"
                ref={fileRef2}
                accept="image/*,.pdf,.doc,.docx,.xlsx,.csv,.xls"
                hidden
                onChange={handleFileUpload2}
              />
              <Button
                disabled={
                  honorPinListCandidateDetail?.status === "A" ? true : false
                }
                variant="outlined"
                onClick={() => fileRef2.current.click()}
                sx={{
                  height: "fit-content",
                  fontSize: "18px",
                  color: "#0fb123",
                  border: "1px solid #0fb123",
                  ":hover": { border: "1px solid #028b13" },
                }}
              >
                อัพโหลดไฟล์
              </Button>
              {files2
                ?.filter((y) => y.status === true)
                .map(
                  (x, i) => (
                    <Box
                      sx={{
                        width: "120px",
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #0089ff",
                        borderRadius: "15px",
                        padding: "3px 5px 3px 10px",
                        marginRight: "10px",
                        marginLeft: "10px",
                      }}
                    >
                      <Typography
                        onClick={() => FileDownload(x)}
                        sx={{
                          height: "auto",
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: "1",
                          overflow: "hidden",
                        }}
                      >
                        {x?.filename || x?.fileAttach?.filename}
                      </Typography>
                      <CancelIcon
                        onClick={() => handleRemoveFileUpload2(x, i)}
                        sx={{
                          color: "#0089ff",
                          display:
                            honorPinListCandidateDetail?.status === "A"
                              ? "none"
                              : "block",
                        }}
                      />
                    </Box>
                  )
                  // <Typography key={i} sx={{ marginRight: '15px', cursor: 'pointer', position: 'relative', color: '#0089ff', transition: '.3s', ':after': { content: '""', position: 'absolute', width: '0%', height: '2px', left: '0px', bottom: '0px', transition: '.3s', backgroundColor: '#0089ff' }, ':hover': { color: '#0062b7', ':after': { width: '100%', backgroundColor: '#0062b7' } } }} onClick={() => FileDownload(x)}>{x?.filename}</Typography>
                )}
            </Box>
            <Divider sx={{ margin: "40px 0" }} />{" "}
          </>
        ) : (
          ""
        )}
        <Typography
          sx={{
            marginBottom: "15px",
            marginTop: "40px",
            fontSize: "18px",
            color: "#0fb123",
          }}
        >
          วันที่คำนวณ{" "}
          {moment(honorPinListCandidateDetail?.calDate)
            .add(543, "year")
            .format("LL")}
        </Typography>
        <Box
          sx={{
            display:
              honorPinListCandidateDetail?.status === "R" ? "flex" : "none",
            alignItems: "center",
            marginTop: "23px",
          }}
        >
          <Typography
            sx={{
              marginRight: "10px",
              display:
                honorPinListCandidateDetail?.status === "R" ? "block" : "none",
            }}
          >
            วันที่รับในพิธี
          </Typography>
          <DesktopDatePicker
            label=""
            inputFormat="DD/MM/YYYY"
            value={date}
            // onViewChange={(newValue) }
            onChange={(newValue) => {
              setDate(newValue);
            }}
            renderInput={(params) => (
              <TextField
                size="small"
                color="success"
                sx={{ width: "200px" }}
                InputProps={params?.InputProps}
                inputRef={params?.inputRef}
                placeholder={params?.inputProps?.placeholder}
                value={
                  params?.inputProps?.value
                    ? params?.inputProps?.value?.substring(
                        0,
                        params?.inputProps?.value?.length - 4
                      ) +
                      `${
                        +params?.inputProps?.value?.substring(
                          params?.inputProps?.value?.length - 4
                        ) + 543
                      }`
                    : null
                }
                onChange={params?.inputProps?.onChange}
                type={params?.inputProps?.type}
              />
            )}
          />
        </Box>
        <Box
          sx={{
            color: "#4caf50",
            display:
              honorPinListCandidateDetail?.status === "R" ||
              selectionModel?.length < 1
                ? "none"
                : "flex",
            alignItems: "center",
            marginTop: "23px",
          }}
        >
          {/* <TextField id="outlined-basic" label="กรอกข้อมูลผู้เสนอ" value={text} onChange={e => setText(e.target.value)} variant="outlined" color="success" size='small' /> */}
          <Autocomplete
            size="small"
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            options={mPresent}
            getOptionLabel={(option) =>
              option?.presentFullName ? option?.presentFullName : ""
            }
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="เลือกผู้เสนอ"
                sx={{ width: "400px" }}
              />
            )}
          />
          <Button
            variant="contained"
            onClick={handleText}
            sx={{
              backgroundColor: "#1fc64c",
              "&:hover": { background: "#4caf50" },
              color: "#FFFF",
            }}
          >
            ตกลง
          </Button>
        </Box>

        {/* <Button variant='contained' onClick={handleGetHonorPinListCandidate} sx={{ backgroundColor: "#1fc64c", '&:hover': { background: '#4caf50' }, color: "#FFFF", padding: "7px 55px", marginTop: "23px" }} >ดึงฐานข้อมูลประวัติ</Button> */}
        <Box sx={{ height: 750, width: "100%", margin: "40px 0" }}>
          {honorPinListCandidateDetail?.status === "R" ? (
            <DataGrid
              rows={rows || []}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[5]}
              checkboxSelection
              disableSelectionOnClick
              disableColumnMenu
              isRowSelectable={(params) => params.row.isApproveSecond === true}
              experimentalFeatures={{ newEditingApi: true }}
              selectionModel={selectionModel}
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              initialState={{
                filter: {
                  filterModel: {
                    items: [],
                    quickFilterLogicOperator: GridLinkOperator.Or,
                  },
                },
              }}
              components={{ Toolbar: QuickSearchToolbar }}
            />
          ) : honorPinListCandidateDetail?.status === "A" ? (
            <DataGrid
              rows={rows || []}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[5]}
              checkboxSelection
              disableSelectionOnClick
              disableColumnMenu
              isRowSelectable={(params) => params.row.isApproveSecond === true}
              experimentalFeatures={{ newEditingApi: true }}
              selectionModel={selectionModel}
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              initialState={{
                filter: {
                  filterModel: {
                    items: [],
                    quickFilterLogicOperator: GridLinkOperator.Or,
                  },
                },
              }}
              components={{ Toolbar: QuickSearchToolbar }}
            />
          ) : honorPinListCandidateDetail?.status === "P" ? (
            <DataGrid
              rows={rows || []}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[5]}
              checkboxSelection
              isRowSelectable={(params) => params.row.isApproveFirst === true}
              disableSelectionOnClick
              disableColumnMenu
              experimentalFeatures={{ newEditingApi: true }}
              selectionModel={selectionModel}
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              initialState={{
                filter: {
                  filterModel: {
                    items: [],
                    quickFilterLogicOperator: GridLinkOperator.Or,
                  },
                },
              }}
              components={{ Toolbar: QuickSearchToolbar }}
            />
          ) : honorPinListCandidateDetail?.status === "D" ? (
            <DataGrid
              rows={rows || []}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[5]}
              checkboxSelection
              // isRowSelectable={(params) => params.row.status === true}
              disableSelectionOnClick
              disableColumnMenu
              experimentalFeatures={{ newEditingApi: true }}
              selectionModel={selectionModel}
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              initialState={{
                filter: {
                  filterModel: {
                    items: [],
                    quickFilterLogicOperator: GridLinkOperator.Or,
                  },
                },
              }}
              components={{ Toolbar: QuickSearchToolbar }}
            />
          ) : (
            <DataGrid
              rows={rows || []}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[5]}
              checkboxSelection
              // isRowSelectable={(params) => params.row.status === true}
              disableSelectionOnClick
              disableColumnMenu
              experimentalFeatures={{ newEditingApi: true }}
              selectionModel={selectionModel}
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              initialState={{
                filter: {
                  filterModel: {
                    items: [],
                    quickFilterLogicOperator: GridLinkOperator.Or,
                  },
                },
              }}
              components={{ Toolbar: QuickSearchToolbar }}
            />
          )}
        </Box>
      </Container>
      <Box
        sx={{
          position: "sticky",
          bottom: "0px",
          width: "100%",
          marginTop: "50px",
          zIndex: "100",
          background: "#fff",
        }}
      >
        <Divider sx={{ marginBottom: "10px" }} />
        <Container maxWidth="xl" sx={{ height: "auto" }}>
          <Box
            sx={{
              padding: "5px 20px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="outlined"
              component={NavLink}
              to={`/HonorPin`}
              sx={{
                width: "150px",
                height: "fit-content",
                fontSize: "18px",
                color: "#0fb123",
                border: "1px solid #0fb123",
                ":hover": { border: "1px solid #028b13" },
              }}
            >
              กลับ
            </Button>
            {honorPinListCandidateDetail?.status && (
              <Button
                onClick={() => handleSubmit("D")}
                variant="contained"
                type="submit"
                sx={{
                  width: "150px",
                  height: "fit-content",
                  fontSize: "18px",
                  marginLeft: "20px",
                  backgroundColor: "#0fb123",
                  ":hover": { backgroundColor: "#028b13" },
                }}
              >
                บันทึก
              </Button>
            )}
            {/* <Button onClick={() => handleSubmit('C')} variant='contained' type='submit' sx={{ width: '150px', height: 'fit-content', fontSize: '18px', marginLeft: '20px', backgroundColor: 'red', ':hover': { backgroundColor: 'red' } }}>ยกเลิก</Button> */}
            {/* {honorPinListCandidateDetail?.status && <Button onClick={() => setOpenPopup(true)} variant='contained' type='submit' sx={{ display: honorPinListCandidateDetail?.status !== 'A' && honorPinListCandidateDetail?.status !== 'P' ? 'block' : 'none', width: '150px', height: 'fit-content', fontSize: '18px', marginLeft: '20px', }}>อนุมัติขั้นต้น</Button>}
                        {honorPinListCandidateDetail?.status && <Button onClick={() => setOpenPopupS(true)} variant='contained' type='submit' sx={{ display: honorPinListCandidateDetail?.status !== 'D' && honorPinListCandidateDetail?.status !== 'A' ? 'block' : 'none', width: '150px', height: 'fit-content', fontSize: '18px', marginLeft: '20px', }}>อนุมัติ</Button>} */}
            {honorPinListCandidateDetail?.status && (
              <Button
                onClick={() => setOpenPopup(true)}
                variant="contained"
                type="submit"
                sx={{
                  display:
                    honorPinListCandidateDetail?.status !== "A" &&
                    honorPinListCandidateDetail?.status !== "P" &&
                    honorPinListCandidateDetail?.status !== "R"
                      ? "block"
                      : "none",
                  width: "150px",
                  height: "fit-content",
                  fontSize: "18px",
                  marginLeft: "20px",
                }}
              >
                พิจารณา
              </Button>
            )}
            {honorPinListCandidateDetail?.status && (
              <Button
                onClick={() => setOpenPopupS(true)}
                variant="contained"
                type="submit"
                sx={{
                  display:
                    honorPinListCandidateDetail?.status !== "D" &&
                    honorPinListCandidateDetail?.status !== "A" &&
                    honorPinListCandidateDetail?.status !== "R"
                      ? "block"
                      : "none",
                  width: "150px",
                  height: "fit-content",
                  fontSize: "18px",
                  marginLeft: "20px",
                }}
              >
                อนุมัติ
              </Button>
            )}
            {honorPinListCandidateDetail?.status && (
              <Button
                onClick={() => setOpenPopupSS(true)}
                variant="contained"
                type="submit"
                sx={{
                  display:
                    honorPinListCandidateDetail?.status === "A" &&
                    honorPinListCandidateDetail?.status !== "R"
                      ? "block"
                      : "none",
                  width: "150px",
                  height: "fit-content",
                  fontSize: "18px",
                  marginLeft: "20px",
                }}
              >
                เสร็จสิ้น
              </Button>
            )}
          </Box>
        </Container>
        <Dialog
          maxWidth="md"
          fullWidth={true}
          open={openPopup}
          onClose={handleClose}
        >
          <DialogTitle sx={{ fontSize: "2rem" }}>การพิจารณา</DialogTitle>
          <Divider sx={{ marginBottom: "20px" }} />
          <DialogContent sx={{ padding: "10px 60px" }}>
            <Box sx={{ height: "auto", width: "100%" }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  lg={2}
                  md={3}
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: { sm: "flex-end", xs: "flex-start" },
                  }}
                >
                  <Typography sx={{ marginRight: "15px" }}>
                    วันที่พิจารณา
                  </Typography>
                </Grid>

                <Grid item lg={9} md={9} xs={12}>
                  <DesktopDatePicker
                    label=""
                    inputFormat="DD/MM/YYYY"
                    value={startDate}
                    maxDate={endDate ? new Date(endDate) : ""}
                    // onViewChange={(newValue) }
                    onChange={(newValue) => {
                      setStartDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        color="success"
                        sx={{ width: "100%" }}
                        InputProps={params?.InputProps}
                        inputRef={params?.inputRef}
                        // placeholder={params?.inputProps?.placeholder}
                        // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                        // onChange={params?.inputProps?.onChange}
                        // type={params?.inputProps?.type}
                        inputProps={{
                          placeholder: params?.inputProps?.placeholder,
                          readOnly: true,
                          value: params?.inputProps?.value
                            ? params?.inputProps?.value?.substring(
                                0,
                                params?.inputProps?.value?.length - 4
                              ) +
                              `${
                                +params?.inputProps?.value?.substring(
                                  params?.inputProps?.value?.length - 4
                                ) + 543
                              }`
                            : null,
                          onChange: params?.inputProps?.onChange,
                          type: params?.inputProps?.type,
                        }}
                      />
                    )}
                  />
                </Grid>
                {/* <Grid item lg={2} md={3} xs={12} sx={{ display: 'flex', alignItems: 'start', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>
                                    <Typography sx={{ marginRight: '15px', }}>วันที่อนุมัติ</Typography>
                                </Grid>


                                <Grid item lg={9} md={9} xs={12}>

                                    <DesktopDatePicker
                                        label=""
                                        inputFormat="DD/MM/YYYY"
                                        value={endDate}

                                        minDate={new Date(startDate)}
                                        onChange={(newValue) => { setEndDate(newValue) }}
                                        renderInput={(params) => <TextField size='small' color="success" sx={{ width: '100%' }}
                                            InputProps={params?.InputProps}
                                            inputRef={params?.inputRef}
                                            // placeholder={params?.inputProps?.placeholder}
                                            // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                                            // onChange={params?.inputProps?.onChange}
                                            // type={params?.inputProps?.type}
                                            inputProps={
                                                {
                                                    placeholder: params?.inputProps?.placeholder,
                                                    readOnly: true,
                                                    value: params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null,
                                                    onChange: params?.inputProps?.onChange,
                                                    type: params?.inputProps?.type
                                                }

                                            }
                                        />}
                                    />

                                </Grid> */}

                <Grid
                  item
                  lg={2}
                  md={3}
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: { sm: "flex-end", xs: "flex-start" },
                  }}
                >
                  <Typography sx={{ marginRight: "15px" }}>เหตุผล</Typography>
                </Grid>

                <Grid item lg={9} md={9} xs={12}>
                  <TextField
                    label=""
                    size="small"
                    value={remark}
                    onChange={(e) => setRemark(e.target.value)}
                    multiline
                    rows={6}
                    variant="outlined"
                    color="success"
                    type="text"
                    sx={{ width: "100%" }}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button
              onClick={handleClose}
              variant="outlined"
              sx={{
                width: "150px",
                height: "fit-content",
                fontSize: "18px",
                color: "#0fb123",
                border: "1px solid #0fb123",
                ":hover": { border: "1px solid #028b13" },
              }}
            >
              ยกเลิก
            </Button>
            {startDate ? (
              <Button
                onClick={() => handleSubmit("P")}
                variant="contained"
                sx={{
                  width: "150px",
                  height: "fit-content",
                  fontSize: "18px",
                  marginLeft: "20px",
                  backgroundColor: "#0fb123",
                  ":hover": { backgroundColor: "#028b13" },
                }}
              >
                ยืนยัน
              </Button>
            ) : (
              ""
            )}
          </DialogActions>
        </Dialog>
        <Dialog
          maxWidth="md"
          fullWidth={true}
          open={openPopupS}
          onClose={handleCloseS}
        >
          <DialogTitle sx={{ fontSize: "2rem" }}>อนุมัติ</DialogTitle>
          <Divider sx={{ marginBottom: "20px" }} />
          <DialogContent sx={{ padding: "10px 60px" }}>
            <Box sx={{ height: "auto", width: "100%" }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  lg={2}
                  md={3}
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: { sm: "flex-end", xs: "flex-start" },
                  }}
                >
                  <Typography sx={{ marginRight: "15px" }}>
                    วันที่พิจารณา
                  </Typography>
                </Grid>

                <Grid item lg={9} md={9} xs={12}>
                  <DesktopDatePicker
                    label=""
                    inputFormat="DD/MM/YYYY"
                    value={startDateS}
                    maxDate={endDateS ? new Date(endDateS) : ""}
                    // onViewChange={(newValue) }
                    onChange={(newValue) => {
                      setStartDateS(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        color="success"
                        sx={{ width: "100%" }}
                        InputProps={params?.InputProps}
                        inputRef={params?.inputRef}
                        // placeholder={params?.inputProps?.placeholder}
                        // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                        // onChange={params?.inputProps?.onChange}
                        // type={params?.inputProps?.type}
                        inputProps={{
                          placeholder: params?.inputProps?.placeholder,
                          readOnly: true,
                          value: params?.inputProps?.value
                            ? params?.inputProps?.value?.substring(
                                0,
                                params?.inputProps?.value?.length - 4
                              ) +
                              `${
                                +params?.inputProps?.value?.substring(
                                  params?.inputProps?.value?.length - 4
                                ) + 543
                              }`
                            : null,
                          onChange: params?.inputProps?.onChange,
                          type: params?.inputProps?.type,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid
                  item
                  lg={2}
                  md={3}
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: { sm: "flex-end", xs: "flex-start" },
                  }}
                >
                  <Typography sx={{ marginRight: "15px" }}>
                    วันที่อนุมัติ
                  </Typography>
                </Grid>

                <Grid item lg={9} md={9} xs={12}>
                  <DesktopDatePicker
                    label=""
                    inputFormat="DD/MM/YYYY"
                    value={endDateS}
                    minDate={new Date(startDateS)}
                    onChange={(newValue) => {
                      setEndDateS(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        color="success"
                        sx={{ width: "100%" }}
                        InputProps={params?.InputProps}
                        inputRef={params?.inputRef}
                        // placeholder={params?.inputProps?.placeholder}
                        // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                        // onChange={params?.inputProps?.onChange}
                        // type={params?.inputProps?.type}
                        inputProps={{
                          placeholder: params?.inputProps?.placeholder,
                          readOnly: true,
                          value: params?.inputProps?.value
                            ? params?.inputProps?.value?.substring(
                                0,
                                params?.inputProps?.value?.length - 4
                              ) +
                              `${
                                +params?.inputProps?.value?.substring(
                                  params?.inputProps?.value?.length - 4
                                ) + 543
                              }`
                            : null,
                          onChange: params?.inputProps?.onChange,
                          type: params?.inputProps?.type,
                        }}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  item
                  lg={2}
                  md={3}
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: { sm: "flex-end", xs: "flex-start" },
                  }}
                >
                  <Typography sx={{ marginRight: "15px" }}>เหตุผล</Typography>
                </Grid>

                <Grid item lg={9} md={9} xs={12}>
                  <TextField
                    label=""
                    size="small"
                    value={remarkS}
                    onChange={(e) => setRemarkS(e.target.value)}
                    multiline
                    rows={6}
                    variant="outlined"
                    color="success"
                    type="text"
                    sx={{ width: "100%" }}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button
              onClick={handleCloseS}
              variant="outlined"
              sx={{
                width: "150px",
                height: "fit-content",
                fontSize: "18px",
                color: "#0fb123",
                border: "1px solid #0fb123",
                ":hover": { border: "1px solid #028b13" },
              }}
            >
              ยกเลิก
            </Button>
            {startDateS && endDateS ? (
              <Button
                onClick={() => handleSubmit("A")}
                variant="contained"
                sx={{
                  width: "150px",
                  height: "fit-content",
                  fontSize: "18px",
                  marginLeft: "20px",
                  backgroundColor: "#0fb123",
                  ":hover": { backgroundColor: "#028b13" },
                }}
              >
                ยืนยัน
              </Button>
            ) : (
              ""
            )}
          </DialogActions>
        </Dialog>
        <Dialog
          maxWidth="md"
          fullWidth={true}
          open={openPopupSS}
          onClose={handleCloseSS}
        >
          <DialogTitle sx={{ fontSize: "2rem" }}>เสร็จสิ้น</DialogTitle>
          <Divider sx={{ marginBottom: "20px" }} />
          <DialogContent sx={{ padding: "10px 60px" }}>
            <Box sx={{ height: "auto", width: "100%" }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  lg={2}
                  md={3}
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: { sm: "flex-end", xs: "flex-start" },
                  }}
                >
                  <Typography sx={{ marginRight: "15px" }}>
                    วันที่รับในพิธี
                  </Typography>
                </Grid>
                <Grid item lg={9} md={9} xs={12}>
                  <DesktopDatePicker
                    label=""
                    inputFormat="DD/MM/YYYY"
                    value={date}
                    onChange={(newValue) => {
                      setDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        color="success"
                        sx={{ width: "100%" }}
                        InputProps={params?.InputProps}
                        inputRef={params?.inputRef}
                        // placeholder={params?.inputProps?.placeholder}
                        // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                        // onChange={params?.inputProps?.onChange}
                        // type={params?.inputProps?.type}
                        inputProps={{
                          placeholder: params?.inputProps?.placeholder,
                          readOnly: true,
                          value: params?.inputProps?.value
                            ? params?.inputProps?.value?.substring(
                                0,
                                params?.inputProps?.value?.length - 4
                              ) +
                              `${
                                +params?.inputProps?.value?.substring(
                                  params?.inputProps?.value?.length - 4
                                ) + 543
                              }`
                            : null,
                          onChange: params?.inputProps?.onChange,
                          type: params?.inputProps?.type,
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button
              onClick={handleCloseSS}
              variant="outlined"
              sx={{
                width: "150px",
                height: "fit-content",
                fontSize: "18px",
                color: "#0fb123",
                border: "1px solid #0fb123",
                ":hover": { border: "1px solid #028b13" },
              }}
            >
              ยกเลิก
            </Button>
            {date ? (
              <Button
                onClick={() => handleSubmit("R")}
                variant="contained"
                sx={{
                  width: "150px",
                  height: "fit-content",
                  fontSize: "18px",
                  marginLeft: "20px",
                  backgroundColor: "#0fb123",
                  ":hover": { backgroundColor: "#028b13" },
                }}
              >
                ยืนยัน
              </Button>
            ) : (
              ""
            )}
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
}
