import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Container,
  Menu,
  Checkbox,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  IconButton,
  Autocomplete,
  Backdrop,
  CircularProgress,
  createFilterOptions,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import qs from "qs";
import Divider from "@mui/material/Divider";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SearchIcon from "@mui/icons-material/Search";
import TitleHead from "../../components/TitleHead";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import api from "../../api/api";
import mime from "mime";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import { selectCanActivate } from "../../store/modules/selector";
import { useSelector } from "react-redux";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#94949473",
    color: theme.palette.common,
    fontWeight: "500",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    /*  backgroundColor: '#F2F5F8', */
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(index, name, province, conf, year) {
  return { index, name, province, conf, year };
}

export default function Donate() {
  const checkPermission = useSelector(selectCanActivate);

  const navigate = useNavigate();
  const [listDonate, setListDonate] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const param = useParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openM = Boolean(anchorEl);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [keyword, setkeyword] = useState("");
  const [name, setName] = useState("");
  const [personalId, setPersonalId] = useState("");
  const [personalList, setPersonalList] = useState([]);
  const [mpreset, setMPresent] = useState([]);
  const [amount_txt, setAmount_txt] = useState([]);
  // const [amount_txtNumeric, setAmount_txtNumeric] = useState("");
  const [date_deposit, setDate_Deposit] = useState(new Date());
  const [minDate, setMinDate] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const [detail, setdetail] = useState({});
  const [present, setPresent] = useState('')
  const [money, setMoney] = useState('')
  const [openE, setOpene] = useState(false)
  const OPTIONS_LIMIT = 10;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
  });
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const getData = async () => {
    const params = qs.stringify({
      ...(keyword && { keyword }),
      ...(startDate && { startDate }),
      ...(endDate && { endDate }),
    });
    await api.get(`api/v1.0/donate/list?${params}`).then((res) => {
      setListDonate(res?.data?.result);
    });
  };

  const getPersonal = async () => {
    const params = qs.stringify({
      // ...(name && { name }),
    });
    await api.get(`api/v1.0/personal/search?${params}`).then((res) => {
      setPersonalList(res?.data?.result);
    });
  };

  useEffect(() => {
    getData();
    getPersonal();
  }, []);

  // useEffect(() => {
  //   getPersonal();
  // }, [name]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const profile = localStorage.getItem("profile");
  const roleID = JSON.parse(profile)?.profile?.roleID;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    // console.log(event);
    setPage(0);
  };
  const handleSubmit1 = async () => {
    const data = {
      donateID: detail.donateID,
      donateDate: detail.donateDate,
      amount: money,
      personalID: detail?.personalID,
      present: detail.present,
      status: true,
    };
    await api
      .post("api/v1.0/donate/update", data)
      .then((res) => {
        if (res?.data?.status) {
          Swal.fire("สำเร็จ", res?.data?.message, "success");
          setShowLoading(false);
          setOpene(false)
          
          
          getData();
        } else {
          getData();
          Swal.fire("gggg ", res?.data?.message, "error");
          setShowLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };







  const handleSubmit = async () => {
    const data = {
      donateID: null,
      donateDate: date_deposit,
      amount: parseInt(amount_txt?.value),
      personalID: personalId,
      present: present,
      status: true,
    };
    await api
      .post("api/v1.0/donate/createDonate", data)
      .then((res) => {
        if (res?.data?.status) {
          Swal.fire("สำเร็จ", res?.data?.message, "success");
          setShowLoading(false);
          setAmount_txt([]);
          setPersonalId("");
          setName("");
          setDate_Deposit("");
          getData();
        } else {
          getData();
          Swal.fire("ผิดพลาด", res?.data?.message, "error");
          setShowLoading(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    const getMasterPresent = async () => {
      const res = await api.get(`api/v1.0/master/present/list`)
      const data = await res.data
      if (data.status === true) {
        setMPresent(data.result)
      }

    }
    getMasterPresent()
  }, [])
  useEffect(()=>{
    setMoney(detail.amount)
  },[detail])

  return (
    <>
      <Container
        maxWidth="xl"
        sx={{ height: "auto", marginTop: "40px", marginBottom: "40px" }}
      >
        <TitleHead title="ผู้บริจาคเงิน" subTitle="ข้อมูลผู้บริจาคเงิน">
          {" "}
          {checkPermission.find((x) => x.menuNo === "M007" && x.isAdd) && (
            <Button
              onClick={() => setOpenDialog(true)}
              sx={{
                backgroundColor: "#1fc64c",
                "&:hover": { background: "#4caf50" },
                color: "#FFFF",
                paddingLeft: '16px',
                paddingRight: '16px',
                marginTop: "23px",
              }}
            >
              สร้างใหม่
            </Button>
          )}
        </TitleHead>

        <Box
          sx={{
            display: "flex",
            color: "#000",
            marginTop: "20px",
            marginLeft: "50px",
            justifyContent: "right",
          }}
        >
          <FormControl sx={{ m: 1, marginRight: 0 }} size="small">
            <DesktopDatePicker
              label=""
              // readOnly
              inputFormat="DD/MM/YYYY"
              value={startDate}
              name="lecDate"
              onChange={(newValue) => {
                setStartDate(moment(newValue).format("YYYY-MM-DD"));
                setMinDate(moment(newValue).format("YYYY-MM-DD"));
                setEndDate(null);
              }}
              renderInput={(params) => (
                // {
                // {console.log(params)}
                <TextField
                  size="small"
                  color="success"
                  sx={{ width: "100%" }}
                  InputProps={params?.InputProps}
                  inputRef={params?.inputRef}
                  inputProps={{
                    placeholder: params?.inputProps?.placeholder,
                    readOnly: true,
                    value: params?.inputProps?.value
                      ? params?.inputProps?.value?.substring(
                        0,
                        params?.inputProps?.value?.length - 4
                      ) +
                      `${+params?.inputProps?.value?.substring(
                        params?.inputProps?.value?.length - 4
                      ) + 543
                      }`
                      : null,
                    onChange: params?.inputProps?.onChange,
                    type: params?.inputProps?.type,
                  }}
                // placeholder={}
                // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                // onChange={params?.inputProps?.onChange}
                // type={params?.inputProps?.type}
                />
              )}

            // }
            />
          </FormControl>
          <FormControl sx={{ m: 1, marginRight: 0 }} size="small">
            <Typography sx={{ mt: 1 }}>~</Typography>
          </FormControl>
          <FormControl sx={{ m: 1, marginRight: 0 }} size="small">
            <DesktopDatePicker
              label=""
              inputFormat="DD/MM/YYYY"
              value={endDate}
              name="lecDate"
              disabled={minDate === null}
              minDate={minDate}
              onChange={(newValue) => {
                setEndDate(moment(newValue).format("YYYY-MM-DD"));
              }}
              renderInput={(params) => (
                <TextField
                  size="small"
                  color="success"
                  sx={{ width: "100%", marginLeft: "5px" }}
                  InputProps={params?.InputProps}
                  inputRef={params?.inputRef}
                  inputProps={{
                    placeholder: params?.inputProps?.placeholder,
                    readOnly: true,
                    value: params?.inputProps?.value
                      ? params?.inputProps?.value?.substring(
                        0,
                        params?.inputProps?.value?.length - 4
                      ) +
                      `${+params?.inputProps?.value?.substring(
                        params?.inputProps?.value?.length - 4
                      ) + 543
                      }`
                      : null,
                    onChange: params?.inputProps?.onChange,
                    type: params?.inputProps?.type,
                  }}
                // placeholder={params?.inputProps?.placeholder}
                // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                // onChange={params?.inputProps?.onChange}
                // type={params?.inputProps?.type}
                />
              )}
            />
          </FormControl>

          <Box sx={{ display: "flex", alignItems: "center", ml: 2 }}>
            <TextField
              id="outlined-basic"
              label="กรอกชื่อค้นหา"
              variant="outlined"
              color="success"
              size="small"
              value={keyword}
              onChange={(e) => setkeyword(e.target.value)}
            />
            <Button
              variant="contained"
              sx={{
                padding: "8px",
                marginLeft: "20px",
                backgroundColor: "#0fb123",
                ":hover": { backgroundColor: "#028b13" },
              }}
              onClick={() => {
                getData();
              }}
            >
              <SearchIcon />
            </Button>
          </Box>
        </Box>

        <Stack spacing={3}>
          <TableContainer sx={{ marginTop: "20px", marginBottom: "20px" }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    align="left"
                    sx={{ fontSize: "18px", fontWeight: "400" }}
                  >
                    #
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{ fontSize: "18px", fontWeight: "400" }}
                  >
                    วันที่บริจาค
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{ fontSize: "18px", fontWeight: "400" }}
                  >
                    คำนำหน้า
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{ fontSize: "18px", fontWeight: "400" }}
                  >
                    ชื่อ
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{ fontSize: "18px", fontWeight: "400" }}
                  >
                    นามสกุล
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{ fontSize: "18px", fontWeight: "400" }}
                  >
                    จำนวนเงินบริจาค
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{ fontSize: "18px", fontWeight: "400" }}
                  >
                    วันที่ใช้คำนวณเข็ม
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{ fontSize: "18px", fontWeight: "400" }}
                  >
                    ผู้เสนอ
                  </StyledTableCell>

                  <StyledTableCell
                    align="right"
                    sx={{ fontSize: "18px", fontWeight: "400" }}
                  >

                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listDonate
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, i) => (
                    <StyledTableRow
                      key={i}
                      /* onClick={() => navigate(`/ProjectSource/SearchPoliticalMinor`)} */
                      sx={{
                        fontSize: "18px",
                        cursor: "pointer",
                        "&:hover": {
                          background: "#f3f2f2",
                        },
                      }}
                    >
                      <StyledTableCell>{row?.seq}</StyledTableCell>

                      <StyledTableCell
                        align="center"
                        component="th"
                        scope="row"
                      >
                        {moment(row?.donateDate).add(543, "year").format("LL")}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row?.personal?.title?.titleNameTH}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row?.personal?.firstname}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row?.personal?.lastname}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row?.amount.toLocaleString("en-US")}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row?.balance == 0 ? moment(row?.modifiedDate).add(543, "year").format("LL") : '-'}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row?.present}
                      </StyledTableCell>
                      {checkPermission.find((x) => x.menuNo === "M007" && x.isEdit) && (
                      <TableCell align="right">
                        <Button
                          onClick={() => {
                            setdetail(row)
                            setOpene(true)
                            
                          }
                          }

                          variant='outlined'
                          color="success"
                          sx={{
                            borderColor: "#1fc64c",

                            "&:hover": { borderColor: '#1fc64c', color: 'susse' },
                            color: "#1fc64c",
                          }}
                        >
                          แก้ไข
                        </Button>
                      </TableCell>
                      )}
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{ margin: 20 }}
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={listDonate.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Stack>
        <Dialog
          open={openE}
          onClose={() => {
            setOpene(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">
            <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
              <Box flexGrow={1}>แก้ไขจำนวนเงิน</Box>
              <Box>
                <IconButton onClick={() => setOpene(false)}>
                  <Close />
                </IconButton>
              </Box>
            </Box>
            <Divider />
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Typography sx={{ mt: 2 }}>จำนวนเงิน</Typography>
              </Grid>
              <Grid item xs={9}>
                <NumericFormat
                  thousandSeparator={true}
                  // decimalScale={4}
                  // fixedDecimalScale={false}
                  size='small'
                  fullWidth
                  disabled={detail.balance <= 0}
                  InputProps={{
                    inputProps: { min: 0, maxLength: 11 },
                  }}
                  maxLength="10"
                  value={money}
                  customInput={TextField}
                  onKeyPress={(event) => {
                    if (event?.key === "-" || event?.key === "+") {
                      event.preventDefault();
                    }
                  }}
                  onValueChange={(e) => {
                    setMoney(e?.floatValue) 
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <Typography sx={{ mt: 2 }}>บาท</Typography>
              </Grid>

            </Grid>
          </DialogContent>
          <DialogActions sx={{ display: "block" }}>
            <Box textAlign="center">
              <Button
                onClick={() => setOpene(false)}
                variant="outlined"
                color="success"
                sx={{
                  color: "#4caf50",
                  padding: "7px 55px",
                  marginTop: "23px",
                  marginRight: "20px",
                }}
              >
                กลับ
              </Button>
              {detail.balance > 0 ? (
                <Button
                  sx={{
                    backgroundColor: "#1fc64c",
                    "&:hover": { background: "#4caf50" },
                    color: "#FFFF",
                    padding: "7px 55px",
                    marginTop: "23px",
                  }}
                  onClick={() => {
                    if (
                    
                      money > 0 
                     
                    ) {
                      setShowLoading(true);
                      handleSubmit1();
                      setOpenDialog(false);
                    } else {
                      setOpenDialog(false);
                      Swal.fire(
                        "ผิดพลาด",
                        "โปรดตรวจสอบข้อมูลการบริจาค",
                        "warning"
                      );
                    }
                  }}
                >
                  บันทึก
                </Button>
              ) : ''}
            </Box>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openDialog}
          onClose={() => {
            setOpenDialog(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">
            <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
              <Box flexGrow={1}>รายละเอียดผู้บริจาคเงิน</Box>
              <Box>
                <IconButton onClick={() => setOpenDialog(false)}>
                  <Close />
                </IconButton>
              </Box>
            </Box>
            <Divider />
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Typography sx={{ mt: 2 }}>วันที่บริจาค</Typography>
              </Grid>
              <Grid item xs={10}>
                <DesktopDatePicker
                  label=""
                  inputFormat="DD/MM/YYYY"
                  value={date_deposit}
                  name="DateDep"
                  maxDate={new Date()}
                  onChange={(newValue) => {
                    setDate_Deposit(moment(newValue).format("YYYY-MM-DD"));
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      readOnly={true}
                      color="success"
                      sx={{ width: "100%", marginLeft: "5px" }}
                      InputProps={params?.InputProps}
                      inputRef={params?.inputRef}
                      // placeholder={params?.inputProps?.placeholder}
                      // value={params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}`}
                      // onChange={params?.inputProps?.onChange}
                      // type={params?.inputProps?.type}
                      inputProps={{
                        placeholder: params?.inputProps?.placeholder,
                        readOnly: true,
                        value: params?.inputProps?.value
                          ? params?.inputProps?.value?.substring(
                            0,
                            params?.inputProps?.value?.length - 4
                          ) +
                          `${+params?.inputProps?.value?.substring(
                            params?.inputProps?.value?.length - 4
                          ) + 543
                          }`
                          : null,
                        onChange: params?.inputProps?.onChange,
                        type: params?.inputProps?.type,
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={2}>
                <Typography sx={{ mt: 2 }}>ชื่อ-สกุล</Typography>
              </Grid>
              <Grid item xs={10}>
                <Autocomplete
                  id="free-solo-demo"
                  // inputValue={name}
                  filterOptions={filterOptions}
                  value={name}
                  onChange={(event, newInputValue) => {
                    if (newInputValue?.label) {
                      setName(newInputValue?.label);
                      setPersonalId(newInputValue?.id);
                    } else {
                      setName("");
                      setPersonalId("");
                    }
                  }}
                  options={personalList?.map((option) => ({
                    id: option?.personalID,
                    label:
                      option?.title?.titleNameTH +
                      " " +
                      option?.firstname +
                      " " +
                      option?.lastname,
                  }))}
                  renderInput={(params) => (
                    <>
                      <TextField
                        required
                        placeholder="จำเป็นต้องระบุ"
                        {...params}
                        label=""
                        color="success"
                        sx={{ width: "100%" }}
                      />
                    </>
                  )}
                />
              </Grid>

              <Grid item xs={2}>
                <Typography sx={{ mt: 2 }}>จำนวนเงิน</Typography>
              </Grid>
              <Grid item xs={9}>
                <NumericFormat
                  thousandSeparator={true}
                  // decimalScale={4}
                  // fixedDecimalScale={false}
                  fullWidth
                  InputProps={{
                    inputProps: { min: 0, maxLength: 11 },
                  }}
                  maxLength="10"
                  value={amount_txt?.formattedValue}
                  customInput={TextField}
                  onKeyPress={(event) => {
                    if (event?.key === "-" || event?.key === "+") {
                      event.preventDefault();
                    }
                  }}
                  onValueChange={(e) => {
                    e?.floatValue > 0 ? setAmount_txt(e) : setAmount_txt([]);
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <Typography sx={{ mt: 2 }}>บาท</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography sx={{ mt: 2 }}>ผู้เสนอ</Typography>
              </Grid>
              <Grid item xs={10}>
                <Autocomplete
                  id="free-solo-demo"
                  // freeSolo
                  name="certificate"
                  inputValue={present}
                  onInputChange={(event, newInputValue) => {
                    setPresent(newInputValue)
                  }}
                  options={mpreset?.map((option) => option?.presentFullName + ' ' + option.presentShortName)}
                  renderInput={(params) => <TextField required placeholder='จำเป็นต้องระบุ' {...params} size='small' label="" color="success" sx={{ width: '100%' }} />}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ display: "block" }}>
            <Box textAlign="center">
              <Button
                onClick={() => setOpenDialog(false)}
                variant="outlined"
                color="success"
                sx={{
                  color: "#4caf50",
                  padding: "7px 55px",
                  marginTop: "23px",
                  marginRight: "20px",
                }}
              >
                กลับ
              </Button>
              <Button
                sx={{
                  backgroundColor: "#1fc64c",
                  "&:hover": { background: "#4caf50" },
                  color: "#FFFF",
                  padding: "7px 55px",
                  marginTop: "23px",
                }}
                onClick={() => {
                  if (
                    personalId !== "" &&
                    parseInt(amount_txt?.value) > 0 &&
                    date_deposit !== ""
                  ) {
                    setShowLoading(true);
                    handleSubmit();
                    setOpenDialog(false);
                  } else {
                    setOpenDialog(false);
                    Swal.fire(
                      "ผิดพลาด",
                      "โปรดตรวจสอบข้อมูลการบริจาค",
                      "warning"
                    );
                  }
                }}
              >
                บันทึก
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={showLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
}

const ThreeDotsMenu = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleMenuClick(Val1) {
    // console.log(Val1);
  }

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data } = props;
  return (
    <>
      <React.Fragment>
        <MoreHorizIcon
          onClick={handleClick}
          sx={{ cursor: "pointer", fontSize: "25px" }}
        />

        <Menu
          id="dense-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          // elevation={0}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{
            ".MuiPopover-paper": {
              boxShadow: "unset",
              border: "0.5px red",
              color: "#3a3a3ae6",
              backgroundColor: "#f9f9f9",
              borderRadius: "5px",
              // border: 'px solid',
            },
          }}
        >
          <MenuItem
            sx={{
              width: "120px",
              fontSize: "20px",
              color: "#00695C",
            }}
            component={NavLink}
            to={`/Lecturer/LecturerDetail/${data.uploadsurveyID}/views`}
          >
            {" "}
            <RemoveRedEyeIcon sx={{ marginRight: "15px" }} />
            ดู
          </MenuItem>
          <MenuItem
            sx={{
              width: "120px",
              fontSize: "20px",
              color: "#00695C",
            }}
            component={NavLink}
            to={`/Lecturer/LecturerEdit/${data.uploadsurveyID}`}
          >
            {" "}
            <EditRoundedIcon sx={{ marginRight: "15px" }} />
            เเก้ไข
          </MenuItem>
          {/* <MenuItem
            sx={{
              width: "120px",
              fontSize: "20px",
              color: "#00695C",
            }}
          >
            {" "}
            <DeleteForeverRoundedIcon sx={{ marginRight: "15px" }} /> ลบ
          </MenuItem> */}
        </Menu>
      </React.Fragment>
    </>
  );
};
