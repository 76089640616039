import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import TitleHead from "../../components/TitleHead";
import SearchIcon from "@mui/icons-material/Search";
import { useContext } from "react";
import ApiContext from "../../context/ApiProvider";
import { useEffect } from "react";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectCanActivate } from "../../store/modules/selector";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
export default function HonorPin() {
  const { getHonorPinList, getHonorPrrson } = useContext(ApiContext);
  const [honorPinList, setHonorPinList] = useState();
  const checkPermission = useSelector(selectCanActivate);
  const [searchText, setSearchText] = useState("");
  const [searchTextP, setSearchTextP] = useState("");
  const [honorPrrson, setHonorPrrson] = useState([]);
  const [body, setBody] = useState("");
  const [bodyP, setBodyP] = useState("");
  const [value, setValue] = React.useState(0);
  const [fillter, setFillter] = useState(1);
  const [fillterS, setFillterS] = useState("All");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const handleSelect = (event) => {
    setFillter(event.target.value);
  };
  const handleFilter = (event) => {
    setFillterS(event.target.value);
  };
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    setRowsPerPage(25);
    setPage(0);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    // console.log(event);
    setPage(0);
  };

  useEffect(() => {
    getHonorPinList(setHonorPinList, body);
  }, [body]);
  useEffect(() => {
    getHonorPrrson(setHonorPrrson, fillter, bodyP, fillterS);
  }, [fillter, bodyP, fillterS]);
  // console.log(honorCerList);
  const handleSearch = () => {
    setBody({
      keyword: searchText,
    });
  };
  const handleSearchP = () => {
    setBodyP({
      keyword: searchTextP,
      status: fillterS,
    });
  };
  return (
    <Container
      maxWidth="xl"
      sx={{ height: "auto", marginTop: "40px", marginBottom: "40px" }}
    >
      <TitleHead
        title="การรับเข็มกิตติคุณ"
        subTitle="ข้อมูลรายชื่อคณะกรรมการพิจารณา"
      >
        {/* <Button variant='contained' onClick={handleClickOpen} sx={{ height: 'fit-content', fontSize: '18px', backgroundColor: '#0fb123', ':hover': { backgroundColor: '#028b13' } }}>สร้างใหม่</Button> */}
        {checkPermission.find((x) => x.menuNo === "M004" && x.isAdd) && (
          <Button
            variant="contained"
            component={NavLink}
            to={`/HonorPin/AddHonorPin`}
            sx={{
              backgroundColor: "#1fc64c",
              "&:hover": { background: "#4caf50" },
              color: "#FFFF",
              padding: "7px",
              paddingLeft: "18px",
              paddingRight: "18px",
              marginTop: "23px",
            }}
          >
            สร้างใหม่
          </Button>
        )}
      </TitleHead>
      <Tabs
        value={value}
        onChange={handleChangeTab}
        aria-label="basic tabs example"
        sx={{
          color: "#000",
          "& .MuiTabs-indicator": {
            display: "none",
          },
          "& .MuiTabs-scrollButtons": {
            color: "#000",
          },
        }}
      >
        <Tab
          label="รอบคำนวณ"
          sx={{
            color: "#4caf50",
            borderBottom: "1px solid #bdbdbd",
            "&.Mui-selected": {
              color: "#000",
              borderTop: "1px solid #bdbdbd",
              borderRight: "1px solid #bdbdbd",
              borderLeft: "1px solid #bdbdbd",
              borderBottom: "1px solid #fff",
              borderRadius: "5px 5px 0 0",
            },
          }}
        />
        <Tab
          label="รายบุคคล"
          sx={{
            color: "#4caf50",
            borderBottom: "1px solid #bdbdbd",
            "&.Mui-selected": {
              color: "#000",
              borderTop: "1px solid #bdbdbd",
              borderRight: "1px solid #bdbdbd",
              borderLeft: "1px solid #bdbdbd",
              borderBottom: "1px solid #fff",
              borderRadius: "5px 5px 0 0",
            },
          }}
        />
        <Box
          sx={{
            width: "100%",
            borderBottom: "1px solid #bdbdbd",
          }}
        ></Box>
      </Tabs>

      <TabPanel value={value} index={0}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "20px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              id="outlined-basic"
              label="กรอกข้อมูลค้นหา"
              variant="outlined"
              color="success"
              size="small"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <Button
              variant="contained"
              sx={{
                padding: "7px",
                marginLeft: "20px",

                backgroundColor: "#0fb123",
                ":hover": { backgroundColor: "#028b13" },
              }}
              onClick={handleSearch}
            >
              <SearchIcon />
            </Button>
          </Box>
        </Box>
        <TableContainer>
          <Table
            sx={{
              minWidth: 650,
              borderCollapse: "inherit",
              borderSpacing: "0",
            }}
          >
            <TableHead sx={{ backgroundColor: "#e7e7e7" }}>
              <TableRow>
                <TableCell
                  sx={{
                    color: "#000",
                    border: "none",
                    fontSize: "14px",
                    fontWeight: "700",
                  }}
                  align="left"
                >
                  ปี
                </TableCell>
                <TableCell
                  sx={{
                    color: "#000",
                    border: "none",
                    fontSize: "14px",
                    fontWeight: "700",
                  }}
                  align="left"
                >
                  คณะกรรมการพิจารณา
                </TableCell>
                <TableCell
                  sx={{
                    color: "#000",
                    border: "none",
                    fontSize: "14px",
                    fontWeight: "700",
                  }}
                  align="left"
                >
                  ประธาน
                </TableCell>
                <TableCell
                  sx={{
                    color: "#000",
                    border: "none",
                    fontSize: "14px",
                    fontWeight: "700",
                  }}
                  align="center"
                >
                  วันที่พิจารณา
                </TableCell>
                <TableCell
                  sx={{
                    color: "#000",
                    border: "none",
                    fontSize: "14px",
                    fontWeight: "700",
                  }}
                  align="center"
                >
                  วันที่สภาอนุมัติ
                </TableCell>
                <TableCell
                  sx={{
                    color: "#000",
                    border: "none",
                    fontSize: "14px",
                    fontWeight: "700",
                  }}
                  align="center"
                >
                  สถานะ
                </TableCell>
                <TableCell
                  sx={{ color: "#000", border: "none" }}
                  align="center"
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {honorPinList
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, i) => (
                  <>
                    <TableRowC key={i} item={item} />
                  </>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={honorPinList?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <FormControl sx={{ marginRight: "16px", width: "100px" }}>
            <InputLabel>สถานะ</InputLabel>
            <Select
              // open={open}
              // onClose={handleClose}
              // onOpen={handleOpen}
              // defaultValue='All'
              value={fillterS}
              onChange={handleFilter}
              label="สถานะ"
              size="small"
            >
              <MenuItem value="All">ทั้งหมด</MenuItem>
              <MenuItem value="D">อยู่ระหว่างดำเนินการ</MenuItem>
              <MenuItem value="P">ผ่านการพิจารณา</MenuItem>
              <MenuItem value="A">สภาอนุมัติ</MenuItem>
              <MenuItem value="R">เสร็จสิ้น</MenuItem>
              {/* <MenuItem value={5}>ค้นหาจากสถานะ</MenuItem> */}
            </Select>
          </FormControl>
          <Box sx={{ display: "flex" }}>
            <FormControl sx={{ marginRight: "16px", width: "100px" }}>
              {/* <InputLabel>กรอกข้อมูลค้นหา</InputLabel> */}
              <Select
                // open={open}
                // onClose={handleClose}
                // onOpen={handleOpen}
                defaultValue={1}
                value={fillter}
                onChange={handleSelect}
                // label="Fillter"
                size="small"
              >
                {/* <MenuItem value={1}>ทั้งหมด</MenuItem> */}
                <MenuItem value={1}>ชื่อ-สกุล</MenuItem>
                <MenuItem value={2}>คุณสมบัติ</MenuItem>
                <MenuItem value={3}>ปีที่ได้รับ</MenuItem>
                <MenuItem value={4}>ชั้นเข็ม</MenuItem>
                {/* <MenuItem value={5}>ค้นหาจากสถานะ</MenuItem> */}
              </Select>
            </FormControl>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                id="outlined-basic"
                label="กรอกข้อมูลค้นหา"
                variant="outlined"
                color="success"
                size="small"
                value={searchTextP}
                onChange={(e) => setSearchTextP(e.target.value)}
              />
              <Button
                variant="contained"
                sx={{
                  padding: "7px",
                  marginLeft: "20px",
                  backgroundColor: "#0fb123",
                  ":hover": { backgroundColor: "#028b13" },
                }}
                onClick={handleSearchP}
              >
                <SearchIcon />
              </Button>
            </Box>
          </Box>
        </Box>
        <TableContainer>
          <Table
            sx={{
              minWidth: 650,
              borderCollapse: "inherit",
              borderSpacing: "0",
            }}
          >
            <TableHead sx={{ backgroundColor: "#e7e7e7" }}>
              <TableRow>
                <TableCell
                  sx={{
                    color: "#000",
                    border: "none",
                    fontSize: "14px",
                    fontWeight: "700",
                    width: "10%",
                  }}
                  align="left"
                >
                  คำนำหน้าชื่อ
                </TableCell>
                <TableCell
                  sx={{
                    color: "#000",
                    border: "none",
                    fontSize: "14px",
                    fontWeight: "700",
                    width: "10%",
                  }}
                  align="left"
                >
                  ชื่อ
                </TableCell>
                <TableCell
                  sx={{
                    color: "#000",
                    border: "none",
                    fontSize: "14px",
                    fontWeight: "700",
                    width: "10%",
                  }}
                  align="left"
                >
                  นามสกุล
                </TableCell>
                <TableCell
                  sx={{
                    color: "#000",
                    border: "none",
                    fontSize: "14px",
                    fontWeight: "700",
                    width: "25%",
                  }}
                  align="center"
                >
                  คุณสมบัติ
                </TableCell>
                <TableCell
                  sx={{
                    color: "#000",
                    border: "none",
                    fontSize: "14px",
                    fontWeight: "700",
                    width: "10%",
                  }}
                  align="center"
                >
                  ปีที่ได้รับ
                </TableCell>
                {/* <TableCell
                  sx={{
                    color: "#000",
                    border: "none",
                    fontSize: "18px",
                    fontWeight: "500",
                    width: '10%'
                  }}
                  align="center"
                >
                  ระยะเวลาดำรงตำเเหน่ง
                </TableCell> */}
                <TableCell
                  sx={{
                    color: "#000",
                    border: "none",
                    fontSize: "14px",
                    fontWeight: "700",
                    width: "10%",
                  }}
                  align="center"
                >
                  ชั้น-เข็มใหม่
                </TableCell>
                <TableCell
                  sx={{
                    color: "#000",
                    border: "none",
                    fontSize: "14px",
                    fontWeight: "700",
                    width: "10%",
                  }}
                  align="center"
                >
                  สถานะ
                </TableCell>
                <TableCell
                  sx={{ color: "#000", border: "none", width: "5%" }}
                  align="center"
                ></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {honorPrrson
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, i) => (
                  <>
                    <TableRowR key={i} item={item} />
                  </>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={honorPrrson?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TabPanel>
    </Container>
  );
}

export function TableRowC({ item }) {
  // console.log(item)
  return (
    <TableRow>
      <TableCell
        sx={{ color: "#000", width: "5%", fontSize: "14px", }}
        align="left"
      >
        {+item?.createDate?.substring(0, 4) + 543}
      </TableCell>
      <TableCell
        sx={{ color: "#000", width: "20%", fontSize: "14px" }}
        align="left"
      >
        {item?.boardDepName}
      </TableCell>
      <TableCell
        sx={{ color: "#000", width: "20%", fontSize: "14px" }}
        align="left"
      >{`${item?.cerBoardDep[0]?.title || ""} ${
        item?.cerBoardDep[0]?.firstname || ""
      } ${item?.cerBoardDep[0]?.lastname || ""}`}</TableCell>
      {/* <TableCell
        sx={{ color: "#000", width: "20%", fontSize: "16px" }}
        align="left"
      >{`${item?.cerBoardDep[0]?.title} ${item?.cerBoardDep[0]?.firstname} ${item?.cerBoardDep[0]?.lastname}`}</TableCell> */}
      <TableCell
        sx={{ color: "#000", width: "15%", fontSize: "14px" }}
        align="center"
      >
        {moment(item?.considerDateFirst).add(543, "year").format("LL") ===
        "Invalid date"
          ? null
          : moment(item?.considerDateFirst).add(543, "year").format("LL")}
        {/* {moment(item?.approveDateFirst).add(543, 'year').format("DD/MM/yyyy") === 'Invalid date' ? null : moment(item?.approveDateFirst).add(543, 'year').format("DD/MM/yyyy")} */}
      </TableCell>
      <TableCell
        sx={{ color: "#000", width: "15%", fontSize: "14px" }}
        align="center"
      >
        {moment(item?.approveDateSecond).add(543, "year").format("LL") ===
        "Invalid date"
          ? null
          : moment(item?.approveDateSecond).add(543, "year").format("LL")}
        {/* {moment(item?.approveDateSecond).add(543, 'year').format("DD/MM/yyyy") === 'Invalid date' ? null : moment(item?.approveDateSecond).add(543, 'year').format("DD/MM/yyyy")} */}
      </TableCell>
      <TableCell
        sx={{
          color:
            item?.status === "R"
              ? "#028b13"
              : item?.status === "D"
              ? "#000"
              : item?.status === "C"
              ? "red"
              : "#000",
          width: "20%",
          fontSize: "14px",
        }}
        align="center"
      >
        {item?.status === "A"
          ? "อนุมัติ"
          : item?.status === "D"
          ? "อยู่ระหว่างดำเนินการ"
          : item?.status === "C"
          ? "ยกเลิก"
          : item?.status === "P"
          ? "ผ่านพิจารณา"
          : item?.status === "R"
          ? "เสร็จสิ้น"
          : ""}
      </TableCell>
      <TableCell
        sx={{ color: "#000", width: "5%", fontSize: "14px" }}
        align="left"
      >
        <NavLink
          to={`/HonorPin/DetailHonorPin/${item?.tscHeadCerID}`}
          style={{ textDecoration: "none" }}
        >
          <Button
            // component={NavLink}
            // to={`/${row?.personalID}`}
            // state={{ add: true }}
            sx={{
              backgroundColor: "#1fc64c",
              "&:hover": { background: "#4caf50" },
              color: "#fff",
            }}
          >
            ดู
          </Button>
          {/* <MoreHorizIcon /> */}
        </NavLink>
      </TableCell>
    </TableRow>
  );
}
export function TableRowR({ item }) {
  // console.log(item)
  return (
    <TableRow>
      <TableCell sx={{ color: "#000", fontSize: "14px" }} align="left">
        {item?.personal?.title?.titleNameTH}
      </TableCell>
      <TableCell sx={{ color: "#000", fontSize: "14px" }} align="left">
        {item?.personal?.firstname}
      </TableCell>
      <TableCell sx={{ color: "#000", fontSize: "14px" }} align="left">
        {item?.personal?.lastname}
      </TableCell>
      <TableCell sx={{ color: "#000", fontSize: "14px" }} align="center">
        {item?.positionName}
      </TableCell>
      <TableCell sx={{ color: "#000", fontSize: "14px" }} align="center">
        {item?.cerHead?.receiveDate
          ? +moment(item?.cerHead?.receiveDate)
              .format("yyyy-MM-DD")
              .substring(0, 4) + 543
          : null}
      </TableCell>
      {/* <TableCell
        sx={{ color: item?.status === "A" ? '#028b13' : item?.status === "D" ? '#000' : item?.status === "C" ? 'red' : "#000", fontSize: "16px" }}
        align="center"
      >

      </TableCell> */}
      <TableCell sx={{ fontSize: "14px" }} align="center">
        {`ชั้นที่ ${item?.rank}`}
      </TableCell>
      <TableCell
        sx={{
          color:
            item?.cerHead?.status === "R"
              ? "#028b13"
              : item?.cerHead?.status === "D"
              ? "#000"
              : item?.cerHead?.status === "C"
              ? "red"
              : "#000",
          fontSize: "14px",
        }}
        align="center"
      >
        {item?.cerHead?.status === "A"
          ? "อนุมัติ"
          : item?.cerHead?.status === "D"
          ? "อยู่ระหว่างดำเนินการ"
          : item?.cerHead?.status === "C"
          ? "ยกเลิก"
          : item?.cerHead?.status === "P"
          ? "ผ่านพิจารณา"
          : item?.cerHead?.status === "R"
          ? "เสร็จสิ้น"
          : ""}
      </TableCell>
      <TableCell sx={{ color: "#000", fontSize: "14px" }} align="left">
        <NavLink
          to={`/HonorPin/DetailHonorPin/${item?.tscHeadCerID}`}
          style={{ textDecoration: "none" }}
        >
          <Button
            // component={NavLink}
            // to={`/${row?.personalID}`}
            // state={{ add: true }}
            sx={{
              backgroundColor: "#1fc64c",
              "&:hover": { background: "#4caf50" },
              color: "#fff",
            }}
          >
            ดู
          </Button>
          {/* <MoreHorizIcon /> */}
        </NavLink>
      </TableCell>
    </TableRow>
  );
}
