import React, { useState, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  ImageListItem,
  ImageList,
  IconButton,
  Autocomplete,
  createFilterOptions,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { useEffect } from "react";
import moment from "moment";
import api from "../../api/api";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
  getErrorMessage,
  isError,
  personalFieldResolver,
} from "../../libs/utils/form";
import * as yup from "yup";
/* import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"; */
import GridField from "../../components/GridTextField";
import SearchIcon from "@mui/icons-material/Search";
import qs from "qs";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useFormik } from "formik";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

export default function ManageLecturer(props) {
  const navigate = useNavigate();
  let { id, views } = useParams();
  const data_params = { id: id };
  const [data_detail, setDataDetail] = useState([]);

  const [personalId, setPersonalId] = useState("");
  const [personalList, setPersonalList] = useState([]);
  const [course, setCourse] = useState([]);
  const [coursev, setCoursev] = useState({});
  // console.log(course);
  const [img, setImg] = useState("");
  const OPTIONS_LIMIT = 10;
  const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT,
  });

  const getPersonal = async () => {
    const params = qs.stringify({
      // ...(name && { name }),
    });
    await api.get(`api/v1.0/personal/search?${params}`).then((res) => {
      setPersonalList(res?.data?.result);
    });
  };
  const getcourse = async () => {
    const resss = await api.get("api/v1.0/master/course/list");
    setCourse(resss?.data?.result);
  };
  const getDetail = async (id) => {
    const res = await api.get(`api/v1.0/lecturer/list?lecID=${id}`);

    if (res?.data?.status) {
      res?.data?.result?.map((x) => {
        setDataDetail(x);
      });
    }
  };

  useEffect(() => {
    getcourse();
  }, []);

  useEffect(() => {
    if (id) {
      getDetail(id);
    }
    getPersonal();
  }, []);

  const validationSchema = yup.object({
    certificate: yup.string().required("กรุณากรอกหลักสูตร."),
    name: yup.string().required("กรุณาใส่ชื่อ."),
    course: yup.string().required("กรุณากรอกวิชาที่สอน."),
    lecDate: yup.string().required("กรุณากรอกวันที่สอน."),
    time: yup.string().required("กรุณากรอกเวลา."),
  });
  // console.log(data_detail?.certificate);
  let formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: {
      lecID: data_detail?.lecID ? data_detail?.lecID : null,
      certificate: data_detail?.certificate ? data_detail?.certificate : "",
      name:
        data_detail?.personal?.title?.titleNameTH &&
        data_detail?.personal?.firstname &&
        data_detail?.personal?.lastname
          ? data_detail?.personal?.title?.titleNameTH +
            " " +
            data_detail?.personal?.firstname +
            " " +
            data_detail?.personal?.lastname
          : "",
      gen: data_detail?.gen ? data_detail?.gen : "",
      course: data_detail?.course ? data_detail?.course : "",
      source: data_detail?.source ? data_detail?.source : "",
      time: data_detail?.time ? data_detail?.time : "",
      lecDate: data_detail?.lecDate ? data_detail?.lecDate : "",
      personalID: data_detail?.personalID ? data_detail?.personalID : null,
      ramark: data_detail?.ramark ? data_detail?.ramark : "",
    },
    onSubmit: async (values) => {
      const data = {
        lecID: values.lecID,
        personalID: values.personalID,
        lecDate: values.lecDate,
        certificate: values.certificate,
        course: values.course,
        gen: values.gen,
        ramark: values.ramark,
        source: values.source,
        time: values.time,
      };
      await api.post("api/v1.0/lecturer/createLecturer", data).then((res) => {
        if (res?.data?.status) {
          Swal.fire("สำเร็จ", res?.data?.message, "success");
          navigate("/Lecturer");
        } else {
          Swal.fire("ไม่สำเร็จ", res?.data?.message, "error");
        }
      });
    },
  });

  return (
    <Box sx={{ width: "100%", overflow: "hidden" }}>
      <Box
        sx={{
          display: "flex",
          color: "#000",
          marginTop: "50px",
          marginLeft: "50px",
        }}
      >
        <Typography variant="h4">เพิ่มข้อมูลวิทยากร</Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          color: "#949494",
          marginTop: "10px",
          marginLeft: "50px",
        }}
      >
        <Typography>ข้อมูลทะเบียนประวัตส่วนบุคคล</Typography>
      </Box>
      <Divider
        sx={{ width: "1500px", marginLeft: "50px", marginTop: "10px" }}
      />
      <Box
        sx={{
          display: "flex",
          color: "#000",
          marginTop: "40px",
          marginLeft: "50px",
        }}
      >
        <Typography variant="h6">ข้อมูลวิทยากร</Typography>
      </Box>

      <Grid container spacing={2} sx={{ mr: 15, mt: 1, p: 5 }}>
        <Grid item lg={12} md={12} xs={12}>
          <Grid container>
            <Grid
              item
              md={2}
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: { md: "flex-end", sm: "flex-start" },
              }}
            >
              <Typography sx={{ marginRight: "15px" }}>หลักสูตร</Typography>
            </Grid>
            <Grid item md={6} xs={12} sx={{ position: "relative" }}>
              {" "}
              {/* <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">หลักสูตร</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formik?.values?.certificate}
                  label="หลักสูตร"
                  onChange={(e, item) => {

                    
                  
                      formik.setFieldValue("certificate", item.props.children);
                     
                  
                 } }
                 
                  sx={{ width: "100%" }}
                  disabled={views === "views"}
                >
                  {course.map((e) => (
                    <MenuItem value={e.courseID}>{`${e.courseFullName}(${e.courseShortName})`}</MenuItem>))

                  }
                </Select>
              </FormControl> */}
              <Autocomplete
                id="free-solo-demo"
                // freeSolo
                disabled={views === "views"}
                name="certificate"
                value={formik?.values?.certificate}
                onChange={(event, newInputValue) => {
                  formik.setFieldValue("certificate", newInputValue);
                }}
                options={course?.map(
                  (option) =>
                    option?.courseFullName + " " + option.courseShortName
                )}
                renderInput={(params) => (
                  <TextField
                    disabled={views === "views"}
                    required
                    placeholder="จำเป็นต้องระบุ"
                    error={isError(`certificate`, formik)}
                    helperText={getErrorMessage(`certificate`, formik)}
                    {...params}
                    size="small"
                    label=""
                    color="success"
                    sx={{ width: "100%" }}
                  />
                )}
              />
              {/* <TextField
                size="small"
                name="certificate"
                value={formik.values.certificate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={isError(`certificate`, formik)}
                helperText={getErrorMessage(`certificate`, formik)}
                label=""
                variant="outlined"
                color="success"
                sx={{ width: "100%" }}
                disabled={views === "views"}
              /> */}
            </Grid>
            <Grid
              item
              md={2}
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: { md: "flex-end", sm: "flex-start" },
              }}
            >
              <Typography sx={{ marginRight: "15px" }}>รุ่น</Typography>
            </Grid>
            <Grid
              item
              md={2}
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: { md: "flex-end", sm: "flex-start" },
              }}
            >
              <TextField
                disabled={views === "views"}
                name="gen"
                variant="outlined"
                placeholder="จำเป็นต้องระบุ"
                size="small"
                label=""
                value={formik.values.gen}
                error={isError(`gen`, formik)}
                helperText={getErrorMessage(`gen`, formik)}
                color="success"
                sx={{ width: "100%" }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={12} md={12} xs={12}>
          <Grid container>
            <Grid
              item
              md={2}
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: { md: "flex-end", sm: "flex-start" },
              }}
            >
              <Typography sx={{ marginRight: "15px" }}>ชื่อ-สกุล</Typography>
            </Grid>
            <Grid item md={6} xs={12} sx={{ position: "relative" }}>
              <Autocomplete
                filterOptions={filterOptions}
                id="free-solo-demo"
                name="name"
                size="small"
                value={formik.values.name}
                onBlur={formik.handleBlur}
                disabled={views === "views"}
                onChange={(event, newInputValue) => {
                  if (newInputValue?.label) {
                    formik.setFieldValue("name", newInputValue?.label);
                    formik.setFieldValue("personalID", newInputValue?.id);
                  } else {
                    formik.setFieldValue("name", "");
                    formik.setFieldValue("personalID", "");
                  }
                }}
                options={personalList?.map((option) => ({
                  id: option?.personalID,
                  label:
                    option?.title?.titleNameTH +
                    " " +
                    option?.firstname +
                    " " +
                    option?.lastname,
                }))}
                renderInput={(params) => (
                  <TextField
                    required
                    placeholder={"จำเป็นต้องระบุ"}
                    size="small"
                    {...params}
                    label=""
                    color="success"
                    sx={{ width: "100%" }}
                    error={isError(`name`, formik)}
                    helperText={getErrorMessage(`name`, formik)}
                    // helperText={getErrorMessage(`name`, formik)}
                    disabled={views === "views"}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        <GridField text="วิชาที่สอน">
          <TextField
            name="course"
            value={formik.values.course}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={isError(`course`, formik)}
            helperText={getErrorMessage(`course`, formik)}
            placeholder=""
            size="small"
            label=""
            variant="outlined"
            color="success"
            sx={{ width: "100%" }}
            disabled={views === "views"}
          />
        </GridField>
        <GridField text="วันที่สอน">
          <FormControl
            required
            sx={{
              width: "100%",
              display: "flex",
              justifyItems: "center",
            }}
            variant="outlined"
            color="success"
            disabled={views === "views"}
          >
            <DesktopDatePicker
              label=""
              readOnly={views === "views"}
              inputFormat="DD/MM/YYYY"
              value={formik.values.lecDate}
              name="lecDate"
              onChange={(newValue) => {
                formik.setFieldValue(
                  "lecDate",
                  moment(newValue).format("YYYY-MM-DD")
                );
              }}
              renderInput={(params) => (
                <TextField
                  size="small"
                  sx={{ width: "100%" }}
                  // {...params}
                  error={isError(`lecDate`, formik)}
                  helperText={getErrorMessage(`lecDate`, formik)}
                  disabled={views === "views"}
                  InputProps={params?.InputProps}
                  inputRef={params?.inputRef}
                  // placeholder={params?.inputProps?.placeholder}
                  // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                  // onChange={params?.inputProps?.onChange}
                  // type={params?.inputProps?.type}
                  inputProps={{
                    placeholder: params?.inputProps?.placeholder,
                    readOnly: true,
                    value: params?.inputProps?.value
                      ? params?.inputProps?.value?.substring(
                          0,
                          params?.inputProps?.value?.length - 4
                        ) +
                        `${
                          +params?.inputProps?.value?.substring(
                            params?.inputProps?.value?.length - 4
                          ) + 543
                        }`
                      : null,
                    onChange: params?.inputProps?.onChange,
                    type: params?.inputProps?.type,
                  }}
                />
              )}
            />
          </FormControl>
        </GridField>
        <GridField text="เวลา">
          <TextField
            name="time"
            value={formik.values.time}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={isError(`time`, formik)}
            helperText={getErrorMessage(`time`, formik)}
            size="small"
            placeholder="กรุณากรอกเวลา"
            required
            label=""
            variant="outlined"
            color="success"
            sx={{ width: "100%" }}
            disabled={views === "views"}
          />
        </GridField>

        <GridField text="ที่มาของข้อมูล">
          <TextField
            size="small"
            name="source"
            value={formik.values.source}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={isError(`source`, formik)}
            helperText={getErrorMessage(`source`, formik)}
            placeholder=""
            required
            label=""
            variant="outlined"
            color="success"
            sx={{ width: "100%" }}
            disabled={views === "views"}
          />
        </GridField>
        <GridField text="หมายเหตุ">
          <TextField
            name="ramark"
            value={formik.values.ramark}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={isError(`ramark`, formik)}
            helperText={getErrorMessage(`ramark`, formik)}
            multiline
            size="small"
            placeholder=""
            label=""
            variant="outlined"
            color="success"
            sx={{ width: "100%" }}
            disabled={views === "views"}
          />
        </GridField>
      </Grid>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          paddingBottom: "100px",
        }}
      >
        <Button
          onClick={() => navigate("/Lecturer")}
          variant="outlined"
          color="success"
          sx={{
            marginRight: "20px",
            "&:hover": { background: "#fff5" },
            color: "#4caf50",
            padding: "7px 55px",
            marginTop: "23px",
          }}
        >
          กลับ
        </Button>
        {views !== "views" && (
          <Button
            onClick={() => formik.handleSubmit()}
            sx={{
              backgroundColor: "#4caf50",
              "&:hover": { background: "#1fc64c" },
              color: "#FFFF",
              padding: "7px 55px",
              marginTop: "23px",
            }}
          >
            บันทึก
          </Button>
        )}
      </Box>
    </Box>
  );
}
