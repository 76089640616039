
import React, { useEffect, useState } from 'react'
import { Autocomplete, Box, Button, Container, FormControl, Stack, Tab, Table, TableBody, TableContainer, TableHead, TableRow, TextField, Typography, Menu, MenuItem} from '@mui/material'
import { NavLink, useLocation, useParams } from "react-router-dom";
import TitleHead from "../../components/TitleHead";
import SearchIcon from "@mui/icons-material/Search";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import CropPortraitIcon from '@mui/icons-material/CropPortrait';
import CropLandscapeIcon from '@mui/icons-material/CropLandscape';
import moment from 'moment/moment';
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import api from '../../api/api'
import mime from "mime";
import { saveAs } from 'file-saver';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#94949473",
        color: theme.palette.common,
        fontWeight: "500",
        fontSize: 14,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 10,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        /*  backgroundColor: '#F2F5F8', */
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));
export default function Report10() {
    const [startDate, setStartDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [minDate, setMinDate] = useState();
    const [endDate, setEndDate] = useState(moment(new Date()).format('YYYY-MM-DD'));
    const [listreport, setReport] = useState([]);
    const [detere, setDatere] = useState([])
    const [value, setValue] = useState('')
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openM = Boolean(anchorEl);
    const handleClick5 = (event) => {
        setAnchorEl(event.currentTarget);
      };
    const handleClick = () => {
        api.get(`api/v1.0/report/Report10/list?startyear=${value.calDate}&endyear=${value.calDate}`).then((res) => {
            // console.log(res);
            setReport(res?.data?.result)
        })
    }
    const handleClose = () => {
       
        setAnchorEl(null);
      };
    useEffect(() => {
        api.get(`api/v1.0/report/reportdate/list?cerType=1`).then((res) => {
            setDatere(res.data.result);
            setValue(res.data.result[0])
        })
    }, [])
    useEffect(() => {
        api.get(`api/v1.0/report/Report10/list?startyear=${value.calDate}&endyear=${value.calDate}`).then((res) => {
            // console.log(res?.data?.result);
            setReport(res?.data?.result)
        })
    }, [startDate, setReport])
    return (
        <Container maxWidth='xl' sx={{ height: 'auto', marginTop: '40px', marginBottom: '40px' }}>
            <TitleHead
                title="รายงาน"
                subTitle="แบบเสนอรายชื่อผู้ได้รับประกาศนียบัตรชั้นสูงกิตติมคักดิ์"
            >
                <Box sx={{ display: "flex", justifyContent: "right" }}>
                    <Button
                        color="success"

                        variant="outlined"
                        sx={{
                            color: "#4caf50",
                            padding: "8px",
                            paddingLeft:'16px',
                            paddingRight:'16px',
                            marginTop: "23px",
                            marginRight: '23px'

                        }}
                        onClick={async () => {
                            await api
                                .get(`api/v1.0/report/Report10/download?format=txt&startyear=${value.calDate}&endyear=${value.calDate}`, {
                                    responseType: "blob",
                                })
                                .then((blobFile) => {
                                    const blobFileRes = new File(
                                        [blobFile?.data],
                                        "Template.txt",
                                        {
                                            type: mime.getType(".txt"),
                                        }
                                    );
                                    // saveAs(URL.createObjectURL(blobFileRes, "Template.txt"))
                                    saveAs(blobFileRes, `แบบเสนอรายชื่อผู้ได้รับประกาศนียบัตรชั้นสูงกิตติมคักดิ์.txt`)

                                    // window.open(

                                    //     ,
                                    //     "_blank"
                                    // );
                                });
                        }}
                    >
                        <SaveAltIcon sx={{ marginRight: '10px' }} />  Text File
                    </Button>
                    <Button
                        color="success"
                        onClick={handleClick5}
                        variant="outlined"
                        sx={{
                            color: "#4caf50",
                            padding: "8px",
                            paddingLeft:'16px',
                            paddingRight:'16px',
                            marginTop: "23px",

                        }}
                    >
                        <SaveAltIcon sx={{ marginRight: '10px' }} /> Excel
                    </Button>
                </Box>
            </TitleHead>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ marginTop: '15px' }}>
                    <Typography sx={{ fontSize: '14px' }}>รายงาน&nbsp;/&nbsp;
                        <span style={{ color: '#35cc62', fontSize: '14px' }}>  แบบเสนอรายชื่อผู้ได้รับประกาศนียบัตรชั้นสูงกิตติมคักดิ์
                        </span>
                    </Typography>
                </Box>
                <Box >
                <Box sx={{ display: 'flex' }}>
                    <Autocomplete
                            size='small'
                            fullWidth

                            value={value}
                            onChange={(event, newValue) => {
                                setValue(newValue)
                            }}
                            options={detere}
                            getOptionLabel={option => moment(option?.calDate).add(543, 'year').format('DD-MM-yyyy') ? moment(option?.calDate).add(543, 'year').format('DD-MM-yyyy') : ''}
                            renderInput={(params) => <TextField {...params} placeholder='เลิอกวันที่' color="success" sx={{ marginRight: '30px', width: '180px' }} />}
                        />
                        {/* <FormControl sx={{ marginRight: 0, }} size="small">
                        <DesktopDatePicker
                                label=""
                                inputFormat="DD/MM/YYYY"
                                value={startDate}
                                maxDate={endDate ? new Date(endDate) : ''}
                                onChange={(newValue) => { setStartDate(moment(newValue).format('YYYY-MM-DD')) }}
                                renderInput={(params) => <TextField size='small' color="success" sx={{ width: '100%' }}
                                    InputProps={params?.InputProps}
                                    inputRef={params?.inputRef}
                                    // placeholder={params?.inputProps?.placeholder}
                                    // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                                    // onChange={params?.inputProps?.onChange}
                                    // type={params?.inputProps?.type}
                                    inputProps={
                                        {
                                          placeholder: params?.inputProps?.placeholder,
                                          readOnly: true,
                                          value: params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null,
                                          onChange: params?.inputProps?.onChange,
                                          type: params?.inputProps?.type
                                        }
                    
                                      }
                                />}
                            />
                        </FormControl>

                        <FormControl sx={{ marginRight: 0, }} size="small">
                            <Typography sx={{ m: 1 }}>~</Typography>
                        </FormControl>
                        <FormControl sx={{ marginRight: 0, }} size="small">
                        <DesktopDatePicker
                                label=""
                                inputFormat="DD/MM/YYYY"
                                value={endDate}
                                minDate={new Date(startDate)}
                                onChange={(newValue) => { setEndDate(moment(newValue).format('YYYY-MM-DD')) }}
                                renderInput={(params) => <TextField size='small' color="success" sx={{ width: '100%' }}
                                    InputProps={params?.InputProps}
                                    inputRef={params?.inputRef}
                                    // placeholder={params?.inputProps?.placeholder}
                                    // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                                    // onChange={params?.inputProps?.onChange}
                                    // type={params?.inputProps?.type}
                                    inputProps={
                                        {
                                          placeholder: params?.inputProps?.placeholder,
                                          readOnly: true,
                                          value: params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null,
                                          onChange: params?.inputProps?.onChange,
                                          type: params?.inputProps?.type
                                        }
                    
                                      }
                                />}
                            />
                        </FormControl> */}

                        <Button
                            onClick={handleClick}
                            
                            size='small'
                            sx={{
                                padding: "8px",
                                marginLeft:'16px',
                                color:'#fafafa',
                                backgroundColor: "#0fb123",
                                ":hover": { backgroundColor: "#028b13" },
                            }}
                        >
                            <SearchIcon />
                        </Button>
                    </Box>
                </Box>
            </Box>

            <Stack spacing={3}>
                <TableContainer sx={{ marginTop: "20px", marginBottom: "20px" }}>
                    {/* {console.log(listreport)} */}

                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell
                                    align="center"
                                    sx={{ fontSize: "18px", fontWeight: "400" }}
                                >
                                    ชือ - สกุล
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    sx={{ fontSize: "18px", fontWeight: "400" }}
                                >
                                    ดำรงตำแหน่ง 
                                </StyledTableCell>


                                <StyledTableCell
                                    align="center"
                                    sx={{ fontSize: "18px", fontWeight: "400" }}
                                >
                                   ระยะเวลาการดำรงตำแหน่ง
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    sx={{ fontSize: "18px", fontWeight: "400" }}
                                >
                                    หมายเหตุ
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    sx={{ fontSize: "18px", fontWeight: "400" }}
                                >
                                  หลักเกณฑ์
                                </StyledTableCell>
                              
                            </TableRow>
                        </TableHead>
                        {listreport.map((e, i) => (
                            <TableRow key={i}>
                                <StyledTableCell
                                    align="center"
                                    sx={{ fontSize: "18px", fontWeight: "400" }}
                                >
                                    {e?.firstname} {e?.lastname}
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    sx={{ fontSize: "18px", fontWeight: "400" }}
                                >
                                    {e?.positionDsc}
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    sx={{ fontSize: "18px", fontWeight: "400" }}
                                >
                                    {e?.duration}
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    sx={{ fontSize: "18px", fontWeight: "400" }}
                                >
                                    {e?.bracket}
                                </StyledTableCell>
                                <StyledTableCell
                                    align="center"
                                    sx={{ fontSize: "18px", fontWeight: "400" }}
                                >
                                    {e?.cerName}
                                </StyledTableCell>
                              
                                {/* <StyledTableCell
                                    align="center"
                                    sx={{ fontSize: "18px", fontWeight: "400" }}
                                >
                                    {e?.certificateTypeName}
                                </StyledTableCell> */}
                            </TableRow>
                        ))}
                    </Table>

                </TableContainer>
            </Stack>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openM}
                onClose={handleClose}
                // elevation={0}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                sx={{
                    ".MuiPopover-paper": {
                        boxShadow: "unset",
                        border: "1px solid #afafaf",
                        marginTop:'10px'
                    },
                }}
            >
                <MenuItem
                    sx={{ width: "100%", fontSize: "20px" }}
                    onClick={async () => {
                        await api
                            .get(`api/v1.0/report/Report10/download?startyear=${value.calDate}&endyear=${value.calDate}&type=1&format=xlsx`, {
                                responseType: "blob",
                            })
                            .then((blobFile) => {
                                const blobFileRes = new File(
                                    [blobFile?.data],
                                    "Template.xlsx",
                                    {
                                        type: mime.getType(".xlsx"),
                                    }
                                );
                                saveAs(blobFileRes, `แบบเสนอรายชื่อผู้ได้รับ/เลื่อนชั้นเข็มกิตติคุณ.xlsx`)

                                // saveAs(URL.createObjectURL(blobFileRes, "Template.txt"))
                                // window.open(

                                //     ,
                                //     "_blank"
                                // );
                            });
                    }}
                >
                  <CropPortraitIcon sx={{marginRight:'8px'}}/> เอกสารแบบแนวตั้ง
                </MenuItem>

                <MenuItem
                    sx={{ width: "100%", fontSize: "20px" }}
                    // onClick={(e) => handleClickOpen(e, board)}
                    onClick={async () => {
                        await api
                            .get(`api/v1.0/report/Report10/download?startyear=${value.calDate}&endyear=${value.calDate}&type=2&format=xlsx`, {
                                responseType: "blob",
                            })
                            .then((blobFile) => {
                                const blobFileRes = new File(
                                    [blobFile?.data],
                                    "Template.xlsx",
                                    {
                                        type: mime.getType(".xlsx"),
                                    }
                                );
                                saveAs(blobFileRes, `แบบเสนอรายชื่อผู้ได้รับ/เลื่อนชั้นเข็มกิตติคุณ.xlsx`)

                                // saveAs(URL.createObjectURL(blobFileRes, "Template.txt"))
                                // window.open(

                                //     ,
                                //     "_blank"
                                // );
                            });
                    }}
                >
                  <CropLandscapeIcon sx={{marginRight:'8px'}}/>  เอกสารแบบแนวนอน
                </MenuItem>

            </Menu>
        </Container>
    )
}
