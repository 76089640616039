import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Container,
  Menu,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Dialog,
  DialogActions,
  OutlinedInput,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { useNavigate, NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import moment from "moment";
import Switch from "@mui/material/Switch";

import SearchIcon from "@mui/icons-material/Search";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";

import api from "../../api/api";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { actionAsync } from "../../store/slices/actionSlice";
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    /*  backgroundColor: '#F2F5F8', */
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(index, img, name, user, date) {
  return { index, img, name, user, date };
}

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

export default function MenuActivate() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openM = Boolean(anchorEl);
  const [checked, setChecked] = React.useState(true);

  const [openAdd, setOpenAdd] = useState(false);
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  //-----------Function Get slidesbanner------------
  const [userlist, setUserlist] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [roleList, setRoleList] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [actionList, setActionList] = useState([]);

  //Add Action
  const [menuSelect, setMenuSelect] = useState(null);
  const [roleSelect, setRoleSelect] = useState(null);
  const [isAdd, setIsAdd] = useState(false);
  const [isView, setIsView] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const Dispatch = useDispatch();
  const getRole = async () => {
    await api.get("api/v1.0/setting/role/list").then((res) => {
      setRoleList(res?.data?.result);
    });
  };

  const getMenu = async () => {
    await api.get("api/v1.0/setting/menu/list").then((res) => {
      setMenuList(res?.data?.result);
    });
  };

  const getActionList = async () => {
    await api.get("api/v1.0/setting/configaction/list").then((res) => {
      setActionList(res?.data?.result);
    });
  };

  const handleSubmitDialog = async () => {
    const data = {
      configActionID: null,
      menuNo: menuSelect,
      roleID: roleSelect,
      isAdd: isAdd,
      isEdit: isEdit,
      isView: isView,
      status: true,
    };

    await api
      .post("api/v1.0/setting/configaction/create", [data])
      .then((res) => {
        // console.log(res);
        if (res?.data?.status) {
          Swal.fire("สำเร็จ", res?.data?.message, "success");
          setMenuSelect(null);
          setRoleSelect(null);
          setIsAdd(false);
          setIsEdit(false);
          setIsView(false);
          setOpenAdd(false);
          getActionList();
        } else {
          Swal.fire("ไม่สำเร็จ", res?.data?.message, "error");
          setMenuSelect(null);
          setRoleSelect(null);
          setIsAdd(false);
          setIsEdit(false);
          setIsView(false);
          setOpenAdd(false);
          getActionList();
        }
      });
  };

  useEffect(() => {
    getRole();
    getMenu();
    getActionList();
  }, []);

  const updatePermission = (id, field, value) => {
    // Find the item to update and store it in new list
    let el = actionList.map((item) => {
      if (item.configActionID === id) {
        item[field] = value;
      }

      return item;
    });

    setActionList(el);
  };

  const SaveData = async () => {
    await api
      .post("api/v1.0/setting/configaction/create", actionList)
      .then((res) => {
        if (res?.data?.status) {
          Swal.fire("สำเร็จ", res?.data?.message, "success");
          getActionList();
          Dispatch(actionAsync());
        } else {
          Swal.fire("ไม่สำเร็จ", res?.data?.message, "error");
          getActionList();
          Dispatch(actionAsync());
        }
      });
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            color: "#000",
            marginTop: "50px",
            marginLeft: "65px",
          }}
        >
          <Typography variant="h4">จัดการสิทธิ์การเข้าถึง</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "right",
            width: "100%",
            marginTop: "-18px",
            paddingRight: "50px",
          }}
        ></Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "right",
            width: "100%",

            paddingRight: "70px",
          }}
        >
          <Button
            onClick={() => setOpenAdd(true)}
            sx={{
              backgroundColor: "#1fc64c",
              "&:hover": { background: "#4caf50" },
              color: "#FFFF",
              padding: "7px 55px",
              marginTop: "23px",
            }}
          >
            สร้างใหม่
          </Button>
        </Box>

        <Box
          sx={{
            display: "flex",
            color: "#000",
            marginTop: "20px",
            marginLeft: "50px",
            justifyContent: "right",
            paddingRight: "70px",
          }}
        >
          <Divider
            sx={{ width: "1485px", marginTop: "10px", marginLeft: "65px" }}
          />
        </Box>

        <Container maxWidth="xl">
          <Stack spacing={3}>
            <TableContainer sx={{ marginTop: "20px", marginBottom: "80px" }}>
              <Table
                sx={{
                  minWidth: 400,
                  borderCollapse: "inherit",
                  borderSpacing: "0",
                }}
              >
                <TableHead sx={{ backgroundColor: "#e7e7e7" }}>
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{
                        fontSize: "18px",
                        fontWeight: "600",
                        color: "#3a3a3ae6",
                        border: "none",
                      }}
                    >
                      เมนู
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontSize: "18px",
                        fontWeight: "600",
                        color: "#3a3a3ae6",
                        border: "none",
                      }}
                    >
                      สิทธิ์
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontSize: "18px",
                        fontWeight: "600",
                        color: "#3a3a3ae6",
                        border: "none",
                      }}
                    >
                      เข้าถึง
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontSize: "18px",
                        fontWeight: "600",
                        color: "#3a3a3ae6",
                        border: "none",
                      }}
                    >
                      เพิ่ม
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        fontSize: "18px",
                        fontWeight: "600",
                        color: "#3a3a3ae6",
                        border: "none",
                      }}
                    >
                      แก้ไข
                    </TableCell>

                    <TableCell
                      align="left"
                      sx={{
                        fontSize: "18px",
                        fontWeight: "600",
                        color: "#3a3a3ae6",
                        border: "none",
                      }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {actionList.map((row, i) => (
                    <StyledTableRow
                      key={i}
                      /* onClick={() => navigate(`/ProjectSource/SearchPoliticalMinor`)} */
                      sx={{
                        fontSize: "18px",
                        cursor: "pointer",
                        "&:hover": {
                          background: "#f3f2f2",
                        },
                      }}
                    >
                      <TableCell align="left" sx={{ fontSize: "17px" }}>
                        {row?.menu?.menuName}
                      </TableCell>
                      <TableCell align="left" sx={{ fontSize: "17px" }}>
                        {row?.role?.roleName}
                      </TableCell>
                      <TableCell align="left" sx={{ fontSize: "17px" }}>
                        <Switch
                          checked={row?.isView}
                          onChange={(e) => {
                            updatePermission(
                              row?.configActionID,
                              "isView",
                              !row?.isView
                            );
                          }}
                        />
                      </TableCell>
                      <TableCell align="left" sx={{ fontSize: "17px" }}>
                        <Switch
                          checked={row?.isAdd}
                          onChange={(e) => {
                            updatePermission(
                              row?.configActionID,
                              "isAdd",
                              !row?.isAdd
                            );
                          }}
                        />
                      </TableCell>
                      <TableCell align="left" sx={{ fontSize: "17px" }}>
                        <Switch
                          checked={row?.isEdit}
                          onChange={(e) => {
                            updatePermission(
                              row?.configActionID,
                              "isEdit",
                              !row?.isEdit
                            );
                          }}
                        />
                      </TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Container>
      </Box>
      <Box
        sx={{
          position: "sticky",
          bottom: "0px",
          width: "100%",
          marginTop: "5px",
          background: "#fff",
          zIndex:'100'
        }}
      >
        <Divider sx={{ marginBottom: "10px" }} />
        <Container maxWidth="xl" sx={{ height: "auto" }}>
          <Box
            sx={{
              padding: "5px 20px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                SaveData();
              }}
              sx={{
                width: "150px",
                height: "fit-content",
                fontSize: "18px",
                marginLeft: "20px",
                marginBottom: "20px",
                backgroundColor: "#0fb123",
                ":hover": { backgroundColor: "#028b13" },
              }}
            >
              บันทึก
            </Button>
          </Box>
        </Container>
      </Box>
      <Dialog
        open={openAdd}
        onClose={() => {
          setOpenAdd(false);
          // setTxt_add("");
          // setDialog_id(null);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        borderRadius="20px"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">สร้างสิทธิ์</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <FormControl sx={{ m: 1, mt: 2 }} fullWidth>
              <InputLabel shrink={true}>เมนู</InputLabel>
              <Select
                value={menuSelect}
                onChange={(e) => {
                  setMenuSelect(e.target.value);
                }}
                displayEmpty
                label="เมนู"
                notched={true}
                fullWidth
              >
                <MenuItem value={null} disabled>
                  กรุณาเลือกเมนู
                </MenuItem>
                {menuList.map((x) => (
                  <MenuItem value={x?.menuNo}>{x?.menuName}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, mt: 2 }} variant="outlined" fullWidth>
              <InputLabel shrink>ตำแหน่ง</InputLabel>
              <Select
                value={roleSelect}
                onChange={(e) => {
                  setRoleSelect(e.target.value);
                }}
                input={<OutlinedInput notched label="ตำแหน่ง" />}
                displayEmpty
                notched={true}
                fullWidth
              >
                <MenuItem value={null} disabled>
                  กรุณาเลือกตำแหน่ง
                </MenuItem>
                {roleList.map((x) => (
                  <MenuItem value={x?.roleID}>{x?.roleName}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormGroup sx={{ m: 1, mt: 1 }} row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isView}
                    onChange={() => setIsView(!isView)}
                  />
                }
                label="อ่านข้อมูล"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={isAdd} onChange={() => setIsAdd(!isAdd)} />
                }
                label="เพิ่มข้อมูล"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isEdit}
                    onChange={() => setIsEdit(!isEdit)}
                  />
                }
                label="แก้ไขข้อมูล"
              />
            </FormGroup>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenAdd(false);
              // setTxt_add("");
              // setDialog_id(null);
            }}
            variant="outlined"
            color="success"
            sx={{
              color: "#4caf50",
              padding: "7px 55px",
              marginTop: "23px",
              marginRight: "20px",
            }}
          >
            กลับ
          </Button>
          <Button
            onClick={() => {
              if (menuSelect && roleSelect) {
                handleSubmitDialog();
              } else {
                setOpenAdd(false);
                Swal.fire("แจ้งเตือน", "กรุณาเลือกตำแหน่งหรือเมนู", "warning");
              }
            }}
            variant="outlined"
            color="success"
            sx={{
              backgroundColor: "#1fc64c",
              "&:hover": { background: "#4caf50" },
              color: "#FFFF",
              padding: "7px 55px",
              marginTop: "23px",
            }}
          >
            ยืนยัน
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
