import {
  Box,
  Button,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Container,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Menu,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import TitleHead from "../../components/TitleHead";
import ApiContext from "../../context/ApiProvider";
import moment from "moment";

export default function CommitteeList() {
  const [board, setBoardList] = useState([]);
  const [searchBoard, setSearchBoard] = useState("");
  const [textSearchBoard, setTextSearchBoard] = useState("");
  const [fillter, setFillter] = useState(1);
  const handleSelect = (event) => {
    setFillter(event.target.value);
  };
  const handleClickSearchBoard = () => {
    setTextSearchBoard(searchBoard);
  };
  // console.log(board)
  const param = useParams();
  let location = useLocation();
  const { getBoardList } = useContext(ApiContext);

  useEffect(() => {
    getBoardList(setBoardList, param, textSearchBoard, fillter);
  }, [getBoardList, setBoardList, param, textSearchBoard, fillter]);

  return (
    <Container maxWidth="xl" sx={{ height: "auto", marginTop: "40px" }}>
      <TitleHead
        title={`${localStorage.getItem("title")}`}
        subTitle="ข้อมูลรายชื่อคณะกรรมการ"
      >
        <Button
         
          component={NavLink}
          to={`/Committee/${param.id}/Add`}
          state={{ state: true, add: true, param: param.id }}
          sx={{
           
            fontSize: "18px",
            paddingLeft:'16px',
            paddingRight:'16px',
            color:'#fff',
            backgroundColor: "#0fb123",
            ":hover": { backgroundColor: "#028b13" },
          }}
        >
          สร้างใหม่
        </Button>
      </TitleHead>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "20px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FormControl sx={{ marginRight: "16px", width: "100px" }}>
            {/* <InputLabel>Fillter</InputLabel> */}
            <Select
              // open={open}
              // onClose={handleClose}
              // onOpen={handleOpen}
              defaultValue={1}
              value={fillter}
              onChange={handleSelect}
              // label="Fillter"
              size="small"
            >
              <MenuItem value={1}>ทั้งหมด</MenuItem>
              <MenuItem value={2}>สิ้นสุดวาระ</MenuItem>
              <MenuItem value={3}>ดำรงตำเเหน่ง</MenuItem>
            </Select>
          </FormControl>
          <TextField
            id="outlined-basic"
            label="กรอกข้อมูลค้นหา"
            value={searchBoard}
            onChange={(e) => setSearchBoard(e.target.value)}
            variant="outlined"
            color="success"
            size="small"
          />
          <Button
            onClick={handleClickSearchBoard}
            sx={{
              padding: "8px",
              color: "#fff",
              marginLeft: "20px",
              backgroundColor: "#0fb123",
              ":hover": { backgroundColor: "#028b13" },
            }}
          >
            <SearchIcon />
          </Button>
        </Box>
      </Box>
      <TableContainer>
        <Table
          sx={{ minWidth: 650, borderCollapse: "inherit", borderSpacing: "0" }}
        >
          <TableHead sx={{ backgroundColor: "#EDEFF0" }}>
            <TableRow>
              <TableCell
                sx={{
                  color: "#000",
                  border: "none",
                  fontSize: "14px",
                  fontWeight: "700px",
                  width: "10%",
                }}
                align="center"
              >
                คำนำหน้าชื่อ
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  border: "none",
                  fontSize: "14px",
                  fontWeight: "700px",
                  width: "10%",
                }}
                align="center"
              >
                ชื่อ
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  border: "none",
                  fontSize: "14px",
                  fontWeight: "700px",
                  width: "10%",
                }}
                align="center"
              >
                นามสกุล
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  border: "none",
                  fontSize: "14px",
                  fontWeight: "700px",
                  width: "20%",
                }}
                align="center"
              >
                ตำเเหน่งปัจจุบัน
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  border: "none",
                  fontSize: "14px",
                  fontWeight: "700px",
                  width: "10%",
                }}
                align="center"
              >
                ตำเเหน่งในคณะกรรมการ
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  border: "none",
                  fontSize: "14px",
                  fontWeight: "700px",
                  width: "10%",
                }}
                align="center"
              >
                วันที่เริ่มการดำรงตำเเหน่ง
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  border: "none",
                  fontSize: "14px",
                  fontWeight: "700px",
                  width: "10%",
                }}
                align="center"
              >
                วันที่สิ้นสุดการดำรงตำเเหน่ง
              </TableCell>
              
              <TableCell
                sx={{ color: "#000", border: "none", width: "10%" }}
                align="center"
              >สถานะการดำรงตำเเหน่ง</TableCell>
              <TableCell
                sx={{ color: "#000", border: "none", width: "10%" }}
                align="center"
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {board?.map((row, i) => (
              // <TableRow key={i}>
              //   <TableCell sx={{ color: '#000', fontSize: '18px' }} align="center">{row?.personal?.title?.titleNameTH}</TableCell>
              //   <TableCell sx={{ color: '#000', fontSize: '18px' }} align="center">{row?.personal?.firstname}</TableCell>
              //   <TableCell sx={{ color: '#000', fontSize: '18px' }} align="center">{row?.personal?.lastname}</TableCell>
              //   <TableCell sx={{ color: '#000', fontSize: '18px' }} align="center">{row?.personal?.position}</TableCell>
              //   <TableCell sx={{ color: '#000', fontSize: '18px' }} align="center">{row?.newPosition?.positionName}</TableCell>
              //   <TableCell sx={{ color: '#000', fontSize: '18px' }} align="center">{moment(row?.endDate).format('DD-MM-YYYY')}</TableCell>
              //   <TableCell sx={{ color: '#000', }} align="center">
              //     <Link to={`/Committee/${row?.boardDepID}/detail/${row?.boardID}`} state={{ state: true, add: true, param: param.id }}>
              //       <MoreHorizIcon />
              //     </Link>
              //   </TableCell>
              // </TableRow>
              <TableRowC row={row} i={i} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export function TableRowC({ row, i }) {
  const [open, setOpen] = React.useState(false);
  const [titleBoard, setTitleBoard] = useState("");
  const [item, setItem] = useState();
  const [searchDBoard, setSearchDBoard] = useState("");
  const [textSearchDBoard, setTextSearchDBoard] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const param = useParams();
  let location = useLocation();
  const openM = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // const handleClickOpen = (e, item) => {
  //   setOpen(true);
  //   setTitleBoard(item?.boardDepName)
  //   setItem(item)
  // };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  // const handleSubmit = () => {
  //   setAnchorEl(null);

  //   const data = {
  //     boardDepID: item?.boardDepID || "",
  //     boardDepName: titleBoard,
  //     status: true
  //   }

  //   addBoardDepartment(data, setOpen)
  //   setOpen(false)

  //   Swal.fire(
  //     'เเก้ไขเเล้ว',
  //     // 'success'
  //   )
  // }
  // const handleDelet = (e, item) => {
  //   setAnchorEl(null);

  //   const data = {
  //     boardDepID: item?.boardDepID,
  //     boardDepName: titleBoard,
  //     status: false
  //   }
  //   Swal.fire({
  //     title: 'ต้องการลบหรือไม่ ?',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#d33',
  //     cancelButtonColor: '#3085d6',
  //     confirmButtonText: 'ลบ',
  //     cancelButtonText: 'ยกเลิก',
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       Swal.fire(
  //         'ลบเเล้ว',
  //         // 'success'
  //       )
  //       addBoardDepartment(data, setOpen)
  //     }
  //   })
  // }
  return (
    <TableRow>
      <TableCell
        sx={{ color: "#000", fontSize: "14px", fontWeight: "500px" }}
        align="center"
      >
        {row?.personal?.title?.titleNameTH}
      </TableCell>
      <TableCell
        sx={{ color: "#000", fontSize: "14px", fontWeight: "500px" }}
        align="center"
      >
        {row?.personal?.firstname}
      </TableCell>
      <TableCell
        sx={{ color: "#000", fontSize: "14px", fontWeight: "500px" }}
        align="center"
      >
        {row?.personal?.lastname}
      </TableCell>
      <TableCell
        sx={{ color: "#000", fontSize: "14px", fontWeight: "500px" }}
        align="center"
      >
        {row?.personal?.position}
      </TableCell>
      <TableCell
        sx={{ color: "#000", fontSize: "14px", fontWeight: "500px" }}
        align="center"
      >
        {row?.newPositionDes}
        {/* {row?.newPosition?.positionName} */}
      </TableCell>
      <TableCell
        sx={{ color: "#000", fontSize: "14px", fontWeight: "500px" }}
        align="center"
      >
        {moment(row?.startDate).add(543, "year").format("LL") ===
        "Invalid date"
          ? null
          : moment(row?.startDate).add(543, "year").format("LL")}
      </TableCell>
      <TableCell
        sx={{ color: "#000", fontSize: "14px", fontWeight: "500px" }}
        align="center"
      >
        {moment(row?.endDate).add(543, "year").format("LL") ===
        "Invalid date"
          ? null
          : moment(row?.endDate).add(543, "year").format("LL")}
      </TableCell>
      <TableCell
        sx={{ color: "#000", fontSize: "14px", fontWeight: "500px" }}
        align="center"
      >
        {moment() > moment(row?.endDate) ? 'สิ้นสุดวาระ' : 'ดำรงตำเเหน่ง'}
        {/* {row?.newPosition?.positionName} */}
      </TableCell>
      <TableCell sx={{ color: "#000" }} align="center">
        {/* <Link to={`/Committee/${row?.boardDepID}/detail/${row?.boardID}`} state={{ state: true, add: true, param: param.id }}> */}
        {/* < MoreHorizIcon onClick={handleClick} sx={{ cursor: 'pointer', fontSize: '35px' }} />

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openM}
          onClose={handleClose}
          // elevation={0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            '.MuiPopover-paper': {
              boxShadow: 'unset',
              border: '1px solid',
            }
          }}
        >
          <MenuItem sx={{ width: '100px', fontSize: '20px' }} component={NavLink} to={`/Committee/${row?.boardDepID}/detail/${row?.boardID}`} state={{ state: true, add: true, param: param.id, open: 'see' }}>ดู</MenuItem>
          <MenuItem sx={{ width: '100px', fontSize: '20px' }} component={NavLink} to={`/Committee/${row?.boardDepID}/detail/${row?.boardID}`} state={{ state: true, add: true, param: param.id, open: 'detail' }}>เเก้ไข</MenuItem>
          {/* <MenuItem sx={{ width: '100px', fontSize: '20px' }}>ลบ</MenuItem> */}
        {/* </Menu>  */}
        <Box sx={{ display: "flex" }}>
          <NavLink
            to={`/Committee/${row?.boardDepID}/detail/${row?.boardID}`}
            state={{ state: true, add: true, param: param.id, open: "see" }}
            style={{ textDecoration: "none" }}
          >
            <Button
              // component={NavLink}
              // to={`/${row?.personalID}`}
              // state={{ add: true }}
              sx={{
                backgroundColor: "#1fc64c",
                "&:hover": { background: "#4caf50" },
                color: "#fff",
              }}
            >
              ดู
            </Button>
            {/* <MoreHorizIcon /> */}
          </NavLink>
          <NavLink
            to={`/Committee/${row?.boardDepID}/detail/${row?.boardID}`}
            state={{ state: true, add: true, param: param.id, open: "detail" }}
            style={{ textDecoration: "none" }}
          >
            <Button
              // component={NavLink}
              // to={`/${row?.personalID}`}
              // state={{ add: true }}
              sx={{
                backgroundColor: "#0089ff",
                "&:hover": { background: "#0089ff" },
                color: "#fff",
                marginLeft: "5px",
              }}
            >
              เเก้ไข
            </Button>
            {/* <MoreHorizIcon /> */}
          </NavLink>
        </Box>
        {/* </Link> */}
      </TableCell>
    </TableRow>
  );
}
