import { Box, Typography, Button, FormControl, Select, MenuItem, InputLabel, TextField, Container, FormControlLabel } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useNavigate, NavLink, } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Grid from "@mui/material/Grid";
import TitleHead from '../../components/TitleHead';
import SearchIcon from '@mui/icons-material/Search';
import GridField from '../../components/GridTextField';
import React, { useState, useEffect, useContext } from 'react';
import Checkbox from '@mui/material/Checkbox';
import api from '../../api/api';

import Swal from 'sweetalert2'
import withReactContent from "sweetalert2-react-content";
import { Formik } from 'formik';
import * as Yup from 'yup';
import ApiContext from '../../context/ApiProvider';
import { display } from '@mui/system';
import moment from 'moment/moment';

export default function Notification() {
  const [notification, setnotification] = useState([]);
  const [num, setnum] = useState([])

  useEffect(() => {
    api.get('api/v1.0/notification/list').then((res) => {
      setnotification(res?.data?.result);
      

    })
  }, [])

  return (
    <>
      <Container maxWidth='xl' sx={{ height: 'auto', marginTop: '40px', marginBottom: '40px' }}>
        <TitleHead title='การแจ้งเตือน' subTitle='การแจ้งเตือนข้อมูลภายในระบบ'>

        </TitleHead>
        {notification.map((e, i) => (
          <Box key={i} sx={{ border: '1px solid #bdbdbd', width: '30%', height: 'auto', marginTop: '20px' }}>
            <Container maxWidth='xs' sx={{ marginTop: '10px', }}>
              <Box>
                <Typography sx={{ fontSize: '15px' }}>{e?.title}</Typography>
              </Box>
              <Box sx={{ marginTop: '10px' }}>
                <Typography sx={{ fontWeight: '500' }}>{e?.description}</Typography>
              </Box>
              <Box sx={{ marginTop: '10px' }}>
                <Typography sx={{ fontSize: '12px', color: '#bdbdbd' }}>{moment(e.createDate).format('DD/MM/YYYY HH:mm')}</Typography>

              </Box>
            </Container>

          </Box>
        ))}
        {/* <Box sx={{ border: '1px solid #bdbdbd', width: '30%', height: 'auto',marginTop:'20px' }}>
          <Container maxWidth='xs' sx={{ marginTop: '10px' }}>
            
            <Box sx={{ marginTop: '10px' }}>
              <Typography sx={{fontWeight:'500'}}>Thitipong มอบสิทธิ์ "Adminnistrator"</Typography>
            </Box>
            <Box sx={{ marginTop: '10px' }}>
              <Typography sx={{ fontSize: '13px' ,color:'#bdbdbd'}}>08/24/2020 20:00  การจัดการสิทธิ์</Typography> 
              
            </Box>
          </Container>

        </Box> */}

        {/* <Box sx={{ border: '1px solid #bdbdbd', width: '30%', height: 'auto',marginTop:'20px' }}>
          <Container maxWidth='xs' sx={{ marginTop: '10px' }}>
            <Box>
              <Typography sx={{ fontSize: '15px' }}>ได้รับสิทธิ์</Typography>
            </Box>
            <Box sx={{ marginTop: '10px' }}>
              <Typography sx={{fontWeight:'500'}}>Varutpong ได้รับสิทธิ์ "Content Creator"</Typography>
            </Box>
            <Box sx={{ marginTop: '10px' }}>
              <Typography sx={{ fontSize: '14px' ,color:'#bdbdbd'}}>08/24/2020 20:00  การจัดการสิทธิ์</Typography> 
              
            </Box>
          </Container>

        </Box> */}
        {/* <Box sx={{ border: '1px solid #bdbdbd', width: '30%', height: 'auto',marginTop:'20px' }}>
          <Container maxWidth='xs' sx={{ marginTop: '10px' }}>
            <Box>
              <Typography sx={{ fontSize: '15px' }}>ได้รับสิทธิ์</Typography>
            </Box>
            <Box sx={{ marginTop: '10px' }}>
              <Typography sx={{fontWeight:'500'}}>Thitipong มอบสิทธิ์ "Content Creator"</Typography>
            </Box>
            <Box sx={{ marginTop: '10px' ,}}>
              <Typography sx={{ fontSize: '15px' ,color:'#bdbdbd'}}>08/24/2020 20:00  การจัดการสิทธิ์</Typography> 
              
              
            </Box>
          </Container>

        </Box> */}

      </Container>

    </>
  )
}
