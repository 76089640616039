import { Box, Typography, Divider} from '@mui/material'
import React from 'react'

export default function TitleHead({children, title, subTitle}) {
    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection:{md:'row', xs:'column' } }}>
                <Box>
                    <Typography sx={{fontSize:'32px'}}>{title}</Typography>
                    <Typography sx={{ color: '#bdbdbd' , fontSize:'15px'}}>{subTitle}</Typography>
                </Box>
                {children}
            </Box>
            <Divider sx={{ margin: '20px 0' }} />
        </>
    )
}
