import { Box, Button, Typography, Divider, Grid, FormControl, Select, MenuItem, TextField, InputLabel, FormHelperText, FormControlLabel, Switch, Container, Autocomplete } from '@mui/material'
import moment from 'moment'
import React, { useContext, useEffect, useRef, useState } from 'react'
import GridField from '../../components/GridTextField'
import TitleHead from '../../components/TitleHead'
import { NavLink, useParams } from 'react-router-dom'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import ApiContext from '../../context/ApiProvider';
import { Formik } from 'formik'
import * as Yup from 'yup';
import Swal from 'sweetalert2'

import api from '../../api/api'
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
export default function AddCommittee() {
  const fileRef = useRef()
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [citizen, setCitizen] = useState('')
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [subtract, setSubtract] = useState({})
  const [personal, setPersonal] = useState([]);
  const [position, setPosition] = useState([]);
  const [appointer, setAppointer] = useState([]);
  const [newPosition, setNewPosition] = useState('')
  const [newPositionDes, setNewPositionDos] = useState('')
  const [status, setStatus] = useState(true);
  const [isCalPin, setIsCalPin] = useState(true);
  const [remark, setRemark] = useState('');
  const [breakCal, setBreakCal] = useState([]);
  const [startBDate, setStartBDate] = useState(null);
  const [endBDate, setEndBDate] = useState(null);
  const [bComment, setBComment] = useState('');
  const [bPresent, setBPresent] = useState('');
  const [files, setFiles] = useState([])
  const [yearMeet, setYearMeet] = useState(new Date().getFullYear() + 543)
  const [dateMeet, setDateMeet] = useState('')
  const [countMeet, setCountMeet] = useState()
  const [openMeet, setOpenMeet] = useState(false)
  const { getPersonal, getPosition, getAppointer, fileUpload, FileDownload, createBoard } = useContext(ApiContext)
  let formData = new FormData();
  const param = useParams()
  // const handleChange = (newValue) => {
  //   setValue(newValue);
  // };
  const handleFileUpload = (event) => {
    if (event.target && event.target.files) {
      let file = event.target.files[0];
      // setFiles([...files, file]);
      const maxAllowedSize = 8 * 1024 * 1024;
      if (file.size < maxAllowedSize) {
        formData.append("file", file)
        // console.log(formData)
        // fileUpload(formData, file, files, setFiles)
        api.post('api/v1.0/filemanages/upload', formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then((res) => res.data)
          .then((data) => {
            if (data.status === true) {
              // console.log(data.result)
              setFiles([...files, data.result]);
            }
            else {
              console.log(' failed')
            }
          })

      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'ไฟล์อัพโหลดใหญ่เกินไป',
        })
      }
    }

  };
  const handleRemoveFileUpload = (filename, i) => {
    setFiles(files.length === 1 ? files.filter(file => file.name !== filename) : files.splice(i, 1))
  }
  const handleBreakCal = () => {
    const body = {
      breakCalID: "",
      startDate: moment(startBDate).format('YYYY-MM-DD') === 'Invalid date' ? moment(new Date()).format('YYYY-MM-DD') : moment(startBDate).format('YYYY-MM-DD'),
      endDate: moment(endBDate).format("YYYY-MM-DD") === "Invalid date" ? null : moment(endBDate).format("YYYY-MM-DD"),
      remark: bComment,
      present: bPresent,
      status: true
    }
    setBreakCal([...breakCal, body])
    setBComment('')
    setBPresent('')
    // setStartBDate(null)
    // setEndBDate(null)
  }

  useEffect(() => {
    if (firstname.length > 0) {
      setPersonal([])
      getPersonal(setPersonal, firstname, lastname)
    } else if (firstname === '') {
      getPersonal(setPersonal, firstname, lastname)
    }
  }, [getPersonal, setPersonal, firstname, lastname])
  useEffect(() => {
    if (citizen.length > 0) {
      setAppointer([])
      getAppointer(setAppointer, citizen)
    } else if (citizen === '') {
      getAppointer(setAppointer, citizen)
    }
  }, [getAppointer, setAppointer, citizen])
  useEffect(() => {
    getPosition(setPosition)
  }, [getPosition, setPosition])

  useEffect(() => {
    const subtract = moment.duration(moment(endDate ? endDate : new Date(), 'DD-MM-YYYY').diff(moment(startDate, 'DD-MM-YYYY')))._data
    setSubtract(subtract)
  }, [setSubtract, endDate, startDate])
  console.log(personal)
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        boardID: "",
        personalID: personal[0]?.personalID || null,
        newPositionID: position[0]?.positionID || null,
        newPositionDes: newPositionDes,
        boardDepID: param?.id,
        startDate: moment(startDate).format('YYYY-MM-DD') === 'Invalid date' ? moment(new Date()).format('YYYY-MM-DD') : moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD') === 'Invalid date' ? null : moment(endDate).format('YYYY-MM-DD'),
        appointerID: appointer[0]?.appointerID || '',
        remark: remark,
        status: status,
        isCalPin: isCalPin,
        boardAttach: files.map((item) =>
        ({
          boardAttachmentID: "",
          fileID: item.fileID,
          status: true
        })
        ),
        // boardMeet: openMeet ? [
        //   {
        //     boardMeetID: "",
        //     year: yearMeet,
        //     countMeet: +countMeet,
        //     isCal: true
        //   }
        // ] : [],
        breakCal: breakCal,
        submit: null
      }}
      validationSchema={Yup.object().shape({
        // bankAccNo: Yup.string().max(255).required('กรุณาระบุ ชื่อ'),
        // bankAccName: Yup.string().max(255).required('กรุณาระบุ ชื่อบัญชี'),
        // bankAccNickName: Yup.string().max(255).required('กรุณาระบุ ชื่อเล่น'),
      })}
      onSubmit={async (value, { setErrors, setStatus, setSubmitting }) => {
        try {

          // console.log(value)

          createBoard(value)

          setStatus({ success: true })
          setSubmitting(false)
        } catch (err) {
          console.log(err)
          setStatus({ success: false })
          setErrors({ submit: err.message })
          setSubmitting(false)
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <Container maxWidth='xl' sx={{ height: 'auto', marginTop: '40px' }}>
            <TitleHead title='เพิ่มคณะกรรมการ' subTitle='ข้อมูลทะเบียนประวัติส่วนบุคคล'>
            </TitleHead>
            <Box>
              <Typography variant='h6' sx={{ marginBottom: '20px' }}>ข้อมูลคณะกรรมการ</Typography>
              <Grid container spacing={2}>
                <GridField text='คำนำ'>
                  {/* <FormControl
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyItems: "center",
                }}
                variant="outlined"
                color="success"
              >

                <Select
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  value={param}
                  onChange={e => setParam(e.target.value)}
                >
                  {NEWS_GROUPS.map((x, i) => <MenuItem key={i} value={x.value}>{x.title}</MenuItem>
                  )}
                </Select>
              </FormControl> */}
                  <TextField label="" disabled size='small' variant="outlined" color="success" name='personalID' value={firstname.length > 0 ? personal[0]?.title?.titleNameTH : ''} sx={{ width: '100%', background: '#f7f7f7' }} />
                </GridField>
                <GridField text={<><span style={{ color: 'red' }}>* </span>ชื่อ</>}>
                  {/* <TextField label="" variant="outlined" color="success" sx={{ width: '100%' }} /> */}
                  <Autocomplete
                    id="free-solo-demo"
                    // value={firstname}
                    // onChange={(event, newValue) => {
                    //   setFirstname(newValue);
                    // }}
                    // freeSolo
                    inputValue={firstname}
                    onInputChange={(event, newInputValue) => {
                      setFirstname(newInputValue);
                    }}
                    options={personal?.map((option) => option?.firstname)}
                    renderInput={(params) => <TextField required placeholder='จำเป็นต้องระบุ' {...params} size='small' label="" color="success" sx={{ width: '100%' }} />}
                  />

                </GridField>
                <GridField text={<><span style={{ color: 'red' }}>* </span>นามสกุล</>}>
                  <Autocomplete
                    id="free-solo-demo"
                    // freeSolo
                    inputValue={lastname}
                    onInputChange={(event, newInputValue) => {
                      setLastname(newInputValue);
                    }}
                    options={personal?.map((option) => option?.lastname)}
                    renderInput={(params) => <TextField required placeholder='จำเป็นต้องระบุ' {...params} size='small' label="" color="success" sx={{ width: '100%' }} />}
                  />

                </GridField>
                <GridField text='ตำเเหน่งปัจจุบัน'>
                  <TextField label="" disabled variant="outlined" size='small' color="success" value={firstname.length > 0 ? personal.length === 1 ?  personal[0]?.position : '' : ''} sx={{ width: '100%', background: '#f7f7f7' }} />
                </GridField>
                <GridField text='วันที่เริ่มต้น'>

                  {/* {console.log(+startDate?.substring(0, 4)+ 543 + startDate?.substring(4))} */}
                  <DesktopDatePicker
                    label=""
                    inputFormat="DD/MM/YYYY"
                    value={startDate}
                    maxDate={endDate ? new Date(endDate) : ''}
                    // onViewChange={(newValue) }
                    onChange={(newValue) => { setStartDate(newValue) }}
                    renderInput={(params) => <TextField size='small' color="success" sx={{  width: '100%' }}
                      InputProps={params?.InputProps}
                      inputRef={params?.inputRef}
                      // placeholder={params?.inputProps?.placeholder}
                      // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                      // onChange={params?.inputProps?.onChange}
                      // type={params?.inputProps?.type}
                      inputProps={
                        {
                          placeholder: params?.inputProps?.placeholder,
                          readOnly: true,
                          value: params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null,
                          onChange: params?.inputProps?.onChange,
                          type: params?.inputProps?.type
                        }
    
                      }
                    />}
                  />
                </GridField>
                <GridField text='วันที่สิ้นสุด'>
                  <DesktopDatePicker
                    label=""
                    inputFormat="DD/MM/YYYY"
                    value={endDate}
                    minDate={new Date(startDate)}
                    onChange={(newValue) => { setEndDate(newValue) }}
                    renderInput={(params) => <TextField size='small' color="success" sx={{ width: '100%' }}
                      InputProps={params?.InputProps}
                      inputRef={params?.inputRef}
                      // placeholder={params?.inputProps?.placeholder}
                      // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                      // onChange={params?.inputProps?.onChange}
                      // type={params?.inputProps?.type}
                      inputProps={
                        {
                          placeholder: params?.inputProps?.placeholder,
                          readOnly: true,
                          value: params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null,
                          onChange: params?.inputProps?.onChange,
                          type: params?.inputProps?.type
                        }
    
                      }
                    />}
                  />
                </GridField>
                <GridField text='ตำเเหน่งในคณะกรรมการ'>
                  <TextField size='small' value={newPositionDes} onChange={e => setNewPositionDos(e.target.value)} label="" variant="outlined" color="success" sx={{ width: '100%' }} />

                </GridField>
                {/* <GridField text='คุณสมบัติ'>
                  <FormControl
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyItems: "center",
                    }}
                    variant="outlined"
                    color="success"
                    size='small'
                  >
                    <InputLabel id="demo-simple-select-label">เลือกตำเเหน่ง</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      inputProps={{ "aria-label": "Without label" }}
                      // placeholder='เลือกตำเเหน่ง'
                      label="เลือกตำเเหน่ง"

                      value={newPosition}
                      onChange={e => setNewPosition(e.target.value)}
                    >
                      {position.map((x, i) => <MenuItem key={i} value={x?.positionID}>{x?.positionName}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </GridField> */}

                <Grid item lg={4} md={6} xs={12}>
                  <Grid container>
                    <Grid item md={2} xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: { md: 'flex-end', sm: 'flex-start' } }}>
                    </Grid>
                    <Grid item md={10} xs={12} sx={{ position: 'relative' }}>
                      <Grid container>
                        {/* <Grid item lg={2} xs={12}>
                    </Grid> */}
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item sm={2} xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>
                              <Typography sx={{ marginRight: '15px', }}>ปี</Typography>
                            </Grid>
                            <Grid item sm={2} xs={12}>
                              <TextField size='small' disabled label="" placeholder='YY' variant="outlined" color="success" value={subtract?.years} sx={{ width: '100%', background: '#f7f7f7' }} />
                            </Grid>
                            <Grid item sm={2} xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>
                              <Typography sx={{ marginRight: '15px', }}>เดือน</Typography>
                            </Grid>
                            <Grid item sm={2} xs={12}>
                              <TextField size='small' disabled label="" placeholder='MM' variant="outlined" color="success" value={subtract?.months} sx={{ width: '100%', background: '#f7f7f7' }} />
                            </Grid>
                            <Grid item sm={2} xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>
                              <Typography sx={{ marginRight: '15px', }}>วัน</Typography>
                            </Grid>
                            <Grid item sm={2} xs={12}>
                              <TextField size='small' label="" disabled placeholder='DD' variant="outlined" color="success" value={subtract?.days} sx={{ width: '100%', background: '#f7f7f7' }} />
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* <Grid item lg={4}>
                    </Grid> */}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <GridField text={<><span style={{ color: 'red' }}>* </span>ผู้แต่งตั้ง</>}>
                  {/* <Autocomplete
                    id="free-solo-demo"
                    // freeSolo
                    inputValue={citizen}
                    onInputChange={(event, newInputValue) => {
                      setCitizen(newInputValue);
                    }}
                    options={appointer?.map((option) => option?.appointerName)}
                    renderInput={(params) => <TextField required placeholder='เลือกผู้เเต่งตั้ง' {...params} size='small' label="" color="success" sx={{ width: '100%' }} />}
                  /> */}
                  <FormControl
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyItems: "center",
                    }}
                    variant="outlined"
                    color="success"
                  >
                    {/* <InputLabel id="demo-simple-select-label">เลือกตำเเหน่ง</InputLabel> */}
                    <Select
                      labelId="demo-simple-select-label"
                      inputProps={{ "aria-label": "Without label" }}
                      // label="เลือกตำเเหน่ง"
                      size='small'
                      value={values?.appointerID}
                      onChange={e => setFieldValue('appointerID', e.target.value)}
                    >
                      {appointer.map((x, i) => <MenuItem key={i} value={x?.appointerID}>{x?.appointerName}</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </GridField>
                <GridField text='สถานะ'>
                  <FormControlLabel control={<Switch name='status' checked={status} onChange={e => setStatus(e.target.checked)} />} label="" />
                </GridField>
                <GridField text='มีสิทธิ์การรับเข็ม'>
                  <FormControlLabel control={<Switch name='isCalPin' checked={isCalPin} onChange={e => setIsCalPin(e.target.checked)} />} label="" />
                </GridField>

                {/* <Grid item xs={12} >
                  <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />
                  <Grid container sx={{ alignItems: 'center' }}>
                    <Grid item lg={2} md={3} xs={12} sx={{ display: 'flex', alignItems: 'start', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>
                      <Typography sx={{ marginRight: '15px', }}>เข้ารวมการประชุม</Typography>
                    </Grid>
                    <Grid item lg={10} md={9} xs={12}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                       
                        <TextField label="" disabled size='small' variant="outlined" color="success" value={yearMeet} onChange={e => setYearMeet(e.target.value)} sx={{ width: '80px', marginRight: '10px', background: '#f7f7f7' }} />
                        <TextField label="" size='small' variant="outlined" color="success" placeholder='วันที่ประชุม' value={dateMeet} onChange={e => setDateMeet(e.target.value)} sx={{ width: '150px', marginRight: '10px', background: '#f7f7f7' }} />
                        <TextField label="" size='small' variant="outlined" color="success" type="text" placeholder='ครั้งที่ประชุม' onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }} value={countMeet} onChange={e => setCountMeet(e.target.value)} sx={{ width: '120px', marginRight: '10px' }} />
                        
                      </Box>
                    </Grid>
                   
                  </Grid>

                </Grid> */}
                <Grid item xs={12} >
                  <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />

                  <Grid container >
                    <Grid item lg={2} md={3} xs={12} sx={{ display: 'flex', alignItems: 'start', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>
                      <Typography sx={{ marginRight: '15px', }}>ระยะเวลาที่ไม่นำไปคำนวณ</Typography>
                    </Grid>
                    <Grid item lg={4} md={9} xs={12}>
                      {
                        breakCal?.map((item, i) =>
                          <>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              {/* <Typography sx={{ marginRight: '10px' }}>ปี</Typography> */}
                              <DesktopDatePicker
                                label=""
                                inputFormat="DD/MM/YYYY"
                                value={item?.startDate}
                                disabled
                                maxDate={endBDate ? new Date(endBDate) : ''}
                                // onChange={(newValue) => { setStartBDate(newValue) }}
                                renderInput={(params) => <TextField size='small' color="success" disabled sx={{ width: '200px',  }}
                                  InputProps={params?.InputProps}
                                  inputRef={params?.inputRef}
                                  // placeholder={params?.inputProps?.placeholder}
                                  // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                                  // onChange={params?.inputProps?.onChange}
                                  // type={params?.inputProps?.type}
                                  inputProps={
                                    {
                                      placeholder: params?.inputProps?.placeholder,
                                      readOnly: true,
                                      value: params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null,
                                      onChange: params?.inputProps?.onChange,
                                      type: params?.inputProps?.type
                                    }
                
                                  }
                                />}
                              />
                              <Typography sx={{ margin: '0 10px' }}>~</Typography>
                              <DesktopDatePicker
                                label=""
                                inputFormat="DD/MM/YYYY"
                                value={item?.endDate}
                                disabled
                                minDate={new Date(startBDate)}
                                // onChange={(newValue) => { setEndBDate(newValue) }}
                                renderInput={(params) => <TextField size='small' color="success" disabled sx={{ width: '200px', }}
                                  InputProps={params?.InputProps}
                                  inputRef={params?.inputRef}
                                  // placeholder={params?.inputProps?.placeholder}
                                  // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                                  // onChange={params?.inputProps?.onChange}
                                  // type={params?.inputProps?.type}
                                  inputProps={
                                    {
                                      placeholder: params?.inputProps?.placeholder,
                                      readOnly: true,
                                      value: params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null,
                                      onChange: params?.inputProps?.onChange,
                                      type: params?.inputProps?.type
                                    }
                
                                  }
                                />}
                              />
                            </Box>
                            <TextField multiline rows={4} disabled size='small' value={item?.remark} placeholder='หมายเหตุ' sx={{ width: '100%', marginTop: '10px' }} />
                            <TextField size='small' disabled value={item?.present} placeholder='ผู้เสนอการหักคำนวณ' sx={{ width: '50%', marginTop: '10px' }} />
                            <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />
                          </>
                        )
                      }

                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {/* <Typography sx={{ marginRight: '10px' }}>ปี</Typography> */}
                        <DesktopDatePicker
                          label=""
                          inputFormat="DD/MM/YYYY"
                          value={startBDate}
                          maxDate={endBDate ? new Date(endBDate) : ''}
                          onChange={(newValue) => { setStartBDate(newValue) }}
                          renderInput={(params) => <TextField size='small' color="success" sx={{ width: '200px' }}
                            InputProps={params?.InputProps}
                            inputRef={params?.inputRef}
                            // placeholder={params?.inputProps?.placeholder}
                            // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                            // onChange={params?.inputProps?.onChange}
                            // type={params?.inputProps?.type}
                            inputProps={
                              {
                                placeholder: params?.inputProps?.placeholder,
                                readOnly: true,
                                value: params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null,
                                onChange: params?.inputProps?.onChange,
                                type: params?.inputProps?.type
                              }
          
                            }
                          />}
                        />
                        <Typography sx={{ margin: '0 10px' }}>~</Typography>
                        <DesktopDatePicker
                          label=""
                          inputFormat="DD/MM/YYYY"
                          value={endBDate}
                          minDate={new Date(startBDate)}
                          onChange={(newValue) => { setEndBDate(newValue) }}
                          renderInput={(params) => <TextField size='small' color="success" sx={{ width: '200px' }}
                            InputProps={params?.InputProps}
                            inputRef={params?.inputRef}
                            // placeholder={params?.inputProps?.placeholder}
                            // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                            // onChange={params?.inputProps?.onChange}
                            // type={params?.inputProps?.type}
                            inputProps={
                              {
                                placeholder: params?.inputProps?.placeholder,
                                readOnly: true,
                                value: params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null,
                                onChange: params?.inputProps?.onChange,
                                type: params?.inputProps?.type
                              }
          
                            }
                          />}
                        />
                      </Box>
                      <TextField multiline rows={4} size='small' value={bComment} onChange={e => setBComment(e.target.value)} placeholder='หมายเหตุ' sx={{ width: '100%', marginTop: '10px' }} />
                      <TextField size='small' value={bPresent} onChange={e => setBPresent(e.target.value)} placeholder='ผู้เสนอการหักคำนวณ' sx={{ width: '50%', marginTop: '10px' }} />
                      <br />
                      <Button disabled={startBDate && endBDate && bPresent !== '' && bComment !== '' ? false : true } onClick={handleBreakCal} variant='outlined' sx={{
                        height: 'fit-content', marginTop: '10px', fontSize: '18px', color: '#0fb123', border: '1px solid #0fb123',
                        ':hover': { border: '1px solid #028b13' }
                      }}>เพิ่ม</Button>


                    </Grid>

                  </Grid>

                  <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />

                </Grid>
             
                <Grid item xs={12}  >
                  <Grid container>
                    <Grid item lg={2} sm={3} xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>
                      <Typography sx={{ marginRight: '15px', }}>แนปไฟล์เอกสารที่เกี่ยวข้อง</Typography>
                    </Grid>
                    <Grid item lg={1.5} sm={3} xs={4}>
                      <input
                        type="file" ref={fileRef} accept="image/*,.pdf,.doc,.docx,.xlsx,.csv,.xls" hidden onChange={handleFileUpload} />
                      <Button variant='outlined' onClick={() => fileRef.current.click()} sx={{
                        height: 'fit-content', fontSize: '18px', color: '#0fb123', border: '1px solid #0fb123',
                        ':hover': { border: '1px solid #028b13' }
                      }}>อัพโหลดไฟล์</Button>
                    </Grid>
                    <Grid item lg={8.5} sm={6} xs={8} sx={{ display: 'flex', alignItems: 'center' }}>
                      {files?.map((x, i) =>
                        <Box sx={{ width: '120px', display: 'flex', alignItems: 'center', border: '1px solid #0089ff', borderRadius: '15px', padding: '3px 5px 3px 10px', marginRight: '10px' }}>
                          <Typography onClick={() => FileDownload(x)} sx={{
                            height: 'auto',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: '1',
                            overflow: 'hidden'
                          }}>{x?.filename}</Typography>
                          <CancelIcon onClick={() => handleRemoveFileUpload(x?.firstname, i)} sx={{ color: '#0089ff' }} />
                        </Box>
                        // <Typography key={i} sx={{ marginRight: '15px', cursor: 'pointer', position: 'relative', color: '#0089ff', transition: '.3s', ':after': { content: '""', position: 'absolute', width: '0%', height: '2px', left: '0px', bottom: '0px', transition: '.3s', backgroundColor: '#0089ff' }, ':hover': { color: '#0062b7', ':after': { width: '100%', backgroundColor: '#0062b7' } } }} onClick={() => FileDownload(x)}>{x?.filename}</Typography>
                      )}
                    </Grid>

                  </Grid>

                </Grid>



                <Grid item xs={12} >
                  <Grid container>
                    <Grid item lg={2} md={3} xs={12} sx={{ display: 'flex', alignItems: 'start', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>
                      <Typography sx={{ marginRight: '15px', }}></Typography>
                    </Grid>
                    <Grid item lg={6} md={9} xs={12}>
                      <Typography sx={{ color: '#d7d7d7', fontSize: '12px' }}>ไฟล์อัพโหลดต้องมีขนาดไม่เกิน 8 MB เเละเป็นไฟล์ PNG, JPG, DOC, XML, PDF  </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} >
                  <Grid container>
                    <Grid item lg={2} md={3} xs={12} sx={{ display: 'flex', alignItems: 'start', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>
                      <Typography sx={{ marginRight: '15px', }}>เหตุผลสิ้นสุดวาระ</Typography>
                    </Grid>
                    <Grid item lg={6} md={9} xs={12}>
                      <TextField label="" size='small' name='remark' placeholder='' value={remark} onChange={e => setRemark(e.target.value)} variant="outlined" multiline
                        rows={4} color="success" sx={{ width: '100%' }} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

            </Box>
          </Container>
          <Box sx={{
            position: 'sticky', bottom: '0px', width: '100%', marginTop: '50px', background: '#fff'
          }}>
            <Divider sx={{ marginBottom: '10px' }} />
            <Container maxWidth='xl' sx={{ height: 'auto', }}>
              <Box sx={{ padding: '5px 20px', display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant='outlined' sx={{
                  width: '150px', height: 'fit-content', fontSize: '18px', color: '#0fb123', border: '1px solid #0fb123',
                  ':hover': { border: '1px solid #028b13' }
                }} component={NavLink} to={`/Committee/${param.id}`}>กลับ</Button>
                <Button variant='contained' type='submit' sx={{ width: '150px', height: 'fit-content', fontSize: '18px', marginLeft: '20px', backgroundColor: '#0fb123', ':hover': { backgroundColor: '#028b13' } }}>บันทึก</Button>
              </Box>
            </Container>
          </Box>

        </form>)}
    </Formik>
  )
}
