import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Container,
  TableRow,
  TableHead,
  Table,
  TableContainer,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Grid,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import Autocomplete from "@mui/material/Autocomplete";
import TitleHead from "../../components/TitleHead";
import { useNavigate, NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import moment from "moment";
import { styled } from "@mui/material/styles";
import api from "../../api/api";
import SearchIcon from "@mui/icons-material/Search";
import mime from "mime";
const data = [];
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#94949473",
    color: theme.palette.common,
    fontWeight: "500",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    /*  backgroundColor: '#F2F5F8', */
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export default function Report() {
  const navigate = useNavigate();

  const [powerBi, setpowerBi] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  //----------------Function post create-------------------
  const handleSubmit = (e) => {
    //e.prevenDefalt();
  };
  // console.log("ff",powerBi.pb_codeEmb1);

  return (
    <>
      <Container maxWidth="xl"
        sx={{ height: "auto", marginTop: "40px", marginBottom: "40px" }}>
        <TitleHead
          title="รายงาน"
          subTitle="รายงานผู้ที่ได้รับเข็มกิตติคุณสถาบันพระปกเกล้า"
        ></TitleHead>


        {/* <Box
          sx={{
            display: "flex",
            color: "#000",
            marginTop: "20px",
            marginLeft: "50px",
            justifyContent: "right",
            
          }}
        >
          <FormControl sx={{ m: 1, marginRight: 0 }} size="small">
            <TextField color="success" sx={{ marginLeft: "5px" }} type="date" />
          </FormControl>
          <FormControl sx={{ m: 1, marginRight: 0 }} size="small">
            <Typography sx={{ mt: 2 }}>~</Typography>
          </FormControl>
          <FormControl sx={{ m: 1, marginRight: 0 }} size="small">
            <TextField color="success" sx={{ marginLeft: "5px" }} type="date" />
          </FormControl>

          <Box sx={{ display: "flex", m: 1, alignItems: "center" }}>
            <TextField
              id="outlined-basic"
              label="กรอกข้อมูลค้นหา"
              variant="outlined"
              color="success"
            />
            <Button
              variant="contained"
              sx={{
                padding: "15px",
                marginLeft: "20px",
                backgroundColor: "#0fb123",
                ":hover": { backgroundColor: "#028b13" },
              }}
            >
              <SearchIcon />
            </Button>
          </Box>
        </Box> */}

        <Grid container spacing={5} sx={{ p: 5 }}>
          <Grid item xs={6}>
            <Box
              sx={{
                width: "100%",
                maxWidth: 600,
                bgcolor: "",
                border: 1,
                borderRadius: 5,
                borderColor: "#CED4DA",
              }}
            >
              <List sx={{ p: 1 }}>
                <ListItem disablePadding>
                  <ListItemButton
                    component={NavLink}
                    to="/Report1"
                  // onClick={async () => {
                  //   await api
                  //     .get("api/v1.0/report/Report1/download", {
                  //       responseType: "blob",
                  //     })
                  //     .then((blobFile) => {
                  //       const blobFileRes = new File(
                  //         [blobFile?.data],
                  //         "Template.xlsx",
                  //         {
                  //           type: mime.getType(".xlsx"),
                  //         }
                  //       );

                  //       window.open(
                  //         URL.createObjectURL(blobFileRes, "Template.xlsx"),
                  //         "_blank"
                  //       );
                  //     });
                  // }}
                  >
                    <ListItemText primary="1. รายงานผู้ได้รับเข็มกิตติคุณสถาบันพระปกเกล้าประจำปี" />
                  </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                  <ListItemButton

                    component={NavLink}
                    to='/Report2'
                  // onClick={async () => {
                  //   await api
                  //     .get("api/v1.0/report/Report2/download", {
                  //       responseType: "blob",
                  //     })
                  //     .then((blobFile) => {
                  //       const blobFileRes = new File(
                  //         [blobFile?.data],
                  //         "Template.xlsx",
                  //         {
                  //           type: mime.getType(".xlsx"),
                  //         }
                  //       );

                  //       window.open(
                  //         URL.createObjectURL(blobFileRes, "Template.xlsx"),
                  //         "_blank"
                  //       );
                  //     });
                  // }}
                  // href="#simple-list"
                  >
                    <ListItemText primary="2. รายงานผู้ได้รับประกาศปิยบัตรชั้นสูงกิตติมศักดิ์ประจำปี" />
                  </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                  <ListItemButton
                    component={NavLink}
                    to='/Report3'
                  // component="a"
                  // onClick={async () => {
                  //   await api
                  //     .get("api/v1.0/report/Report3/download", {
                  //       responseType: "blob",
                  //     })
                  //     .then((blobFile) => {
                  //       const blobFileRes = new File(
                  //         [blobFile?.data],
                  //         "Template.xlsx",
                  //         {
                  //           type: mime.getType(".xlsx"),
                  //         }
                  //       );

                  //       window.open(
                  //         URL.createObjectURL(blobFileRes, "Template.xlsx"),
                  //         "_blank"
                  //       );
                  //     });
                  // }}
                  // href="#simple-list"
                  >
                    <ListItemText primary="3. รายงานผู้ได้รับเข็มซึ่งสามารถเลือกแต่ละชั้นได้" />
                  </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                  <ListItemButton
                    component={NavLink}
                    to='/Report4'
                  // component="a"
                  // onClick={async () => {
                  //   await api
                  //     .get("api/v1.0/report/Report4/download", {
                  //       responseType: "blob",
                  //     })
                  //     .then((blobFile) => {
                  //       const blobFileRes = new File(
                  //         [blobFile?.data],
                  //         "Template.xlsx",
                  //         {
                  //           type: mime.getType(".xlsx"),
                  //         }
                  //       );

                  //       window.open(
                  //         URL.createObjectURL(blobFileRes, "Template.xlsx"),
                  //         "_blank"
                  //       );
                  //     });
                  // }}
                  // href="#simple-list"
                  >
                    <ListItemText primary="4. รายงานผู้ได้รับเข็มกิตติคุณสถาบันพระปกเกล้า ในแต่ละคุณสมบัติ" />
                  </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                  <ListItemButton
                    component={NavLink}
                    to='/Report5'

                  // onClick={async () => {
                  //   await api
                  //     .get("api/v1.0/report/Report5/download", {
                  //       responseType: "blob",
                  //     })
                  //     .then((blobFile) => {
                  //       const blobFileRes = new File(
                  //         [blobFile?.data],
                  //         "Template.xlsx",
                  //         {
                  //           type: mime.getType(".xlsx"),
                  //         }
                  //       );

                  //       window.open(
                  //         URL.createObjectURL(blobFileRes, "Template.xlsx"),
                  //         "_blank"
                  //       );
                  //     });
                  // }}
                  // href="#simple-list"
                  >
                    <ListItemText primary="5. รายงานข้อมูลเข็มกิตติคุณ และประกาศนียบัตรชั้นสูงกิตติมศักดิ์ ของคณะกรรมการแต่ละชุด" />
                  </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                  <ListItemButton
                    component={NavLink}
                    to='/Report6'
                  >
                    <ListItemText primary="6. รายงานทะเบียนเข็มกิตติคุณสถาบันพระปกเกล้า และประกาศนียบัตรชั้นสูงกิตติมศักดิ์" />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                width: "100%",
                maxWidth: 600,
                bgcolor: "",
                border: 1,
                borderRadius: 5,
                borderColor: "#CED4DA",
              }}
            >
              <List sx={{ p: 1 }}>
                {/* <ListItem disablePadding>
                  <ListItemButton
                    component={NavLink}
                    to='/Report6'
                  >
                    <ListItemText primary="6. รายงานทะเบียนเข็มกิตติคุณสถาบันพระปกเกล้า และประกาศนียบัตรชั้นสูงกิตติมศักดิ์" />
                  </ListItemButton>
                </ListItem> */}
               
                <ListItem disablePadding>
                  <ListItemButton
                    component={NavLink}
                    to='/Report7'
                  // onClick={async () => {
                  //   await api
                  //     .get("api/v1.0/report/Report7/download", {
                  //       responseType: "blob",
                  //     })
                  //     .then((blobFile) => {
                  //       const blobFileRes = new File(
                  //         [blobFile?.data],
                  //         "Template.xlsx",
                  //         {
                  //           type: mime.getType(".xlsx"),
                  //         }
                  //       );

                  //       window.open(
                  //         URL.createObjectURL(blobFileRes, "Template.xlsx"),
                  //         "_blank"
                  //       );
                  //     });
                  // }}
                  // href="#simple-list"
                  >
                    <ListItemText primary="7. รายงานประวัติการได้รับเข็ม และเข็มที่จะได้รับในลำดับถัดไป ของแต่ละบุคคล" />
                  </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                  <ListItemButton
                    component={NavLink}
                    to='/Report8'
                  // component="a"
                  // onClick={async () => {
                  //   await api
                  //     .get("api/v1.0/report/Report8/download", {
                  //       responseType: "blob",
                  //     })
                  //     .then((blobFile) => {
                  //       const blobFileRes = new File(
                  //         [blobFile?.data],
                  //         "Template.xlsx",
                  //         {
                  //           type: mime.getType(".xlsx"),
                  //         }
                  //       );

                  //       window.open(
                  //         URL.createObjectURL(blobFileRes, "Template.xlsx"),
                  //         "_blank"
                  //       );
                  //     });
                  // }}
                  // href="#simple-list"
                  >
                    <ListItemText primary="8. รายงานชื่อกรรมการแต่ละชุด ตามช่วงเวลาการดำรงตำแหน่ง" />
                  </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                  <ListItemButton

                    // component="a"
                    // onClick={async () => {
                    //   await api
                    //     .get("api/v1.0/report/Report9/download", {
                    //       responseType: "blob",
                    //     })
                    //     .then((blobFile) => {
                    //       const blobFileRes = new File(
                    //         [blobFile?.data],
                    //         "Template.xlsx",
                    //         {
                    //           type: mime.getType(".xlsx"),
                    //         }
                    //       );

                    //       window.open(
                    //         URL.createObjectURL(blobFileRes, "Template.xlsx"),
                    //         "_blank"
                    //       );
                    //     });
                    // }}
                    component={NavLink}
                    to='/Report9'
                  // href="#simple-list"
                  >
                    <ListItemText primary="9. แบบเสนอรายชื่อผู้ได้รับ/เลื่อนชั้นเข็มกิตติคุณ" />
                  </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                  <ListItemButton
                    component={NavLink}
                    to='/Report10'
                  // component="a"
                  // onClick={async () => {
                  //   await api
                  //     .get("api/v1.0/report/Report10/download", {
                  //       responseType: "blob",
                  //     })
                  //     .then((blobFile) => {
                  //       const blobFileRes = new File(
                  //         [blobFile?.data],
                  //         "Template.xlsx",
                  //         {
                  //           type: mime.getType(".xlsx"),
                  //         }
                  //       );

                  //       window.open(
                  //         URL.createObjectURL(blobFileRes, "Template.xlsx"),
                  //         "_blank"
                  //       );
                  //     });
                  // }}
                  // href="#simple-list"
                  >
                    <ListItemText primary="10. แบบเสนอรายชื่อผู้ได้รับประกาศนียบัตรชั้นสูงกิตติมคักดิ์" />
                  </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                  <ListItemButton
                    component={NavLink}
                    to='/Report11'
                  // component="a"
                  // onClick={async () => {
                  //   await api
                  //     .get("api/v1.0/report/Report10/download", {
                  //       responseType: "blob",
                  //     })
                  //     .then((blobFile) => {
                  //       const blobFileRes = new File(
                  //         [blobFile?.data],
                  //         "Template.xlsx",
                  //         {
                  //           type: mime.getType(".xlsx"),
                  //         }
                  //       );

                  //       window.open(
                  //         URL.createObjectURL(blobFileRes, "Template.xlsx"),
                  //         "_blank"
                  //       );
                  //     });
                  // }}
                  // href="#simple-list"
                  >
                    <ListItemText primary="11. สรุปการบรรยายของวิทยากร(รอบปี)" />
                  </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem disablePadding>
                  <ListItemButton
                    component={NavLink}
                    to='/Report12'
                  // component="a"
                  // onClick={async () => {
                  //   await api
                  //     .get("api/v1.0/report/Report10/download", {
                  //       responseType: "blob",
                  //     })
                  //     .then((blobFile) => {
                  //       const blobFileRes = new File(
                  //         [blobFile?.data],
                  //         "Template.xlsx",
                  //         {
                  //           type: mime.getType(".xlsx"),
                  //         }
                  //       );

                  //       window.open(
                  //         URL.createObjectURL(blobFileRes, "Template.xlsx"),
                  //         "_blank"
                  //       );
                  //     });
                  // }}
                  // href="#simple-list"
                  >
                    <ListItemText primary="12. สรุปรายชื่อวิทยากรที่มีการบรรยายครบตามเงื่อนไขที่สถาบันกำหนด" />
                  </ListItemButton>
                </ListItem>
                <Divider/>
                <ListItem disablePadding>
                  <ListItemButton
                    component={NavLink}
                    to='/Report13'
                  // component="a"
                  // onClick={async () => {
                  //   await api
                  //     .get("api/v1.0/report/Report10/download", {
                  //       responseType: "blob",
                  //     })
                  //     .then((blobFile) => {
                  //       const blobFileRes = new File(
                  //         [blobFile?.data],
                  //         "Template.xlsx",
                  //         {
                  //           type: mime.getType(".xlsx"),
                  //         }
                  //       );

                  //       window.open(
                  //         URL.createObjectURL(blobFileRes, "Template.xlsx"),
                  //         "_blank"
                  //       );
                  //     });
                  // }}
                  // href="#simple-list"
                  >
                    <ListItemText primary="13. รายงานคณะกรรมการแต่ละชุด" />
                  </ListItemButton>
                </ListItem>
                {/* <ListItem disablePadding>
                  <ListItemButton
                    component={NavLink}
                    to='/Report10'
                  // component="a"
                  // onClick={async () => {
                  //   await api
                  //     .get("api/v1.0/report/Report10/download", {
                  //       responseType: "blob",
                  //     })
                  //     .then((blobFile) => {
                  //       const blobFileRes = new File(
                  //         [blobFile?.data],
                  //         "Template.xlsx",
                  //         {
                  //           type: mime.getType(".xlsx"),
                  //         }
                  //       );

                  //       window.open(
                  //         URL.createObjectURL(blobFileRes, "Template.xlsx"),
                  //         "_blank"
                  //       );
                  //     });
                  // }}
                  // href="#simple-list"
                  >
                    <ListItemText primary="11. แบบเสนอรายชื่อผู้ได้รับประกาศนียบัตรชั้นสูงกิตติมคักดิ์" />
                  </ListItemButton>
                </ListItem> */}
              </List>
            </Box>
          </Grid>
        </Grid>

      </Container>
    </>
  );
}
