import {
  Autocomplete,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import TitleHead from "../../components/TitleHead";
import ApiContext from "../../context/ApiProvider";
import {
  DataGrid,
  GridToolbarQuickFilter,
  GridLinkOperator,
} from "@mui/x-data-grid";
import moment from "moment";
import api from "../../api/api";
import CancelIcon from "@mui/icons-material/Cancel";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";

import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { NavLink, useNavigate } from "react-router-dom";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import DialogContentText from '@mui/material/DialogContentText';
import VisibilityIcon from '@mui/icons-material/Visibility';
function QuickSearchToolbar() {
  
  return (
    <Box
      sx={{
        p: 2,
        width: "100%",
        display: "flex",
        justifyContent: "end",
      }}
    >
      <GridToolbarQuickFilter
        quickFilterParser={(searchInput) =>
          searchInput
            .split(",")
            .map((value) => value.trim())
            .filter((value) => value !== "")
        }
      />
    </Box>
  );
}
export default function AddHonor() {
  const fileRef = useRef();
  moment.locale("th");
  const navigate = useNavigate();
  const [boardDepartmentList, setBoardDepartmentList] = useState([]);
  const [honorPinListCandidate, setHonorPinListCandidate] = useState([]);
  const [boardList, setBoardList] = useState([]);
  const [boardSelect, setBoardSelect] = useState("");
  const [open, setOpen] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [datecal, setDateCal] = useState("");
  const [text, setText] = useState("");
  const [textSelect, setTextSelect] = useState("");
  const [textSelect2, setTextSelect2] = useState("");
  const [data2, setData2] = useState([]);
  const [value, setValue] = useState({});
  const [perID, setPer] = useState("");

  const [oldr, setOr] = useState("");
  const [list,setlist] =useState([]);
  console.log(list);
  const [openperID, setPero] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [date, setDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [remark, setRemark] = useState("");
  const {
    getBoardDepartmentList,
    getBoardList,
    getHonorPinListCandidate,
    FileDownload,
  } = useContext(ApiContext);
  const [files, setFiles] = useState([]);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [mPresent, setMPresent] = useState([]);
  let formData = new FormData();
  const handleFileUpload = (event) => {
    if (event.target && event.target.files) {
      let file = event.target.files[0];
      // setFiles([...files, file]);
      const maxAllowedSize = 8 * 1024 * 1024;

      if (file.size < maxAllowedSize) {
        formData.append("file", file);
        // console.log(formData)
        // fileUpload(formData, file, files, setFiles)
        api
          .post("api/v1.0/filemanages/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => res.data)
          .then((data) => {
            if (data.status === true) {
              // console.log(data.result)
              setFiles([...files, data.result]);
            } else {
              console.log(" failed");
            }
          });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "ไฟล์อัพโหลดใหญ่เกินไป",
        });
      }
    }
  };
  const handleText = () => {
    setTextSelect(value.presentFullName);
    setText("");
    setTimeout(() => {
      setTextSelect("");
      setValue(null);
    }, 1000);
  };


  const close =()=>{
    setPero(false)
  }
  const detail = async  (e)=>{

    const data = await api.get(`api/v1.0/lecturer/detail?personalID=${e?.personalID}&startDate=${e?.oldRankDate}`)
    const res = await data?.data?.result
    setPero(true)
    setlist(res)
    
   
  }
 
  

  const handleRemoveFileUpload = (filename, i) => {
    setFiles(
      files.length === 1
        ? files.filter((file) => file.name !== filename)
        : files.splice(i, 1)
    );
  };
  useEffect(() => {
    getBoardDepartmentList(setBoardDepartmentList, "");
  }, []);
  useEffect(() => {
    const param = { id: boardSelect };
    if (boardSelect !== "") {
      getBoardList(setBoardList, param, "", 1);
    }
  }, [boardSelect]);

  const handleGetHonorPinListCandidate = () => {
    const dateFomat = moment(date).format("yyyy-MM-DD");
    getHonorPinListCandidate(setHonorPinListCandidate, dateFomat);
    setDateCal(dateFomat);
    setOpen(true);
  };
  // console.log(datecal)
  const handleClose = () => {
    setOpenPopup(false);
  };
  const getMasterPresent = async () => {
    const res = await api.get(`api/v1.0/master/present/list`);
    const data = await res.data;
    if (data.status === true) {
      setMPresent(data.result);
      // setValue(data?.result?.filter((y) => y?.presentFullName === x?.present)[0])
      // console.log(data.result);
    }
  };
  useEffect(() => {
    getMasterPresent();
  }, []);
  const columns = [
    // { field: 'id', headerName: 'ID', width: 50 },
    { field: "title", headerName: "คำนำหน้า", width: 100 },
    {
      field: "firstName",
      headerName: "ชื่อ",
      width: 150,
      editable: false,
    },
    {
      field: "lastName",
      headerName: "นามสกุล",
      width: 150,
      editable: false,
    },
    {
      field: "cerName",
      headerName: "คุณสมบัติ",
      width: 220,
      editable: false,
    },
    {
      field: "boardDepName",
      headerName: "ชุดคณะกรรมการ",
      width: 180,
      editable: false,
    },
    {
      field: "oldRankDate",
      headerName: "ปีที่ได้รับ",
      width: 80,
      editable: false,
    },
    {
      field: "oldRank",
      headerName: "ชั้น-เข็มปัจจุบัน",
      width: 110,
      editable: false,
    },
    {
      field: "rankCounter",
      headerName: "ระยะเวลาดำรงตำเเหน่ง/จำนวนบริจาค",
      width: 250,
      editable: false,
      renderCell: (params) => {
        return (
          <>
            <Typography>{params.row.rankCounter}</Typography>
            {params.row.rankCounter.includes("ผ่านเกณฑ์") ? (
              <VisibilityIcon sx={{marginLeft:'15px'}}  onClick={()=>detail(params.row)}/>
              // <Button
              //   onClick={()=>detail(params.row)}
              // >
              //   d
              // </Button>
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      field: "rank",
      headerName: "ชั้น-เข็มใหม่",
      width: 110,
      editable: false,
    },
    {
      field: "present",
      headerName: "ผู้เสนอ",
      width: 150,
      editable: false,
    },
  ];
  const rows = honorPinListCandidate?.map((x, i) => {
    const date = moment.duration(x?.rankCounter * 24 * 60 * 60 * 1000)._data;

    x.id = x?.seq;
    x.title = x?.title;
    x.firstName = x?.firstname;
    x.lastName = x?.lastname;
    x.cerName = x?.cerName;
    x.personalID = x?.personalID;
    x.boardDepName = x?.boardDepName;
    x.oldRankDate = x?.oldRankDate
      ? x?.oldRankDate.includes("-")
        ? moment(x?.oldRankDate).format("DD/MM/yyyy").substring(6) === "d date"
          ? ""
          : moment(x?.oldRankDate)
              .add(543, "year")
              .format("DD/MM/yyyy")
              .substring(6)
        : x?.oldRankDate
      : "-";
    x.oldRank = x?.oldRank
      ? x?.oldRank.includes("ชั้นที่")
        ? x?.oldRank
        : `ชั้นที่ ` + x?.oldRank
      : null;
    x.rankCounter = x?.rankCounter
      ? x?.cerName?.includes("วิทยากร")
        ? "ผ่านเกณฑ์"
        : x?.cerName?.includes("ผู้บริจาคเงิน")
        ? x?.rankCounter?.includes("บาท")
          ? x?.rankCounter
          : `${parseFloat(x?.rankCounter, 10)
              .toFixed(2)
              .replace(/\d(?=(\d{3})+\.)/g, "$&,")} บาท`
        : x?.cerName?.includes("ผู้บำเพ็ญประโยชน์")
        ? x?.rankCounter.includes("ครั้ง")
          ? x?.rankCounter
          : x?.rankCounter != 0
          ? x?.rankCounter === "กรณีพิเศษ"
            ? "กรณีพิเศษ"
            : x?.rankCounter + " ครั้ง"
          : "กรณีพิเศษ"
        : x?.rankCounter?.includes("ปี")
        ? x?.rankCounter
        : `${date?.years} ปี ${date?.months} เดือน ${date?.days} วัน`
      : "-";
    x.rank = x?.rank
      ? x?.rank.includes("ชั้นที่")
        ? x.rank
        : `ชั้นที่ ` + x?.rank
      : null;
    x.present =
      textSelect === ""
        ? x.present
        : selectionModel?.filter((y) => y === x?.seq)[0] == x?.seq
        ? textSelect
        : x.present;
    return x;
  });
  // const data = honorPinListCandidate?.map((x, i) => {
  //     const date = moment.duration(x?.rankCounter * 24 * 60 * 60 * 1000)._data
  //     return {
  //         id: i + 1,
  //         title: x?.title,
  //         firstName: x?.firstname,
  //         lastName: x?.lastname,
  //         cerName: x?.cerName,
  //         oldRankDate: moment(x?.oldRankDate).add(543, 'year').format('DD/MM/yyyy').substring(6) === 'd date' ? '' : moment(x?.oldRankDate).add(543, 'year').format('DD/MM/yyyy').substring(6),
  //         oldRank: x?.oldRank ? `ชั้นที่ ` + x?.oldRank : '',
  //         rankCounter: x?.cerName?.includes('ผู้บริจาคเงิน') ? `${parseFloat(x?.rankCounter, 10).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} บาท` : `${date?.years} ปี ${date?.months} เดือน ${date?.days} วัน`,
  //         rank: x?.rank ? `ชั้นที่ ` + x?.rank : '',
  //         // present: ''
  //     }

  // });

  // const rows = data?.map((x, i) => {

  //     x.present = textSelect === '' ? x.present : selectionModel?.filter(y => y === i + 1)[0] == i + 1 ? textSelect : x.present

  //     return x
  // });

  // console.log(honorPinListCandidate)
  const handleSubmit = (value) => {
    const body = {
      calDate: moment(datecal).format("YYYY-MM-DD HH:mm"),
      // endDate: moment(endDate).format('YYYY-MM-DD') === 'Invalid date' ? null : moment(endDate).format('YYYY-MM-DD'),
      considerDateFirst:
        moment(startDate).format("YYYY-MM-DD") === "Invalid date"
          ? null
          : moment(startDate).format("YYYY-MM-DD"),
      approveDateFirst:
        moment(endDate).format("YYYY-MM-DD") === "Invalid date"
          ? null
          : moment(endDate).format("YYYY-MM-DD"),
      remarkFirst: remark,
      cerDetail: honorPinListCandidate?.map((x, i) => {
        return {
          seq: x?.seq,
          cerID: x?.cerID,
          cerName: x?.cerName,
          personalID: x?.personalID,
          positionName: x?.positionName,
          rank: x?.rank ? x?.rank?.replace("ชั้นที่ ", "") : null,
          cerCodition: x?.cerCodition,
          workStartDate: x?.workStartDate,
          workEndDate: x?.workEndDate,
          appreciateTypeID: x?.appreciateTypeID,
          oldRankDate: x?.oldRankDate,
          oldRank: x?.oldRank ? x?.oldRank?.replace("ชั้นที่ ", "") : null,
          status: selectionModel?.filter((y) => y === x?.seq)[0] == x?.seq,
          isApproveFirst:
            selectionModel?.filter((y) => y === x?.seq)[0] == x?.seq,
          isApproveSecond:
            selectionModel?.filter((y) => y === x?.seq)[0] == x?.seq,
          isReceive: selectionModel?.filter((y) => y === x?.seq)[0] == x?.seq,

          present: rows?.filter((y, index) => index == i)[0]?.present || "",
        };
      }),
      cerBoardDep: boardList?.map((x) => {
        return {
          title: x?.personal?.title?.titleNameTH,
          firstname: x?.personal?.firstname,
          lastname: x?.personal?.lastname,
          position: x?.newPosition?.positionName,
        };
      }),
      cerFileAttach: files.map((item) => ({
        cerAttachID: "",
        fileID: item.fileID,
        status: true,
      })),
      boardDepName: boardDepartmentList
        .filter((x) => x.boardDepID === boardSelect)
        .map((y) => y.boardDepName)[0],
      status: value,
    };
    // console.log(body)
    const postHonorPin = async () => {
      const res = await api.post(`api/v1.0/honor-pin/create`, body);
      const data = await res.data;
      // console.log(data)
      if (data.status === true) {
        setOpenPopup(false);
        // const MySwal = withReactContent(Swal)

        // MySwal.fire({
        //     title: <p>สร้างข้อมูลสำเร็จ</p>,

        //     icon: "success",

        //     didOpen: () => {
        //         // `MySwal` is a subclass of `Swal` with all the same instance & static methods

        //         MySwal.clickConfirm();
        //     },
        // }).then(() => {
        //     return MySwal.fire({
        //         title: 'บันทึกแล้ว',
        //         text: "บันทึกข้อมูลสำเร็จ",
        //         icon: "success",
        //         confirmButtonColor: '#0f962d',
        //         confirmButtonText: 'ตกลง',
        //         allowOutsideClick: false
        //     }

        //     ).then((result) => {
        //         if (result.isConfirmed) {
        //             window.location = `/HonorPin`
        //         }
        //     });
        // });
        Swal.fire({
          title: "สร้างแล้ว",
          text: "สร้างข้อมูลสำเร็จ",
          icon: "success",
          confirmButtonColor: "#0f962d",
          confirmButtonText: "ตกลง",
          allowOutsideClick: false,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            navigate(`/HonorPin/DetailHonorPin/${data?.result?.tscHeadCerID}`);
          }
        });
      }
    };
    postHonorPin();
  };

  return (
    <>
      <Container maxWidth="xl" sx={{ height: "auto", marginTop: "40px" }}>
        <TitleHead
          title="ข้อมูลการรับเข็มกิตติคุณ"
          subTitle="ประวัติการรับเข็มกิตติคุณ"
        ></TitleHead>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Dialog
            open={openperID}
            maxWidth= 'lg'
            onClose={close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
            <TableContainer  sx={{ marginTop: "20px" }}>
            <Table sx={{ minWidth: 600 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                 
                  <TableCell
                    align="left"
                    sx={{ fontSize: "18px", fontWeight: "400" }}
                  >
                    หลักสูตร
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ fontSize: "18px", fontWeight: "400" }}
                  >
                  รุ่น
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ fontSize: "18px", fontWeight: "300" }}
                  >
                    วันที่สอน
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ fontSize: "18px", fontWeight: "400" }}
                  >
                    วิชาที่สอน
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ fontSize: "18px", fontWeight: "400" }}
                  >
                    เวลา
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{overflowY:'auto',height:'600'}}>
                {list?.map((e,i)=>(
                  <TableRow key={i}>
                    <TableCell  sx={{ fontSize: "18px", fontWeight: "400" }}>{e?.certificate}</TableCell>
                    <TableCell  sx={{ fontSize: "18px", fontWeight: "400" }}>{e?.gen}</TableCell>
                    <TableCell  sx={{ fontSize: "18px", fontWeight: "400" }}>{moment(e?.lecDate).format('DD/MM/YYYY')}</TableCell>
                    <TableCell  sx={{ fontSize: "18px", fontWeight: "400" }}>{e?.course}</TableCell>
                    <TableCell  sx={{ fontSize: "18px", fontWeight: "400" }}>{e?.time}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
              </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              {/* <Button onClick={handleClose}>Disagree</Button>
              <Button onClick={handleClose} autoFocus>
                Agree
              </Button> */}
            </DialogActions>
          </Dialog>
          <FormControl
            sx={{ m: 1, minWidth: "20%", marginRight: 3 }}
            color="success"
          >
            <InputLabel>เลือกชุดคณะกรรมการพิจารณาเข็มกิติคุณ</InputLabel>
            <Select
              // open={open}
              // onClose={handleClose}
              // onOpen={handleOpen}
              // defaultValue={1}
              value={boardSelect}
              onChange={(e) => setBoardSelect(e.target.value)}
              label="เลือกชุดคณะกรรมการพิจารณาเข็มกิติคุณ"
            >
              {boardDepartmentList?.map((item, i) => (
                <MenuItem key={i} value={item?.boardDepID}>
                  {item?.boardDepName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* <Box sx={{ display: 'flex', justifyContent: 'space-between' }}> */}
          <Typography sx={{ color: "#1fc64c" }}>
            รายชื่อคณะกรรมการพิจารณาทั้งหมด {boardList?.length} ท่าน
          </Typography>
          {/* </Box> */}
        </Box>

        <Grid
          container
          spacing={2}
          sx={{ marginTop: "15px", marginBottom: "30px" }}
        >
          {boardList?.map((board, i) => (
            <Grid key={i} item lg={4}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "10px",
                }}
              >
                <Typography variant="h6">{`${board?.personal?.title?.titleNameTH} ${board?.personal?.firstname} ${board?.personal?.lastname}`}</Typography>
                <Typography sx={{ color: "#1fc64c" }}>
                  {board?.newPosition?.positionName}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Typography sx={{ marginBottom: "15px" }}>
          เเนบไฟล์เอกสารที่เกี่ยวข้อง
        </Typography>
        <input
          type="file"
          ref={fileRef}
          accept="image/*,.pdf,.doc,.docx,.xlsx,.csv,.xls"
          hidden
          onChange={handleFileUpload}
        />
        <Button
          variant="outlined"
          onClick={() => fileRef.current.click()}
          sx={{
            height: "fit-content",
            fontSize: "18px",
            color: "#0fb123",
            border: "1px solid #0fb123",
            ":hover": { border: "1px solid #028b13" },
          }}
        >
          อัพโหลดไฟล์
        </Button>

        {files?.map(
          (x, i) => (
            <Box
              sx={{
                width: "120px",
                display: "flex",
                alignItems: "center",
                border: "1px solid #0089ff",
                borderRadius: "15px",
                padding: "3px 5px 3px 10px",
                marginRight: "10px",
                marginTop: "20px",
              }}
            >
              <Typography
                onClick={() => FileDownload(x)}
                sx={{
                  height: "auto",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: "1",
                  overflow: "hidden",
                }}
              >
                {x?.filename}
              </Typography>
              <CancelIcon
                onClick={() => handleRemoveFileUpload(x?.firstname, i)}
                sx={{ color: "#0089ff" }}
              />
            </Box>
          )
          // <Typography key={i} sx={{ marginRight: '15px', cursor: 'pointer', position: 'relative', color: '#0089ff', transition: '.3s', ':after': { content: '""', position: 'absolute', width: '0%', height: '2px', left: '0px', bottom: '0px', transition: '.3s', backgroundColor: '#0089ff' }, ':hover': { color: '#0062b7', ':after': { width: '100%', backgroundColor: '#0062b7' } } }} onClick={() => FileDownload(x)}>{x?.filename}</Typography>
        )}
        <Divider sx={{ marginTop: "40px" }} />

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <DesktopDatePicker
            label=""
            inputFormat="DD/MM/YYYY"
            value={date}
            // onViewChange={(newValue) }
            onChange={(newValue) => {
              setDate(newValue);
            }}
            renderInput={(params) => (
              <TextField
                size="small"
                color="success"
                sx={{
                  width: "200px",
                  marginTop: "23px",
                  display: open ? "none" : "block",
                }}
                InputProps={params?.InputProps}
                inputRef={params?.inputRef}
                // placeholder={params?.inputProps?.placeholder}
                // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                // onChange={params?.inputProps?.onChange}
                // type={params?.inputProps?.type}
                inputProps={{
                  placeholder: params?.inputProps?.placeholder,
                  readOnly: true,
                  value: params?.inputProps?.value
                    ? params?.inputProps?.value?.substring(
                        0,
                        params?.inputProps?.value?.length - 4
                      ) +
                      `${
                        +params?.inputProps?.value?.substring(
                          params?.inputProps?.value?.length - 4
                        ) + 543
                      }`
                    : null,
                  onChange: params?.inputProps?.onChange,
                  type: params?.inputProps?.type,
                }}
              />
            )}
          />
          <Button
            variant="contained"
            onClick={handleGetHonorPinListCandidate}
            sx={{
              display: open ? "none" : "block",
              backgroundColor: "#1fc64c",
              "&:hover": { background: "#4caf50" },
              color: "#FFFF",
              padding: "7px 55px",
              marginTop: "23px",
              marginLeft: "10px",
            }}
          >
            ดึงฐานข้อมูลประวัติ
          </Button>
        </Box>
        <Typography
          sx={{
            display: open ? "block" : "none",
            marginBottom: "15px",
            marginTop: "40px",
            fontSize: "18px",
            color: "#0fb123",
          }}
        >
          วันที่คำนวณ {moment(datecal).add(543, "year").format("LL")}
        </Typography>

        {/* <Typography sx={{ display: open ? 'block' : 'none', marginBottom: '30px', marginTop: '40px', fontSize: '18px', color: '#0fb123' }}>วันที่สร้าง {moment(datecal).format('YYYY-MM-DD HH:mm')}</Typography> */}
        <Box
          sx={{
            color: "#4caf50",
            display: selectionModel?.length < 1 ? "none" : "flex",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          {/* <TextField id="outlined-basic" label="กรอกข้อมูลผู้เสนอ" value={text} onChange={e => setText(e.target.value)} variant="outlined" color="success" size='small' /> */}
          <Autocomplete
            size="small"
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            options={mPresent}
            getOptionLabel={(option) =>
              option?.presentFullName ? option?.presentFullName : ""
            }
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="เลือกผู้เสนอ"
                sx={{ width: "400px" }}
              />
            )}
          />
          <Button
            variant="contained"
            onClick={handleText}
            sx={{
              backgroundColor: "#1fc64c",
              "&:hover": { background: "#4caf50" },
              color: "#FFFF",
            }}
          >
            ตกลง
          </Button>
        </Box>
        {/* <TextField id="outlined-basic" label="กรอกข้อมูล" value={text} onChange={e => setText(e.target.value)} variant="outlined" color="success" sx={{ display: selectionModel.length > 0 ? 'block' : 'none', }} /> */}

        <Box
          sx={{
            height: 600,
            width: "100%",
            margin: "40px 0",
            display: open ? "block" : "none",
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[5]}
            checkboxSelection
            disableSelectionOnClick
            disableColumnMenu
            experimentalFeatures={{ newEditingApi: true }}
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
            initialState={{
              filter: {
                filterModel: {
                  items: [],
                  quickFilterLogicOperator: GridLinkOperator.Or,
                },
              },
            }}
            components={{ Toolbar: QuickSearchToolbar }}
          />
        </Box>
        <Box sx={{ display: open ? "none" : "block", height: "20vh" }}></Box>
      </Container>
      <Box
        sx={{
          position: "sticky",
          bottom: "0px",
          width: "100%",
          marginTop: "50px",
          zIndex: "100",
          background: "#fff",
        }}
      >
        <Divider sx={{ marginBottom: "10px" }} />
        <Container maxWidth="xl" sx={{ height: "auto" }}>
          <Box
            sx={{
              padding: "5px 20px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="outlined"
              component={NavLink}
              to={`/HonorPin`}
              sx={{
                width: "150px",
                height: "fit-content",
                fontSize: "18px",
                color: "#0fb123",
                border: "1px solid #0fb123",
                ":hover": { border: "1px solid #028b13" },
              }}
            >
              กลับ
            </Button>
            {open && boardSelect ? (
              <>
                <Button
                  onClick={() => handleSubmit("D")}
                  variant="contained"
                  type="submit"
                  sx={{
                    width: "150px",
                    height: "fit-content",
                    fontSize: "18px",
                    marginLeft: "20px",
                    backgroundColor: "#0fb123",
                    ":hover": { backgroundColor: "#028b13" },
                  }}
                >
                  บันทึก
                </Button>
                <Button
                  onClick={() => setOpenPopup(true)}
                  variant="contained"
                  type="submit"
                  sx={{
                    width: "150px",
                    height: "fit-content",
                    fontSize: "18px",
                    marginLeft: "20px",
                  }}
                >
                  พิจารณา
                </Button>
                {/* <Button onClick={() => handleSubmit('A')} variant='contained' type='submit' sx={{ width: '150px', height: 'fit-content', fontSize: '18px', marginLeft: '20px', }}>อนุมัติ</Button> */}
              </>
            ) : (
              ""
            )}
          </Box>
        </Container>
        <Dialog
          maxWidth="md"
          fullWidth={true}
          open={openPopup}
          onClose={handleClose}
        >
          <DialogTitle sx={{ fontSize: "2rem" }}>การพิจารณา</DialogTitle>
          <Divider sx={{ marginBottom: "20px" }} />
          <DialogContent sx={{ padding: "10px 60px" }}>
            <Box sx={{ height: "auto", width: "100%" }}>
              <Grid container spacing={2}>
                <Grid
                  item
                  lg={2}
                  md={3}
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: { sm: "flex-end", xs: "flex-start" },
                  }}
                >
                  <Typography sx={{ marginRight: "15px" }}>
                    วันที่พิจารณา
                  </Typography>
                </Grid>

                <Grid item lg={9} md={9} xs={12}>
                  <DesktopDatePicker
                    label=""
                    inputFormat="DD/MM/YYYY"
                    value={startDate}
                    maxDate={endDate ? new Date(endDate) : ""}
                    // onViewChange={(newValue) }
                    onChange={(newValue) => {
                      setStartDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        color="success"
                        sx={{ width: "100%" }}
                        InputProps={params?.InputProps}
                        inputRef={params?.inputRef}
                        // placeholder={params?.inputProps?.placeholder}
                        // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                        // onChange={params?.inputProps?.onChange}
                        // type={params?.inputProps?.type}
                        inputProps={{
                          placeholder: params?.inputProps?.placeholder,
                          readOnly: true,
                          value: params?.inputProps?.value
                            ? params?.inputProps?.value?.substring(
                                0,
                                params?.inputProps?.value?.length - 4
                              ) +
                              `${
                                +params?.inputProps?.value?.substring(
                                  params?.inputProps?.value?.length - 4
                                ) + 543
                              }`
                            : null,
                          onChange: params?.inputProps?.onChange,
                          type: params?.inputProps?.type,
                        }}
                      />
                    )}
                  />
                </Grid>
                {/* <Grid item lg={2} md={3} xs={12} sx={{ display: 'flex', alignItems: 'start', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>
                                    <Typography sx={{ marginRight: '15px', }}>วันที่อนุมัติ</Typography>
                                </Grid>


                                <Grid item lg={9} md={9} xs={12}>

                                    <DesktopDatePicker
                                        label=""
                                        inputFormat="DD/MM/YYYY"
                                        value={endDate}
                                        minDate={new Date(startDate)}
                                        onChange={(newValue) => { setEndDate(newValue) }}
                                        renderInput={(params) => <TextField size='small' color="success" sx={{ width: '100%' }}
                                            InputProps={params?.InputProps}
                                            inputRef={params?.inputRef}
                                            // placeholder={params?.inputProps?.placeholder}
                                            // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                                            // onChange={params?.inputProps?.onChange}
                                            // type={params?.inputProps?.type}
                                            inputProps={
                                                {
                                                  placeholder: params?.inputProps?.placeholder,
                                                  readOnly: true,
                                                  value: params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null,
                                                  onChange: params?.inputProps?.onChange,
                                                  type: params?.inputProps?.type
                                                }
                            
                                              }
                                        />}
                                    />

                                </Grid> */}

                <Grid
                  item
                  lg={2}
                  md={3}
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: { sm: "flex-end", xs: "flex-start" },
                  }}
                >
                  <Typography sx={{ marginRight: "15px" }}>เหตุผล</Typography>
                </Grid>

                <Grid item lg={9} md={9} xs={12}>
                  <TextField
                    label=""
                    size="small"
                    value={remark}
                    onChange={(e) => setRemark(e.target.value)}
                    multiline
                    rows={6}
                    variant="outlined"
                    color="success"
                    type="text"
                    sx={{ width: "100%" }}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button
              onClick={handleClose}
              variant="outlined"
              sx={{
                width: "150px",
                height: "fit-content",
                fontSize: "18px",
                color: "#0fb123",
                border: "1px solid #0fb123",
                ":hover": { border: "1px solid #028b13" },
              }}
            >
              ยกเลิก
            </Button>
            {startDate ? (
              <Button
                onClick={() => handleSubmit("P")}
                variant="contained"
                sx={{
                  width: "150px",
                  height: "fit-content",
                  fontSize: "18px",
                  marginLeft: "20px",
                  backgroundColor: "#0fb123",
                  ":hover": { backgroundColor: "#028b13" },
                }}
              >
                ยืนยัน
              </Button>
            ) : (
              ""
            )}
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
}
