import React, { useContext, useEffect, useState } from "react";
import {
    Box,
    Typography,
    Button,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    TextField,
    Container,
    Menu,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { useNavigate, NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AuthContext from "../../context/ApiProvider";
import moment from "moment";
import Switch from "@mui/material/Switch";

import SearchIcon from "@mui/icons-material/Search";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";

import api from "../../api/api";
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
        /*  backgroundColor: '#F2F5F8', */
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

function createData(index, img, name, user, date) {
    return { index, img, name, user, date };
}

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
        "& .MuiSwitch-thumb": {
            width: 15,
        },
        "& .MuiSwitch-switchBase.Mui-checked": {
            transform: "translateX(9px)",
        },
    },
    "& .MuiSwitch-switchBase": {
        padding: 2,
        "&.Mui-checked": {
            transform: "translateX(12px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                opacity: 1,
                backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
            },
        },
    },
    "& .MuiSwitch-thumb": {
        boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(["width"], {
            duration: 200,
        }),
    },
    "& .MuiSwitch-track": {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === "dark"
                ? "rgba(255,255,255,.35)"
                : "rgba(0,0,0,.25)",
        boxSizing: "border-box",
    },
}));
export default function PresentList() {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openM = Boolean(anchorEl);
    const [checked, setChecked] = React.useState(true);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setOpen(false);
        setAnchorEl(null);
    };

    const [presentList, setPresentList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const getPresent = async () => {
        setIsLoading(true)
        await api
            .get(`/api/v1.0/setting/present/list`)
            .then((res) => res.data)
            .then((data) => {
                if (data.status === true) {
                    setPresentList(data.result);
                    setIsLoading(false)
                } else {
                    console.log("failed");
                    setIsLoading(false)
                }
            });
    }
    useEffect(() => {
        getPresent()
    }, []);

    return (
        <>
            {!isLoading ? (
                <Box sx={{ width: "100%" }}>
                    <Box
                        sx={{
                            display: "flex",
                            color: "#000",
                            marginTop: "50px",
                            marginLeft: "65px",
                        }}
                    >
                        <Typography variant="h4">ผู้เสนอ</Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "right",
                            width: "100%",
                            marginTop: "-70px",
                            paddingRight: "70px",
                        }}
                    >
                        <Button
                            onClick={() => navigate("/AddPresent")}
                            sx={{
                                backgroundColor: "#1fc64c",
                                "&:hover": { background: "#4caf50" },
                                color: "#FFFF",
                                padding: "7px 55px",
                                marginTop: "23px",
                            }}
                        >
                            เพิ่มผู้เสนอ
                        </Button>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            color: "#949494",
                            marginTop: "10px",
                            marginLeft: "65px",
                        }}
                    >
                        <Typography>
                            เพิ่มผู้เสนอได้ที่นี่
                        </Typography>
                    </Box>
                    <Divider
                        sx={{ width: "1485px", marginTop: "10px", marginLeft: "65px" }}
                    />
                    {/* <Box
          sx={{
            display: "flex",
            color: "#000",
            marginTop: "20px",
            marginLeft: "50px",
            justifyContent: "right",
            paddingRight: "70px",
          }}
        >
          <FormControl
            sx={{ m: 1, minWidth: 100, marginRight: 0 }}
            size="small"
          >
            <TextField
              sx={{ width: "250px" }}
              id="outlined-basic"
              label="กรอกข้อมูลค้นหา"
              variant="outlined"
              //placeholder="กรอกข้อมูลค้นหา"
              size="small"
            />
          </FormControl>
          <FormControl
            sx={{ m: 1, minWidth: 10, marginRight: 0 }}
            size="small"
          >
            <Button
              onClick={() => navigate("/")}
              sx={{
                backgroundColor: "#1fc64c",
                "&:hover": { background: "#4caf50" },
                color: "#FFFF",
                padding: "7px",
              }}
            >
              <SearchIcon />
            </Button>
          </FormControl>
        </Box> */}

                    <Container maxWidth="xl">
                        <Stack spacing={3}>
                            <TableContainer sx={{ marginTop: "20px", marginBottom: "80px" }}>
                                <Table
                                    sx={{
                                        minWidth: 400,
                                        borderCollapse: "inherit",
                                        borderSpacing: "0",
                                    }}
                                >
                                    <TableHead sx={{ backgroundColor: "#e7e7e7" }}>
                                        <TableRow>
                                            {/*  <TableCell align="left" sx={{ fontSize: "18px", fontWeight: "600", color: '#3a3a3ae6', border: 'none' }}>#</TableCell> */}
                                            <TableCell
                                                align="left"
                                                sx={{
                                                    fontSize: "18px",
                                                    fontWeight: "600",
                                                    color: "#3a3a3ae6",
                                                    border: "none",
                                                }}
                                            >
                                                ชื่อผู้เสนอ
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                sx={{
                                                    fontSize: "18px",
                                                    fontWeight: "600",
                                                    color: "#3a3a3ae6",
                                                    border: "none",
                                                }}
                                            >
                                                ชื่อย่อ
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                sx={{
                                                    fontSize: "18px",
                                                    fontWeight: "600",
                                                    color: "#3a3a3ae6",
                                                    border: "none",
                                                }}
                                            >
                                                สถานะ
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                sx={{
                                                    fontSize: "18px",
                                                    fontWeight: "600",
                                                    color: "#3a3a3ae6",
                                                    border: "none",
                                                }}
                                            ></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {presentList.map((row, i) => (
                                            <StyledTableRow
                                                key={i}
                                                /* onClick={() => navigate(`/ProjectSource/SearchPoliticalMinor`)} */
                                                sx={{
                                                    fontSize: "18px",
                                                    cursor: "pointer",
                                                    "&:hover": {
                                                        background: "#f3f2f2",
                                                    },
                                                }}
                                            >
                                                <TableCell sx={{ fontSize: "17px" }}>
                                                    {row?.presentFullName}
                                                </TableCell>
                                                {/* <TableCell>
                                                  <Box component='img' sx={{ maxwidth: '150px', height: '70px' }} src={democracy} alt='logo' />
                                              </TableCell> */}
                                                <TableCell align="left" sx={{ fontSize: "17px" }}>
                                                    {row?.presentShortName}
                                                </TableCell>
                                                <TableCell align="left" sx={{ fontSize: "17px" }}>
                                                    <AntSwitch
                                                        type="checkbox"
                                                        name="status"
                                                        checked={row?.status}
                                                        onChange={async () => {
                                                            const body = {
                                                                ...row,
                                                                status: !row?.status,
                                                            };
                                                            const res = await api.post(
                                                                "api/v1.0/setting/present/update",
                                                                body
                                                            );
                                                            if (res?.data?.status) {
                                                                getPresent();
                                                            }
                                                        }}
                                                        inputProps={{ "aria-label": "ant design" }}
                                                        sx={{ marginLeft: "10px" }}
                                                    />
                                                </TableCell>
                                                {/*  <TableCell align="left" sx={{ fontSize: "17px" }}>{moment(row.createDate).format("DD-MM-YYYY")}</TableCell> */}
                                                <TableCell align="left">
                                                    <ThreeDotsMenu data={row} />
                                                </TableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Stack>
                    </Container>
                </Box>
            ) : (
                "..."
            )}
        </>
    )
}


const ThreeDotsMenu = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    function handleMenuClick(Val1) {
        // console.log(Val1);
    }

    const handleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const { data } = props;
    return (
        <>
            <React.Fragment>
                <MoreHorizIcon
                    onClick={handleClick}
                    sx={{ cursor: "pointer", fontSize: "25px" }}
                />

                <Menu
                    id="dense-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    // elevation={0}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    sx={{
                        ".MuiPopover-paper": {
                            boxShadow: "unset",
                            border: "0.5px red",
                            color: "#3a3a3ae6",
                            backgroundColor: "#f9f9f9",
                            borderRadius: "5px",
                            // border: 'px solid',
                        },
                    }}
                >
                    <MenuItem
                        sx={{
                            width: "120px",
                            fontSize: "20px",
                            color: "#00695C",
                        }}
                        component={NavLink}
                        to={`/Present/PresentDetail/${data.presentID}/views`}
                    >
                        {" "}
                        <RemoveRedEyeIcon sx={{ marginRight: "15px" }} />
                        ดู
                    </MenuItem>
                    <MenuItem
                        sx={{
                            width: "120px",
                            fontSize: "20px",
                            color: "#00695C",
                        }}
                        component={NavLink}
                        to={`/Present/PresentEdit/${data.presentID}`}
                    >
                        {" "}
                        <EditRoundedIcon sx={{ marginRight: "15px" }} />
                        เเก้ไข
                    </MenuItem>
                </Menu>
            </React.Fragment>
        </>
    );
};
