import { Typography, Toolbar, Box, Divider, Menu, MenuItem } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import kpi from '../../assets/image/kpi_logo_text.png';
import { useDispatch, useSelector } from 'react-redux';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import api from '../../api/api';
import { Link, NavLink } from 'react-router-dom';
export default function Header({ openHome, openNoti, openSett, setOpenHome, setOpenNoti, setOpenSett, }) {
    const { profile } = useSelector(state => state.profile)
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openM = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setOpen(false);
        setAnchorEl(null);
      };
    const [list, setlist] = useState([]);
    const handleDrawerHome = () => {
        setOpenHome(true);
        setOpenNoti(false)
        setOpenSett(false)
    };
    const handleDrawerNoti = () => {
        const body = list.filter(e => e.isRead === false).map((e) => {
            return {
                notificationID: e.notificationID, isRead: true
            }
        })
        api.post('api/v1.0/notification/read', body).then((res) => {

            api.get('api/v1.0/notification/list').then((res) => {
                setlist(res?.data?.result);


            })
        })




        setOpenHome(false);
        setOpenNoti(true)
        setOpenSett(false)




    };
    const handleDrawerSett = () => {
        setOpenHome(false);
        setOpenNoti(false)
        setOpenSett(true)
    };
    useEffect(() => {

        api.get('api/v1.0/notification/list').then((res) => {
            setlist(res?.data?.result);


        })
    }, [])

    const handleLogOut = async (event) => {
        // event.preventDefault();
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("ActiveTimeOut");
        window.location = "/Login";
      };

    return (
        <>
            <div>
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <img src={kpi} width='180px' height='auto' alt='' />
                    <Box sx={{ display: 'flex', color: '#939393', cursor:"pointer" }} onClick={handleClick}>
                        <PersonIcon sx={{ marginRight: '10px' }} />
                        <Typography>{profile?.firstname + ' ' + profile?.lastname}</Typography>
                    </Box>
                </Toolbar>
                <Divider />
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', position: 'relative' }}>
                        <Typography className={openHome ? 'active' : ''} onClick={handleDrawerHome} sx={{
                            cursor: 'pointer', marginRight: '40px',
                            '&.active ~ .slide': {
                                opacity: '1', left: '-18px !important'
                            }
                        }}>หน้าหลัก</Typography>
                        <Typography className={openNoti ? 'active' : ''} onClick={handleDrawerNoti} sx={{
                            cursor: 'pointer',
                            '&.active ~ .slide': {
                                opacity: '1', left: '80px !important'
                            }

                        }}>เเจ้งเตือน  {list.filter(e => e?.isRead === false).length !== 0 ? (<span style={{ background: '#ff4500', color: '#ffff', padding: '0px 5px', borderRadius: '5px' }}>{list.filter(e => e?.isRead === false).length}</span>) : ''}</Typography>

                        <span className='slide' style={{ position: 'absolute', bottom: '-20px', width: '100px', height: '4px', left: '-130px', background: '#0fb123', transition: '.3s', borderRadius: '10px' }}></span>
                    </Box>
                    <Box sx={{ display: 'flex', color: '#939393' }}>
                        <SettingsIcon sx={{ marginRight: '10px' }} />
                        <Typography onClick={handleDrawerSett} sx={{ cursor: 'pointer' }}>ตั้งค่า</Typography>
                    </Box>
                </Toolbar>
                <Divider />
            </div>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openM}
                onClose={handleClose}
                // elevation={0}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                sx={{
                    ".MuiPopover-paper": {
                        boxShadow: "unset",
                        border: "1px solid #afafaf",
                        marginTop:'10px'
                    },
                }}
            >
                <MenuItem
                    sx={{ width: "100%", fontSize: "20px" }}
                    component={Link}
                    to={`/profile`}   
                >
                 <AccountCircleIcon sx={{marginRight:'10px'}}/>   บัญชีผู้ใช้
                </MenuItem>

                <MenuItem
                    sx={{ width: "100%", fontSize: "20px" }}
                    // onClick={(e) => handleClickOpen(e, board)}
                    onClick={handleLogOut}
                >
                  <ExitToAppIcon sx={{marginRight:'10px'}}/>  ออกจากระบบ
                </MenuItem>

            </Menu>
        </>


    )
}
