import { Api } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import React, { useEffect, useState } from "react";
import api from "../../api/api";

export default function DialogJobedit({
  open,
  setOpen,
  setFieldValue,
  workexperinceDetail,
  values,
  i
}) {
  const [startDate, setStartDate] = useState(workexperinceDetail?.startDate);
  const [endDate, setEndDate] = useState(workexperinceDetail?.endDate);
  const [mPosition, setMPosition] = useState([]);
  const [value, setValue] = useState({});
  const [checked, setChecked] = useState(
    workexperinceDetail?.endDate === null ? true : false
  );
  const [positionDsc, setPositionDsc] = useState(
    workexperinceDetail?.positionDsc
  );
  // console.log(workexperinceDetail?.positionID);
  const body = {
    startDate:
      moment(startDate).format("YYYY-MM-DD") === "Invalid date"
        ? moment(new Date()).format("YYYY-MM-DD")
        : moment(startDate).format("YYYY-MM-DD"),
    endDate: checked ? null : null,
    positionID: value?.positionID,
    positionName: value?.positionName,
    positionDsc: positionDsc,
    status: true,
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClick = () => {
    setOpen(false);
    setFieldValue(
      "workexperinceDetail",
      values.workexperinceDetail.map((x, index) => {
        x.startDate = index === i ?
          moment(startDate).format("YYYY-MM-DD") === "Invalid date"
            ? moment(new Date()).format("YYYY-MM-DD")
            : moment(startDate).format("YYYY-MM-DD") : x.startDate
        x.endDate = index === i ? moment(endDate).format("YYYY-MM-DD") === "Invalid date" ? null : checked ? null : moment(endDate).format("YYYY-MM-DD") : x.endDate
        x.positionID = index === i ? body.positionID : x.positionID
        x.positionName = index === i ? body.positionName : x?.positionName
        x.positionDsc = index === i ? body.positionDsc : x.positionDsc
        x.status = x.status
        return x;
      })
    );
  };
  useEffect(() => {
    const getMasterPosition = async () => {
      const res = await api.get(
        `api/v1.0/master/position/list?positionNameType=P`
      );
      const data = await res.data;
      if (data.status === true) {
        setMPosition(data.result);
        setValue(
          data.result.filter(
            (x) => x?.positionID == workexperinceDetail?.positionID
          )[0]
        );
      }
    };
    getMasterPosition();
  }, [workexperinceDetail]);
  // console.log(workexperinceDetail)
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <DialogTitle sx={{ margin: "10px 40px" }}>เพิ่มตำเเหน่งงาน</DialogTitle>
      <Divider />
      <DialogContent sx={{ margin: {md:'10px 100px', xs:'10px 30px'} }}>
        <Grid container spacing={2}>
          <Grid item lg={3}>
            <Typography sx={{ marginRight: "40px" }}>
              {checked ? "วันที่เริ่มต้น" : "วันที่"}
            </Typography>
          </Grid>
          <Grid item lg={checked ? 9 : 4}  xs={12}>
            <DesktopDatePicker
              label=""
              inputFormat="DD/MM/YYYY"
              value={startDate}
              maxDate={endDate ? new Date(endDate) : ""}
              onChange={(newValue) => {
                setStartDate(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  size="small"
                  color="success"
                  sx={{ width: "100%" }}
                  InputProps={params?.InputProps}
                  inputRef={params?.inputRef}
                  // placeholder={params?.inputProps?.placeholder}
                  // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                  // onChange={params?.inputProps?.onChange}
                  // type={params?.inputProps?.type}
                  inputProps={
                    {
                      placeholder: params?.inputProps?.placeholder,
                      readOnly: true,
                      value: params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null,
                      onChange: params?.inputProps?.onChange,
                      type: params?.inputProps?.type
                    }

                  }
                />
              )}
            />
          </Grid>

          {!checked && (
            <>
              <Grid
                item
                lg={1}  xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography>~</Typography>
              </Grid>
              <Grid item lg={4}  xs={12}>
                <DesktopDatePicker
                  label=""
                  inputFormat="DD/MM/YYYY"
                  value={endDate}
                  minDate={new Date(startDate)}
                  onChange={(newValue) => {
                    setEndDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      size="small"
                      color="success"
                      sx={{ width: "100%" }}
                      InputProps={params?.InputProps}
                      inputRef={params?.inputRef}
                      // placeholder={params?.inputProps?.placeholder}
                      // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                      // onChange={params?.inputProps?.onChange}
                      // type={params?.inputProps?.type}
                      inputProps={
                        {
                          placeholder: params?.inputProps?.placeholder,
                          readOnly: true,
                          value: params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null,
                          onChange: params?.inputProps?.onChange,
                          type: params?.inputProps?.type
                        }

                      }
                    />
                  )}
                />
              </Grid>
            </>
          )}
          <Grid item lg={3}></Grid>
          <Grid item lg={9} xs={12} sx={{ display: "flex", alignItems: "center" }}>
            <Checkbox checked={checked} onChange={() => setChecked(!checked)} />{" "}
            <Typography>ยังคงดำรงตำเเหน่งอยู่ ณ ปัจจุบัน</Typography>
          </Grid>
          <Grid item lg={3}>
            <Typography sx={{ marginRight: "40px" }}>คุณสมบัติ</Typography>
          </Grid>
          <Grid item lg={9} xs={12}>
            <Autocomplete
              size="small"
              fullWidth
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              options={mPosition}
              getOptionLabel={(option) =>
                option?.positionName ? option?.positionName : ""
              }
              renderInput={(params) => (
                <TextField {...params} placeholder="เลือกคุณสมบัติ" />
              )}
            />
          </Grid>
          <Grid item lg={3}>
            <Typography sx={{ marginRight: "40px" }}>ตำเเหน่ง</Typography>
          </Grid>
          <Grid item lg={9} xs={12}>
            <TextField
              size="small"
              fullWidth
              required
              variant="outlined"
              value={positionDsc}
              onChange={(e) => setPositionDsc(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          variant="outlined"
          sx={{
            borderColor: "#1fc64c",
            "&:hover": { borderColor: "#4caf50" },
            color: "#1fc64c",
            padding: "7px 55px",
            marginBottom: "20px",
          }}
          onClick={handleClose}
        >
          กลับ
        </Button>
        {value && startDate && positionDsc !== "" ? (
          <Button
            onClick={handleClick}
            sx={{
              backgroundColor: "#1fc64c",
              "&:hover": { background: "#4caf50" },
              color: "#FFFF",
              padding: "7px 55px",
              marginBottom: "20px",
            }}
          >
            บันทึก
          </Button>
        ) : (
          ""
        )}
      </DialogActions>
    </Dialog>
  );
}
