import { Autocomplete, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system';
import React, { useContext, useRef } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import TitleHead from '../../components/TitleHead';
import ApiContext from '../../context/ApiProvider';
import { DataGrid, GridToolbarQuickFilter, GridLinkOperator, } from '@mui/x-data-grid';
import moment from 'moment';
import { useParams, useRoutes } from 'react-router-dom';
import api from '../../api/api'
import Swal from 'sweetalert2'
import withReactContent from "sweetalert2-react-content";
import { NavLink } from 'react-router-dom';
import CancelIcon from '@mui/icons-material/Cancel';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import PrintIcon from '@mui/icons-material/Print';
import mime from "mime";
import { saveAs } from 'file-saver';
function QuickSearchToolbar() {
    return (
        <Box
            sx={{
                p: 2,
                width: '100%',
                display: 'flex',
                justifyContent: 'end'
            }}
        >
            <GridToolbarQuickFilter
                quickFilterParser={(searchInput) =>
                    searchInput
                        .split(',')
                        .map((value) => value.trim())
                        .filter((value) => value !== '')
                }
            />
        </Box>
    );
}
export default function DetailHonorDiploma() {
    const fileRef = useRef()
    const fileRef2 = useRef()

    // const [boardDepartmentList, setBoardDepartmentList] = useState([])
    // const [honorPinListCandidate, setHonorPinListCandidate] = useState([])
    const [honorCerListCandidateDetail, setHonorCerListCandidateDetail] = useState([])
    const [text, setText] = useState('')
    const [certificateID, setCertificateID] = useState('')
    // const [boardList, setBoardList] = useState([])
    // const [boardListS, setBoardListS] = useState([])
    const [typeSelect, setTypeSelect] = useState('')
    const [textSelect, setTextSelect] = useState('')
    // const [datecal, setDateCal] = useState('')
    const [mPresent, setMPresent] = useState([])
    // const [date, setDate] = useState(new Date());
    const [value, setValue] = useState({});
    const [value2, setValue2] = useState({});
    const [startDate, setStartDate] = useState(null);
    const [date, setDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [remark, setRemark] = useState('')
    const [startDateS, setStartDateS] = useState(null);
    const [endDateS, setEndDateS] = useState(null);
    const [remarkS, setRemarkS] = useState('')
    const { id } = useParams()
    const { getHonorCerListCandidateDetail, getCertificate, FileDownload } = useContext(ApiContext)
    const [files, setFiles] = useState([])
    const [certificate, setCertificate] = useState([])
    const [open, setOpen] = useState(false)
    const [files2, setFiles2] = useState([])
    const [openPopup, setOpenPopup] = useState(false)
    const [openPopupS, setOpenPopupS] = useState(false)
    const [openPopupSS, setOpenPopupSS] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const handleText = () => {
        setTextSelect(value.presentFullName)
        setText('')
        setTimeout(() => {
            setTextSelect('')
            setValue(null)
        }, 1000);

    }
    const handleSelet = () => {

        setTypeSelect(certificate?.filter((y) => y?.certificateTypeID === value2.certificateTypeID)[0]?.certificateTypeName)
        setCertificateID('')
        setTimeout(() => {
            setTypeSelect('')
            setValue2(null)
        }, 1000);
    }
    const handleClose = () => {
        setOpenPopup(false);

    };
    const handleCloseS = () => {
        setOpenPopupS(false);

    };
    const handleCloseSS = () => {
        setOpenPopupSS(false);

    };
    let formData = new FormData();
    let formData2 = new FormData();
    const handleFileUpload = (event) => {
        if (event.target && event.target.files) {
            let file = event.target.files[0];
            // setFiles([...files, file]);
            const maxAllowedSize = 8 * 1024 * 1024;
            if (file.size < maxAllowedSize) {
                formData.append("file", file)
                api.post('api/v1.0/filemanages/upload', formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }).then((res) => res.data)
                    .then((data) => {
                        if (data.status === true) {
                            // console.log(data.result)
                            setFiles([...files, data.result]);
                        }
                        else {
                            console.log(' failed')
                        }
                    })

            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'ไฟล์อัพโหลดใหญ่เกินไป',
                })
            }

        }

        // console.log(formData)
        // fileUpload(formData, file, files, setFiles)


    };
    const handleFileUpload2 = (event) => {
        if (event.target && event.target.files) {
            let file = event.target.files[0];
            // setFiles([...files, file]);
            const maxAllowedSize = 8 * 1024 * 1024;

            if (file.size < maxAllowedSize) {
                formData2.append("file", file)
                // console.log(formData)
                // fileUpload(formData, file, files, setFiles)
                api.post('api/v1.0/filemanages/upload', formData2, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }).then((res) => res.data)
                    .then((data) => {
                        if (data.status === true) {
                            // console.log(data.result)
                            setFiles2([...files2, data.result]);
                        }
                        else {
                            console.log(' failed')
                        }
                    })

            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'ไฟล์อัพโหลดใหญ่เกินไป',
                })
            }
        }

    };
    const handleRemoveFileUpload2 = (x, i) => {
        const body = files2.map((z) => {
            z.cerAttachID = z?.cerAttachID || ""
            z.fileID = z.fileID
            z.status = z.fileID === x.fileID ? false : z.status
            return z
        }
        )
        // console.log(body)
        setFiles2(body)
    }
    const handleRemoveFileUpload = (x, i) => {
        const body = files.map((z) => {
            z.cerAttachID = z?.cerAttachID || ""
            z.fileID = z.fileID
            z.status = z.fileID === x.fileID ? false : z.status
            return z
        }
        )
        // console.log(body)
        setFiles(body)
    }
    // useEffect(() => {
    //     getBoardDepartmentList(setBoardDepartmentList, '')
    // }, [])
    // useEffect(() => {
    //     const param = { id: boardSelect === ''}
    //         getBoardList(setBoardList, param, '', 1)
    // }, [boardSelect])
    // useEffect(() => {
    //         getBoardList(setBoardList, '', '', 1)
    // }, [])

    // console.log(honorPinListCandidateDetail)

    useEffect(() => {
        getHonorCerListCandidateDetail(setHonorCerListCandidateDetail, id)
    }, [id])
    useEffect(() => {
        setFiles(honorCerListCandidateDetail?.cerFileAttach)

    }, [honorCerListCandidateDetail])
    useEffect(() => {
        getCertificate(setCertificate)
    }, [])
    useEffect(() => {
        setDate(honorCerListCandidateDetail?.receiveDate)

    }, [honorCerListCandidateDetail])
    const getMasterPresent = async () => {
        const res = await api.get(`api/v1.0/master/present/list`)
        const data = await res.data
        if (data.status === true) {
            setMPresent(data.result)
            // setValue(data?.result?.filter((y) => y?.presentFullName === x?.present)[0])
            // console.log(data.result);

        }

    }
    useEffect(() => {

        getMasterPresent()
    }, [])
    // console.log(files)
    const columns = [
        // { field: 'id', headerName: 'ID', width: 50 },
        { field: 'title', headerName: 'คำนำหน้า', width: 120 },
        {
            field: 'firstName',
            headerName: 'ชื่อ',
            width: 150,
            // editable: true,
        },
        {
            field: 'lastName',
            headerName: 'นามสกุล',
            width: 150,
            // editable: true,
        },
        {
            field: 'cerName',
            headerName: 'คุณสมบัติ',
            width: 250,
            // editable: true,
        },
        {
            field: 'oldRankDate',
            headerName: 'ปีที่ได้รับ',
            width: 110,
            // editable: true,
        },
        // {
        //     field: 'rankCounter',
        //     headerName: 'จำนวนการอบรม/ครั้ง',
        //     width: 150,
        //     // editable: true,

        // },
        {
            field: 'meetCounter',
            headerName: 'การเข้ารวมประชุม/ครั้ง',
            width: 150,
            // editable: true,
        },
        {
            field: 'certificate',
            headerName: 'ใบรับรอง',
            width: 200,
            // editable: true,

        },
        {
            field: 'present',
            headerName: 'ผู้เสนอ',
            width: 150,

        },
        {
            field: 'allStatus',
            headerName: 'สถานะ',
            width: 100,
        },

    ];
    const [selectionModel, setSelectionModel] = useState('');
    const rows = honorCerListCandidateDetail?.cerDetail?.map((x, i) => {
        
        x.id = i + 1
        x.title = x?.personal?.title?.titleNameTH
        x.firstName = x?.personal?.firstname
        x.lastName = x?.personal?.lastname
        x.cerName = x?.cerName
        x.oldRankDate = x?.oldRankDate ? x?.oldRankDate.includes('-') ? moment(x?.oldRankDate).format('DD/MM/yyyy').substring(6) === 'd date' ? '' : moment(x?.oldRankDate).add(543, 'year').format('DD/MM/yyyy').substring(6) : x?.oldRankDate : '-'
        // x.rankCounter = ''
        x.meetCounter = x?.meetCounter
        // x.certificate = certificateData?.filter((y) => y?.personalID == x?.personalID)[0]?.certificateID
        x.present = textSelect === '' ? x.present : selectionModel?.filter(y => y === i + 1)[0] == i + 1 ? textSelect : x.present
        // x.certiName = typeSelect === '' ? x?.certificateTypeID ? certificate?.filter((y) => y?.certificateTypeID === x?.certificateTypeID)[0]?.certificateTypeName : x.certificate : x.certificate

        x.certificate = typeSelect === '' ? x.certificate || x?.certificateType?.certificateTypeName  : selectionModel?.filter(y => y === i + 1)[0] == i + 1 ? typeSelect : x.certificate
        // x.certificate = typeSelect === '' ? x?.certificateTypeID ? certificate?.filter((y) => y?.certificateTypeID === x?.certificateTypeID)[0]?.certificateTypeName : x.certificate : selectionModel?.filter(y => y === i + 1)[0] == i + 1 ? typeSelect : x?.certificateTypeID ? certificate?.filter((y) => y?.certificateTypeID === x?.certificateTypeID)[0]?.certificateTypeName : x.certificate
        // x.certificate = selectionModel?.filter(y => y === i + 1)[0] == i + 1
        // x.certificate = certificate?.filter((y) => y?.certificateTypeID === x?.certificateTypeID)[0]?.certificateTypeName || selectionModel?.filter(y => y === i + 1)[0] == i + 1 ? certificate?.filter((x) => x?.certificateTypeID === certificateID)[0]?.certificateTypeName : certificate?.filter((y) => y?.certificateTypeID === x?.certificateTypeID)[0]?.certificateTypeName
        // x.present = certificateData?.filter((y) => y?.personalID == x?.personalID)[0]?.present
        x.status = x?.status
        x.isApproveFirst = x?.isApproveFirst
        x.isApproveSecond = x?.isApproveSecond
        x.isReceive = x?.isReceive
        x.allStatus = honorCerListCandidateDetail?.status === 'D' ? '' : honorCerListCandidateDetail?.status === 'P' ? `${x?.isApproveFirst ? '✓' :'x'}` : honorCerListCandidateDetail?.status === 'A' ? `${x?.isApproveFirst ? '✓' :'x'}  ${x?.isApproveSecond ? '✓' :'x'}` : `${x?.isApproveFirst ? '✓' :'x'}  ${x?.isApproveSecond ? '✓' :'x'}`

        return x
    });

    // console.log(rows)


    useEffect(() => {
        // let selectHonorCer = honorCerListCandidateDetail?.cerDetail?.map((x, i) => x.status === true ? i + 1 : '').filter(x => x !== '')

        // setSelectionModel(selectHonorCer)
        if (honorCerListCandidateDetail?.status === 'P') {
            let selectHonorPin = honorCerListCandidateDetail?.cerDetail?.map((x, i) => x.isApproveSecond === true ? i + 1 : '').filter(x => x !== '')
            setSelectionModel(selectHonorPin)
        }
        if (honorCerListCandidateDetail?.status === 'A') {
            let selectHonorPin = honorCerListCandidateDetail?.cerDetail?.map((x, i) => x.isReceive === true ? i + 1 : '').filter(x => x !== '')
            setSelectionModel(selectHonorPin)
        }
        if (honorCerListCandidateDetail?.status === 'R') {
            let selectHonorPin = honorCerListCandidateDetail?.cerDetail?.map((x, i) => x.isReceive === true ? i + 1 : '').filter(x => x !== '')
            setSelectionModel(selectHonorPin)
        }
        if (honorCerListCandidateDetail?.status === 'D') {
            let selectHonorPin = honorCerListCandidateDetail?.cerDetail?.map((x, i) => x.isApproveFirst === true ? i + 1 : '').filter(x => x !== '')
            setSelectionModel(selectHonorPin)
        }
    }, [honorCerListCandidateDetail])
    useEffect(() => {
        setFiles2(honorCerListCandidateDetail?.cerFileAttachSecond)

    }, [honorCerListCandidateDetail])
    const dataHonor = honorCerListCandidateDetail?.cerDetail?.map((x, i) => {
        return {
            tscCerID: x?.tscCerID,
            cerID: x?.cerID,
            cerName: x?.cerName,
            personalID: x?.personalID,
            rank: x?.rank,
            cerCodition: x?.cerCodition,
            certificateTypeID: certificate?.filter((m) => m?.certificateTypeName === rows?.filter((n, index) => index === i)[0]?.certificate)[0]?.certificateTypeID || null,
            present: rows?.filter((y, index) => index == i)[0]?.present || '',
            // status: selectionModel?.filter(y => y === i + 1)[0] == i + 1
            // status: honorCerListCandidateDetail?.status === 'D' ? selectionModel?.filter(y => y === i + 1)[0] == i + 1 : honorCerListCandidateDetail?.status === 'P' ? x?.status : x?.status,
            // isApproveFirst: honorCerListCandidateDetail?.status === 'D' ? selectionModel?.filter(y => y === i + 1)[0] == i + 1 : honorCerListCandidateDetail?.status === 'P' ? selectionModel?.filter(y => y === i + 1)[0] == i + 1 : x?.isApproveFirst,
            // isApproveSecond: honorCerListCandidateDetail?.status === 'D' ? selectionModel?.filter(y => y === i + 1)[0] == i + 1 : honorCerListCandidateDetail?.status === 'P' ? selectionModel?.filter(y => y === i + 1)[0] == i + 1 : x?.isApproveSecond,
            // isReceive: honorCerListCandidateDetail?.status === 'D' ? selectionModel?.filter(y => y === i + 1)[0] == i + 1 : honorCerListCandidateDetail?.status === 'A' ? selectionModel?.filter(y => y === i + 1)[0] == i + 1 : x?.isReceive,
            status: honorCerListCandidateDetail?.status === 'D' ? selectionModel?.filter(y => y === i + 1)[0] == i + 1 : honorCerListCandidateDetail?.status === 'P' ? x?.status : honorCerListCandidateDetail?.status === 'A' ? x?.status : honorCerListCandidateDetail?.status === 'R' ? x?.status : x?.status,
            isApproveFirst: honorCerListCandidateDetail?.status === 'D' ? selectionModel?.filter(y => y === i + 1)[0] == i + 1 : honorCerListCandidateDetail?.status === 'P' ? x?.isApproveFirst : honorCerListCandidateDetail?.status === 'A' ? x?.isApproveFirst : honorCerListCandidateDetail?.status === 'R' ? x?.isApproveFirst : x?.isApproveFirst,
            isApproveSecond: honorCerListCandidateDetail?.status === 'D' ? selectionModel?.filter(y => y === i + 1)[0] == i + 1 : honorCerListCandidateDetail?.status === 'P' ? selectionModel?.filter(y => y === i + 1)[0] == i + 1 : honorCerListCandidateDetail?.status === 'A' ? x?.isApproveSecond : honorCerListCandidateDetail?.status === 'R' ? x?.isApproveSecond : x?.isApproveSecond,
            isReceive: honorCerListCandidateDetail?.status === 'D' ? selectionModel?.filter(y => y === i + 1)[0] == i + 1 : honorCerListCandidateDetail?.status === 'P' ? selectionModel?.filter(y => y === i + 1)[0] == i + 1 : honorCerListCandidateDetail?.status === 'A' || honorCerListCandidateDetail?.status === 'R' ? selectionModel?.filter(y => y === i + 1)[0] == i + 1 : x?.isReceive,
            meetTotal: x?.meetTotal,
            meetCounter: x?.meetCounter
        }
    })
    const handleSubmit = (value) => {
        const body = {
            tscHeadCerID: honorCerListCandidateDetail?.tscHeadCerID,
            receiveDate: honorCerListCandidateDetail?.status === "A" ? moment(date).format('YYYY-MM-DD HH:mm') : null,
            // receiveDate: value === 'A' ? moment(new Date()).format('YYYY-MM-DD HH:mm') : null,
            // calDate: moment(honorPinListCandidateDetail?.calDate).format('YYYY-MM-DD HH:mm'),
            cerDetail: dataHonor,
            cerBoardDep: honorCerListCandidateDetail?.cerBoardDep,
            cerFileAttach: files,
            cerFileAttachSecond: files2,
            considerDateFirst: honorCerListCandidateDetail?.status === 'P' || honorCerListCandidateDetail?.status === 'A' || honorCerListCandidateDetail?.status === 'R' ? honorCerListCandidateDetail?.considerDateFirst : moment(startDate).format('YYYY-MM-DD') === 'Invalid date' ? null : moment(startDate).format('YYYY-MM-DD'),
            approveDateFirst: honorCerListCandidateDetail?.status === 'P' || honorCerListCandidateDetail?.status === 'A' || honorCerListCandidateDetail?.status === 'R' ? honorCerListCandidateDetail?.approveDateFirst : moment(endDate).format('YYYY-MM-DD') === 'Invalid date' ? null : moment(endDate).format('YYYY-MM-DD'),
            considerDateSecond: honorCerListCandidateDetail?.status === 'A' || honorCerListCandidateDetail?.status === 'R' ? honorCerListCandidateDetail?.considerDateSecond : moment(startDateS).format('YYYY-MM-DD') === 'Invalid date' ? null : moment(startDateS).format('YYYY-MM-DD'),
            approveDateSecond: honorCerListCandidateDetail?.status === 'A' || honorCerListCandidateDetail?.status === 'R' ? honorCerListCandidateDetail?.approveDateSecond : moment(endDateS).format('YYYY-MM-DD') === 'Invalid date' ? null : moment(endDateS).format('YYYY-MM-DD'),
            remarkFirst: honorCerListCandidateDetail?.status === 'P' || honorCerListCandidateDetail?.status === 'A' || honorCerListCandidateDetail?.status === 'R' ? honorCerListCandidateDetail?.remarkFirst : remark,
            remarkSecond: honorCerListCandidateDetail?.status === 'A' || honorCerListCandidateDetail?.status === 'R' ? honorCerListCandidateDetail?.remarkSecond : remarkS,
            // boardDepName: honorPinListCandidateDetail?.boardDepName,
            status: value === "D" ? honorCerListCandidateDetail?.status === "A" ? "A" : honorCerListCandidateDetail?.status === "P" ? "P" : honorCerListCandidateDetail?.status === "R" ? "R" : value : value

            // boardDepName: honorPinListCandidateDetail?.boardDepName,
        }
        // console.log(body)
        const updateHonorCer = async () => {
            const res = await api.post(`api/v1.0/honor-cer/update`, body)
            const data = await res.data
            if (data.status === true) {
                setOpenPopup(false)
                setOpenPopupS(false)
                setRemark('')
                setRemarkS('')
                Swal.fire({
                    title: 'บันทึกแล้ว',
                    text: "บันทึกข้อมูลสำเร็จ",
                    icon: "success",
                    confirmButtonColor: '#0f962d',
                    confirmButtonText: 'ตกลง',
                    allowOutsideClick: false
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        window.location = `/Diploma`
                    }
                })
                // const MySwal = withReactContent(Swal)

                // MySwal.fire({
                //     title: <p>สร้างข้อมูลสำเร็จ</p>,

                //     icon: "success",

                //     didOpen: () => {
                //         // `MySwal` is a subclass of `Swal` with all the same instance & static methods

                //         MySwal.clickConfirm();
                //     },
                // }).then(() => {
                //     return MySwal.fire({
                //         title: 'บันทึกแล้ว',
                //         text: "บันทึกข้อมูลสำเร็จ",
                //         icon: "success",
                //         confirmButtonColor: '#0f962d',
                //         confirmButtonText: 'ตกลง',
                //         allowOutsideClick: false
                //     }

                //     ).then((result) => {
                //         if (result.isConfirmed) {
                //             window.location = `/Diploma`
                //         }
                //     });
                // });
            }
        }
        updateHonorCer()
    }
    // console.log(honorCerListCandidateDetail)
    return (
        <>
            <Container maxWidth='xl' sx={{ height: 'auto', marginTop: '40px' }}>
                <TitleHead title='การรับประกาศนียบัตรชั้นสูงกิตติมศักดิ์' subTitle='ประวัติการรับประกาศนียบัตรชั้นสูงกิตติมศักดิ์'>

                    <Box>
                        <Typography>สถานะ <span style={{ color: '#1fc64c' }}> {honorCerListCandidateDetail?.status === 'D' ? 'กำลังดำเนินการ' : honorCerListCandidateDetail?.status === 'P' ? 'อนุมัติขั้นต้น' : honorCerListCandidateDetail?.status === 'A' ? 'สภาอนุมัติ' : honorCerListCandidateDetail?.status === 'R' ? 'เสร็จสิ้น' : ''}</span></Typography>
                        <Button
                            color="success"
                            onClick={async () => {
                                setIsLoading(true)
                                await api
                                    .get(`api/v1.0/honor-cer/download/${id}`, {
                                        responseType: "blob",
                                        timeout:100000,
                                    })
                                    .then((blobFile) => {
                                        const blobFileRes = new File(
                                            [blobFile?.data],
                                            "Template.pdf",
                                            {
                                                type: mime.getType(".pdf"),
                                            }
                                        );
                                        // saveAs(blobFileRes, `รายงานผู้ได้รับประกาศนียบัตรชั้นสูงกิตติมศักดิ์.pdf`)

                                        window.open(
                                            URL.createObjectURL(blobFileRes, "รายงานผู้ได้รับประกาศนียบัตรชั้นสูงกิตติมศักดิ์.pdf")
                                            ,
                                            "_blank"
                                        );
                                        setIsLoading(false)
                                    });
                            }}
                            variant="outlined"
                            sx={{
                                color: "#4caf50",
                                padding: "7px 55px",
                                marginTop: "10px",
                                width:'240px'

                            }}
                        >
                     { isLoading ?  <CircularProgress color="inherit" size={24} /> :  <><PrintIcon sx={{ marginRight: '10px' }} /> <Typography>พิมพ์เอกสาร</Typography></>  }
                        </Button>
                    </Box>
                </TitleHead>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    {/* <FormControl sx={{ m: 1, minWidth: "20%", marginRight: 3 }} color="success" >
                        <InputLabel>เลือกชุดคณะกรรมการ</InputLabel>
                        <Select
                            // open={open}
                            // onClose={handleClose}
                            // onOpen={handleOpen}
                            // defaultValue={1}
                            value={boardSelect}
                            onChange={e => setBoardSelect(e.target.value)}
                            label="เลือกชุดคณะกรรมการ"
                        >
                            {boardDepartmentList?.map((item, i) =>
                                <MenuItem key={i} value={item?.boardDepID}>{item?.boardDepName}</MenuItem>
                            )}
                        </Select>
                    </FormControl> */}

                    <Typography sx={{ color: '#1fc64c' }}>รายชื่อคณะกรรมการอนุมัติขั้นต้น</Typography>

                    <Box sx={{ display: 'flex' }}>
                        <Typography>วันที่พิจารณา <span style={{ color: '#1fc64c' }}>{moment(honorCerListCandidateDetail?.considerDateFirst).add(543, 'year').format('LL')}</span></Typography> <span style={{ margin: '0 5px' }}>|</span>
                        <Typography>วันที่อนุมัติ <span style={{ color: '#1fc64c' }}>{moment(honorCerListCandidateDetail?.approveDateFirst).add(543, 'year').format('LL')}</span></Typography>
                    </Box>

                    {/* <Box sx={{ display: 'flex', justifyContent: 'space-between' }}> */}
                    {/* <Typography sx={{ color: '#1fc64c' }}>รายชื่อคณะกรรมการพิจารณาทั้งหมด {honorCerListCandidateDetail?.cerBoardDep?.length} ท่าน</Typography> */}
                    {/* </Box> */}
                </Box>

                <Grid container spacing={2} sx={{ marginTop: '15px', marginBottom: '30px' }}>
                    {honorCerListCandidateDetail?.cerBoardDep?.map((board, i) =>
                        <Grid key={i} item lg={4}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px' }}>
                                <Typography variant='h6'>{`${board?.title} ${board?.firstname} ${board?.lastname}`}</Typography>
                                <Typography sx={{ color: '#1fc64c' }}>{board?.position}</Typography>
                            </Box>
                        </Grid>
                    )}

                </Grid>
                <Box sx={{ display: 'flex' }}>
                    <Typography sx={{ marginLeft: '10px' }}>
                        เหตุผล:
                    </Typography>
                    <Typography>{honorCerListCandidateDetail?.remarkFirst}</Typography>
                </Box>
                {/* <Typography sx={{ marginBottom: '15px' }}>เเนบไฟล์เอกสารที่เกี่ยวข้อง</Typography> */}
                <Typography sx={{ marginBottom: '15px' }}></Typography>
                <Box sx={{ display: 'flex' }}>
                    <input
                        type="file" ref={fileRef} accept="image/*,.pdf,.doc,.docx,.xlsx,.csv,.xls" hidden onChange={handleFileUpload} />
                    <Button disabled={honorCerListCandidateDetail?.status === 'A' ? true : false} variant='outlined' onClick={() => fileRef.current.click()} sx={{
                        height: 'fit-content', fontSize: '18px', color: '#0fb123', border: '1px solid #0fb123',
                        ':hover': { border: '1px solid #028b13' }
                    }}>อัพโหลดไฟล์</Button>
                    {files?.filter((y) => y.status === true).map((x, i) =>
                        <Box sx={{ width: '120px', display: 'flex', alignItems: 'center', border: '1px solid #0089ff', borderRadius: '15px', padding: '3px 5px 3px 10px', marginRight: '10px', marginLeft: '10px' }}>
                            <Typography onClick={() => FileDownload(x)} sx={{
                                height: 'auto',
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: '1',
                                overflow: 'hidden'
                            }}>{x?.filename || x?.fileAttach?.filename}</Typography>
                            <CancelIcon onClick={() => handleRemoveFileUpload(x, i)} sx={{ color: '#0089ff', display: honorCerListCandidateDetail?.status === 'A' ? 'none' : 'block' }} />
                        </Box>
                        // <Typography key={i} sx={{ marginRight: '15px', cursor: 'pointer', position: 'relative', color: '#0089ff', transition: '.3s', ':after': { content: '""', position: 'absolute', width: '0%', height: '2px', left: '0px', bottom: '0px', transition: '.3s', backgroundColor: '#0089ff' }, ':hover': { color: '#0062b7', ':after': { width: '100%', backgroundColor: '#0062b7' } } }} onClick={() => FileDownload(x)}>{x?.filename}</Typography>
                    )}
                </Box>
                <Divider sx={{ margin: '40px 0' }} />
                {honorCerListCandidateDetail?.status === 'P' || honorCerListCandidateDetail?.status === 'A' || honorCerListCandidateDetail?.status === 'P' ? <>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>


                        {/* <Box sx={{ display: 'flex', justifyContent: 'space-between' }}> */}
                        <Typography sx={{ color: '#1fc64c' }}>รายชื่อคณะกรรมการสภาสถาบันพระปกเกล้า</Typography>
                        {/* <Typography sx={{ color: '#1fc64c' }}>รายชื่อคณะกรรมการพิจารณาทั้งหมด {honorPinListCandidateDetail?.cerBoardDep?.length} ท่าน</Typography> */}
                        {/* </Box> */}
                        <Box sx={{ display: 'flex' }}>
                            {honorCerListCandidateDetail?.considerDateSecond && <> <Typography>วันที่พิจารณา <span style={{ color: '#1fc64c' }}>{moment(honorCerListCandidateDetail?.considerDateSecond).add(543, 'year').format('LL')}</span></Typography> <span style={{ margin: '0 5px' }}>|</span></>}
                            {honorCerListCandidateDetail?.approveDateSecond && <Typography>วันที่อนุมัติ <span style={{ color: '#1fc64c' }}>{moment(honorCerListCandidateDetail?.approveDateSecond).add(543, 'year').format('LL')}</span></Typography>}
                        </Box>
                    </Box>

                    <Grid container spacing={2} sx={{ marginTop: '15px', marginBottom: '30px' }}>

                        <Grid item lg={4}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px' }}>
                                <Typography variant='h6'>นาย ชนะ นิติการ</Typography>
                                <Typography sx={{ color: '#1fc64c' }}>ประธาน</Typography>
                            </Box>
                        </Grid>
                        <Grid item lg={4}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px' }}>
                                <Typography variant='h6'>นาย ทินพัฒน์ เรืองฤทธิ์</Typography>
                                <Typography sx={{ color: '#1fc64c' }}>รองประธาน</Typography>
                            </Box>
                        </Grid>
                        <Grid item lg={4}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px' }}>
                                <Typography variant='h6'>นาย ธวัช อธิการบดี</Typography>
                                <Typography sx={{ color: '#1fc64c' }}>กรรมการ</Typography>
                            </Box>
                        </Grid>


                    </Grid>
                    {honorCerListCandidateDetail?.remarkSecond && <Box sx={{ display: 'flex' }}>
                        <Typography sx={{ marginLeft: '10px' }}>
                            เหตุผล:
                        </Typography>
                        <Typography>{honorCerListCandidateDetail?.remarkSecond}</Typography>
                    </Box>}

                    <Typography sx={{ marginBottom: '15px' }}></Typography>
                    <Box sx={{ display: 'flex' }}>
                        <input
                            type="file" ref={fileRef2} accept="image/*,.pdf,.doc,.docx,.xlsx,.csv,.xls" hidden onChange={handleFileUpload2} />
                        <Button disabled={honorCerListCandidateDetail?.status === 'A' ? true : false} variant='outlined' onClick={() => fileRef2.current.click()} sx={{
                            height: 'fit-content', fontSize: '18px', color: '#0fb123', border: '1px solid #0fb123',
                            ':hover': { border: '1px solid #028b13' }
                        }}>อัพโหลดไฟล์</Button>
                        {files2?.filter((y) => y.status === true).map((x, i) =>
                            <Box sx={{ width: '120px', display: 'flex', alignItems: 'center', border: '1px solid #0089ff', borderRadius: '15px', padding: '3px 5px 3px 10px', marginRight: '10px', marginLeft: '10px' }}>
                                <Typography onClick={() => FileDownload(x)} sx={{
                                    height: 'auto',
                                    display: '-webkit-box',
                                    WebkitBoxOrient: 'vertical',
                                    WebkitLineClamp: '1',
                                    overflow: 'hidden'
                                }}>{x?.filename || x?.fileAttach?.filename}</Typography>
                                <CancelIcon onClick={() => handleRemoveFileUpload2(x, i)} sx={{ color: '#0089ff', display: honorCerListCandidateDetail?.status === 'A' ? 'none' : 'block' }} />
                            </Box>
                            // <Typography key={i} sx={{ marginRight: '15px', cursor: 'pointer', position: 'relative', color: '#0089ff', transition: '.3s', ':after': { content: '""', position: 'absolute', width: '0%', height: '2px', left: '0px', bottom: '0px', transition: '.3s', backgroundColor: '#0089ff' }, ':hover': { color: '#0062b7', ':after': { width: '100%', backgroundColor: '#0062b7' } } }} onClick={() => FileDownload(x)}>{x?.filename}</Typography>
                        )}
                    </Box>
                    <Divider sx={{ margin: '40px 0' }} /> </>
                    : ''}
                <Box>
                    <Typography sx={{ marginBottom: '15px', marginTop: '40px', fontSize: '18px', color: '#0fb123' }}>วันที่คำนวณ {moment(honorCerListCandidateDetail?.calDate).add(543, 'year').format('LL')}</Typography>
                    <Box sx={{ display: honorCerListCandidateDetail?.status === 'R' ? 'flex' : 'none', alignItems: 'center', marginTop: "23px" }}>
                        <Typography sx={{ marginRight: '10px', display: honorCerListCandidateDetail?.status === 'R' ? 'block' : 'none' }}>วันที่รับในพิธี</Typography>
                        <DesktopDatePicker
                            label=""
                            inputFormat="DD/MM/YYYY"
                            value={date}
                            // onViewChange={(newValue) }
                            onChange={(newValue) => { setDate(newValue) }}
                            renderInput={(params) => <TextField size='small' color="success" sx={{ width: '200px', }}
                                InputProps={params?.InputProps}
                                inputRef={params?.inputRef}
                                placeholder={params?.inputProps?.placeholder}
                                value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                                onChange={params?.inputProps?.onChange}
                                type={params?.inputProps?.type}
                            />}
                        />
                    </Box>
                    {/* <Typography sx={{ marginBottom: '15px', marginTop: '40px', fontSize: '18px', color: '#0fb123' }}>วันที่สร้าง {moment(honorCerListCandidateDetail?.createDate).format('YYYY-MM-DD HH:mm')}</Typography> */}
                    <Box sx={{ display: honorCerListCandidateDetail?.status === 'R' || selectionModel?.length < 1  ? 'none' : 'flex', marginTop: '20px' }}>
                        {/* <TextField id="outlined-basic" label="กรอกข้อมูลผู้เสนอ" value={text} onChange={e => setText(e.target.value)} variant="outlined" color="success" /> */}
                        <Autocomplete
                            size='small'
                            value={value}
                            onChange={(event, newValue) => {
                                setValue(newValue)
                            }}
                            options={mPresent}
                            getOptionLabel={option => option?.presentFullName ? option?.presentFullName : ''}
                            renderInput={(params) => <TextField {...params} placeholder='เลือกผู้เสนอ' sx={{ width: '400px' }} />}
                        />
                        <Button variant='contained' onClick={handleText} sx={{ backgroundColor: "#1fc64c", '&:hover': { background: '#4caf50' }, color: "#FFFF", }}>ตกลง</Button>
                        {/* <FormControl sx={{ minWidth: "20%", marginLeft: 3 }} color="success" >
                            <InputLabel>เลือกใบรับรอง</InputLabel>
                            <Select
                                // open={open}
                                // onClose={handleClose}
                                // onOpen={handleOpen}
                                // defaultValue={1}
                                value={certificateID}
                                onChange={e => setCertificateID(e.target.value)}
                                label="ใบรับรอง"
                            >
                                {certificate?.map((item, i) =>
                                    <MenuItem key={i} value={item?.certificateTypeID}>{item?.certificateTypeName.substring(0, 50)}</MenuItem>
                                )}
                            </Select>
                        </FormControl> */}
                        <Autocomplete
                            size='small'
                            value={value2}
                            onChange={(event, newValue) => {
                                setValue2(newValue)
                            }}
                            options={certificate}
                            getOptionLabel={option => option?.certificateTypeName?.substring(0, 49) ? option?.certificateTypeName?.substring(0, 49) : ''}
                            renderInput={(params) => <TextField {...params} placeholder='เลือกใบรับรอง' sx={{ width: '400px', marginLeft: '20px' }} />}
                        />
                        <Button variant='contained' onClick={handleSelet} sx={{ backgroundColor: "#1fc64c", '&:hover': { background: '#4caf50' }, color: "#FFFF" }}>ตกลง</Button>

                    </Box>
                </Box>

                {/* <Button variant='contained' onClick={handleGetHonorPinListCandidate} sx={{ backgroundColor: "#1fc64c", '&:hover': { background: '#4caf50' }, color: "#FFFF", padding: "7px 55px", marginTop: "23px" }} >ดึงฐานข้อมูลประวัติ</Button> */}
                <Box sx={{ height: 600, width: '100%', margin: '40px 0' }}>
                    {/* {honorCerListCandidateDetail?.status === 'A' ? <DataGrid
                        rows={rows || []}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[5]}
                        checkboxSelection
                        disableSelectionOnClick
                        disableColumnMenu
                        experimentalFeatures={{ newEditingApi: true }}
                        selectionModel={selectionModel}
                        initialState={{
                            filter: {
                                filterModel: {
                                    items: [],
                                    quickFilterLogicOperator: GridLinkOperator.Or,
                                },
                            },
                        }}
                        components={{ Toolbar: QuickSearchToolbar }}
                    />
                        :
                        <DataGrid
                            rows={rows || []}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[5]}
                            checkboxSelection
                            disableSelectionOnClick
                            disableColumnMenu
                            experimentalFeatures={{ newEditingApi: true }}
                            selectionModel={selectionModel}
                            onSelectionModelChange={(newSelectionModel) => {
                                setSelectionModel(newSelectionModel);
                            }}
                            initialState={{
                                filter: {
                                    filterModel: {
                                        items: [],
                                        quickFilterLogicOperator: GridLinkOperator.Or,
                                    },
                                },
                            }}
                            components={{ Toolbar: QuickSearchToolbar }}
                        />
                    } */}
                    {honorCerListCandidateDetail?.status === 'R' ? <DataGrid
                        rows={rows || []}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[5]}
                        checkboxSelection
                        disableSelectionOnClick
                        disableColumnMenu
                        isRowSelectable={(params) => params.row.isApproveSecond === true}
                        experimentalFeatures={{ newEditingApi: true }}
                        selectionModel={selectionModel}
                        onSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                        }}
                        initialState={{
                            filter: {
                                filterModel: {
                                    items: [],
                                    quickFilterLogicOperator: GridLinkOperator.Or,
                                },
                            },
                        }}
                        components={{ Toolbar: QuickSearchToolbar }}

                    /> : honorCerListCandidateDetail?.status === 'A' ? <DataGrid
                        rows={rows || []}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[5]}
                        checkboxSelection
                        disableSelectionOnClick
                        disableColumnMenu
                        isRowSelectable={(params) => params.row.isApproveSecond === true}
                        experimentalFeatures={{ newEditingApi: true }}
                        selectionModel={selectionModel}
                        onSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                        }}
                        initialState={{
                            filter: {
                                filterModel: {
                                    items: [],
                                    quickFilterLogicOperator: GridLinkOperator.Or,
                                },
                            },
                        }}
                        components={{ Toolbar: QuickSearchToolbar }}

                    /> :
                        honorCerListCandidateDetail?.status === 'P' ? <DataGrid
                            rows={rows || []}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[5]}
                            checkboxSelection
                            isRowSelectable={(params) => params.row.isApproveFirst === true}
                            disableSelectionOnClick
                            disableColumnMenu
                            experimentalFeatures={{ newEditingApi: true }}
                            selectionModel={selectionModel}
                            onSelectionModelChange={(newSelectionModel) => {
                                setSelectionModel(newSelectionModel);
                            }}
                            initialState={{
                                filter: {
                                    filterModel: {
                                        items: [],
                                        quickFilterLogicOperator: GridLinkOperator.Or,
                                    },
                                },
                            }}
                            components={{ Toolbar: QuickSearchToolbar }}
                        /> : honorCerListCandidateDetail?.status === 'D' ? <DataGrid
                            rows={rows || []}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[5]}
                            checkboxSelection
                            // isRowSelectable={(params) => params.row.status === true}
                            disableSelectionOnClick
                            disableColumnMenu
                            experimentalFeatures={{ newEditingApi: true }}
                            selectionModel={selectionModel}
                            onSelectionModelChange={(newSelectionModel) => {
                                setSelectionModel(newSelectionModel);
                            }}
                            initialState={{
                                filter: {
                                    filterModel: {
                                        items: [],
                                        quickFilterLogicOperator: GridLinkOperator.Or,
                                    },
                                },
                            }}
                            components={{ Toolbar: QuickSearchToolbar }}

                        /> : <DataGrid
                            rows={rows || []}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[5]}
                            checkboxSelection
                            // isRowSelectable={(params) => params.row.status === true}
                            disableSelectionOnClick
                            disableColumnMenu
                            experimentalFeatures={{ newEditingApi: true }}
                            selectionModel={selectionModel}
                            onSelectionModelChange={(newSelectionModel) => {
                                setSelectionModel(newSelectionModel);
                            }}
                            initialState={{
                                filter: {
                                    filterModel: {
                                        items: [],
                                        quickFilterLogicOperator: GridLinkOperator.Or,
                                    },
                                },
                            }}
                            components={{ Toolbar: QuickSearchToolbar }}
                        />}
                </Box>
            </Container>
            <Box sx={{
                position: 'sticky', bottom: '0px', width: '100%', marginTop: '50px', zIndex: '100', background: '#fff'
            }}>
                <Divider sx={{ marginBottom: '10px' }} />
                <Container maxWidth='xl' sx={{ height: 'auto', }}>
                    <Box sx={{ padding: '5px 20px', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant='outlined' component={NavLink} to={`/Diploma`} sx={{
                            width: '150px', height: 'fit-content', fontSize: '18px', color: '#0fb123', border: '1px solid #0fb123',
                            ':hover': { border: '1px solid #028b13' }
                        }} >กลับ</Button>
                        {honorCerListCandidateDetail?.status && <Button onClick={() => handleSubmit('D')} variant='contained' type='submit' sx={{ width: '150px', height: 'fit-content', fontSize: '18px', marginLeft: '20px', backgroundColor: '#0fb123', ':hover': { backgroundColor: '#028b13' } }}>บันทึก</Button>}
                        {/* <Button onClick={() => handleSubmit('C')} variant='contained' type='submit' sx={{ width: '150px', height: 'fit-content', fontSize: '18px', marginLeft: '20px', backgroundColor: 'red', ':hover': { backgroundColor: 'red' } }}>ยกเลิก</Button> */}
                        {honorCerListCandidateDetail?.status && <Button onClick={() => setOpenPopup(true)} variant='contained' type='submit' sx={{ display: honorCerListCandidateDetail?.status !== 'A' && honorCerListCandidateDetail?.status !== 'P' && honorCerListCandidateDetail?.status !== 'R' ? 'block' : 'none', width: '150px', height: 'fit-content', fontSize: '18px', marginLeft: '20px', }}>อนุมัติขั้นต้น</Button>}
                        {honorCerListCandidateDetail?.status && <Button onClick={() => setOpenPopupS(true)} variant='contained' type='submit' sx={{ display: honorCerListCandidateDetail?.status !== 'D' && honorCerListCandidateDetail?.status !== 'A' && honorCerListCandidateDetail?.status !== 'R' ? 'block' : 'none', width: '150px', height: 'fit-content', fontSize: '18px', marginLeft: '20px', }}>อนุมัติ</Button>}
                        {honorCerListCandidateDetail?.status && <Button onClick={() => setOpenPopupSS(true)} variant='contained' type='submit' sx={{ display: honorCerListCandidateDetail?.status === 'A' && honorCerListCandidateDetail?.status !== 'R' ? 'block' : 'none', width: '150px', height: 'fit-content', fontSize: '18px', marginLeft: '20px', }}>เสร็จสิ้น</Button>}

                        {/* {honorCerListCandidateDetail?.status === 'C' || honorCerListCandidateDetail?.status === 'A' ? '' : <Button onClick={() => handleSubmit('D')} variant='contained' type='submit' sx={{ width: '150px', height: 'fit-content', fontSize: '18px', marginLeft: '20px', backgroundColor: '#0fb123', ':hover': { backgroundColor: '#028b13' } }}>บันทึก</Button>}
                        {/* <Button onClick={() => handleSubmit('C')} variant='contained' type='submit' sx={{ width: '150px', height: 'fit-content', fontSize: '18px', marginLeft: '20px', backgroundColor: 'red', ':hover': { backgroundColor: 'red' } }}>ยกเลิก</Button> */}
                        {/* {honorCerListCandidateDetail?.status === 'A' ? '' : <Button onClick={() => handleSubmit('A')} variant='contained' type='submit' sx={{ width: '150px', height: 'fit-content', fontSize: '18px', marginLeft: '20px', }}>อนุมัติ</Button>}  */}
                    </Box>
                </Container>
                <Dialog
                    maxWidth="md"
                    fullWidth={true}
                    open={openPopup}
                    onClose={handleClose}
                >
                    <DialogTitle sx={{ fontSize: "2rem" }}>อนุมัติขั้นต้น</DialogTitle>
                    <Divider sx={{ marginBottom: '20px' }} />
                    <DialogContent sx={{ padding: "10px 60px" }}>
                        <Box sx={{ height: 'auto', width: '100%', }}>
                            <Grid container spacing={2}>
                                <Grid item lg={2} md={3} xs={12} sx={{ display: 'flex', alignItems: 'start', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>
                                    <Typography sx={{ marginRight: '15px', }}>วันที่พิจารณา</Typography>
                                </Grid>


                                <Grid item lg={9} md={9} xs={12}>

                                    <DesktopDatePicker
                                        label=""
                                        inputFormat="DD/MM/YYYY"
                                        value={startDate}
                                        maxDate={endDate ? new Date(endDate) : ''}
                                        // onViewChange={(newValue) }
                                        onChange={(newValue) => { setStartDate(newValue) }}
                                        renderInput={(params) => <TextField size='small' color="success" sx={{ width: '100%' }}
                                            InputProps={params?.InputProps}
                                            inputRef={params?.inputRef}
                                            // placeholder={params?.inputProps?.placeholder}
                                            // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                                            // onChange={params?.inputProps?.onChange}
                                            // type={params?.inputProps?.type}
                                            inputProps={
                                                {
                                                    placeholder: params?.inputProps?.placeholder,
                                                    readOnly: true,
                                                    value: params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null,
                                                    onChange: params?.inputProps?.onChange,
                                                    type: params?.inputProps?.type
                                                }

                                            }
                                        />}
                                    />

                                </Grid>
                                <Grid item lg={2} md={3} xs={12} sx={{ display: 'flex', alignItems: 'start', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>
                                    <Typography sx={{ marginRight: '15px', }}>วันที่อนุมัติ</Typography>
                                </Grid>


                                <Grid item lg={9} md={9} xs={12}>

                                    <DesktopDatePicker
                                        label=""
                                        inputFormat="DD/MM/YYYY"
                                        value={endDate}
                                        minDate={new Date(startDate)}
                                        onChange={(newValue) => { setEndDate(newValue) }}
                                        renderInput={(params) => <TextField size='small' color="success" sx={{ width: '100%' }}
                                            InputProps={params?.InputProps}
                                            inputRef={params?.inputRef}
                                            // placeholder={params?.inputProps?.placeholder}
                                            // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                                            // onChange={params?.inputProps?.onChange}
                                            // type={params?.inputProps?.type}
                                            inputProps={
                                                {
                                                    placeholder: params?.inputProps?.placeholder,
                                                    readOnly: true,
                                                    value: params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null,
                                                    onChange: params?.inputProps?.onChange,
                                                    type: params?.inputProps?.type
                                                }

                                            }
                                        />}
                                    />

                                </Grid>

                                <Grid item lg={2} md={3} xs={12} sx={{ display: 'flex', alignItems: 'start', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>
                                    <Typography sx={{ marginRight: '15px', }}>เหตุผล</Typography>
                                </Grid>


                                <Grid item lg={9} md={9} xs={12}>

                                    <TextField label="" size='small' value={remark} onChange={e => setRemark(e.target.value)} multiline rows={6} variant="outlined" color="success" type="text" sx={{ width: '100%' }} />

                                </Grid>
                            </Grid>
                        </Box>

                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'center' }}>
                        <Button
                            onClick={handleClose}
                            variant="outlined"
                            sx={{
                                width: "150px",
                                height: "fit-content",
                                fontSize: "18px",
                                color: "#0fb123",
                                border: "1px solid #0fb123",
                                ":hover": { border: "1px solid #028b13" },
                            }}
                        >
                            ยกเลิก
                        </Button>
                        {startDate && endDate ?
                            <Button
                                onClick={() => handleSubmit('P')}
                                variant="contained"
                                sx={{
                                    width: "150px",
                                    height: "fit-content",
                                    fontSize: "18px",
                                    marginLeft: "20px",
                                    backgroundColor: "#0fb123",
                                    ":hover": { backgroundColor: "#028b13" },
                                }}
                            >
                                ยืนยัน
                            </Button> : ''}
                    </DialogActions>
                </Dialog>
                <Dialog
                    maxWidth="md"
                    fullWidth={true}
                    open={openPopupS}
                    onClose={handleCloseS}
                >
                    <DialogTitle sx={{ fontSize: "2rem" }}>อนุมัติ</DialogTitle>
                    <Divider sx={{ marginBottom: '20px' }} />
                    <DialogContent sx={{ padding: "10px 60px" }}>
                        <Box sx={{ height: 'auto', width: '100%', }}>
                            <Grid container spacing={2}>
                                <Grid item lg={2} md={3} xs={12} sx={{ display: 'flex', alignItems: 'start', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>
                                    <Typography sx={{ marginRight: '15px', }}>วันที่พิจารณา</Typography>
                                </Grid>


                                <Grid item lg={9} md={9} xs={12}>

                                    <DesktopDatePicker
                                        label=""
                                        inputFormat="DD/MM/YYYY"
                                        value={startDateS}
                                        maxDate={endDateS ? new Date(endDateS) : ''}
                                        // onViewChange={(newValue) }
                                        onChange={(newValue) => { setStartDateS(newValue) }}
                                        renderInput={(params) => <TextField size='small' color="success" sx={{ width: '100%' }}
                                            InputProps={params?.InputProps}
                                            inputRef={params?.inputRef}
                                            placeholder={params?.inputProps?.placeholder}
                                            value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                                            onChange={params?.inputProps?.onChange}
                                            type={params?.inputProps?.type}
                                        />}
                                    />

                                </Grid>
                                <Grid item lg={2} md={3} xs={12} sx={{ display: 'flex', alignItems: 'start', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>
                                    <Typography sx={{ marginRight: '15px', }}>วันที่อนุมัติ</Typography>
                                </Grid>


                                <Grid item lg={9} md={9} xs={12}>

                                    <DesktopDatePicker
                                        label=""
                                        inputFormat="DD/MM/YYYY"
                                        value={endDateS}
                                        minDate={new Date(startDateS)}
                                        onChange={(newValue) => { setEndDateS(newValue) }}
                                        renderInput={(params) => <TextField size='small' color="success" sx={{ width: '100%' }}
                                            InputProps={params?.InputProps}
                                            inputRef={params?.inputRef}
                                            placeholder={params?.inputProps?.placeholder}
                                            value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                                            onChange={params?.inputProps?.onChange}
                                            type={params?.inputProps?.type}
                                        />}
                                    />

                                </Grid>

                                <Grid item lg={2} md={3} xs={12} sx={{ display: 'flex', alignItems: 'start', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>
                                    <Typography sx={{ marginRight: '15px', }}>เหตุผล</Typography>
                                </Grid>


                                <Grid item lg={9} md={9} xs={12}>

                                    <TextField label="" size='small' value={remarkS} onChange={e => setRemarkS(e.target.value)} multiline rows={6} variant="outlined" color="success" type="text" sx={{ width: '100%' }} />

                                </Grid>
                            </Grid>
                        </Box>

                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'center' }}>
                        <Button
                            onClick={handleCloseS}
                            variant="outlined"
                            sx={{
                                width: "150px",
                                height: "fit-content",
                                fontSize: "18px",
                                color: "#0fb123",
                                border: "1px solid #0fb123",
                                ":hover": { border: "1px solid #028b13" },
                            }}
                        >
                            ยกเลิก
                        </Button>
                        {startDateS && endDateS ?
                            <Button
                                onClick={() => handleSubmit('A')}
                                variant="contained"
                                sx={{
                                    width: "150px",
                                    height: "fit-content",
                                    fontSize: "18px",
                                    marginLeft: "20px",
                                    backgroundColor: "#0fb123",
                                    ":hover": { backgroundColor: "#028b13" },
                                }}
                            >
                                ยืนยัน
                            </Button> : ''}
                    </DialogActions>
                </Dialog>
                <Dialog
                    maxWidth="md"
                    fullWidth={true}
                    open={openPopupSS}
                    onClose={handleCloseSS}
                >
                    <DialogTitle sx={{ fontSize: "2rem" }}>เสร็จสิ้น</DialogTitle>
                    <Divider sx={{ marginBottom: '20px' }} />
                    <DialogContent sx={{ padding: "10px 60px" }}>
                        <Box sx={{ height: 'auto', width: '100%', }}>
                            <Grid container spacing={2}>
                                <Grid item lg={2} md={3} xs={12} sx={{ display: 'flex', alignItems: 'start', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>
                                    <Typography sx={{ marginRight: '15px', }}>วันที่รับในพิธี</Typography>
                                </Grid>
                                <Grid item lg={9} md={9} xs={12}>
                                    <DesktopDatePicker
                                        label=""
                                        inputFormat="DD/MM/YYYY"
                                        value={date}

                                        onChange={(newValue) => { setDate(newValue) }}
                                        renderInput={(params) => <TextField size='small' color="success" sx={{ width: '100%' }}
                                            InputProps={params?.InputProps}
                                            inputRef={params?.inputRef}
                                            placeholder={params?.inputProps?.placeholder}
                                            value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                                            onChange={params?.inputProps?.onChange}
                                            type={params?.inputProps?.type}
                                        />}
                                    />
                                </Grid>
                            </Grid>
                        </Box>

                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'center' }}>
                        <Button
                            onClick={handleCloseSS}
                            variant="outlined"
                            sx={{
                                width: "150px",
                                height: "fit-content",
                                fontSize: "18px",
                                color: "#0fb123",
                                border: "1px solid #0fb123",
                                ":hover": { border: "1px solid #028b13" },
                            }}
                        >
                            ยกเลิก
                        </Button>
                        {date ?
                            <Button
                                onClick={() => handleSubmit('R')}
                                variant="contained"
                                sx={{
                                    width: "150px",
                                    height: "fit-content",
                                    fontSize: "18px",
                                    marginLeft: "20px",
                                    backgroundColor: "#0fb123",
                                    ":hover": { backgroundColor: "#028b13" },
                                }}
                            >
                                ยืนยัน
                            </Button> : ''}
                    </DialogActions>
                </Dialog>
            </Box>
        </>
    )
}