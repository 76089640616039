import { Box, Button, TextField, FormControl, Select, InputLabel, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Container, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Menu, MenuItem } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import { NavLink } from 'react-router-dom'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import TitleHead from '../../components/TitleHead';
import ApiContext from '../../context/ApiProvider';
import Divider from '@mui/material/Divider';
import Swal from 'sweetalert2'
import Grid from "@mui/material/Grid";
import GridField from '../../components/GridTextField';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
function createData(name, pee, last, datec, datea, sss) {
    return { name, pee, last, datec, datea, sss };
}

const rows = [
    createData('นาย', 'เดชสวัสดิ์', 'นกพึ่ง', 2561, 10, 10,),

];



export default function Addverifi() {
    const [data, setdata] = useState([])
    const handleClickOpen = (e) => {
        setdata(rows)
        // setTitleBoard(item?.boardDepName)
        // setItem(item)
        // console.log(item)
    };
    return (
        <>
            <Container maxWidth='xl' sx={{ height: 'auto', marginTop: '40px' }}>
                <TitleHead title='ข้อมูลการรับเข็มกิตติคุณ' subTitle='ประวัติรับเข็มกิตติคุณ'>
                    {data.length > 0 ? (
                        <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                            <Button variant='outlined' color='success'><LocalPrintshopIcon sx={{ marginRight: '10px' }} />พิมเอกสาร</Button>
                        </Box>
                    ) : null}
                </TitleHead>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <FormControl sx={{ m: 1, minWidth: "20%", marginRight: 3 }} color="success" >
                        <InputLabel>เลือกชุดคณะกรรมการ</InputLabel>
                        <Select
                            // open={open}
                            // onClose={handleClose}
                            // onOpen={handleOpen}
                            // defaultValue={1}
                            // value={site}
                            // onChange={handleSelect}
                            label="Fillter"
                        >
                            <MenuItem value={1}>สิ้นสุดวาระ</MenuItem>
                            <MenuItem value={2}>ปัจจุบันที่ดำรงตำเเหน่ง</MenuItem>
                        </Select>
                    </FormControl>



                    {/* <Box sx={{ display: 'flex', justifyContent: 'space-between' }}> */}
                    <Typography sx={{ color: 'green' }}>รายชื่อคณะกรรมการพิจารณาทั้งหมด 3 ท่าน</Typography>
                    {/* </Box> */}
                </Box>
                <Grid container spacing={2} sx={{ marginTop: '15px', }}>
                    <GridField >
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography sx={{ fontSize: '17px', fontWeight: '500' }}>นาย ชนะ นิติการ</Typography>
                            <Typography sx={{ color: 'green' }}>ประธาน</Typography>
                        </Box>
                    </GridField>
                    <GridField >
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography sx={{ fontSize: '17px', fontWeight: '500' }}>นาย ทินพัฒน์ เรืองฤทธิ์</Typography>
                            <Typography sx={{ color: 'green' }}>รองประธาน</Typography>
                          
                            </Box>
                    </GridField>
                    <GridField >
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography sx={{ fontSize: '17px', fontWeight: '500' }}>นาย ธวัช อธิการบดี</Typography>
                            <Typography sx={{ color: 'green' }}>กรรมการ</Typography>
                            <Divider orientation="vertical" variant="middle" flexItem sx={{fontWeight:'400' , }} />

                        </Box>

                    </GridField>
                </Grid>
                <Divider sx={{ margin: '40px 0px' }} />
                {data?.length > 0 ? (<>
                    <Box sx={{ display: 'flex', flexDirection: 'column', }}>
                        <Typography sx={{ fontSize: '23px', fontWeight: "500" }}>แนปไฟล์เอกสารที่เกี่ยวข้อง</Typography>
                        <Box sx={{ marginTop: "15px" }}>

                            <Button variant='outlined' color='success'>   <FileUploadIcon />อัพโหลดไฟล์


                            </Button>
                           
                        </Box>
                        
                    </Box>
                    <Divider sx={{ margin: '40px 0px' }} />
                    <Typography sx={{ fontSize: '23px', fontWeight: "500" }}>ประกาศนียบัตร</Typography>
                    <Divider sx={{ margin: '25px 0px' }} />
                    <Box sx={{ display: 'flex', }}>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', marginBottom: '20px', }}>
                            <TextField type='date' />
                            <Button sx={{ marginLeft: '25px', color: '#fff', backgroundColor: '#1fc64c', '&:hover': { background: '#4caf50' } }} >ดึงฐานข้อมูลประวัติ  </Button>
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginBottom: '20px', }}>
                            <FormControl sx={{ m: 0, minWidth: 100, marginRight: 3 }} color="success" >
                                <InputLabel>Fillter</InputLabel>
                                <Select
                                    // open={open}
                                    // onClose={handleClose}
                                    // onOpen={handleOpen}
                                    // defaultValue={1}
                                    // value={site}
                                    // onChange={handleSelect}
                                    label="Fillter"
                                >

                                    <MenuItem value={2}>ชื่อ</MenuItem>
                                </Select>
                            </FormControl>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <TextField id="outlined-basic" label="กรอกข้อมูลค้นหา" variant="outlined" color="success" />
                                <Button variant='contained' sx={{ padding: '15px', marginLeft: '20px', backgroundColor: '#0fb123', ':hover': { backgroundColor: '#028b13' } }}><SearchIcon /></Button>
                            </Box>
                        </Box>
                    </Box>

                    <Stack spacing={3}>
                        <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead sx={{ backgroundColor: "#e7e7e7" }}>
                                    <TableRow>
                                        <TableCell align="left" sx={{ fontSize: "18px", fontWeight: "500" }}>
                                            <Checkbox value='all' color="success" />
                                        </TableCell>
                                        <TableCell align="left" sx={{ fontSize: "18px", fontWeight: "500" }}>คำนำหน้าชื่อ</TableCell>
                                        <TableCell align="left" sx={{ fontSize: "18px", fontWeight: "500" }}>ชื่อ</TableCell>
                                        <TableCell align="left" sx={{ fontSize: "18px", fontWeight: "500" }}>นามสกุล</TableCell>
                                        <TableCell align="left" sx={{ fontSize: "18px", fontWeight: "500" }}>ปีที่ได้รับ</TableCell>

                                        <TableCell align="left" sx={{ fontSize: "18px", fontWeight: "500" }}>การอบรม</TableCell>
                                        <TableCell align="left" sx={{ fontSize: "18px", fontWeight: "500" }}>การร่วมประชุม</TableCell>
                                        <TableCell sx={{ color: '#000', border: 'none', }} align="center"></TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((row, i) => (
                                        <TableRow
                                            key={i}
                                            /* onClick={() => navigate(`/ProjectSource/SearchPoliticalMinor`)} */
                                            sx={{
                                                fontSize: '18px', cursor: 'pointer', '&:hover': {
                                                    background: '#f3f2f2'
                                                }
                                            }}>
                                            <TableCell>
                                                <Checkbox color="success"

                                                    checked={row?.isChecked || false}

                                                /></TableCell>
                                            <TableCell component="th" scope="row" sx={{ fontSize: "18px", }} >
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="left" sx={{ fontSize: "18px", }}>{row.pee}</TableCell>
                                            <TableCell align="left" sx={{ fontSize: "18px", }}>{row.last}</TableCell>
                                            <TableCell align="left" sx={{ fontSize: "18px", }}>{row.datec}</TableCell>
                                            <TableCell align="left" sx={{ fontSize: "18px", }}>{row.datea}</TableCell>
                                            <TableCell align="left" sx={{ fontSize: "18px", }}>{row.sss}</TableCell>
                                            <TableCell sx={{ color: '#000', }} align="center">
                                                <MoreHorizIcon />
                                            </TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Stack>

                </>


                ) :
                    <Box sx={{ display: 'flex', }}>
                        <TextField type='date' />
                        <Button onClick={handleClickOpen} sx={{ marginLeft: '25px', color: '#fff', backgroundColor: '#1fc64c', '&:hover': { background: '#4caf50' } }} >ดึงฐานข้อมูลประวัติ  </Button>
                    </Box>
                }








            </Container>
            {data?.length > 0 ? (
                <Box sx={{
                    position: 'sticky', bottom: '0px', width: '100%', marginTop: '50px', background: '#fff'
                }}>
                    <Divider sx={{ marginBottom: '10px' }} />
                    <Container maxWidth='xl' sx={{ height: 'auto', }}>
                        <Box sx={{ padding: '5px 20px', display: 'flex', justifyContent: 'flex-end' }}>
                            <Button variant='outlined' sx={{
                                width: '150px', height: 'fit-content', fontSize: '18px', color: '#0fb123', border: '1px solid #0fb123',
                                ':hover': { border: '1px solid #028b13' }
                            }} component={NavLink} to='/DataVerification'>กลับ</Button>
                            <Button variant='contained' type='sumbit' sx={{ width: '150px', height: 'fit-content', fontSize: '18px', marginLeft: '20px', backgroundColor: '#0fb123', ':hover': { backgroundColor: '#028b13' } }}>บันทึก</Button>
                            <Button variant='contained' type='sumbit' sx={{ width: '150px', height: 'fit-content', fontSize: '18px', marginLeft: '20px', }}>อนุมัติ</Button>
                        </Box>
                    </Container>
                </Box>
            ) : null}

        </>
    )
}
