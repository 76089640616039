import { Box, Container, Divider, Typography } from '@mui/material'
import React from 'react'
import Header from './Header'
import Sidebar from './Sidebar.js'
import { Outlet, useLocation } from 'react-router-dom';

export default function Layout() {
  const [openHome, setOpenHome] = React.useState(true);
  const [openNoti, setOpenNoti] = React.useState(false);
  const [openSett, setOpenSett] = React.useState(false);
  let location = useLocation()  

  return (
    <div>
      <Header openHome={openHome} openNoti={openNoti} openSett={openSett} setOpenHome={setOpenHome} setOpenNoti={setOpenNoti} setOpenSett={setOpenSett} />
      <Box sx={{ display: 'flex', position: 'relative', overflow: 'hidden' }}>
        <Sidebar openHome={openHome} openNoti={openNoti} openSett={openSett} setOpenHome={setOpenHome} setOpenNoti={setOpenNoti} setOpenSett={setOpenSett}>
          <Box sx={{ display: 'block', width: '100%', height:'86vh', overflow:'auto', position:'relative' }}>
            {/* <Container maxWidth='xl' sx={{ height: 'auto', marginTop: '40px' }}> */}
              <Outlet />
            {/* </Container> */}
            {location?.state?.add ? '' : 
            <Box sx={{ position: 'fixed',display:{md:'block', xs:'none'}, bottom: '0', width: openSett ? '88%' : openHome ? '88%' : openNoti ? '88%' : '100%',background:'#fff' }}>
              <Divider />
              <Box sx={{ padding: '5px 20px', display: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={{color:'#bdbdbd'}}>@ 2019 King Prajadhipok's Institute the Gevernment Complex Commemorating All Right Reserved</Typography>
                {/* <Typography sx={{marginRight:'10px'}}>ข้อกำหนดเเละนโยบาย</Typography> */}
              </Box>
            </Box> }
          </Box>
        </Sidebar>

      </Box>

    </div>
  )
}
