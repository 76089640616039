import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Box,
  Divider,
  Collapse,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { NavLink, useLocation, useParams } from "react-router-dom";
import HistoryIcon from '@mui/icons-material/History';
import api from "../../api/api";
import { useSelector } from "react-redux";
import { selectCanActivate } from "../../store/modules/selector";
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import ApprovalOutlinedIcon from '@mui/icons-material/ApprovalOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
export default function MenuHome({ open, setOpenHome }) {
  let location = useLocation();
  // console.log(location)
  const [openList, setOpenList] = React.useState(true);

  const roleList = useSelector(selectCanActivate);

  const handleClick = () => {
    setOpenList(!openList);
  };
  const param = useParams();
  // console.log(param)
  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={open}
      sx={{
        display: open ? "block" : "none",
        flexShrink: 0,
        zIndex: "10",
        "& .MuiDrawer-paper": {
          position: "relative",
          width: "240px",
          height: "86vh",
          background: "#f7f7f7",
        },
      }}
    >
      <List>
        <ListItem disablePadding sx={{ display: "block" }}>
          {roleList.find((x) => x.menuNo === "M001" && x.isView) && (
            <ListItemButton
              component={NavLink}
              to="/"
              className={location?.state?.add ? "active" : ""}
              sx={{
                "&.active": {
                  color: "#0fb123",
                  "& .MuiListItemIcon-root": { color: "#0fb123" },
                },
              }}
            >
              <ListItemIcon sx={{ justifyContent: "center" }}>
                <AccountBoxIcon />
              </ListItemIcon>
              <ListItemText primary="ข้อมูลส่วนบุคคล" />
            </ListItemButton>
          )}
          <>
            <ListItemButton
              // component={NavLink}
              // to="/committee"
              className={openList ? "active" : ""}
              onClick={handleClick}
              sx={{
                "&.active": {
                  color: "#0fb123",
                  "& .MuiListItemIcon-root": { color: "#0fb123" },
                },
              }}
            >
              <ListItemIcon sx={{ justifyContent: "center" }}>
                <WorkspacePremiumIcon />
              </ListItemIcon>
              <ListItemText primary="การบันทึกข้อมูล" />
              {openList ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openList} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {roleList.find((x) => x.menuNo === "M002" && x.isView) && (
                  <ListItemButton
                    component={NavLink}
                    to="/DataVerification"
                    sx={{
                      "&.active": {
                        color: "#0fb123",
                        "& .MuiListItemIcon-root": { color: "#0fb123" },
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{ justifyContent: "center" }}
                    ></ListItemIcon>
                    <ListItemText primary="ผู้แต่งตั้ง" />
                  </ListItemButton>
                )}
                {roleList.find((x) => x.menuNo === "M003" && x.isView) && (
                  <ListItemButton
                    component={NavLink}
                    to="/committee"
                    sx={{
                      "&.active": {
                        color: "#0fb123",
                        "& .MuiListItemIcon-root": { color: "#0fb123" },
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{ justifyContent: "center" }}
                    ></ListItemIcon>
                    <ListItemText primary="ชุดกรรมการ" />
                  </ListItemButton>
                )}
                {roleList.find((x) => x.menuNo === "M004" && x.isView) && (
                  <ListItemButton
                    component={NavLink}
                    to="/HonorPin"
                    sx={{
                      "&.active": {
                        color: "#0fb123",
                        "& .MuiListItemIcon-root": { color: "#0fb123" },
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{ justifyContent: "center" }}
                    ></ListItemIcon>
                    <ListItemText primary="การรับเข็ม" />
                  </ListItemButton>
                )}
                {roleList.find((x) => x.menuNo === "M005" && x.isView) && (
                  <ListItemButton
                    component={NavLink}
                    to="/Diploma"
                    sx={{
                      "&.active": {
                        color: "#0fb123",
                        "& .MuiListItemIcon-root": { color: "#0fb123" },
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{ justifyContent: "center" }}
                    ></ListItemIcon>
                    <ListItemText primary="การรับประกาศนียบัตร" />
                  </ListItemButton>
                )}
              </List>
            </Collapse>
          </>
        </ListItem>
        {roleList.find((x) => x.menuNo === "M006" && x.isView) && (
          <ListItemButton
            component={NavLink}
            to="Lecturer"
            sx={{
              "&.active": {
                color: "#0fb123",
                "& .MuiListItemIcon-root": { color: "#0fb123" },
              },
            }}
          >
            <ListItemIcon sx={{ justifyContent: "center" }}>
              <ContactPageOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="ข้อมูลวิทยากร" />
          </ListItemButton>
        )}
        {roleList.find((x) => x.menuNo === "M007" && x.isView) && (
          <ListItemButton
            component={NavLink}
            to="/Donate"
            sx={{
              "&.active": {
                color: "#0fb123",
                "& .MuiListItemIcon-root": { color: "#0fb123" },
              },
            }}
          >
            <ListItemIcon sx={{ justifyContent: "center" }}>
              <LocalAtmOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="ผู้บริจาคเงิน" />
          </ListItemButton>
        )}
        {roleList.find((x) => x.menuNo === "M008" && x.isView) && (
          <ListItemButton
            component={NavLink}
            to="/RuleCalculate"
            sx={{
              "&.active": {
                color: "#0fb123",
                "& .MuiListItemIcon-root": { color: "#0fb123" },
              },
            }}
          >
            <ListItemIcon sx={{ justifyContent: "center" }}>
              <ApprovalOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="คุณสมบัติที่คำนวณเข็ม" />
          </ListItemButton>
        )}
        {roleList.find((x) => x.menuNo === "M009" && x.isView) && (
          <ListItemButton
            component={NavLink}
            to="/Report"
            sx={{
              "&.active": {
                color: "#0fb123",
                "& .MuiListItemIcon-root": { color: "#0fb123" },
              },
            }}
          >
            <ListItemIcon sx={{ justifyContent: "center" }}>
              <AssessmentOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="รายงาน" />
          </ListItemButton>
        )}
        {/* <ListItemButton
            component={NavLink}
            to="/Logfile"
            sx={{
              "&.active": {
                color: "#0fb123",
                "& .MuiListItemIcon-root": { color: "#0fb123" },
              },
            }}
          >
            <ListItemIcon sx={{ justifyContent: "center" }}>
              <HistoryIcon />
            </ListItemIcon>
            <ListItemText primary="Logfile" />
          </ListItemButton> */}
      </List>
      <Box sx={{ position: "absolute", bottom: "0", width: "100%" }}>
        <Divider />
        <Box
          sx={{ display: "flex", padding: "5px 10px", color: "#939393" }}
          onClick={() => setOpenHome(false)}
        >
          <ArrowCircleLeftIcon sx={{ marginRight: "10px" }} />
          <Typography>ซ่อนเมนู</Typography>
        </Box>
      </Box>
    </Drawer>
  );
}
