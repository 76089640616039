import React from 'react'
import MenuHome from './MenuHome'
import MenuNoti from './MenuNoti'
import MenuSetting from './MenuSetting'

export default function Sidebar({ openHome, openNoti, openSett, children, setOpenHome, setOpenNoti, setOpenSett, }) {
  // console.log(openHome, openNoti, openSett)
  return (
    <>
      <MenuHome open={openHome} setOpenHome={setOpenHome} />
      <MenuNoti open={openNoti} setOpenNoti={setOpenNoti} />
      <MenuSetting open={openSett} setOpenSett={setOpenSett} />
      {children}
    </>
  )
}
