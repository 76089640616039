import { Api } from '@mui/icons-material';
import { Autocomplete, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, TextField, Typography, Checkbox } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import api from '../../api/api';
import Swal from "sweetalert2";
export default function DialogJobe({ open, setOpen, workexp, setworkexp, id }) {

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [mPosition, setMPosition] = useState([]);
    const [value, setValue] = useState('');
    const [checked, setChecked] = useState(false);
    const [positionDsc, setPositionDsc] = useState('')
    const body = {
        personalID: id,
        startDate: moment(startDate).format('YYYY-MM-DD') === 'Invalid date' ? moment(new Date()).format('YYYY-MM-DD') : moment(startDate).format('YYYY-MM-DD'),
        endDate:  moment(endDate).format("YYYY-MM-DD") === "Invalid date" ? null :  checked ? null : moment(endDate).format("YYYY-MM-DD"),
        positionID: value?.positionID,
        // position: {
        //     positionName: value?.positionName
        // },
        positionDsc: positionDsc,

        status: true
    }
    const handleClose = () => {
        setOpen(false);
        setStartDate(null)
        setEndDate(null)
        setValue(null)
        setPositionDsc('')
    };
    const getMasterPosition = async () => {
        const res = await api.get(`api/v1.0/master/position/list?positionNameType=P`)
        const data = await res.data
        if (data.status === true) {
            setMPosition(data.result)
            // console.log(data.result);

        }

    }
    const handleClick = () => {
        if (positionDsc !== "" && value !== null ) {
            const postappre = async () => {
                const res = await api.post(`api/v1.0/personal/create-workexperince`, body)
                const data = await res.data
                if (data.status === true) {
                    // console.log(data.result);

                    Swal.fire(
                        "สร้างเเล้ว"
                        // 'success'
                    );
                    setTimeout(() => {
                        workexp()
                    }, 1000);


                    setOpen(false);


                    setStartDate(null)
                    setEndDate(null)
                    setValue(null)
                    setPositionDsc('')
                }

            }
            postappre()
        } else {
            alert('กรุณากรอกข้อมูลให้ครบ')
        }
    };
    useEffect(() => {

        getMasterPosition()
    }, [])
    // console.log(workexperinceDetail)
    return (
        <Dialog fullWidth maxWidth='md' open={open} onClose={handleClose}>
            <DialogTitle sx={{ margin: '10px 40px' }}>เพิ่มตำเเหน่งงาน</DialogTitle>
            <Divider />
            <DialogContent sx={{ margin:  {md:'10px 100px', xs:'10px 30px'} }}>
                <Grid container spacing={2}>
                    <Grid item lg={3}>
                        <Typography sx={{ marginRight: '40px' }}>วันที่</Typography>
                    </Grid>
                    <Grid item lg={checked ? 9 : 4} xs={12}>
                        <DesktopDatePicker
                            label=""
                            inputFormat="DD/MM/YYYY"
                            value={startDate}
                            maxDate={endDate ? new Date(endDate) : ''}
                            onChange={(newValue) => { setStartDate(newValue) }}
                            renderInput={(params) => <TextField size='small' color="success" sx={{ width: '100%' }}
                                InputProps={params?.InputProps}
                                inputRef={params?.inputRef}
                                // placeholder={params?.inputProps?.placeholder}
                                // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                                // onChange={params?.inputProps?.onChange}
                                // type={params?.inputProps?.type}
                                inputProps={
                                    {
                                      placeholder: params?.inputProps?.placeholder,
                                      readOnly: true,
                                      value: params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null,
                                      onChange: params?.inputProps?.onChange,
                                      type: params?.inputProps?.type
                                    }
                
                                  }
                            />}
                        />
                    </Grid>
                    {!checked && <><Grid item lg={1}  xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Typography>~</Typography></Grid>
                        <Grid item lg={4}  xs={12}>
                            <DesktopDatePicker
                                label=""
                                inputFormat="DD/MM/YYYY"
                                value={endDate}
                                minDate={new Date(startDate)}
                                onChange={(newValue) => { setEndDate(newValue) }}
                                renderInput={(params) => <TextField size='small' color="success" sx={{ width: '100%' }}
                                    InputProps={params?.InputProps}
                                    inputRef={params?.inputRef}
                                    // placeholder={params?.inputProps?.placeholder}
                                    // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                                    // onChange={params?.inputProps?.onChange}
                                    // type={params?.inputProps?.type}
                                    inputProps={
                                        {
                                          placeholder: params?.inputProps?.placeholder,
                                          readOnly: true,
                                          value: params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null,
                                          onChange: params?.inputProps?.onChange,
                                          type: params?.inputProps?.type
                                        }
                    
                                      }
                                />}
                            />
                        </Grid></>}
                    <Grid item lg={3}>
                    </Grid>
                    <Grid item lg={9}  xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox checked={checked} onChange={() => setChecked(!checked)} /> <Typography>ยังคงดำรงตำเเหน่งอยู่ ณ ปัจจุบัน</Typography>
                    </Grid>
                    <Grid item lg={3}>
                        <Typography sx={{ marginRight: '40px' }}>คุณสมบัติ</Typography>
                    </Grid>
                    <Grid item lg={9}  xs={12}>
                        <Autocomplete
                            size='small'
                            fullWidth
                            value={value}
                            onChange={(event, newValue) => {
                                setValue(newValue)
                            }}
                            options={mPosition}
                            getOptionLabel={option => option?.positionName ? option?.positionName : ''}
                            renderInput={(params) => <TextField {...params} placeholder='เลือกคุณสมบัติ' />}
                        />
                    </Grid>
                    <Grid item lg={3}>
                        <Typography sx={{ marginRight: '40px' }}>ตำเเหน่ง</Typography>
                    </Grid>
                    <Grid item lg={9} xs={12}>
                        <TextField
                            size='small'
                            fullWidth
                            variant="outlined"
                            value={positionDsc}
                            onChange={e => setPositionDsc(e.target.value)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center' }}>
                <Button variant='outlined' sx={{ borderColor: "#1fc64c", '&:hover': { borderColor: '#4caf50' }, color: "#1fc64c", padding: "7px 55px", marginBottom: '20px' }} onClick={handleClose}>กลับ</Button>
                <Button onClick={handleClick} sx={{ backgroundColor: "#1fc64c", '&:hover': { background: '#4caf50' }, color: "#FFFF", padding: "7px 55px", marginBottom: '20px' }}>บันทึก</Button>
            </DialogActions>
        </Dialog>

    )
}
