import React, { createContext } from "react";
import api from "../api/api";
import Swal from "sweetalert2";
const ApiContext = createContext({});

export function ApiProvideer({ children }) {
  ///////////////////////BoardDepartment////////////////////////
  const getBoardDepartmentList = (setBoardDepartmentList, textSearchDBoard) => {
    api
      .get(`api/v1.0/board/department/list?boardDepName=${textSearchDBoard || ''}`)
      .then((res) => res.data)
      .then((data) => {
        if (data.status === true) {
          setBoardDepartmentList(data.result);
        } else {
          console.log("failed");
        }
      });
  };
  const addBoardDepartment = (data, setOpen) => {
    api
      .post("api/v1.0/board/department/create", data)
      .then((res) => res.data)
      .then((data) => {
        if (data.status === true) {
          // console.log(data);
          // setOpen(false)
          // setTimeout(() => {
          //     window.location.reload()
          // }, 1000);
        } else {
          console.log("failed");
        }
      });
  };
  ////////////////////////////BoardList/////////////////////////
  const getBoardList = (setBoardList, param, textSearchBoard, fillter) => {
    const data = [
      {
        value: 1,
        fit: "",
      },
      {
        value: 2,
        fit: "I",
      },
      {
        value: 3,
        fit: "A",
      },
    ];
    const boardFil = data.filter((x) => x?.value === fillter).map((x) => x?.fit);
    // console.log(boardFil)
    api
      .get(
        `api/v1.0/board/list?boardDepID=${param?.id || ''}&keyword=${textSearchBoard || ''}&boardFil=${boardFil[0] || ''}`
      )
      .then((res) => res.data)
      .then((data) => {
        if (data.status === true) {
          setBoardList(data.result);
        } else {
          console.log(" failed");
        }
      });
  };
  const getBoardDetail = (setBoardDetail, boardId, setLoading) => {
    api
      .get(`api/v1.0/board/list?boardID=${boardId}`)
      .then((res) => res.data)
      .then((data) => {
        if (data.status === true) {
          setBoardDetail(data.result);
          setLoading(false);
        } else {
          console.log(" failed");
        }
      });
  };
  const getPersonal = (setPersonal, firstname, lastname) => {
    api
      .get(
        `api/v1.0/personal/search?firstname=${firstname}&lastname=${lastname}`
      )
      .then((res) => res.data)
      .then((data) => {
        if (data.status === true) {
          setPersonal(data.result);
        } else {
          console.log(" failed");
        }
      });
  };
  const getAppointer = (setAppointer, citizen) => {
    api
      .get(`/api/v1.0/appointer/list?keyword=${citizen || ''}`)
      .then((res) => res.data)
      .then((data) => {
        if (data.status === true) {
          setAppointer(data.result);
        } else {
          console.log(" failed");
        }
      });
  };
  const getPosition = (setPosition) => {
    api
      .get(`api/v1.0/master/position/list?positionNameType=B`)
      .then((res) => res.data)
      .then((data) => {
        if (data.status === true) {
          setPosition(data.result);
        } else {
          console.log(" failed");
        }
      });
  };
  const createBoard = (value) => {
    api
      .post(`api/v1.0/board/create`, value)
      .then((res) => res.data)
      .then((data) => {
        if (data.status === true) {
          // console.log(data.result)
          Swal.fire({
            title: "บันทึกแล้ว",
            text: "บันทึกข้อมูลสำเร็จ",
            icon: "success",
            confirmButtonColor: "#0f962d",
            confirmButtonText: "ตกลง",
            allowOutsideClick: false,

          }).then((result) => {
            if (result.isConfirmed) {
              window.location = `/Committee/${value.boardDepID}`;
            }
          });
        } else {
          console.log(" failed");
        }
      });
  };
  const updateBoard = (value) => {
    api
      .post(`api/v1.0/board/create`, value)
      .then((res) => res.data)
      .then((data) => {
        if (data.status === true) {
          // console.log(data.result)
          Swal.fire({
            title: "อัพเดทแล้ว",
            text: "บันทึกข้อมูลสำเร็จ",
            icon: "success",
            confirmButtonColor: "#0f962d",
            confirmButtonText: "ตกลง",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              window.location = `/Committee/${value.boardDepID}`;
            }
          });
        } else {
          console.log(" failed");
        }
      });
  };

  // const fileUpload = (formData, file, files, setFiles) => {
  //     api.post('api/v1.0/filemanages/upload', formData, {
  //         headers: {
  //             "Content-Type": "multipart/form-data",
  //         },
  //     }).then((res) => res.data).then((data) => {
  //         if (data.status === true) {
  //             console.log(data.result)
  //             file.isUploading = false
  //             setFiles([...files, data.result]);
  //         }
  //         else {
  //             console.log(' failed')
  //         }
  //     })
  // }
  const FileDownload = async (x) => {
    try {
      const result = await api({
        url: `api/v1.0/filemanages/download?fileID=${x?.fileID}`,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", x?.filename);
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {
      console.log("error => ", error);
    }

    //   const getPersonalTitle = async (setTitle) => {
    //     await api
    //       .get(`api/v1.0/personal/title`)
    //       .then((res) => res.data)
    //       .then((data) => {
    //         if (data.status === true) {
    //           setTitle(data.result);
    //         } else {
    //           console.log(" failed");
    //         }
    //       });
    //   };
    //   const getPersonalProvince = async (setProvince, setCurProvince) => {
    //     await api
    //       .get(`api/v1.0/personal/province`)
    //       .then((res) => res.data)
    //       .then((data) => {
    //         if (data.status === true) {
    //           setProvince(data.result);
    //           setCurProvince(data.result);
    //         } else {
    //           console.log(" failed");
    //         }
    //       });
    //   };
    //   const getPersonalDistrict = async (setDistrict, provinceid) => {
    //     await api
    //       .get(`api/v1.0/personal/district/${provinceid}`)
    //       .then((res) => res.data)
    //       .then((data) => {
    //         if (data.status === true) {
    //           setDistrict(data.result);
    //         } else {
    //           console.log(" failed");
    //         }
    //       });
    //   };
    //   const getPersonalSubDistrict = async (
    //     setSubDistrict,
    //     provinceid,
    //     districtid
    //   ) => {
    //     await api
    //       .get(`api/v1.0/personal/subdistrict/${provinceid}/${districtid}`)
    //       .then((res) => res.data)
    //       .then((data) => {
    //         if (data.status === true) {
    //           setSubDistrict(data.result);
    //         } else {
    //           console.log(" failed");
    //         }
    //       });
    //   };
    //   const getCurPersonalDistrict = async (setCurdistrict, newprovinceid) => {
    //     await api
    //       .get(`api/v1.0/personal/district/${newprovinceid}`)
    //       .then((res) => res.data)
    //       .then((data) => {
    //         if (data.status === true) {
    //           setCurdistrict(data.result);
    //           // console.log(data.result);
    //         } else {
    //           console.log(" failed");
    //         }
    //       });
    //   };
    //   const getCurPersonalSubDistrict = async (
    //     setcurSubdistrict,
    //     newprovinceid,
    //     newdistrictid
    //   ) => {
    //     await api
    //       .get(`api/v1.0/personal/subdistrict/${newprovinceid}/${newdistrictid}`)
    //       .then((res) => res.data)
    //       .then((data) => {
    //         if (data.status === true) {
    //           setcurSubdistrict(data.result);
    //         } else {
    //           console.log(" failed");
    //         }
    //       });
    //   };
    //   const getPersonalDetail = async (setPDtail, id, setIsLoading) => {
    //     await api
    //       .get(`api/v1.0/personal/detail/${id}`)
    //       .then((res) => res.data)
    //       .then((data) => {
    //         if (data.status === true) {
    //           setPDtail(data.result[0]);
    //           setIsLoading(false);
    //         } else {
    //           console.log(" failed");
    //         }
    //       });
    //   };
  };

  ///////////////////////////////////////////////////

  const getPersonalTitle = async (setTitle) => {
    await api
      .get(`api/v1.0/personal/title`)
      .then((res) => res.data)
      .then((data) => {
        if (data.status === true) {
          setTitle(data.result);
        } else {
          console.log(" failed");
        }
      });
  };
  const getPersonalProvince = async (setProvince, setCurProvince) => {
    await api
      .get(`api/v1.0/personal/province`)
      .then((res) => res.data)
      .then((data) => {
        if (data.status === true) {
          setProvince(data.result);
          setCurProvince(data.result);
        } else {
          console.log(" failed");
        }
      });
  };
  const getPersonalDistrict = async (setDistrict, provinceid) => {
    await api
      .get(`api/v1.0/personal/district/${provinceid}`)
      .then((res) => res.data)
      .then((data) => {
        if (data.status === true) {
          setDistrict(data.result);
        } else {
          console.log(" failed");
        }
      });
  };
  const getPersonalSubDistrict = async (
    setSubDistrict,
    provinceid,
    districtid
  ) => {
    await api
      .get(
        `api/v1.0/personal/subdistrict/${provinceid}/${districtid.md_districtID || districtid}`
      )
      .then((res) => res.data)
      .then((data) => {
        if (data.status === true) {
          setSubDistrict(data.result);
        } else {
          console.log(" failed");
        }
      });
  };
  const getCurPersonalDistrict = async (setCurdistrict, newprovinceid) => {
    await api
      .get(`api/v1.0/personal/district/${newprovinceid.mp_provinceID || newprovinceid}`)
      .then((res) => res.data)
      .then((data) => {
        if (data.status === true) {
          setCurdistrict(data.result);
        } else {
          console.log(" failed");
        }
      });
  };
  const getCurPersonalSubDistrict = async (
    setcurSubdistrict,
    newprovinceid,
    newdistrictid
  ) => {
    await api
      .get(
        `api/v1.0/personal/subdistrict/${newprovinceid.mp_provinceID || newprovinceid}/${newdistrictid.md_districtID || newdistrictid}`
      )
      .then((res) => res.data)
      .then((data) => {
        if (data.status === true) {
          setcurSubdistrict(data.result);
        } else {
          console.log(" failed");
        }
      });
  };
  const getPersonalDetail = async (setPDtail, id, setIsLoading) => {
    await api
      .get(`api/v1.0/personal/detail/${id}`)
      .then((res) => res.data)
      .then((data) => {
        if (data.status === true) {
          setPDtail(data.result[0]);
          setIsLoading(false);
        } else {
          console.log(" failed");
        }
      });
  };
  const getHonorPinList = async (setHonorPinList, body) => {
    await api
      .get(`api/v1.0/honor-pin/list?keyword=${body?.keyword || ''}`)
      .then((res) => res.data)
      .then((data) => {
        if (data.status === true) {
          setHonorPinList(data.result);
        } else {
          console.log("failed");
        }
      });
  };
  const getHonorPinListCandidate = async (setHonorPinListCandidate, date) => {
    await api
      .get(`api/v1.0/honor-pin/list-candidate?calDate=${date}`)
      .then((res) => res.data)
      .then((data) => {
        if (data.status === true) {
          setHonorPinListCandidate(data.result);
        } else {
          console.log("failed");
        }
      });
  };
  const getHonorPinListCandidateDetail = async (
    setHonorPinListCandidateDetail,
    id
  ) => {
    await api
      .get(`api/v1.0/honor-pin/detail/${id}`)
      .then((res) => res.data)
      .then((data) => {
        if (data.status === true) {
          setHonorPinListCandidateDetail(data.result);
        } else {
          console.log("failed");
        }
      });
  };

  const getHonorCerList = async (setHonorCerList, body) => {
    await api
      .get(`api/v1.0/honor-cer/list?keyword=${body?.keyword || ''}`)
      .then((res) => res.data)
      .then((data) => {
        if (data.status === true) {
          setHonorCerList(data.result);
        } else {
          console.log("failed");
        }
      });
  };
  const getHonorCerListCandidate = async (setHonorCerListCandidate, dateFomat) => {
    await api
      // .get(`api/v1.0/honor-pin/list-candidate?calDate=${dateFomat}`)
      .get(`api/v1.0/honor-cer/list-candidate?calDate=${dateFomat}`)
      .then((res) => res.data)
      .then((data) => {
        if (data.status === true) {
          setHonorCerListCandidate(data.result);
        } else {
          console.log("failed");
        }
      });
  };
  const getHonorCerListCandidateDetail = async (
    setHonorCerListCandidateDetail,
    id
  ) => {
    await api
      .get(`api/v1.0/honor-cer/detail/${id}`)
      .then((res) => res.data)
      .then((data) => {
        if (data.status === true) {
          setHonorCerListCandidateDetail(data.result);
        } else {
          console.log("failed");
        }
      });
  };



  const getAppointerList = async (
    setAppointerList,
    body
  ) => {
    await api
      .get(`/api/v1.0/appointer/list?keyword=${body || ''}`)
      .then((res) => res.data)
      .then((data) => {
        if (data.status === true) {
          setAppointerList(data.result);
        } else {
          console.log("failed");
        }
      });
  };
  //-------------------------------GET User----------------------------------
  const getUser = (setUser, setIsLoading) => {
    api
      .get(`api/v1.0/users/list`, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        setUser(response.data.result);
        setIsLoading(false);
      });
  };

  const getCertificate = async (setCertificate) => {
    await api
      .get(`api/v1.0/master/certificatetype/list`)
      .then((res) => res.data)
      .then((data) => {
        if (data.status === true) {
          setCertificate(data.result);
        } else {
          console.log("failed");
        }
      });
  };
  const postBoardCreate = async (body) => {
    await api
      .post(`api/v1.0/board/department/create`, body)
      .then((res) => res.data)
      .then((data) => {
        if (data.status === true) {
          // console.log('gg')
          // window.location = `/Committee`;

          // console.log(data.result)
          Swal.fire({
            title: "บันทึกแล้ว",
            text: "บันทึกข้อมูลสำเร็จ",
            icon: "success",
            confirmButtonColor: "#0f962d",
            confirmButtonText: "ตกลง",
            allowOutsideClick: false,

          }).then((result) => {
            if (result.isConfirmed) {
              window.location = `/Committee`;
            }
          });
        }
        else {
          console.log("failed");
        }
      });
  };
  const postBoardMeet = async (boardBody) => {
    await api
      .post(`api/v1.0/board/department/meet/create`, boardBody)
      .then((res) => res.data)
      .then((data) => {
        if (data.status === true) {
          // console.log(data.status)
          // window.location = `/Committee`;
        } else {
          console.log("failed");
        }
      });
  };
  const getBoardDepList = (setBoardDepartmentList, setMeetName, setPresent, setCheckedPin, setCheckedCer, id) => {
    api
      .get(`api/v1.0/board/department/list?boardDepID=${id}&boardFil=A`)
      .then((res) => res.data)
      .then((data) => {
        if (data.status === true) {
          setBoardDepartmentList(data.result);
          setMeetName(data.result[0]?.boardDepName)
          setPresent(data.result[0]?.present)
          setCheckedPin(data.result[0]?.isPin)
          setCheckedCer(data.result[0]?.isCer)
        } else {
          console.log("failed");
        }
      });
  };
  const getHonorPrrson = (setHonorPrrson,  fillter, bodyP, fillterS) => {
    const data = [
      {
        value: 1,
        fit: "keyword",
      },
      {
        value: 2,
        fit: "personName",
      },
      {
        value: 3,
        fit: "cerName",
      },
      {
        value: 4,
        fit: "year",
      },
      {
        value: 5,
        fit: "rank",
      },
      {
        value: 6,
        fit: "status",
      },
    ];
    const boardFil = data.filter((x) => x?.value === fillter).map((x) => x?.fit);
    api
      .get(`api/v1.0/honor-pin/list-person?${boardFil||''}=${bodyP?.keyword||''}&status=${fillterS === 'All' ? '' : fillterS || ''}`)
      .then((res) => res.data)
      .then((data) => {
        if (data.status === true) {
          setHonorPrrson(data.result);
         
        } else {
          console.log("failed");
        }
      });
  };
  const getHonorCerPrrson = (setHonorPrrson,  fillter, bodyP, fillterS) => {
    const data = [
      // {
      //   value: 1,
      //   fit: "keyword",
      // },
      {
        value: 1,
        fit: "personName",
      },
      {
        value: 2,
        fit: "cerName",
      },
      {
        value: 3,
        fit: "year",
      },
      {
        value: 4,
        fit: "rank",
      },
      // {
      //   value: 6,
      //   fit: "status",
      // },
    ];
    const boardFil = data.filter((x) => x?.value === fillter).map((x) => x?.fit);
    api
      .get(`api/v1.0/honor-cer/list-person?${boardFil||''}=${bodyP?.keyword ||''}&status=${fillterS === 'All' ? '' : fillterS || ''}`)
      .then((res) => res.data)
      .then((data) => {
        if (data.status === true) {
          setHonorPrrson(data.result);
         
        } else {
          console.log("failed");
        }
      });
  };

  const contextData = {
    getBoardDepartmentList: getBoardDepartmentList,
    addBoardDepartment: addBoardDepartment,
    getBoardList: getBoardList,
    getPersonal: getPersonal,
    getPosition: getPosition,
    getAppointer: getAppointer,
    // fileUpload: fileUpload,
    FileDownload: FileDownload,
    createBoard: createBoard,
    getBoardDetail: getBoardDetail,
    updateBoard: updateBoard,
    getUser: getUser,
    getPersonalTitle: getPersonalTitle,
    getPersonalProvince: getPersonalProvince,
    getPersonalDistrict: getPersonalDistrict,
    getPersonalSubDistrict: getPersonalSubDistrict,

    getPersonalDetail: getPersonalDetail,
    getHonorPinList: getHonorPinList,
    getHonorPinListCandidate: getHonorPinListCandidate,
    getHonorPinListCandidateDetail: getHonorPinListCandidateDetail,
    getCurPersonalDistrict: getCurPersonalDistrict,
    getCurPersonalSubDistrict: getCurPersonalSubDistrict,
    getHonorCerList: getHonorCerList,
    getHonorCerListCandidate: getHonorCerListCandidate,
    getHonorCerListCandidateDetail: getHonorCerListCandidateDetail,
    getAppointerList: getAppointerList,
    getCertificate: getCertificate,
    postBoardCreate: postBoardCreate,
    postBoardMeet: postBoardMeet,
    getBoardDepList: getBoardDepList,
    getHonorPrrson: getHonorPrrson,
    getHonorCerPrrson: getHonorCerPrrson
  };
  return (
    <ApiContext.Provider value={contextData}>{children}</ApiContext.Provider>
  );
}

export default ApiContext;
