import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  Container,
  Menu,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import qs from "qs";
import Divider from "@mui/material/Divider";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import TitleHead from "../../components/TitleHead";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SearchIcon from "@mui/icons-material/Search";
import { saveAs } from "file-saver";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import api from "../../api/api";
import mime from "mime";
import { DesktopDatePicker } from "@mui/lab";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectCanActivate } from "../../store/modules/selector";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#94949473",
    color: theme.palette.common,
    fontWeight: "500",
  },
  [`&.${tableCellClasses.body}`]: {
    width: "10 %",
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    /*  backgroundColor: '#F2F5F8', */
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(index, name, province, conf, year) {
  return { index, name, province, conf, year };
}

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

export default function Lecturer() {
  const navigate = useNavigate();
  const [listLecturer, setListLecturer] = useState([]);
  const inputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const param = useParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openM = Boolean(anchorEl);
  const [checked, setChecked] = React.useState(true);
  const checkPermission = useSelector(selectCanActivate);
  const [keyword, setkeyword] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [actionSelect, setActionSelect] = useState("");
  // console.log(uploadedFiles);
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const handleClick1 = () => {
    // 👇️ open file input box on click of other element
    inputRef.current.click();
    // console.log(inputRef);
  };
  const handleFileChange = async (event) => {
    const fileObj = event.target.files && event.target.files[0];
    // console.log('fileObj is', fileObj);

    let formData = new FormData();
    formData.append("fileUpload", fileObj);
    event.target.value = null;

    const result = await api
      .post(`api/v1.0/lecturer/uploads`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setOpen(true);

        // console.log(res.data.result);
        setUploadedFiles(res.data.result);
      });
    // 👇️ is now empty
    // console.log(event.target.files);

    // 👇️ can still access file object here
    // console.log(fileObj);
    // console.log(fileObj.Title);

    return result;
    // if (!fileObj) {

    //     return;
    // }

    // 👇️ reset file input
  };
  const getData = async () => {
    const params = qs.stringify({
      ...(keyword && { keyword }),
    });
    await api.get(`api/v1.0/lecturer/list?${params}`).then((res) => {
      setListLecturer(res?.data?.result);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const profile = localStorage.getItem("profile");
  const roleID = JSON.parse(profile)?.profile?.roleID;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    // console.log(event);
    setPage(0);
  };

  const sumbit = () => {
    const idrows = uploadedFiles.filter((item) => item.isChecked === true);
    // console.log(idrows);
    api.post("/api/v1.0/lecturer/create-lecturer-array", idrows).then((res) => {
      Swal.fire({
        title: "นำเข้าข้อมูลสำเร็จ",
        confirmButtonColor: "#1fc64c",
      });

      setOpen(false);
      setTimeout(() => {
        // window.location.reload();
      }, 1000);
    });
  };
  {
  }
  const idrows = uploadedFiles.filter((item) => item.isChecked === true);
  const handleSelect = (e) => {
    const { value, checked } = e.target;
    if (value === "all") {
      let id = uploadedFiles
        .filter((item) => item.remark == "" || item.ramark == "")
        .map((row) => {
          return { ...row, isChecked: checked };
        });
      setUploadedFiles(id);
    } else {
      let id = uploadedFiles.map((row) =>
        row.runNum == value ? { ...row, isChecked: checked } : row
      );

      // let id = uploadedFiles.map((row) => {
      //     if(row.runNum == value)
      //     {
      //         return { ...row, isChecked: checked };
      //     }
      //     else
      //     {
      //         return row;
      //     }
      // })

      setUploadedFiles(id);
    }
  };

  return (
    <Container
      maxWidth="xl"
      sx={{ height: "auto", marginTop: "40px", marginBottom: "40px" }}
    >
      <TitleHead
        title="ข้อมูลวิทยากร"
        subTitle="ข้อมูลทะเบียนประวัตข้อมูลวิทยากร"
      >
        <Box sx={{ display: "flex", justifyContent: "right" }}>
          <input
            style={{ display: "none" }}
            ref={inputRef}
            type="file"
            onChange={handleFileChange}
            encType="multipart/form-data"
          />

          <Button
            variant="outlined"
            onClick={async () => {
              await api
                .get(`api/v1.0/lecturer/template/download`, {
                  responseType: "blob",
                })
                .then((blobFile) => {
                  const blobFileRes = new File([blobFile?.data], "test.csv", {
                    type: mime.getType(".csv"),
                  });

                  // window.open(
                  //   URL.createObjectURL(blobFileRes, "test.csv"),
                  //   "_blank"
                  // );
                  saveAs(blobFileRes, "tamplate.csv");
                });
            }}
            sx={{
              color: "#4caf50",
              padding: "7px ",
              marginTop: "23px",
              marginRight: "16px",
              marginLeft: "20px",
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
            color="success"
          >
            <SaveAltIcon sx={{ marginRight: "5px" }} fontSize="small" />
            ดาวน์โหลดเทมเพลต
          </Button>

          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ marginRight: "16px", marginTop: "30px" }}
          />
          {checkPermission.find((x) => x.menuNo === "M006" && x.isAdd) && (
            <>
              <Button
                color="success"
                onClick={handleClick1}
                variant="outlined"
                sx={{
                  color: "#4caf50",

                  marginTop: "23px",
                  marginRight: "16px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
              >
                นำข้อมูลเข้า
              </Button>
              <Button
                component={NavLink}
                to="/LecturerAdd"
                sx={{
                  backgroundColor: "#1fc64c",
                  "&:hover": { background: "#4caf50" },
                  color: "#FFFF",

                  marginTop: "23px",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                }}
                state={{ add: true }}
              >
                สร้างใหม่
              </Button>
            </>
          )}
        </Box>
      </TitleHead>
      <Box
        sx={{
          display: "flex",
          color: "#000",
          marginTop: "20px",
          marginLeft: "50px",
          justifyContent: "right",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <TextField
            id="outlined-basic"
            size="small"
            label="กรอกข้อมูลค้นหา"
            variant="outlined"
            color="success"
            value={keyword}
            onChange={(e) => setkeyword(e.target.value)}
          />
          <Button
            sx={{
              color: "#fff",
              padding: "8px",
              marginLeft: "16px",

              backgroundColor: "#0fb123",
              ":hover": { backgroundColor: "#028b13" },
            }}
            onClick={() => {
              getData();
            }}
          >
            <SearchIcon />
          </Button>
        </Box>
      </Box>

      <Stack spacing={3}>
        <TableContainer sx={{ marginTop: "20px", marginBottom: "80px" }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell
                  align="left"
                  sx={{ fontSize: "14px", fontWeight: "700", width: "10%" }}
                >
                  คำนำหน้าชื่อ
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  sx={{ fontSize: "14px", fontWeight: "700", width: "10%" }}
                >
                  ชื่อ
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  sx={{ fontSize: "14px", fontWeight: "700", width: "10%" }}
                >
                  นามสกุล
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  sx={{ fontSize: "14px", fontWeight: "700", width: "25%" }}
                >
                  หลักสูตร
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  sx={{ fontSize: "14px", fontWeight: "700", width: "10%" }}
                >
                  รุ่น
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  sx={{ fontSize: "14px", fontWeight: "700", width: "10%" }}
                >
                  วันที่สอน
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  sx={{ fontSize: "14px", fontWeight: "700", width: "20%" }}
                >
                  วิชาที่สอน
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  sx={{ fontSize: "14px", fontWeight: "700", width: "10%" }}
                >
                  เวลา
                </StyledTableCell>
                <StyledTableCell
                  align="left"
                  sx={{ fontSize: "14px", fontWeight: "700", width: "5%" }}
                ></StyledTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {listLecturer
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => (
                  <StyledTableRow
                    key={i}
                    /* onClick={() => navigate(`/ProjectSource/SearchPoliticalMinor`)} */
                    sx={{
                      fontSize: "18px",
                      cursor: "pointer",
                      "&:hover": {
                        background: "#f3f2f2",
                      },
                    }}
                  >
                    <StyledTableCell>
                      {" "}
                      {row?.personal?.title?.titleNameTH}
                    </StyledTableCell>

                    <StyledTableCell component="th" scope="row">
                      {row?.personal?.firstname}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row?.personal?.lastname}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.certificate}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row?.gen}</StyledTableCell>
                    <StyledTableCell align="left">
                      {moment(row?.lecDate).add(543, "year").format("LL") ===
                      "Invalid date"
                        ? ""
                        : moment(row.lecDate).add(543, "year").format("LL")}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row?.course}</StyledTableCell>
                    <StyledTableCell align="left">{row?.time}</StyledTableCell>
                    <TableCell align="left">
                      <ThreeDotsMenu data={row} />
                    </TableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={listLecturer?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Stack>
      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "100%", maxHeight: 500 } }}
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="responsive-dialog-title">
          {"ข้อมูลส่วนบุคคลนำข้อมูลเข้า"}
        </DialogTitle>
        <DialogContent>
          <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
            <Table sx={{ minWidth: 600 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left" sx={{ fontSize: "18px" }}>
                    {" "}
                    <Checkbox
                      onChange={handleSelect}
                      value="all"
                      color="success"
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{ fontSize: "18px", fontWeight: "400" }}
                  >
                    คำนำหน้า
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{ fontSize: "18px", fontWeight: "400" }}
                  >
                    ชื่อ
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{ fontSize: "18px", fontWeight: "400" }}
                  >
                    นามสกุล
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{ fontSize: "18px", fontWeight: "400" }}
                  >
                    หลักสูตร
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{ fontSize: "18px", fontWeight: "400" }}
                  >
                    รุ่น
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{ fontSize: "18px", fontWeight: "400" }}
                  >
                    วันที่สอน
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{ fontSize: "18px", fontWeight: "400" }}
                  >
                    หมายเหตุ
                  </StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {uploadedFiles?.map((row, i) => (
                  <StyledTableRow
                    key={i}
                    /* onClick={() => navigate(`/ProjectSource/SearchPoliticalMinor`)} */
                    sx={{
                      fontSize: "18px",
                      cursor: "pointer",
                      "&:hover": {
                        background: "#f3f2f2",
                      },
                    }}
                  >
                    <StyledTableCell align="left" fontSize="18px">
                      <Checkbox
                        color="success"
                        value={row.runNum}
                        checked={row?.isChecked || false}
                        disabled={
                          row?.remark === null
                            ? false
                            : row?.remark === ""
                            ? false
                            : true
                        }
                        onChange={handleSelect}
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.title}</StyledTableCell>
                    <StyledTableCell align="left">
                      {row?.firstname}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row?.lastname}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row?.certificate}
                    </StyledTableCell>
                    <StyledTableCell align="left">{row?.gen}</StyledTableCell>

                    <StyledTableCell align="left">
                      {" "}
                      {moment(row.lecDate).add(543, "year").format("LL")}
                    </StyledTableCell>

                    <StyledTableCell
                      align="left"
                      sx={{ color: row?.remark === "" ? "#000" : "red" }}
                    >
                      {row?.remark === "" ? row?.ramark : row?.remark}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        >
          <Button
            onClick={handleClose}
            color="success"
            variant="outlined"
            sx={{ color: "#4caf50", width: "20%", marginRight: "10px" }}
          >
            กลับ
          </Button>
          <Button
            sx={{
              backgroundColor: "#1fc64c",
              "&:hover": { background: "#4caf50" },
              color: "#FFFF",
              width: "20%",
            }}
            onClick={sumbit}
          >
            ยืนยัน
          </Button>
        </Box>
      </Dialog>
    </Container>
  );
}

const ThreeDotsMenu = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleMenuClick(Val1) {
    // console.log(Val1);
  }

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const checkPermission = useSelector(selectCanActivate);
  const { data } = props;
  return (
    <>
      <React.Fragment>
        <MoreHorizIcon
          onClick={handleClick}
          sx={{ cursor: "pointer", fontSize: "25px" }}
        />

        <Menu
          id="dense-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          // elevation={0}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{
            ".MuiPopover-paper": {
              boxShadow: "unset",
              border: "0.5px red",
              color: "#3a3a3ae6",
              backgroundColor: "#f9f9f9",
              borderRadius: "5px",
              // border: 'px solid',
            },
          }}
        >
          {checkPermission.find((x) => x.menuNo === "M006" && x.isView) && (
            <MenuItem
              sx={{
                width: "120px",
                fontSize: "20px",
                color: "#00695C",
              }}
              component={NavLink}
              to={`/Lecturer/LecturerDetail/${data.lecID}/views`}
            >
              {" "}
              <RemoveRedEyeIcon sx={{ marginRight: "15px" }} />
              ดู
            </MenuItem>
          )}
          {checkPermission.find((x) => x.menuNo === "M006" && x.isEdit) && (
            <MenuItem
              sx={{
                width: "120px",
                fontSize: "20px",
                color: "#00695C",
              }}
              component={NavLink}
              to={`/Lecturer/LecturerEdit/${data.lecID}`}
            >
              {" "}
              <EditRoundedIcon sx={{ marginRight: "15px" }} />
              เเก้ไข
            </MenuItem>
          )}
          {/* <MenuItem
            sx={{
              width: "120px",
              fontSize: "20px",
              color: "#00695C",
            }}
          >
            {" "}
            <DeleteForeverRoundedIcon sx={{ marginRight: "15px" }} /> ลบ
          </MenuItem> */}
        </Menu>
      </React.Fragment>
    </>
  );
};
