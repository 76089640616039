import {
  Box,
  Grid,
  Typography,
  Button,
  InputBase,
  Paper,
  FormHelperText,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Formik } from "formik";
import * as Yup from "yup";
import api from "../api/api";
import { useDispatch, useSelector } from "react-redux";
import { loginAsync } from "../store/slices/authSlice";

import kpi from "../assets/image/KPI-LOGO-PNG-Size-S.png";
// import background from "../assets/image/19n_1_Ecer_Background_18.png";
import background from "../assets/image/bgstyle_3.jpg";

import { useLocation } from "react-router-dom";
import queryString from "query-string";
import Swal from "sweetalert2";

export default function ResetPassword() {
  const { user, loading, error } = useSelector((state) => state.auth);

  const location = useLocation();
  //   const { id } = route;

  const dispatch = useDispatch();

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container columns={{ sm: 8, md: 12 }}>
        <Grid
          item
          sm={8}
          md={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100vh",
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
            backgroundImage: `url(${background})`,
          }}
        >
          <Box
            sx={{
              width: "550px",
              height: "auto",
              background: "#fff",
              boxShadow:
                "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px",
              borderRadius: "15px",
            }}
          >
            <Formik
              initialValues={{
                newpassword: "",
                confirmpassword: "",
                submit: null,
              }}
              validationSchema={Yup.object().shape({
                newpassword: Yup.string()
                  .max(255)
                  .required("กรุณาระบุ รหัสผ่านใหม่"),
                confirmpassword: Yup.string()
                  .max(255)
                  .required("กรุณาระบุ ยืนยันรหัสผ่าน"),
              })}
              onSubmit={async (
                value,
                { setErrors, setStatus, setSubmitting }
              ) => {
                try {
                  const body = {
                    userID: location.state.id,
                    password: value.newpassword,
                    repassword: value.confirmpassword,
                  };
                  await api
                    .post("api/v1.0/auth/updatepassword", body)
                    .then((res) => {
                      // console.log(res);
                      Swal.fire("สำเร็จ ?", res?.data?.message, "success").then(
                        (x) => {
                          if (x.isConfirmed) {
                            window.location = `/`;
                          }
                        }
                      );
                    });

                  setStatus({ success: true });
                  setSubmitting(false);
                } catch (err) {
                  console.log(err);
                  setStatus({ success: false });
                  setErrors({ submit: err.message });
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <Box
                  component="form"
                  noValidate
                  onSubmit={handleSubmit}
                  sx={{ margin: "50px 80px" }}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      color: "#000",
                      fontWeight: "bold",
                      marginBottom: "20px",
                    }}
                  >
                    เปลี่ยนรหัสผ่านใหม่
                  </Typography>

                  <Paper
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px",
                      marginBottom: "20px",
                      color: "#A3A3A3",
                    }}
                  >
                    <InputBase
                      error={Boolean(touched.newpassword && errors.newpassword)}
                      placeholder="รหัสผ่านใหม่"
                      type="password"
                      // ref={userRef}
                      autoComplete="off"
                      name="newpassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                      required
                      // onChange={(e) => setUsername(e.target.value)}
                      // value={username}
                      value={values.newpassword}
                      inputProps={{}}
                    />
                    {touched.newpassword && errors.newpassword && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-email-login"
                      >
                        {errors.newpassword}
                      </FormHelperText>
                    )}
                  </Paper>
                  <Paper
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px",
                      marginBottom: "30px",
                      color: "#A3A3A3",
                    }}
                  >
                    <InputBase
                      error={Boolean(
                        touched.confirmpassword && errors.confirmpassword
                      )}
                      placeholder="ยืนยันรหัสผ่าน"
                      type="password"
                      // ref={userRef}
                      autoComplete="off"
                      name="confirmpassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      fullWidth
                      required
                      // onChange={(e) => setUsername(e.target.value)}
                      // value={username}
                      value={values.confirmpassword}
                      inputProps={{}}
                    />
                    {touched.confirmpassword && errors.confirmpassword && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-email-login"
                      >
                        {errors.confirmpassword}
                      </FormHelperText>
                    )}
                  </Paper>
                  {/* {console.log(errors)} */}

                  {/* <Typography variant='h6' sx={{ fontSize: '1rem', width: '100%', textAlign: 'right', marginBottom: '40px' }}>Forgot Password ?</Typography> */}

                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <FormHelperText sx={{ fontSize: "24px" }} error>
                      {user?.code === 1001
                        ? "ไม่พบชื่อผู้ใช้งานนี้ในระบบ"
                        : "" || user?.code === 1002
                          ? "ไม่พบอีเมลนี้ในระบบ"
                          : "" || user?.code === 1003
                            ? "รหัสผ่านไม่ถูกต้อง"
                            : ""}
                    </FormHelperText>
                  </Box>

                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="contained"
                    sx={{
                      background: "#4caf50",
                      "&:hover": { background: "#00695C" },
                      fontSize: "18px",
                      width: "100%",
                      marginBottom: "20px",
                    }}
                  >
                    {loading ? "Loading..." : "ตกลง"}
                  </Button>
                </Box>
              )}
            </Formik>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
