import {
  Box,
  Typography,
  Button,
  TextField,
  Container,
  Select,
  MenuItem,
  FormGroup,
  InputLabel,
  FormControl,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { useNavigate, NavLink, useParams } from "react-router-dom";
import api from "../../api/api";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { useRef } from "react";
import React, { useState, useContext, useEffect } from "react";
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

export default function AddPresent() {
  const inputRef = useRef(null);
  const navigate = useNavigate();
  let { id, views } = useParams();
  const [presentFullName, setPresentFullName] = useState("");
  const [presentShortName, setPresentShortName] = useState("");
  const [status, setStatus] = useState(true);

  const getDetail = async (id) => {
    await api.get(`api/v1.0/setting/present/list`).then((res) => {
      res?.data?.result?.filter((x) => x.presentID == id)?.map((x) => {
        setPresentFullName(x.presentFullName);
        setPresentShortName(x.presentShortName);
        setStatus(x?.status)
      });
    });
  };

  useEffect(() => {
    if (id) {
      getDetail(id);
    }
  }, []);

  console.log(id);
  const handleChangeStatus = (event, newStatus) => {
    setStatus(newStatus);
  };

  const handleSubmit = async () => {
    const body = {
      presentID: id ? id : undefined,
      presentFullName: presentFullName,
      presentShortName: presentShortName,
      status: status,
    };
    if (id) {
      if (presentFullName && presentShortName) {
        await api.post("api/v1.0/setting/present/update", body).then((res) => {
          if (res?.data?.status) {
            Swal.fire(
              "สำเร็จ",
              "แก้ไขสำเร็จ",
              "success"
            );
            navigate("/PresentList");
          }
        });
      } else {
        Swal.fire(
          "แจ้งเตือน",
          "โปรดตรวจสอบข้อมูลให้ครบถ้วน",
          "warning"
        );
      }
    } else {
      if (presentFullName && presentShortName) {
        await api.post("api/v1.0/setting/present/create", body).then((res) => {
          if (res?.data?.status) {
            Swal.fire(
              "สำเร็จ",
              "สร้างผู้เสนอสำเร็จ",
              "success"
            );
            navigate("/PresentList");
          }
        });
      } else {
        Swal.fire(
          "แจ้งเตือน",
          "โปรดตรวจสอบข้อมูลให้ครบถ้วน",
          "warning"
        );
      }
    }

  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            color: "#000",
            marginTop: "50px",
            marginLeft: "50px",
          }}
        >
          <Typography variant="h4">ข้อมูลผู้เสนอ</Typography>
        </Box>

        <Divider
          sx={{ width: "1500px", marginLeft: "50px", marginTop: "10px" }}
        />

        <Box
          sx={{
            display: "flex",
            color: "#000",
            marginTop: "40px",
            marginLeft: "150px",
          }}
        >
          <Typography sx={{ fontSize: "16px" }}>ชื่อผู้เสนอ</Typography>
          <TextField
            sx={{
              width: "500px",
              height: "200px",
              marginLeft: "100px",
              marginTop: "-15px",
            }}
            id="outlined-textarea"
            placeholder="กรุณากรอกชื่อผู้เสนอ"
            required
            disabled={views === "views"}
            value={presentFullName || ''}
            onChange={(e) => {
              setPresentFullName(e.target.value);
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            color: "#000",
            marginTop: "-100px",
            marginLeft: "150px",
          }}
        >
          <Typography sx={{ fontSize: "16px" }}>ชื่อย่อ</Typography>
          <TextField
            sx={{
              width: "500px",
              height: "200px",
              marginLeft: "125px",
              marginTop: "-15px",
            }}
            id="outlined-textarea"
            placeholder="กรุณากรอกชื่อย่อ"
            required
            disabled={views === "views"}
            value={presentShortName || ''}
            onChange={(e) => {
              setPresentShortName(e.target.value);
            }}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            color: "#000",
            marginTop: "-100px",
            marginLeft: "150px",
          }}
        >
          <Typography sx={{ fontSize: "16px" }}>สถานะ</Typography>
          <AntSwitch
            type="checkbox"
            name="status"
            value={status}
            checked={status}
            disabled={views === "views"}
            onChange={handleChangeStatus}
            defaultChecked
            inputProps={{ "aria-label": "ant design" }}
            sx={{ marginTop: "3px", marginLeft: "122px" }}
          />
        </Box>
        <Box
          sx={{
            position: "sticky",
            bottom: "0px",
            width: "100%",
            marginTop: "335px",
            background: "#fff",
            zIndex: '100'
          }}
        >
          <Divider sx={{ marginBottom: "10px" }} />
          <Container maxWidth="xl" sx={{ height: "auto" }}>
            <Box
              sx={{
                padding: "5px 20px",
                marginBottom: "50px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  width: "150px",
                  height: "fit-content",
                  fontSize: "18px",
                  color: "#0fb123",
                  border: "1px solid #0fb123",
                  ":hover": { border: "1px solid #028b13" },
                }}
                component={NavLink}
                to="/PresentList"
              >
                กลับ
              </Button>
              {views !== "views" && (
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{
                    width: "150px",
                    height: "fit-content",
                    fontSize: "18px",
                    marginLeft: "20px",
                    backgroundColor: "#0fb123",
                    ":hover": { backgroundColor: "#028b13" },
                  }}
                >
                  บันทึก
                </Button>
              )}
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
}
