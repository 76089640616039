import {
  Box,
  Grid,
  Typography,
  Button,
  InputBase,
  Paper,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, NavLink, useLocation, Navigate } from "react-router-dom";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Autocomplete from "@mui/material/Autocomplete";
import { Formik } from "formik";
import * as Yup from "yup";
import api from "../api/api";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { loginAsync } from "../store/slices/authSlice";
import background from "../assets/image/bgstyle_3.jpg";

// import background from "../assets/image/19n_1_Ecer_Background_18.png";

const data = [];

export default function ForgotPassword() {
  const { user, loading, error } = useSelector((state) => state.auth);
  const search = useLocation().search;
  const queryParams = queryString.parse(search);
  const id = encodeURIComponent(queryParams.userID);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    //e.prevenDefalt();
    api
      .post("api/v1.0/auth/forgotpassword", JSON.stringify({ email }), {
        headers: { "Content-Type": "application/json" },
      })

      .then((response) => response.data)
      .then((data) => {
        if (data.code === 1000) {
          const MySwal = withReactContent(Swal);

          MySwal.fire({
            title: <p>ยืนยันอีเมลสำเร็จ</p>,
            icon: "success",
            didOpen: () => {
              MySwal.clickConfirm();
            },
          }).then(() => {
            return MySwal.fire({
              title: "ยืนยันอีเมลสำเร็จ",
              text: "บันทึกข้อมูลสำเร็จ",
              icon: "success",
              confirmButtonColor: "#0f962d",
              confirmButtonText: "ตกลง",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location = `/ResetPassword`;
              }
            });
          });
        } else {
          console.log("failed");
          Swal.fire(
            {
              title: `${data.message}`,
              // text: "บันทึกข้อมูลสำเร็จ",
              // icon: "success",
              confirmButtonColor: "#0f962d",
              color: "#C70039",
              confirmButtonText: "ตกลง",
            }
            // `${user.data.message}`,
          );
        }
      });
    setEmail("");
  };

  const ValidUser = async () => {
    if (id !== "undefined" && id !== "") {
      const res = await api.get(
        `api/v1.0/auth/forgotpassword/valid?userID=${id}`
      );
      if (res?.data?.status) {
        // window.location = `/ResetPassword?userID=${res?.data?.result?.userID}`;

        navigate("/ResetPassword", {
          state: { id: res?.data?.result?.userID },
        });
      } else {
        Swal.fire("ไม่สำเร็จ ?", res?.data?.message, "error").then((x) => {
          if (x.isConfirmed) {
            window.location = `/ForgotPassword`;
          }
        });
      }
    }
  };

  useEffect(() => {
    ValidUser();
  }, [id]);

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container columns={{ sm: 8, md: 12 }}>
        <Grid
          item
          sm={8}
          md={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100vh",
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
            backgroundImage: `url(${background})`,
          }}
        >
          <Box
            sx={{
              width: "550px",
              height: "auto",
              background: "#fff",
              boxShadow:
                "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px",
              borderRadius: "15px",
            }}
          >
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ margin: "50px 80px" }}
            >
              <Typography
                variant="h4"
                sx={{ color: "#000", fontWeight: "bold", marginBottom: "20px" }}
              >
                {id !== "undefined" ? "กำลังยืนยันตัวตน" : "ขอรหัสผ่านใหม่"}
              </Typography>

              {id === "" || id === "undefined" ? (
                <>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#adadad",
                      fontSize: "13px",
                      width: "100%",
                      marginBottom: "5px",
                      fontWeight: "400",
                    }}
                  >
                    กรุณายืนยันการตั้งรหัสผ่านใหม่ทาง E-mail
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#adadad",
                      fontSize: "13px",
                      width: "100%",
                      marginBottom: "40px",
                      fontWeight: "400",
                    }}
                  >
                    (Please confirm reset Password on your E-mail)
                  </Typography>

                  <Paper
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px",
                      marginBottom: "40px",
                      color: "#A3A3A3",
                    }}
                  >
                    <Autocomplete
                      id="free-solo-demo"
                      freeSolo
                      options={data.map((option) => option.title)}
                      renderInput={(params) => (
                        <InputBase
                          {...params}
                          placeholder="E-mail"
                          type="text"
                          // ref={userRef}
                          autoComplete="off"
                          name="username"
                          // onBlur={handleBlur}
                          // onChange={handleChange}
                          fullWidth
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          // onChange={(e) => setUsername(e.target.value)}
                          // value={username}
                          // value={values.username}
                          inputProps={{}}
                        />
                      )}
                    />
                    {/*  {touched.username && errors.username && (
                                                <FormHelperText error id="standard-weight-helper-text-email-login">
                                                    {errors.username}
                                                </FormHelperText>
                                            )} */}
                  </Paper>
                  {/* {console.log(errors)} */}

                  {/* <Typography variant='h6' sx={{ fontSize: '1rem', width: '100%', textAlign: 'right', marginBottom: '40px' }}>Forgot Password ?</Typography> */}

                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <FormHelperText sx={{ fontSize: "24px" }} error>
                      {user?.code === 1001
                        ? "ไม่พบชื่อผู้ใช้งานนี้ในระบบ"
                        : "" || user?.code === 1002
                          ? "ไม่พบอีเมลนี้ในระบบ"
                          : "" || user?.code === 1003
                            ? "รหัสผ่านไม่ถูกต้อง"
                            : ""}
                    </FormHelperText>
                  </Box>

                  {/* <Button type='submit' onClick={handleSubmit} variant="contained" sx={{ background: "#4caf50", '&:hover': { background: '#00695C' }, fontSize: '18px', width: '100%', marginBottom: '20px' }}>{loading ? 'Loading...' : 'ตกลง'}</Button> */}
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    sx={{
                      background: "#4caf50",
                      "&:hover": { background: "#00695C" },
                      fontSize: "18px",
                      width: "45%",
                      marginBottom: "20px",
                    }}
                  >
                    ตกลง
                  </Button>
                  <Button
                    color="success"
                    variant="outlined"
                    component={NavLink}
                    to="/Login"
                    sx={{



                      "&:hover": { borderColor: '#4caf50' },
                      fontSize: "18px",
                      width: "45%",
                      marginBottom: "20px",
                      marginLeft: "36px",
                    }}
                  >
                    กลับ
                  </Button>
                </>
              ) : null}
              {id !== "undefined" && <CircularProgress />}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
