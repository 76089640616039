import React, { useEffect, useState, useRef,useContext } from 'react'
import { Box, Typography, Button, FormControl, Select, MenuItem, InputLabel, TextField, Container,Menu } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useNavigate, NavLink, Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import { CSVLink, CSVDownload } from "react-csv";
import TitleHead from '../../components/TitleHead';
import SearchIcon from '@mui/icons-material/Search';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import api from '../../api/api';
import ApiContext from '../../context/ApiProvider';
import Swal from 'sweetalert2'
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#EDEFF0',
        color: theme.palette.common,
        fontWeight: '500'
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        /*  backgroundColor: '#F2F5F8', */
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        // border: 1,
    },
}));


function createData(name, first, lastname, ) {
    return { name, first, lastname, };
  }
  
  const rows = [
    createData('คุณหญิง', 'กุลทรัพย์', "เกษแม่นกิจ",),
    
  ];

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));







export default function Personal_Information() {
    const [data, setdata] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [Check, setCheck] = useState([]);
    const { getBoardDepartmentList, addBoardDepartment } = useContext(ApiContext)
    const fileRef = useRef()


    // console.log("uploadedFiles", uploadedFiles);
    const [open, setOpen] = useState(false);
    useEffect(() => {
        if (open === false) {
            api.get('api/v1.0/personal').then((res) => {

                // setTimeout(() => {
                //     window.location.reload()
                // }, 1000);
                setdata(res.data.result)

            })
        }



    }, [setUploadedFiles, uploadedFiles, setdata])
    // console.log(data);
    const navigate = useNavigate()
    const inputRef = useRef(null);
   

    const handleClose = () => {
        setOpen(false);
    };
    const handleClick = () => {

        // 👇️ open file input box on click of other element
        inputRef.current.click();
        // console.log(inputRef)
    };
    // const handleClick2 = () => {
    //     console.log(fileRef)
    //     // 👇️ open file input box on click of other element
    //     fileRef.current.click()
    // };

    const handleFileChange = async (event) => {
        const fileObj = event.target.files && event.target.files[0];
        // console.log('fileObj is', fileObj);

        let formData = new FormData();
        formData.append("fileUpload", fileObj);
        event.target.value = null;

        const result = await api.post(`api/v1.0/personal/uploads`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },

        }).then((res) => {

            setOpen(true);

            // console.log(res.data.result);
            setUploadedFiles(res.data.result)
        })
        // 👇️ is now empty
        // console.log(event.target.files);

        // 👇️ can still access file object here
        // console.log(fileObj);
        // console.log(fileObj.Title);

        return result;
        // if (!fileObj) {

        //     return;
        // }


        // 👇️ reset file input

    };
    // const headers = [
    //     { label: "Title", },
    //     // { label: "Name", key: "Name" },
    //     // { label: "SurName", key: "SurName" },
    //     // { label: "Position", key: "Position" },
    //     // { label: "Email", key: "Email" },
    //     // { label: "czAddress", key: "czAddress" },
    //     // { label: "czHouseNo", key: "czHouseNo" },
    //     // { label: "czProvince", key: "czProvince" },
    //     // { label: "czDistrict", key: "czDistrict" },
    //     // { label: "czSubDistrict", key: "czSubDistrict" },
    //     // { label: "crAddress", key: "crAddress" },
    //     // { label: "crHouseNo", key: "crHouseNo" },
    //     // { label: "crMoo", key: "crMoo" },
    //     // { label: "crProvince", key: "crProvince" },
    //     // { label: "crDistrict", key: "crDistrict" },
    //     // { label: "crSubDistrict", key: "crSubDistrict" },


    // ]
    // console.log(uploadedFiles);
    const csvData = [
        ["Title", "Name", "SurName", "Position", "Email", "czAddress", 'czHouseNo', "czProvince", "czDistrict", "czSubDistrict", "crAddress", "crHouseNo", "crMoo", "crProvince", "crDistrict", "crSubDistrict"],

    ];

    const idrows = uploadedFiles.filter((item) => item.isChecked === true)
    const handleChange = (e) => {

        const { value, checked } = e.target;
        if (value === 'all') {
            let id = uploadedFiles.map((row) => {
                return { ...row, isChecked: checked };

            });
            setUploadedFiles(id)
        } else {


          let id = uploadedFiles.map((row) =>
          row.runNum == value ? { ...row, isChecked: checked } : row
      )
      setUploadedFiles(id)
        }
    }
        ;
    // con



    // console.log(idrows);

    const sumbit = () => {



        api.post('api/v1.0/personal/create-personal-array', idrows).then((res) => {


            setOpen(false)
            setTimeout(() => {
                window.location.reload()
            },1000);

        })

    }



    return (
        <Container maxWidth='xl' sx={{ height: 'auto', marginTop: '40px', marginBottom: '40px' }}>
            <TitleHead title='ข้อมูลวิทยาการ' subTitle='ข้อมูลทะเบียนประวัติข้อมูลวิทยา'>
                <Box sx={{ display: 'flex', justifyContent: 'right' }}>

                    <input
                        style={{ display: 'none' }}
                        ref={inputRef}
                        type="file"
                        onChange={handleFileChange}
                        encType="multipart/form-data"
                    />
                    <CSVLink data={csvData} style={{ textDecoration: 'none' }}>
                        <Button variant="outlined" sx={{ color: "#4caf50", padding: "7px ", marginTop: "23px", marginRight: '20px' }} color="success" >ดาวน์โหลดเทมเพลต
                            <SaveAltIcon sx={{ paddingLeft: '5px' }} />


                        </Button>
                    </CSVLink>

                    <Divider orientation="vertical" variant="middle" flexItem sx={{ marginRight: '20px', marginTop: '30px' }} />
                    <Button color="success" onClick={handleClick} variant='outlined' sx={{ color: "#4caf50", padding: "7px 55px", marginTop: "23px", marginRight: '20px' }} >นำข้อมูลเข้า</Button>
                    <Button component={NavLink} to='/Scienceinformation/Addformation'  sx={{ backgroundColor: "#1fc64c", '&:hover': { background: '#4caf50' }, color: "#FFFF", padding: "7px 55px", marginTop: "23px" }} state={{ add: true }}>สร้างใหม่</Button>
                </Box>
            </TitleHead>


            <Box sx={{ display: 'flex', color: '#000', marginTop: '20px', marginLeft: '50px', justifyContent: 'right', }}>

                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginBottom: '20px', }}>
                    <FormControl sx={{ m: 1, minWidth: 100, marginRight: 3 }} color="success" >
                        <InputLabel>ทั้งหมด</InputLabel>
                        <Select
                            // open={open}
                            // onClose={handleClose}
                            // onOpen={handleOpen}
                            // defaultValue={1}
                            // value={site}
                            // onChange={handleSelect}
                            label="ทั้งหมด"
                        >
                           <MenuItem value='1'>ชื่อ</MenuItem>
                           <MenuItem value='2'>นามสกุล</MenuItem>
                           <MenuItem value='3'>หลักสูตร</MenuItem>
                           <MenuItem value='4'>วิชาที่สอน</MenuItem>
                        </Select>
                    </FormControl>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <TextField id="outlined-basic" label="กรอกข้อมูลค้นหา" variant="outlined" color="success" />
                        <Button variant='contained' sx={{ padding: '15px', marginLeft: '20px', backgroundColor: '#0fb123', ':hover': { backgroundColor: '#028b13' } }}><SearchIcon /></Button>
                    </Box>
                </Box>
            </Box>
            <TableContainer>
        <Table sx={{ minWidth: 400, borderCollapse: 'inherit', borderSpacing: '0' }}>
          <TableHead sx={{ backgroundColor: "#EDEFF0" }}>
            <TableRow>
              <TableCell sx={{ color: '#000', border: 'none', fontSize: '18px', fontWeight: '500' }} align="left">คำนำหน้าชื่อ</TableCell>
              <TableCell sx={{ color: '#000', border: 'none', fontSize: '18px', fontWeight: '500' }} align="left">ชื่อ</TableCell>
              <TableCell sx={{ color: '#000', border: 'none', fontSize: '18px', fontWeight: '500' }} align="left">นามสกุล</TableCell>
              <TableCell sx={{ color: '#000', border: 'none', fontSize: '18px', fontWeight: '500' }} align="left">หลักสูตร</TableCell>
              <TableCell sx={{ color: '#000', border: 'none', fontSize: '18px', fontWeight: '500' }} align="left">วิชาที่สอน</TableCell>
              <TableCell sx={{ color: '#000', border: 'none', fontSize: '18px', fontWeight: '500' }} align="left">เวลา</TableCell>
            
              <TableCell sx={{ color: '#000', border: 'none', }} align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((board, i) =>
              <>
                <TableRowC key={i} i={i} board={board} />
              </>
            )}

          </TableBody>
        </Table>
      </TableContainer>


            <Dialog
                sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: 500 } }}
                maxWidth="md"
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"ข้อมูลส่วนบุคคลนำข้อมูลเข้า"}
                </DialogTitle>
                <DialogContent>
                    <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
                        <Table sx={{ minWidth: 600 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>


                                    <StyledTableCell align="left" sx={{ fontSize: "18px" }}> <Checkbox onChange={handleChange} value='all' color="success" /></StyledTableCell>
                                    <StyledTableCell align="left" sx={{ fontSize: "18px", fontWeight: "500" }}>คำนำหน้า</StyledTableCell>
                                    <StyledTableCell align="left" sx={{ fontSize: "18px", fontWeight: "500" }}>ชื่อ</StyledTableCell>
                                    <StyledTableCell align="left" sx={{ fontSize: "18px", fontWeight: "500" }}>นามสกุล</StyledTableCell>
                                    <StyledTableCell align="left" sx={{ fontSize: "18px", fontWeight: "500" }}>เลขบัตรประชาชน</StyledTableCell>
                                    <StyledTableCell align="left" sx={{ fontSize: "18px", fontWeight: "500" }}>หมายเหตุ</StyledTableCell>

                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {uploadedFiles.map((row, i) => (
                                    <StyledTableRow
                                        key={i}
                                        /* onClick={() => navigate(`/ProjectSource/SearchPoliticalMinor`)} */
                                        sx={{
                                            fontSize: '18px', cursor: 'pointer', '&:hover': {
                                                background: '#f3f2f2'
                                            }
                                        }}>

                                        <StyledTableCell align="left" fontSize="18px">
                                            <Checkbox color="success"
                                                value={row.runNum}
                                                checked={row?.isChecked || false}
                                                onChange={handleChange}
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell align="left">{row.Title}</StyledTableCell>
                                        <StyledTableCell align="left">{row.Name}</StyledTableCell>
                                        <StyledTableCell align="left">{row.SurName}</StyledTableCell>
                                        <StyledTableCell align="left">{row.CardID}</StyledTableCell>
                                        <StyledTableCell align="left" sx={{ color: 'red' }}>{row.remark}</StyledTableCell>

                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>

                <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                    <Button onClick={handleClose} color="success" variant='outlined' sx={{ color: "#4caf50", width: "20%", marginRight: '10px' }}>
                        กลับ
                    </Button>
                    <Button sx={{ backgroundColor: "#1fc64c", '&:hover': { background: '#4caf50' }, color: "#FFFF", width: "20%" }}
                        onClick={sumbit}
                    >
                        ยืนยัน
                    </Button>
                </Box>

            </Dialog>

        </Container>


    )
}
export function TableRowC({ board, i }) {
    const { getBoardDepartmentList, addBoardDepartment } = useContext(ApiContext)
    const [open, setOpen] = React.useState(false);
    const [titleBoard, setTitleBoard] = useState('')
    const [item, setItem] = useState()
    const [searchDBoard, setSearchDBoard] = useState('')
    const [textSearchDBoard, setTextSearchDBoard] = useState('')
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openM = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClickOpen = (e, item) => {
      setOpen(true);
      setTitleBoard(item?.boardDepName)
      setItem(item)
    };
  
    const handleClose = () => {
      setOpen(false);
      setAnchorEl(null);
    };
    const handleSubmit = () => {
      setAnchorEl(null);
  
      const data = {
        boardDepID: item?.boardDepID || "",
        boardDepName: titleBoard,
        status: true
      }
  
      addBoardDepartment(data, setOpen)
      setOpen(false)
  
      Swal.fire(
        'เเก้ไขเเล้ว',
        // 'success'
      )
    }
    const handleDelet = (e, item) => {
      setAnchorEl(null);
  
      const data = {
        boardDepID: item?.boardDepID,
        boardDepName: titleBoard,
        status: false
      }
      Swal.fire({
        title: 'ต้องการลบหรือไม่ ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'ลบ',
        cancelButtonText: 'ยกเลิก',
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'ลบเเล้ว',
            // 'success'
          )
          addBoardDepartment(data, setOpen)
        }
      })
    }
    return (
      <>
        <Dialog maxWidth='md' fullWidth={true} open={open} onClose={handleClose}>
          <DialogTitle sx={{ fontSize: '3rem' }}>เเก้ไขชุดคณะกรรมการ</DialogTitle>
          <DialogContent sx={{ padding: '10px 60px' }}>
            {/* <DialogContentText>
                To subscribe to this website, please enter your email address here. We
                will send updates occasionally.
              </DialogContentText> */}
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="ชื่อชุดคณะกรรมการ"
              type="text"
              fullWidth
              color="success"
              variant="standard"
              value={titleBoard}
              onChange={e => setTitleBoard(e.target.value)}
              sx={{ '.MuiInputBase-root, .MuiInputLabel-root': { fontSize: '1.5rem' } }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant='outlined' sx={{
              width: '150px', height: 'fit-content', fontSize: '18px', color: '#0fb123', border: '1px solid #0fb123',
              ':hover': { border: '1px solid #028b13' }
            }}>ยกเลิก</Button>
            <Button onClick={handleSubmit} variant='contained' sx={{ width: '150px', height: 'fit-content', fontSize: '18px', marginLeft: '20px', backgroundColor: '#0fb123', ':hover': { backgroundColor: '#028b13' } }}>อัพเดท</Button>
          </DialogActions>
        </Dialog>
        {/* {console.log(board)} */}
        <TableRow key={i}>
          <TableCell sx={{ color: '#000', width: '20%', fontSize: '16px' }} align="left">{board?.name}</TableCell>
          <TableCell sx={{ color: '#000', width: '20%', fontSize: '16px' }} align="left">{board?.first}</TableCell>
          <TableCell sx={{ color: '#000', width: '20%', fontSize: '16px' }} align="left">{board?.lastname}</TableCell>
          <TableCell sx={{ color: '#000', width: '20%', fontSize: '16px' }} align="left">{board?.datea}</TableCell>
          <TableCell sx={{ color: '#000', width: '20%', fontSize: '16px',color:'#0fb123' }} align="left">{board?.status}</TableCell>
          <TableCell sx={{ color: '#000', width: '20%', fontSize: '16px',color:'#0fb123' }} align="left">{board?.status}</TableCell>
  
          <TableCell sx={{ color: '#000', width: '30%', position: 'relative' }} align="right" >
            {/* <Button variant='outlined' sx={{
            height: 'fit-content', fontSize: '18px', color: '#0fb123', border: '1px solid #0fb123',
            ':hover': { border: '1px solid #028b13' }
          }}
            onClick={handleClick}
          // component={NavLink} to={`/Committee/${board?.boardDepID}`} state={{ state: true, title: board?.boardDepName }}
          >ดูทั้งหมด</Button> */}
            < MoreHorizIcon onClick={handleClick} sx={{ cursor: 'pointer', fontSize: '35px' }} />
  
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openM}
              onClose={handleClose}
              // elevation={0}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              sx={{
                '.MuiPopover-paper': {
                  boxShadow: 'unset',
                  border: '1px solid',
                }
              }}
            >
              <MenuItem sx={{ width: '100px', fontSize: '20px' }} component={NavLink} to={`/Committee/${board?.boardDepID}`} onClick={e => localStorage.setItem("title", board?.boardDepName)
              } state={{ state: true, title: board?.boardDepName }}>ดู</MenuItem>
              <MenuItem sx={{ width: '100px', fontSize: '20px' }} onClick={e => handleClickOpen(e, board)}>เเก้ไข</MenuItem>
              <MenuItem sx={{ width: '100px', fontSize: '20px' }} onClick={e => handleDelet(e, board)}>ลบ</MenuItem>
            </Menu>
          </TableCell>
        </TableRow></>
    )
  }
