import { get, startCase } from "lodash";
import * as yup from "yup";

export const isError = (fieldName, formik) =>
  get(formik.touched, fieldName, "") &&
  Boolean(get(formik.errors, fieldName, ""));

export const getErrorMessage = (fieldName, formik) =>
  get(formik.touched, fieldName, "")
    ? get(formik.errors, fieldName, "") || " "
    : " ";
