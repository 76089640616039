import { Autocomplete, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system';
import React, { useContext, useRef } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import TitleHead from '../../components/TitleHead';
import ApiContext from '../../context/ApiProvider';
import { DataGrid, GridToolbarQuickFilter, GridLinkOperator, } from '@mui/x-data-grid';
import moment from 'moment';
import api from '../../api/api'
import CancelIcon from '@mui/icons-material/Cancel';
import Swal from 'sweetalert2'
import withReactContent from "sweetalert2-react-content";
import { NavLink } from 'react-router-dom';
import { DesktopDatePicker } from '@mui/x-date-pickers';
function QuickSearchToolbar() {
    return (
        <Box
            sx={{
                p: 2,
                width: '100%',
                display: 'flex',
                justifyContent: 'end'
            }}
        >
            <GridToolbarQuickFilter
                quickFilterParser={(searchInput) =>
                    searchInput
                        .split(',')
                        .map((value) => value.trim())
                        .filter((value) => value !== '')
                }
            />
        </Box>
    );
}
export default function AddDiploma() {
    const fileRef = useRef()
    const [boardDepartmentList, setBoardDepartmentList] = useState([])
    const [honorCerListCandidate, setHonorCerListCandidate] = useState([])
    const [boardList, setBoardList] = useState([])
    const [boardSelect, setBoardSelect] = useState('')
    const [open, setOpen] = useState(false)
    const [openPopup, setOpenPopup] = useState(false)
    const [datecal, setDateCal] = useState('')
    const [text, setText] = useState('')
    const [mPresent, setMPresent] = useState([])
    // const [date, setDate] = useState(new Date());
    const [value, setValue] = useState({});
    const [value2, setValue2] = useState({});
    const [startDate, setStartDate] = useState(null);
    const [date, setDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const [remark, setRemark] = useState('')
    const [textSelect, setTextSelect] = useState('')
    const [typeSelect, setTypeSelect] = useState('')
    const [certificateID, setCertificateID] = useState('')
    const [certificateData, setCertificateData] = useState([])
    const [certificate, setCertificate] = useState([])
    const { getBoardDepartmentList, getBoardList, getHonorCerListCandidate, FileDownload, getCertificate } = useContext(ApiContext)
    const [files, setFiles] = useState([])
    let formData = new FormData();
    const handleFileUpload = (event) => {
        if (event.target && event.target.files) {
            let file = event.target.files[0];
            // setFiles([...files, file]);
            const maxAllowedSize = 8 * 1024 * 1024;
            if (file.size < maxAllowedSize) {
                formData.append("file", file)
                // console.log(formData)
                // fileUpload(formData, file, files, setFiles)
                api.post('api/v1.0/filemanages/upload', formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }).then((res) => res.data)
                    .then((data) => {
                        if (data.status === true) {
                            // console.log(data.result)
                            setFiles([...files, data.result]);
                        }
                        else {
                            console.log(' failed')
                        }
                    })

            }else {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'ไฟล์อัพโหลดใหญ่เกินไป',
                })
              }
        }

    };
    // const handleText = () => {
    //     setTextSelect(text)
    // }
    // const handleSelet = () => {
    //     setTypeSelect(certificateID)
    // }
    const handleText = () => {
        setTextSelect(value.presentFullName)
        setText('')
        setTimeout(() => {
            setTextSelect('')
        }, 1000);

    }
    const handleSelet = () => {

        setTypeSelect(certificate?.filter((y) => y?.certificateTypeID === value2.certificateTypeID)[0]?.certificateTypeName)
        setCertificateID('')
        setTimeout(() => {
            setTypeSelect('')
            setValue2(null)
        }, 1000);
    }
    const handleRemoveFileUpload = (filename, i) => {
        setFiles(files.length === 1 ? files.filter(file => file.name !== filename) : files.splice(i, 1))
    }
    useEffect(() => {
        getBoardDepartmentList(setBoardDepartmentList, '')
    }, [])
    useEffect(() => {
        const param = { id: boardSelect }
        if (boardSelect !== '') {
            getBoardList(setBoardList, param, '', 1)
        }
    }, [boardSelect])
    useEffect(() => {
        getCertificate(setCertificate)
    }, [])

    const handleGetHonorCerListCandidate = () => {
        const dateFomat = moment(date).format('yyyy-MM-DD')
        getHonorCerListCandidate(setHonorCerListCandidate, dateFomat)
        setDateCal(dateFomat)
        setOpen(true)
    }
    const getMasterPresent = async () => {
        const res = await api.get(`api/v1.0/master/present/list`)
        const data = await res.data
        if (data.status === true) {
            setMPresent(data.result)
            // setValue(data?.result?.filter((y) => y?.presentFullName === x?.present)[0])
            // console.log(data.result);

        }

    }
    useEffect(() => {

        getMasterPresent()
    }, [])
    // console.log(honorCerListCandidate)
    const handleClose = () => {
        setOpenPopup(false);

    };
    const columns = [
        // { field: 'id', headerName: 'ID', width: 50 },
        { field: 'title', headerName: 'คำนำหน้า', width: 120 },
        {
            field: 'firstName',
            headerName: 'ชื่อ',
            width: 150,
            // editable: true,
        },
        {
            field: 'lastName',
            headerName: 'นามสกุล',
            width: 150,
            // editable: true,
        },
        {
            field: 'cerName',
            headerName: 'คุณสมบัติ',
            width: 250,
            // editable: true,
        },
        {
            field: 'oldRankDate',
            headerName: 'ปีที่ได้รับ',
            width: 110,
            // editable: true,
        },
        // {
        //     field: 'rankCounter',
        //     headerName: 'จำนวนการอบรม/ครั้ง',
        //     width: 150,
        //     // editable: true,

        // },
        {
            field: 'meetCounter',
            headerName: 'การเข้ารวมประชุม/ครั้ง',
            width: 150,
            // editable: true,
        },
        {
            field: 'certificate',
            headerName: 'ใบรับรอง',
            width: 300,
            editable: true,

        },
        {
            field: 'present',
            headerName: 'ผู้เสนอ',
            width: 150,

        },



    ];
    const [selectionModel, setSelectionModel] = React.useState([]);

    // console.log(honorCerListCandidate)

    const rows = honorCerListCandidate?.map((x, i) => {
        // console.log(certificateData?.filter((y, i) => y?.personalID === x?.personalID)[0])
        x.id = i + 1
        x.title = x?.title
        x.firstName = x?.firstname
        x.lastName = x?.lastname
        x.cerName = x?.cerName
        x.oldRankDate = x?.oldRankDate ? x?.oldRankDate.includes('-') ?  moment(x?.oldRankDate).format('DD/MM/yyyy').substring(6) === 'd date' ? '' : moment(x?.oldRankDate).add(543, 'year').format('DD/MM/yyyy').substring(6) : x?.oldRankDate :'-'
        // x.rankCounter = ''
        x.meetCounter = x?.meetCounter
        // x.certificate = certificateData?.filter((y) => y?.personalID == x?.personalID)[0]?.certificateID
        x.present = textSelect === '' ? x.present : selectionModel?.filter(y => y === i + 1)[0] == i + 1 ? textSelect : x.present
        x.certificate = typeSelect === '' ? x.certificate : selectionModel?.filter(y => y === i + 1)[0] == i + 1 ? typeSelect : x.certificate
        // x.present = selectionModel?.filter(y => y === i + 1)[0] == i + 1 ? text : x.present
        // x.certificate = typeSelect === '' ? x.certificate : selectionModel?.filter(y => y === i + 1)[0] == i + 1 ? certificate?.filter((x) => x?.certificateTypeID === typeSelect)[0]?.certificateTypeName : x.certificate
        // x.certificate = selectionModel?.filter(y => y === i + 1)[0] == i + 1 ? certificate?.filter((x) => x?.certificateTypeID === certificateID)[0]?.certificateTypeName : x.certificate
        // x.present = certificateData?.filter((y) => y?.personalID == x?.personalID)[0]?.present
        return x

    });
    // console.log(rows)
    // console.log(honorCerListCandidate)
    const handleSubmit = (value) => {
        const body = {
            calDate: moment(datecal).format('YYYY-MM-DD HH:mm'),
            considerDateFirst: moment(startDate).format('YYYY-MM-DD') === 'Invalid date' ? null : moment(startDate).format('YYYY-MM-DD'),
            approveDateFirst: moment(endDate).format('YYYY-MM-DD') === 'Invalid date' ? null : moment(endDate).format('YYYY-MM-DD'),
            // receiveDate: value === 'A' ? moment(new Date()).format('YYYY-MM-DD HH:mm') : null,
            remarkFirst: remark,
            cerDetail: honorCerListCandidate?.map((x, i) => {
                return {

                    cerName: x?.cerName,
                    personalID: x?.personalID,
                    rank: x?.rank,
                    meetCounter: rows?.filter((y, index) => index == i)[0]?.meetCounter || 0,
                    meetTotal: x?.meetTotal,
                    certificateTypeID: certificate?.filter((m) => m?.certificateTypeName === rows?.filter((n, index) => index === i)[0]?.certificate)[0]?.certificateTypeID || null,
                    workStartDate: x?.workStartDate,
                    workEndDate: x?.workEndDate,
                    oldRank: x?.oldRank,
                    present: rows?.filter((y, index) => index == i)[0]?.present || '',
                    status: selectionModel?.filter(y => y === i + 1)[0] == i + 1,
                    isApproveFirst: selectionModel?.filter(y => y === i + 1)[0] == i + 1,
                    isApproveSecond: selectionModel?.filter(y => y === i + 1)[0] == i + 1,
                    isReceive: selectionModel?.filter(y => y === i + 1)[0] == i + 1,

                }
            }),
            cerBoardDep: boardList?.map(x => {
                return {
                    cerBoardDepID: x?.boardDepID,
                    title: x?.personal?.title?.titleNameTH,
                    firstname: x?.personal?.firstname,
                    lastname: x?.personal?.lastname,
                    position: x?.newPosition?.positionName
                }
            }),
            cerFileAttach: files.map((item) =>
            ({
                cerAttachID: "",
                fileID: item.fileID,
                status: true
            })
            ),
            boardDepName: boardDepartmentList.filter((x) => x.boardDepID === boardSelect).map((y) => y.boardDepName)[0],
            status: value
        }
        // console.log(body)
        const postHonorCer = async () => {
            const res = await api.post(`api/v1.0/honor-cer/create`, body)
            const data = await res.data
            // console.log(data)
            if (data.status === true) {
                Swal.fire({
                    title: 'สร้างแล้ว',
                    text: "สร้างข้อมูลสำเร็จ",
                    icon: "success",
                    confirmButtonColor: '#0f962d',
                    confirmButtonText: 'ตกลง',
                    allowOutsideClick: false
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        window.location = `/Diploma`
                    } 
                })
                // const MySwal = withReactContent(Swal)

                // MySwal.fire({
                //     title: <p>สร้างข้อมูลสำเร็จ</p>,

                //     icon: "success",

                //     didOpen: () => {
                //         // `MySwal` is a subclass of `Swal` with all the same instance & static methods

                //         MySwal.clickConfirm();
                //     },
                // }).then(() => {
                //     return MySwal.fire({
                //         title: 'บันทึกแล้ว',
                //         text: "บันทึกข้อมูลสำเร็จ",
                //         icon: "success",
                //         confirmButtonColor: '#0f962d',
                //         confirmButtonText: 'ตกลง',
                //         allowOutsideClick: false
                //     }

                //     ).then((result) => {
                //         if (result.isConfirmed) {
                //             window.location = `/Diploma`
                //         }
                //     });
                
            }
        }
        postHonorCer()
    }
// console.log(boardList)
    return (
        <>
            <Container maxWidth='xl' sx={{ height: 'auto', marginTop: '40px' }}>
                <TitleHead title='การรับประกาศนียบัตรชั้นสูงกิตติมศักดิ์' subTitle='ประวัติการรับประกาศนียบัตรชั้นสูงกิตติมศักดิ์'>
                
                </TitleHead>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <FormControl sx={{ m: 1, minWidth: "29%", marginRight: 3 }}  size='small' color="success" >
                        <InputLabel>เลือกชุดคณะกรรมการพิจารณาประกาศนียบัตรชั้นสูงกิตติมศักดิ์</InputLabel>

                        <Select
                        
                            // open={open}
                            // onClose={handleClose}
                            // onOpen={handleOpen}
                            // defaultValue={1}
                            value={boardSelect}
                            onChange={e => setBoardSelect(e.target.value)}
                            label="เลือกชุดคณะกรรมการพิจารณาประกาศนียบัตรชั้นสูงกิตติมศักดิ์"
                        >
                            {boardDepartmentList?.map((item, i) =>
                                <MenuItem key={i} value={item?.boardDepID}>{item?.boardDepName}</MenuItem>
                            )}
                        </Select>
                    </FormControl>



                    {/* <Box sx={{ display: 'flex', justifyContent: 'space-between' }}> */}
                    <Typography sx={{ color: '#1fc64c' }}>รายชื่อคณะกรรมการพิจารณาทั้งหมด {boardList?.length} ท่าน</Typography>
                    {/* </Box> */}
                </Box>

                <Grid container spacing={2} sx={{ marginTop: '15px', marginBottom: '30px' }}>
                    {boardList?.map((board, i) =>
                        <Grid key={i} item lg={4}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px' }}>
                                <Typography variant='h6'>{`${board?.personal?.title?.titleNameTH} ${board?.personal?.firstname} ${board?.personal?.lastname}`}</Typography>
                                {/* <Typography sx={{ color: '#1fc64c' }}>{board?.newPositionDes}</Typography> */}
                                <Typography sx={{ color: '#1fc64c' }}>{board?.newPosition?.positionName}</Typography>
                            </Box>
                        </Grid>
                    )}

                </Grid>
                <Typography sx={{ marginBottom: '15px' }}>เเนบไฟล์เอกสารที่เกี่ยวข้อง</Typography>
                <input
                    type="file" ref={fileRef} accept="image/*,.pdf,.doc,.docx,.xlsx,.csv,.xls" hidden onChange={handleFileUpload} />
                <Button size='small' variant='outlined' onClick={() => fileRef.current.click()} sx={{
                    height: 'fit-content', fontSize: '18px', color: '#0fb123', border: '1px solid #0fb123',
                    ':hover': { border: '1px solid #028b13' }
                }}>อัพโหลดไฟล์</Button>

                {files?.map((x, i) =>
                    <Box sx={{ width: '120px', display: 'flex', alignItems: 'center', border: '1px solid #0089ff', borderRadius: '15px', padding: '3px 5px 3px 10px', marginRight: '10px', marginTop: '20px' }}>
                        <Typography onClick={() => FileDownload(x)} sx={{
                            height: 'auto',
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: '1',
                            overflow: 'hidden'
                        }}>{x?.filename}</Typography>
                        <CancelIcon onClick={() => handleRemoveFileUpload(x?.firstname, i)} sx={{ color: '#0089ff' }} />
                    </Box>
                    // <Typography key={i} sx={{ marginRight: '15px', cursor: 'pointer', position: 'relative', color: '#0089ff', transition: '.3s', ':after': { content: '""', position: 'absolute', width: '0%', height: '2px', left: '0px', bottom: '0px', transition: '.3s', backgroundColor: '#0089ff' }, ':hover': { color: '#0062b7', ':after': { width: '100%', backgroundColor: '#0062b7' } } }} onClick={() => FileDownload(x)}>{x?.filename}</Typography>
                )}
                <Divider sx={{ marginTop: '40px' }} />
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <DesktopDatePicker
                        label=""
                        inputFormat="DD/MM/YYYY"
                        value={date}
                        // onViewChange={(newValue) }
                        onChange={(newValue) => { setDate(newValue) }}
                        renderInput={(params) => <TextField size='small' color="success" sx={{ width: '200px', marginTop: "23px", display: open ? 'none' : 'block' }}
                            InputProps={params?.InputProps}
                            inputRef={params?.inputRef}
                            // placeholder={params?.inputProps?.placeholder}
                            // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                            // onChange={params?.inputProps?.onChange}
                            // type={params?.inputProps?.type}
                            inputProps={
                                {
                                  placeholder: params?.inputProps?.placeholder,
                                  readOnly: true,
                                  value: params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null,
                                  onChange: params?.inputProps?.onChange,
                                  type: params?.inputProps?.type
                                }
            
                              }
                        />}
                    /><Button variant='contained' onClick={handleGetHonorCerListCandidate} sx={{ display: open ? 'none' : 'block', backgroundColor: "#1fc64c", '&:hover': { background: '#4caf50' }, color: "#FFFF", padding: "7px 55px", marginTop: "23px", marginLeft: '10px' }} >ดึงฐานข้อมูลประวัติ</Button>

                </Box>
                {/* <Button variant='contained' onClick={handleGetHonorCerListCandidate} sx={{ display: open ? 'none' : 'block', backgroundColor: "#1fc64c", '&:hover': { background: '#4caf50' }, color: "#FFFF", padding: "7px 55px", marginTop: "23px" }} >ดึงฐานข้อมูลประวัติ</Button> */}
                <Box>
                <Typography sx={{  display: open ? 'block' : 'none', marginBottom: '15px', marginTop: '40px', fontSize: '18px', color: '#0fb123' }}>วันที่คำนวณ {moment(datecal).add(543, 'year').format('LL')}</Typography>

                    {/* <Typography sx={{ display: open ? 'block' : 'none', marginBottom: '15px', marginTop: '40px', fontSize: '18px', color: '#0fb123' }}>วันที่สร้าง {moment(datecal).format('YYYY-MM-DD HH:mm')}</Typography> */}
                    <Box sx={{ display: selectionModel?.length < 1 ? 'none' : 'flex', marginTop: '20px' }}>
                        {/* <TextField id="outlined-basic" label="กรอกข้อมูลผู้เสนอ" value={text} onChange={e => setText(e.target.value)} variant="outlined" color="success" /> */}
                        <Autocomplete
                            size='small'
                            value={value}
                            onChange={(event, newValue) => {
                                setValue(newValue)
                            }}
                            options={mPresent}
                            getOptionLabel={option => option?.presentFullName ? option?.presentFullName : ''}
                            renderInput={(params) => <TextField {...params} placeholder='เลือกผู้เสนอ' sx={{width:'400px'}} />}
                        />
                        <Button variant='contained' onClick={handleText} sx={{ backgroundColor: "#1fc64c", '&:hover': { background: '#4caf50' }, color: "#FFFF", }}>ตกลง</Button>
                        <Autocomplete
                            size='small'
                            value={value2}
                            onChange={(event, newValue) => {
                                setValue2(newValue)
                            }}
                            options={certificate}
                            getOptionLabel={option => option?.certificateTypeName?.substring(0, 49) ? option?.certificateTypeName?.substring(0, 49) : ''}
                            renderInput={(params) => <TextField {...params} placeholder='เลือกใบรับรอง' sx={{width:'400px', marginLeft:'20px'}} />}
                        />
                        {/* <FormControl sx={{ minWidth: "20%", marginLeft: 3 }} color="success" >
                            <InputLabel>เลือกใบรับรอง</InputLabel>
                            <Select
                                // open={open}
                                // onClose={handleClose}
                                // onOpen={handleOpen}
                                // defaultValue={1}
                                value={certificateID}
                                onChange={e => setCertificateID(e.target.value)}
                                label="ใบรับรอง"
                            >
                                {certificate?.map((item, i) =>
                                    <MenuItem key={i} value={item?.certificateTypeID}>{item?.certificateTypeName.substring(0, 50)}</MenuItem>
                                )}
                            </Select>
                        </FormControl> */}
                        <Button variant='contained' onClick={handleSelet} sx={{ backgroundColor: "#1fc64c", '&:hover': { background: '#4caf50' }, color: "#FFFF" }}>ตกลง</Button>

                    </Box>
                </Box>

                <Box sx={{ height: 600, width: '100%', margin: '40px 0', display: open ? 'block' : 'none', }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[5]}
                        checkboxSelection
                        disableSelectionOnClick
                        disableColumnMenu
                        experimentalFeatures={{ newEditingApi: true }}
                        onSelectionModelChange={(newSelectionModel) => {
                            setSelectionModel(newSelectionModel);
                        }}
                        selectionModel={selectionModel}
                        initialState={{
                            filter: {
                                filterModel: {
                                    items: [],
                                    quickFilterLogicOperator: GridLinkOperator.Or,
                                },
                            },
                        }}
                        components={{ Toolbar: QuickSearchToolbar }}
                    />
                </Box>
                <Box sx={{ display: open ? 'none' : 'block', height: '20vh' }}></Box>
            </Container>
            <Box sx={{
                position: 'sticky', bottom: '0px', width: '100%', marginTop: '50px', zIndex: '100', background: '#fff'
            }}>
                <Divider sx={{ marginBottom: '10px' }} />
                <Container maxWidth='xl' sx={{ height: 'auto', }}>
                    <Box sx={{ padding: '5px 20px', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant='outlined' component={NavLink} to={`/Diploma`} sx={{
                            width: '150px', height: 'fit-content', fontSize: '18px', color: '#0fb123', border: '1px solid #0fb123',
                            ':hover': { border: '1px solid #028b13' }
                        }} >กลับ</Button>
                        {open && boardSelect ? <>
                            <Button onClick={() => handleSubmit('D')} variant='contained' type='submit' sx={{ width: '150px', height: 'fit-content', fontSize: '18px', marginLeft: '20px', backgroundColor: '#0fb123', ':hover': { backgroundColor: '#028b13' } }}>บันทึก</Button>
                            <Button onClick={() => setOpenPopup(true)} variant='contained' type='submit' sx={{ width: '150px', height: 'fit-content', fontSize: '18px', marginLeft: '20px', }}>อนุมัติขั้นต้น</Button>
                                </> : ''
                        }
                    </Box>
                </Container>
                <Dialog
                    maxWidth="md"
                    fullWidth={true}
                    open={openPopup}
                    onClose={handleClose}
                >
                    <DialogTitle sx={{ fontSize: "2rem" }}>อนุมัติขั้นต้น</DialogTitle>
                    <Divider sx={{ marginBottom: '20px' }} />
                    <DialogContent sx={{ padding: "10px 60px" }}>
                        <Box sx={{ height: 'auto', width: '100%', }}>
                            <Grid container spacing={2}>
                                <Grid item lg={2} md={3} xs={12} sx={{ display: 'flex', alignItems: 'start', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>
                                    <Typography sx={{ marginRight: '15px', }}>วันที่พิจารณา</Typography>
                                </Grid>


                                <Grid item lg={9} md={9} xs={12}>

                                    <DesktopDatePicker
                                        label=""
                                        inputFormat="DD/MM/YYYY"
                                        value={startDate}
                                        maxDate={endDate ? new Date(endDate) : ''}
                                        // onViewChange={(newValue) }
                                        onChange={(newValue) => { setStartDate(newValue) }}
                                        renderInput={(params) => <TextField size='small' color="success" sx={{ width: '100%' }}
                                            InputProps={params?.InputProps}
                                            inputRef={params?.inputRef}
                                            // placeholder={params?.inputProps?.placeholder}
                                            // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                                            // onChange={params?.inputProps?.onChange}
                                            // type={params?.inputProps?.type}
                                            inputProps={
                                                {
                                                  placeholder: params?.inputProps?.placeholder,
                                                  readOnly: true,
                                                  value: params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null,
                                                  onChange: params?.inputProps?.onChange,
                                                  type: params?.inputProps?.type
                                                }
                            
                                              }
                                        />}
                                    />

                                </Grid>
                                <Grid item lg={2} md={3} xs={12} sx={{ display: 'flex', alignItems: 'start', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>
                                    <Typography sx={{ marginRight: '15px', }}>วันที่อนุมัติ</Typography>
                                </Grid>


                                <Grid item lg={9} md={9} xs={12}>

                                    <DesktopDatePicker
                                        label=""
                                        inputFormat="DD/MM/YYYY"
                                        value={endDate}
                                        minDate={new Date(startDate)}
                                        onChange={(newValue) => { setEndDate(newValue) }}
                                        renderInput={(params) => <TextField size='small' color="success" sx={{ width: '100%' }}
                                            InputProps={params?.InputProps}
                                            inputRef={params?.inputRef}
                                            // placeholder={params?.inputProps?.placeholder}
                                            // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                                            // onChange={params?.inputProps?.onChange}
                                            // type={params?.inputProps?.type}
                                            inputProps={
                                                {
                                                  placeholder: params?.inputProps?.placeholder,
                                                  readOnly: true,
                                                  value: params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null,
                                                  onChange: params?.inputProps?.onChange,
                                                  type: params?.inputProps?.type
                                                }
                            
                                              }
                                        />}
                                    />

                                </Grid>

                                <Grid item lg={2} md={3} xs={12} sx={{ display: 'flex', alignItems: 'start', justifyContent: { sm: 'flex-end', xs: 'flex-start' } }}>
                                    <Typography sx={{ marginRight: '15px', }}>เหตุผล</Typography>
                                </Grid>


                                <Grid item lg={9} md={9} xs={12}>

                                    <TextField label="" size='small' value={remark} onChange={e => setRemark(e.target.value)} multiline rows={6} variant="outlined" color="success" type="text" sx={{ width: '100%' }} />

                                </Grid>
                            </Grid>
                        </Box>

                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'center' }}>
                        <Button
                            onClick={handleClose}
                            variant="outlined"
                            sx={{
                                width: "150px",
                                height: "fit-content",
                                fontSize: "18px",
                                color: "#0fb123",
                                border: "1px solid #0fb123",
                                ":hover": { border: "1px solid #028b13" },
                            }}
                        >
                            ยกเลิก
                        </Button>
                        {startDate && endDate ?
                        <Button
                            onClick={() => handleSubmit('P')}
                            variant="contained"
                            sx={{
                                width: "150px",
                                height: "fit-content",
                                fontSize: "18px",
                                marginLeft: "20px",
                                backgroundColor: "#0fb123",
                                ":hover": { backgroundColor: "#028b13" },
                            }}
                        >
                            ยืนยัน
                        </Button>:''}
                    </DialogActions>
                </Dialog>
            </Box>
        </>
    )
}
