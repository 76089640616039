import { Api } from '@mui/icons-material';
import { Autocomplete, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, TextField, Typography, Checkbox } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import api from '../../api/api';
import Swal from "sweetalert2";
export default function DialogBreakCalPEdite({ open, setOpen, x, setListBreakCalPersonal, id, fetdata }) {

    const [startDate, setStartDate] = useState(x?.startDate);
    const [endDate, setEndDate] = useState(x?.endDate);
    const [remark, setRemark] = useState(x?.remark);
    const [value, setValue] = useState({});
    const [checked, setMPosition] = useState(false);
    const [present, setPresent] = useState(x?.present)
    const [mPresent, setMPresent] = useState([])

    const body = {
        breakCalID: x?.breakCalID,
        personalID: x?.personalID,
        startDate: moment(startDate).format('YYYY-MM-DD') === 'Invalid date' ? moment(new Date()).format('YYYY-MM-DD') : moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format("YYYY-MM-DD") === "Invalid date" ? null : moment(endDate).format("YYYY-MM-DD"),
        remark: remark,
        // position: {
        //     positionName: value?.positionName
        // },
        present: value?.presentFullName,

        status: true
    }
    const handleClose = () => {
        setOpen(false);

    };
    const getMasterPresent = async () => {
        const res = await api.get(`api/v1.0/master/present/list`)
        const data = await res.data
        if (data.status === true) {
            setMPresent(data.result)
            setValue(data?.result?.filter((y) => y?.presentFullName === x?.present)[0])
            // console.log(data.result);

        }

    }
    const handleClick = () => {
        const postCreatebreakcal = async () => {
            const res = await api.post(`api/v1.0/personal/createbreakcal-personal`, body)
            const data = await res.data
            if (data.status === true) {
                // console.log(data.result);

                Swal.fire(
                    "สร้างเเล้ว"
                    // 'success'
                );
                setTimeout(() => {
                    fetdata()
                }, 1000);


                setOpen(false);



            }

        }
        postCreatebreakcal()
    }

    useEffect(() => {

        getMasterPresent()
    }, [])
    // console.log(workexperinceDetail)
    return (
        <Dialog fullWidth maxWidth='md' open={open} onClose={handleClose}>
            <DialogTitle sx={{ margin: '10px 40px' }}>เเก้ไขการลดสิทธิ</DialogTitle>
            <Divider />
            <DialogContent sx={{ margin: {md:'10px 100px', xs:'10px 30px'} }}>
                <Grid container spacing={2}>
                    <Grid item lg={3}>
                        <Typography sx={{ marginRight: '40px' }}>วันที่</Typography>
                    </Grid>
                    <Grid item lg={4} xs={12}>
                        <DesktopDatePicker
                            label=""
                            inputFormat="DD/MM/YYYY"
                            value={startDate}
                            maxDate={endDate ? new Date(endDate) : ''}
                            onChange={(newValue) => { setStartDate(newValue) }}
                            renderInput={(params) => <TextField size='small' color="success" sx={{ width: '100%' }}
                                InputProps={params?.InputProps}
                                inputRef={params?.inputRef}
                                // placeholder={params?.inputProps?.placeholder}
                                // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                                // onChange={params?.inputProps?.onChange}
                                // type={params?.inputProps?.type}
                                inputProps={
                                    {
                                        placeholder: params?.inputProps?.placeholder,
                                        readOnly: true,
                                        value: params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null,
                                        onChange: params?.inputProps?.onChange,
                                        type: params?.inputProps?.type
                                    }

                                }
                            />}
                        />
                    </Grid>
                    <Grid item lg={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Typography>~</Typography></Grid>
                    <Grid item lg={4} xs={12}>
                        <DesktopDatePicker
                            label=""
                            inputFormat="DD/MM/YYYY"
                            value={endDate}
                            minDate={new Date(startDate)}
                            onChange={(newValue) => { setEndDate(newValue) }}
                            renderInput={(params) => <TextField size='small' color="success" sx={{ width: '100%' }}
                                InputProps={params?.InputProps}
                                inputRef={params?.inputRef}
                                // placeholder={params?.inputProps?.placeholder}
                                // value={params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null}
                                // onChange={params?.inputProps?.onChange}
                                // type={params?.inputProps?.type}
                                inputProps={
                                    {
                                        placeholder: params?.inputProps?.placeholder,
                                        readOnly: true,
                                        value: params?.inputProps?.value ? params?.inputProps?.value?.substring(0, params?.inputProps?.value?.length - 4) + `${+params?.inputProps?.value?.substring(params?.inputProps?.value?.length - 4) + 543}` : null,
                                        onChange: params?.inputProps?.onChange,
                                        type: params?.inputProps?.type
                                    }

                                }
                            />}
                        />
                    </Grid>

                    <Grid item lg={3}>
                        <Typography sx={{ marginRight: '40px' }}>รายละเอียด</Typography>
                    </Grid>
                    <Grid item lg={9} xs={12}>
                        <TextField
                            size='small'
                            fullWidth
                            multiline
                            rows={4}
                            variant="outlined"
                            value={remark}
                            onChange={e => setRemark(e.target.value)}
                        />
                    </Grid>
                    <Grid item lg={3}>
                        <Typography sx={{ marginRight: '40px' }}>ผู้เสนอ</Typography>
                    </Grid>
                    <Grid item lg={9} xs={12}>
                        {/* <TextField
                            size='small'
                            fullWidth
                            variant="outlined"
                            value={present}
                            onChange={e => setPresent(e.target.value)}
                        /> */}
                        <Autocomplete
                            size='small'
                            fullWidth
                            value={value}
                            onChange={(event, newValue) => {
                                setValue(newValue)
                            }}
                            options={mPresent}
                            getOptionLabel={option => option?.presentFullName ? option?.presentFullName : ''}
                            renderInput={(params) => <TextField {...params} placeholder='เลือกผู้เสนอ' />}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center' }}>
                <Button variant='outlined' sx={{ borderColor: "#1fc64c", '&:hover': { borderColor: '#4caf50' }, color: "#1fc64c", padding: "7px 55px", marginBottom: '20px' }} onClick={handleClose}>กลับ</Button>
                {value !== null && startDate !== null ? <Button onClick={handleClick} sx={{ backgroundColor: "#1fc64c", '&:hover': { background: '#4caf50' }, color: "#FFFF", padding: "7px 55px", marginBottom: '20px' }}>บันทึก</Button> : ''}
            </DialogActions>
        </Dialog>

    )
}
