import { Box, Button, Container, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { loginAsync } from "../store/slices/authSlice";
// import { ProfileAsync } from '../store/slices/profileSlice'
import kpi from "../assets/image/KPI-LOGO-PNG-Size-S.png";
import background from "../assets/image/bgstyle_3.jpg";
import background1 from "../assets/image/Main.jpg"
// import background from "../assets/image/19n_1_Ecer_Background_18.png";
import { Link } from "react-router-dom";

export default function Login() {
  // const { user, loading, error } = useSelector(state => state.auth)

  const dispatch = useDispatch();
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        backgroundImage: `url(${background1})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
        display: "flex",
        alignItems: "center",
      }}
    >
      <Container maxWidth="sm">
        <Box
          sx={{
            padding: "20px 100px",
            borderRadius: "15px",
            background: "#fff",
          }}
        >
          <Box
            sx={{ display: "flex", justifyContent: "center", margin: "20px 0" }}
          >
            <img src={kpi} width="120px" height="auto" alt="" />
          </Box>
          <Typography
            variant="h4"
            sx={{ color: "#1C6E43", fontWeight: "bold", marginBottom: "20px" }}
          >
            เข้าสู่ระบบ
          </Typography>
          <Formik
            initialValues={{
              username: "",
              password: "",
              submit: null,
            }}
            validationSchema={Yup.object().shape({
              // username: Yup.string().max(255).required('กรุณาระบุ Username'),
              // password: Yup.string().max(255).required('กรุณาระบุ Password')
            })}
            onSubmit={async (
              value,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                // console.log(value)
                dispatch(loginAsync(value));

                setStatus({ success: true });
                setSubmitting(false);
              } catch (err) {
                console.log(err);
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  label=""
                  required
                  variant="outlined"
                  color="success"
                  placeholder="Username"
                  type="text"
                  // ref={userRef}
                  autoComplete="off"
                  name="username"
                  value={values.username}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  sx={{ width: "100%", marginBottom: "20px" }}
                />

                <TextField
                  label=""
                  required
                  variant="outlined"
                  color="success"
                  placeholder="Password"
                  type="password"
                  value={values.password}
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  sx={{ width: "100%", marginBottom: "20px" }}
                />
                <Link to="/ForgotPassword"  style={{textDecorationColor:'#0089ff'}} >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      display:'flex',
                      justifyContent:'flex-end',
                      color: "#0089ff",
                      "&:hover": { color: "#0089ff" },
                      transition: "0.3s",
                    }}
                  >
                    {" "}
                    Forgot Password ?
                  </Typography>
                </Link>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    padding: "15px",
                    margin: "20px 0",
                    width: "100%",
                    backgroundColor: "#1C6E43",
                    ":hover": { backgroundColor: "#1C6E43" },
                  }}
                >
                  เข้าสู่ระบบ
                </Button>
              </form>
            )}
          </Formik>
          <Typography sx={{ textAlign: "center", color: "#9d9d9d" }}>
            version 0.01
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
